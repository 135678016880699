import {Injectable} from '@angular/core';
import {UsersService} from './users.service';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(private userService: UsersService) {
  }

  getLocale(): string {
    if (this.userService.business) {
      return this.userService.business.locale;
    }
    return 'en-US'; // Default locale
  }
}
