import { Pipe, PipeTransform } from '@angular/core';

// https://stackoverflow.com/questions/38554562/how-can-i-use-ngfor-to-iterate-over-typescript-enum-as-an-array-of-strings


@Pipe({
    name: 'enumStrToArray'
})
export class EnumStrToArrayPipe implements PipeTransform {
    transform(data: Object) {
        return Object.keys(data);
    }
}
