import {Component, OnDestroy, OnInit} from '@angular/core';
import {TitleService} from 'src/app/services/title.service';
import {ServiceSheetService} from '../../services/service-sheets.service';
import {ServiceSheetWorkSession} from '../../models/servicesheets';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {UsersService} from '../../services/users.service';
import {User} from '../../models/user';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  isMobile = false;
  unfinishedWorkSessions: ServiceSheetWorkSession[];

  userMe$: Observable<User>;

  private destroy$ = new Subject<void>();

  constructor(
    private titleService: TitleService,
    private serviceSheetService: ServiceSheetService,
    private usersService: UsersService
  ) {
    this.isMobile = window.innerWidth < 768;
    this.userMe$ = this.usersService.userMe$;
  }

  getTotalTimeString(time: number): string {
    return new Date(time * 1000).toISOString().substr(11, 8);
  }

  ngOnInit(): void {
    this.titleService.setTitleTranslated('DASHBOARD');

    this.serviceSheetService.getUnfinishedWorkSessionsTimeTracker()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // tslint:disable-next-line:no-string-literal
      this.unfinishedWorkSessions = data['results'];
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
