/* tslint:disable:no-string-literal */


export const posTicketFields = {
  createdAt: {
    I18N_KEY: 'DATE',
    TYPE: 'date_time',
  },
  shortPrettyId: {
    I18N_KEY: 'ID',
    TYPE: 'text',
  },
  shortDescription: {
    I18N_KEY: 'DESCRIPTION',
    TYPE: 'anchor',
  },
  roPaymentStatus: {
    I18N_KEY: 'PAYMENTS.PAYMENT_STATUS',
    TYPE: 'templated',
  },
  roPaymentMethod: {
    I18N_KEY: 'PAYMENTS.PAYMENT_METHOD',
    TYPE: 'templated',
  },
  invoiceNumber: {
    I18N_KEY: 'INVOICE',
    TYPE: 'text',
  },
  itemCount: {
    I18N_KEY: 'ITEM_COUNT',
    TYPE: 'number',
  },
  totalAmountWithTax: {
    I18N_KEY: 'TOTAL_COST',
    TYPE: 'currency',
  },
  budgetStatus: {
    I18N_KEY: 'CTX_SERVICE_SHEETS.BUDGET_STATUS',
    TYPE: 'templated',
  }
};

