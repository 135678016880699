<div fxLayout="column" class="py1">
  <file-pond
    #myPond
    [options]="pondOptions"
    [files]="pondFiles"
    (oninit)="pondHandleInit()"
    (onaddfile)="pondHandleAddFile($event)"
  >
  </file-pond>

  <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <div *ngFor="let file of bssImages" class="framed" fxLayout="column" fxFlex="45" fxLayoutGap="10px">
      <img  [src]="_getUrlToLoad(file.file)" alt="" />
      <div fxLayout="row" fxLayoutAlign="end">
        <mat-icon class="mr1 pointer" (click)="downloadFile(file)">file_download</mat-icon>
        <mat-icon class="mr1 pointer text-danger" (click)="deleteFile(file)">delete</mat-icon>
      </div>
    </div>
  </div>

</div>
