import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {
  title: string;
  message: string;

  confirmLiteral: string;
  confirmIcon: string;
  confirmColor: ThemePalette;

  cancelLiteral: string;
  cancelIcon: string;
  cancelColor: ThemePalette;

  constructor(
    public dialog: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogModel) {
      console.log('DEPRECATED!!! ConfirmationDialogComponent, use ConfirmDialogComponent instead');

      // Update view with given values
      this.title = data.title;
      this.message = data.message;
      this.confirmLiteral = data.confirmLiteral ?? 'Yes';
      this.confirmIcon = data.confirmIcon ?? 'check';
      this.confirmColor = data.confirmColor ?? null;

      this.cancelLiteral = data.cancelLiteral ?? 'No';
      this.cancelIcon = data.cancelIcon ?? 'cancel';
      this.cancelColor = data.cancelColor ?? 'warn';
      if (data.cancelColor == null && this.confirmColor === 'warn') {
        this.cancelColor = null;
      }
    }

  closeDialog(): void {
    this.dialog.close(false);
  }
  confirmDialog(): void {
    this.dialog.close(true);
  }

  ngOnInit(): void {
  }

}

export class ConfirmationDialogModel {

  constructor(
    public message: string,
    public title?: string,
    public confirmLiteral?: string,
    public confirmIcon?: string,
    public confirmColor?: ThemePalette,
    public cancelLiteral?: string,
    public cancelIcon?: string,
    public cancelColor?: ThemePalette
    ) {
  }
}
