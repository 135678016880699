import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  DetailField,
  DetailFieldTypes,
  ItemDetailAbstractComponent
} from '../../components/abstract-item-detail/abstract-item-detail.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {PosCategoryProductService} from '../../services/pos-category-product.service';
import {PosCategoryProduct} from '../../models/pos_category_product';


@Component({
  selector: 'app-pos-category-detail',
  templateUrl: '../../components/abstract-item-detail/abstract-item-detail.component.html',
  styleUrls: ['../../components/abstract-item-detail/abstract-item-detail.component.scss']
})
export class PosCategoriesDetailComponent extends ItemDetailAbstractComponent<PosCategoryProduct> implements OnInit {
  // TODO: enable fully barcode scanner if fully is present
  // TODO: check how we can read barcodes on web without fully

  navBase = '/pos/categories/';

  editableFields = {
    name: new DetailField('NAME', DetailFieldTypes.text, '', ['start', 0, 75], [Validators.required]),
    defaultLifetimeDays: new DetailField('DEFAULT_LIFETIME_DAYS', DetailFieldTypes.number, '', ['start', 1, 50]),
    defaultLifetimeKm: new DetailField('DEFAULT_LIFETIME_KM', DetailFieldTypes.number, '', ['start', 1, 50]),
  };

  constructor(
    @Optional() public dialogRef: MatDialogRef<PosCategoriesDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { itemId: string, extraMessageI18nKey: string, code: string },
    protected router: Router,
    protected route: ActivatedRoute,
    protected notificationService: CrmNotificationsService,
    protected translate: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected nativeInterfacesService: NativeInterfacesService,
    protected posCategoryProductService: PosCategoryProductService,
    protected usersService: UsersService
  ) {
    super(dialogRef,
      data,
      router,
      route,
      notificationService,
      translate,
      formBuilder,
      dialog,
      posCategoryProductService,
      nativeInterfacesService,
      usersService);

    // https://stackoverflow.com/questions/38971660/angular-2-reload-route-on-param-change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

}
