import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Fab, FabTypes} from './fab-interface';
import {animate, query, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-custom-fab',
  templateUrl: './fab-custom.component.html',
  styleUrls: ['./fab-custom.component.scss'],
  // TODO: Document
  animations: [
    trigger('spinInOut', [
      state('in', style({transform: 'rotate(0)', opacity: '1'})),
      transition(':enter', [
        style({transform: 'rotate(-180deg)', opacity: '0'}),
        animate('150ms ease')
      ]),
      transition(':leave', [
        animate('150ms ease', style({transform: 'rotate(180deg)', opacity: '0'}))
      ]),
    ]),
    trigger('preventInitialAnimation', [
      transition(':enter', [
        query(':enter', [], {optional: true})
      ]),
    ]),
  ],
})
export class FabCustomComponent implements OnInit, AfterViewInit {
  // TODO: for now we only support one multipleAction as the dial (in the template) has a hardcoded id #dial

  @Input() primaryFab: Fab;
  @Input() secondaryFab: Fab;
  @Output() fabAction: EventEmitter<string> = new EventEmitter<string>();

  // tslint:disable-next-line:variable-name
  _fabs: Fab[];

  constructor() {}

  ngOnInit(): void {
    this._fabs = [this.secondaryFab, this.primaryFab];
  }

  ngAfterViewInit(): void {
  }

  onAction(actionId: string): boolean {
   this.fabAction.emit(actionId);
   return true;
  }

  getFabTypes(): typeof FabTypes {
    return FabTypes;
  }

}
