import {Component, Input} from '@angular/core';
import {environment} from 'src/environments/environment';


@Component({
  selector: 'app-image-kit-image',
  templateUrl: './image-kit-image.component.html',
  styleUrls: ['./image-kit-image.component.scss']
})
export class ImageKitImageComponent {
  // TODO: use height also for image thumbnail?
  @Input() w: number;
  @Input() h: number;

  // https://angular.io/guide/component-interaction#!#parent-to-child-setter
  @Input()
  get imgSrc(): string { return this.src; }
  set imgSrc(imgSrc: string) {
    // Default blurred image:
    // console.log(imgSrc);
    this.finalImageLoaded = false;
    this._origSsrc = imgSrc;
    this.src = `${this._getUrlToLoad(imgSrc)}?tr=bl-10:w-${Math.floor(this.w / 3)}`;
  }

  // TODO improve error url:
  @Input() errSrc = `/assets/brands/${environment.brand}/images/logo_square.jpg`;
  @Input() classes = 'rounded';
  @Input() objectFit = 'contain';

  // tslint:disable-next-line:variable-name
  _origSsrc: string;
  src: string;
  finalImageLoaded = false;

  constructor() {}

  imgError(): void {
    // TODO: notify to sentry
    this.src = this.errSrc;
  }

  _getUrlToLoad(url: string): string {
    if (url == null) {
      return '';
    }
    let u = url.replace('https://bikecrmstatic.s3.amazonaws.com/media/', environment.imageKitUrlEndpoint);
    u = u.replace('https://bikecrmstatic-beta.s3.amazonaws.com/media/', environment.imageKitUrlEndpoint);
    return u;
  }

  imgLoaded(): void {
    if (this.finalImageLoaded) {
      return;
    }

    this.src = `${this._getUrlToLoad(this._origSsrc)}?tr=w-${this.w}`;
    this.finalImageLoaded = true;
  }

}
