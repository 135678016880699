import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {TitleService} from 'src/app/services/title.service';
import {
  AbstractParentDetailComponent,
  ParentItemAction
} from '../../components/abstract-parent-detail/abstract-parent-detail.component';
import {UsersService} from '../../services/users.service';
import {Fab, FabAction, FabTypes} from '../../components/fab-custom/fab-interface';
import {PaymentStatusCardComponent} from '../../components/payment-status-card/payment-status-card.component';
import {PosSupplierOrdersService} from '../../services/pos-supplier-orders.service';
import {SupplierOrder, SupplierOrderItem} from '../../models/pos_supplier_order';
import {takeUntil} from 'rxjs/operators';
import {
  PosSupplierOrderItemListComponent
} from '../../components/pos-supplier-order-item-list/pos-supplier-order-item-list.component';
import {AppMetricsService} from '../../services/app-metrics.service';


@Component({
  selector: 'app-pos-supplier-orders-detail',
  templateUrl: '../../components/abstract-parent-detail/abstract-parent-detail.component.html',
  styleUrls: ['../../components/abstract-parent-detail/abstract-parent-detail.component.scss']
})
export class PosSupplierOrdersDetailComponent extends AbstractParentDetailComponent<SupplierOrder> implements OnInit, OnDestroy {

  constructor(
    protected titleService: TitleService,
    protected translate: TranslateService,
    protected notificationService: CrmNotificationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected router: Router,
    protected dialog: MatDialog,
    protected progressDialog: MatDialog,
    protected route: ActivatedRoute,
    protected posSupplierOrdersService: PosSupplierOrdersService,
    protected usersService: UsersService,
    private resolver: ComponentFactoryResolver,
    public native: NativeInterfacesService,
    public appMetricsService: AppMetricsService
  ) {
    super(
      titleService,
      translate,
      notificationService,
      nativeInterfacesService,
      router,
      dialog,
      progressDialog,
      route,
      posSupplierOrdersService,
      usersService,
      appMetricsService
    );
  }

  navBase = '/pos/supplier_orders/';

  titleI18N = 'SUPPLIER_ORDER';
  titleIcon = 'warehouse';

  @ViewChild(PosSupplierOrderItemListComponent) childItemListRef: PosSupplierOrderItemListComponent;
  @ViewChild('childListHost', {read: ViewContainerRef, static: false}) childListHost;
  @ViewChild('paymentCard') paymentCard: PaymentStatusCardComponent;

  primaryFab = new Fab(
    'ADD_PRODUCT',
    'add',
    'fab_add_product',
    FabTypes.multipleAction,
    [
      new FabAction('SEARCH_PRODUCT', '', 'search_product'),
      new FabAction('ADD_BY_CODE', '', 'add_by_code'),
      new FabAction('ADD_PRODUCT_MANUALLY', '', 'add_item_manually')
    ]);
  secondaryFab = null;

  actions = [
    // new ParentItemAction('PRINT_TICKET', '', 'print_ticket'),
    // new ParentItemAction('ASSIGN_TICKET_TO_CLIENT', '', 'assign_ticket_to_client'),
    // new ParentItemAction('PRINT_INVOICE', '', 'print_invoice'),
    new ParentItemAction('DELETE', '', 'delete_order'),
  ];

  loadChildListComponent(): void {
    // TODO: migrate use componentfactory to viewcontaineref.createcomponent
    //    https://stackoverflow.com/a/70947152/888245
    // Dynamically create the component, so we don't need multiple templates for different types of lists
    this.childListHost.clear();
    const factory: ComponentFactory<PosSupplierOrderItemListComponent> = this.resolver.resolveComponentFactory(PosSupplierOrderItemListComponent);
    this.childListRefComponentRef = this.childListHost.createComponent(factory);
    this.childListRefComponentRef.instance.parentApiContentTypeName = 'supplierorder';
    this.childListRefComponentRef.instance.parent = this.parentItem;
    this.childItemListRef = this.childListRefComponentRef.instance;

    // subscribe to child output event, remember to unsubscribe on destroy
    this.childListRefComponentRef.instance.itemsChange.subscribe(val => {
      this.parentItem.totalAmountWithTax = this.childListRefComponentRef.instance.getTotalCost();
      this.paymentCard.updatePaymentStatus();
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    // TODO: move to an abstract class as 'title' or 'page title' or something on those lines:
    this.titleService.setTitleTranslated(null);

    if (this.nativeInterfacesService.hasCamera) {
      this.secondaryFab = new Fab('', 'qr_code_scanner', 'fab_add_by_cam_code_scanner', FabTypes.singleAction);
    }
  }

  selectedDocumentType(documentType: string): void {
    this.posSupplierOrdersService.modify(this.parentItem.id, {documentType}).pipe(takeUntil(this.onDestroy$)).subscribe(
      x => this.parentItem = x
    );
  //   TODO: feedback use that the document type has been changed
  }

  changedDocumentNumber(): void {
    this.posSupplierOrdersService.modify(this.parentItem.id, {documentNumber: this.parentItem.documentNumber}).pipe(takeUntil(this.onDestroy$)).subscribe(
      x => this.parentItem = x
    );
  }

  createNewParentItem(): void {
    // TODO: try to not override all the parent item, just the fields we need
    const formData = new FormData();

    const supplierID = this.route.snapshot.paramMap.get('parentId');
    formData.append('supplier', supplierID);

    this.parentItemService.create(formData).pipe(takeUntil(this.onDestroy$)).subscribe(
      x => this.router.navigate([`${this.navBase}${x.id}`], {replaceUrl: true})
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  addOrderItem(orderItem?: SupplierOrderItem): void {
    this.childItemListRef.addItemEnd(orderItem);
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'search_product') {
      this.childItemListRef.searchExistingProductDialog();
      return true;
    }
    if (actionId === 'add_by_code') {
      this.childItemListRef.addByCodeDialog();
      return true;
    }
    if (actionId === 'add_item_manually') {
      this.addOrderItem();
      return true;
    }
    if (actionId === 'fab_add_by_cam_code_scanner') {
      this.childItemListRef.startCamBarcodeScanner();
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  onAction(actionId: string): boolean {
    // if (actionId === 'print_ticket') {
    //   this.printTicket();
    //   return true;
    // }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

}
