import { Component, Input, OnInit } from '@angular/core';

function nameToColor(name: string) {
  // bg, color
  const colorCombinations = [
    ['#512da8', '#fff'],
    ['#004d40', '#fff'],
    ['#AEDAC6', '#fff'],
    ['#ff4583', '#fff'],
    ['#B8B1C1', '#fff'],
    ['#E8D7AD', '#fff'],
    ['#ACD0C0', '#fff'],
    ['#63caf0', '#fff'],
    ['#B64D3A', '#fff'],
    ['#572D15', '#fff'],
  ];
  const hash = hashStr(name);
  const index = hash % colorCombinations.length;
  return colorCombinations[index];
}

// very simple hash
function hashStr(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash += charCode;
  }
  return hash;
}

@Component({
  selector: 'app-image-profile',
  templateUrl: './image-profile.component.html',
  styleUrls: ['./image-profile.component.scss']
})
export class ImageProfileComponent implements OnInit {
  @Input() imgSrc: string;
  @Input() w: number;
  @Input() h: number;

  // https://angular.io/guide/component-interaction#!#parent-to-child-setter
  @Input()
  get name(): string { return this._name; }
  set name(name: string) {
    this.initials = name.split(' ').length > 1 ? name.split(' ')[0].charAt(0) + name.split(' ')[1].charAt(0) : name.split(' ')[0].charAt(0);
    const colors = nameToColor(name);
    this.bg = colors[0];
    this.fc = colors[1];
    this.fontSize = Math.floor(this.w - (this.w * 0.40));
    this._name = name;
  }

  _name = '';

  fontSize = 20;
  bg = '#512da8';
  fc = '#fff';
  initials = '';

  constructor() { }

  ngOnInit(): void {}

}
