import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {HotkeysService} from 'angular2-hotkeys';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {TitleService} from 'src/app/services/title.service';
import {
  AbstractParentDetailComponent,
  ParentItemAction
} from '../../components/abstract-parent-detail/abstract-parent-detail.component';
import {UsersService} from '../../services/users.service';
import {takeUntil} from 'rxjs/operators';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from '../../components/utils/confirm-dialog/confirm-dialog.component';
import {Fab, FabAction, FabTypes} from '../../components/fab-custom/fab-interface';
import {PaymentStatusCardComponent} from '../../components/payment-status-card/payment-status-card.component';
import {Rental} from '../../models/rental';
import {RentalsService} from '../../services/rentals.service';
import {RentedBikesService} from '../../services/rented-bikes.service';
import {
  RentalRentedItemsListComponent
} from '../../components/rental-rented-items-list/rental-rented-items-list.component';
import {environment} from '../../../environments/environment';
import {
  SignPadDialogComponent,
  SignPadDialogModel
} from '../../components/utils/sign-pad-dialog/sign-pad-dialog.component';
import {MatSelect} from '@angular/material/select';
import {AppMetricsService} from '../../services/app-metrics.service';


@Component({
  selector: 'app-rental-detail',
  templateUrl: '../../components/abstract-parent-detail/abstract-parent-detail.component.html',
  styleUrls: ['../../components/abstract-parent-detail/abstract-parent-detail.component.scss']
})
export class RentalDetailComponent extends AbstractParentDetailComponent<Rental> implements OnInit, OnDestroy {

  constructor(
    private hotkeysService: HotkeysService,
    protected titleService: TitleService,
    protected translate: TranslateService,
    protected notificationService: CrmNotificationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected router: Router,
    protected dialog: MatDialog,
    protected progressDialog: MatDialog,
    protected route: ActivatedRoute,
    private rentalsService: RentalsService,
    private rentedBikesService: RentedBikesService,
    protected usersService: UsersService,
    private resolver: ComponentFactoryResolver,
    public native: NativeInterfacesService,
    private ref: ChangeDetectorRef,
    public appMetricsService: AppMetricsService
  ) {
    super(
      titleService,
      translate,
      notificationService,
      nativeInterfacesService,
      router,
      dialog,
      progressDialog,
      route,
      rentalsService,
      usersService,
      appMetricsService
    );
  }

  navBase = '/rentals/';

  titleI18N = 'RENTAL';
  titleIcon = 'bike_skooter';

  @ViewChild(RentalRentedItemsListComponent) childItemListRef: RentalRentedItemsListComponent;
  @ViewChild('childListHost', {read: ViewContainerRef, static: false}) childListHost;
  @ViewChild('paymentCard') paymentCard: PaymentStatusCardComponent;
  @ViewChild('rentalStatusSelect') rentalStatusSelect: MatSelect;

  showSharePrintButtons = true;

  primaryFab = new Fab(
    'ADD_BIKES_OR_ACCESSORIES',
    'add',
    'fab_add_product',
    FabTypes.multipleAction,
    [
      new FabAction('ADD_BIKE', '', 'add_bike'),
      new FabAction('ADD_ACCESSORY', '', 'add_accessory'),
    ]);
  secondaryFab = null;

  actions = [
    new ParentItemAction('PRINT_TICKET', '', 'print_ticket'),
    new ParentItemAction('SIGN', '', 'sign'),
    // new ParentItemAction('ASSIGN_TICKET_TO_CLIENT', '', 'assign_ticket_to_client'),
    // new ParentItemAction('PRINT_INVOICE', '', 'print_invoice'),
    new ParentItemAction('SHOW_HIDE_VAT', '', 'commute_vat'),
    // new ParentItemAction('SEND_INVOICE_BY_EMAIL', '', 'send_invoice_by_email'),
    // new ParentItemAction('SEND_TICKET_BY_EMAIL', '', 'send_ticket_by_email'),
    new ParentItemAction('DELETE_RENTAL', '', 'delete_rental'),
  ];

  documentTypes = {
    // tslint:disable-next-line:max-line-length
    ticket: {enabled: true, nameI18N: 'TICKET', disable_reason: '', whatsapp_enabled: true, mail_enabled: true, pdf_enabled: true, print_enabled: true},
  };

  rentalStatuses = [
    {value: 'draft', labeli18N: 'DRAFT'},
    {value: 'reserved', labeli18N: 'RESERVED'},
    {value: 'rented', labeli18N: 'RENTED'},
    {value: 'returned', labeli18N: 'RETURNED'},
    {value: 'cancelled', labeli18N: 'CANCELLED'}
  ];

  loadChildListComponent(): void {
    // TODO: migrate use componentfactory to viewcontaineref.createcomponent
    //    https://stackoverflow.com/a/70947152/888245
    // Dynamically create the component, so we don't need multiple templates for different types of lists
    this.childListHost.clear();
    const factory: ComponentFactory<RentalRentedItemsListComponent> = this.resolver.resolveComponentFactory(RentalRentedItemsListComponent);
    this.childListRefComponentRef = this.childListHost.createComponent(factory);
    this.childListRefComponentRef.instance.parentApiContentTypeName = 'rental';
    this.childListRefComponentRef.instance.parent = this.parentItem;
    this.childItemListRef = this.childListRefComponentRef.instance;

    if (this.parentItem.invoiceNumber && this.parentItem.invoiceNumber.length > 3) {
      this.childItemListRef.showVatFields(true);
      // TODO: instead of redefine all the array to remove just one item, remove the item
      // TODO: replicate logic used on bss, it's all on the template
      this.actions = [
        new ParentItemAction('PRINT_TICKET', '', 'print_ticket'),
        new ParentItemAction('SIGN', '', 'sign'),
        // new ParentItemAction('PRINT_INVOICE', '', 'print_invoice'),
        new ParentItemAction('SHOW_HIDE_VAT', '', 'commute_vat'),
        // new ParentItemAction('SEND_INVOICE_BY_EMAIL', '', 'send_invoice_by_email'),
        // new ParentItemAction('SEND_TICKET_BY_EMAIL', '', 'send_ticket_by_email'),
        new ParentItemAction('DELETE_RENTAL', '', 'delete_rental'),
      ];
    }
    // subscribe to child output event, remember to unsubscribe on destroy
    this.childListRefComponentRef.instance.itemsChange.subscribe(val => {
      this.parentItem.totalAmountWithTax = this.childListRefComponentRef.instance.getTotalCost();
      this.paymentCard.updatePaymentStatus();
    });
  }

  ngOnInit(): void {
    this.parentId = this.route.snapshot.paramMap.get('id');
    if (!this.parentId) {
      // New rental:
      const clientID = this.route.snapshot.paramMap.get('clientId');
      if (clientID) {
        this.initialCreateFormData.append('client', clientID);
        this.initialCreateFormData.append('openedBy', this.usersService.selectedMechanic.id);
        this.initialCreateFormData.append('workshop', this.usersService.userMe.workshop);
      } else {
        throw new Error('Client is requiered for creating a rental');
      }
    }
    super.ngOnInit();

    this.parentItem$.subscribe(x => {
      this.readyToAddChildItems = !(x.startDatetime == null || x.endDatetime == null);
    });

    if (this.nativeInterfacesService.hasCamera) {
      this.secondaryFab = new Fab('', 'qr_code_scanner', 'fab_add_by_cam_code_scanner', FabTypes.singleAction);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  changeRentalStatus(status: string): void {
    const origStatus = this.parentItem.status;
    console.log(origStatus);
    this.rentalsService.modify(this.parentItem.id, {status}).pipe(takeUntil(this.onDestroy$))
      .subscribe(x => {
        this.parentItem = x;
        this.notificationService.successI18N('RENTAL_STATUS_CHANGED');
      }, err => {
        // TODO: this is not working. the select is not getting back to the original value:
        this.parentItem.status = origStatus;
        // tslint:disable-next-line:no-string-literal
        for (const errorLiteral of err['error']) {
          this.notificationService.error(errorLiteral);
        }
      }
    );
    //   TODO: feedback use that the document type has been changed
  }

  sendDocumentWhatsapp(document: string): void {
    if (document === 'ticket') {
      const ticketUrl = this.rentalsService.getTicketPublicUrl(this.parentItem);
      this.openWhatsApp(this.parentItem.clientPhoneFormatted, ticketUrl);
    }
  }


  // configureKeyboardShortcuts(): void {
  //   if (!this.isFully) {
  //     // Hot Keys:
  //     // https://github.com/brtnshrdr/angular2-hotkeys
  //     this.hotkeysService.add(
  //       new Hotkey(
  //         'c', // key combination
  //         (): boolean => { // callback function to execute after key combination
  //           this.addrentedBike();
  //           return false; // prevent bubbling
  //         },
  //         undefined, // allow shortcut execution in these html elements
  //         'Add product' // shortcut name // TODO: translate i18n
  //       )
  //     );
  //     this.hotkeysService.add(
  //       new Hotkey(
  //         'C', // key combination
  //         (): boolean => { // callback function to execute after key combination
  //           this.childItemListRef.addByCodeDialog();
  //           return false; // prevent bubbling
  //         },
  //         undefined, // allow shortcut execution in these html elements
  //         'Add product by code' // shortcut name // TODO: translate i18n
  //       )
  //     );
  //     this.hotkeysService.add(
  //       new Hotkey(
  //         's', // key combination
  //         (): boolean => { // callback function to execute after key combination
  //           this.childItemListRef.searchExistingProductDialog();
  //           return false; // prevent bubbling
  //         },
  //         undefined, // allow shortcut execution in these html elements
  //         'Search product' // shortcut name // TODO: translate i18n
  //       )
  //     );
  //   }
  // }

  onFabAction(actionId: string): boolean {
    if (actionId === 'add_bike') {
      this.childItemListRef.addRentedBikeDialog();
      return true;
    }
    if (actionId === 'add_accessory') {
      this.childItemListRef.addRentedAccessoryDialog();
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  onAction(actionId: string): boolean {
    if (actionId === 'print_ticket') {
      this.printTicket();
      return true;
    }
    if (actionId === 'send_ticket_by_whatsapp') {
      this.sendDocumentWhatsapp('ticket');
      return true;
    }
    if (actionId === 'sign') {
      this.openSignPadDialog();
      return true;
    }
    if (actionId === 'delete_rental') {
      this.deleteRental();
      return true;
    }
    if (actionId === 'commute_vat') {
      this.childItemListRef.commuteVatFieldsDisplay();
      return true;
    }

    // if (actionId === 'send_ticket_by_email') {
    //   this.sendTicketByMail();
    //   return true;
    // }
    // if (actionId === 'send_invoice_by_email') {
    //   this.sendInvoiceByMail();
    //   return true;
    // }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  printTicket(): void {
    window.open(`${environment.apiUrl}/rentals/rental/reports/${this.parentId}/ticket?tk=${this.usersService.userTokenValue.token}&print=1`, '_blank');
  }

  async startDatetimeChanged(evt): Promise<void> {
    // TODO: unify code with endDateTimeChanged
    if (evt == null) {
      return;
    }

    let d = evt;
    if (d.length === 0) {
      d = null;
    }
    await this.rentalsService.modify(this.parentId,
      {startDatetime: d}).toPromise().then(
      async res => { // Success
        this.parentItem.startDatetime = d;
        this.notificationService.success(
          await this.translate.get('FEEDBACK_MESSAGES.ESTIMATED_CHECKIN_DATE_UPDATED').toPromise()
        );

        this.readyToAddChildItems = !(this.parentItem.startDatetime == null || this.parentItem.endDatetime == null);
      },
      async msg => { // Error
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.ERROR_UPDATING_ESTIMATED_CHECKIN_DATE').toPromise()
        );
      }
    );
  }

  async endDatetimeChanged(evt): Promise<void> {
    // TODO: unify code with startDatetimeChanged
    if (evt == null) {
      return;
    }
    let d = evt;
    if (d.length === 0) {
      d = null;
    }
    await this.rentalsService.modify(this.parentId,
      {endDatetime: d}).toPromise().then(
      async res => { // Success
        this.parentItem.endDatetime = d;
        this.notificationService.success(
          await this.translate.get('FEEDBACK_MESSAGES.ESTIMATED_CHECKOUT_DATE_UPDATED').toPromise()
        );
        this.readyToAddChildItems = !(this.parentItem.startDatetime == null || this.parentItem.endDatetime == null);
      },
      async msg => { // Error
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.ERROR_UPDATING_ESTIMATED_CHECKOUT_DATE').toPromise()
        );
      }
    );
  }

  // async checkClientAssignedHasEmail(client: User): Promise<string> {
  //   if (client.email || client.email.length > 3) {
  //     return client.email;
  //   }
  //   const newEmail = await this.getNewEmail().toPromise();
  //   if (!newEmail || newEmail.length < 3) {
  //     return;
  //   }
  //   client.email = newEmail;
  //   await this.usersService.modify(client.id, {email: newEmail}).toPromise();
  //   return client.email;
  //
  // }
  //
  // async checkClientAssigned(): Promise<User> {
  //   if (this.parentItem.client == null) {
  //     const selectedUser = await this.openClientSelector().toPromise();
  //     if (!selectedUser) {
  //       return;
  //     }
  //     this.parentItem.client = selectedUser.id;
  //     this.saveParentItem();
  //   }
  //   return await this.usersService.get(this.parentItem.client).toPromise();
  // }

  // async sendInvoiceByMail(): Promise<void> {
  //   // TODO: replace all async/await promises for flatmap or something like that (has ii any advantages?
  //   //        being able to cancel things for example?
  //   //        .toPromise is deprecated, so that's that... replace with something like firstValueFrom or lastValueFrom
  //
  //   // TODO: give option to create new user
  //
  //   const client = await this.checkClientAssigned();
  //   if (client == null) {
  //     await this.notificationService.errorI18N('SALE_MUST_BE_ASSIGNED_TO_CLIENT_BEFORE_SENDING_IT');
  //     return;
  //   }
  //
  //   const email = await this.checkClientAssignedHasEmail(client);
  //   if (!email || email.length < 3) {
  //     await this.notificationService.errorI18N('USER_DOES_NOT_HAVE_ANY_EMAIL_PLEASE_SET_ONE');
  //     return;
  //   }
  //
  //   const r = await this.usersService.confirmInvoiceInformation(client, ClientsDetailComponent).toPromise();
  //   if (r == null) {
  //     await this.notificationService.infoI18N('INVOICE_SENDING_CANCELLED_BY_USER');
  //     return;
  //   }
  //
  //
  //   this.rentalsService.sendInvoiceToClient(this.parentId).subscribe(async () => {
  //     await this.notificationService.successI18N('INVOICE_SENT_TO_CLIENT');
  //   }, async (err) => {
  //     await this.notificationService.errorI18N('ERROR_SENDING_INVOICE_TO_CLIENT');
  //   });
  // }

  // async sendTicketByMail(): Promise<void> {
  //   // TODO: replace all async/await promises for flatmap or something like that (has ii any advantages?
  //   //        being able to cancel things for example?
  //   //        .toPromise is deprecated, so that's that... replace with something like firstValueFrom or lastValueFrom
  //
  //   const client = await this.checkClientAssigned();
  //   if (client == null) {
  //     await this.notificationService.errorI18N('SALE_MUST_BE_ASSIGNED_TO_CLIENT_BEFORE_SENDING_IT');
  //     return;
  //   }
  //
  //   const email = await this.checkClientAssignedHasEmail(client);
  //   if (!email || email.length < 3) {
  //     await this.notificationService.errorI18N('USER_DOES_NOT_HAVE_ANY_EMAIL_PLEASE_SET_ONE');
  //     return;
  //   }
  //
  //
  //   this.rentalsService.sendTicketToClient(this.parentId).subscribe(async () => {
  //     await this.notificationService.successI18N('TICKET_SENT_TO_CLIENT');
  //   }, async (err) => {
  //     await this.notificationService.errorI18N('ERROR_SENDING_TICKET_TO_CLIENT');
  //   });
  // }

  // openClientSelector(): Observable<User> {
  //   const dialogRef = this.dialog.open(ClientsListComponent, {
  //     height: '90%',
  //     width: '90%',
  //     maxWidth: '90%',
  //     panelClass: 'no-padding-dialog-container',
  //     data: {
  //       mode: 'client-selector'
  //     }
  //   });
  //
  //   return dialogRef.afterClosed();
  // }

  // getNewEmail(): Observable<string> {
  //   const dialogRef = this.dialog.open(SimpleTextDialogComponent, {
  //     maxWidth: '90%',
  //     panelClass: 'no-padding-dialog-container',
  //     data: {
  //       mode: 'floating-selector'
  //     }
  //   });
  //   dialogRef.componentInstance.showBarCodeButton = true;
  //   dialogRef.componentInstance.titleI18N = 'USER_DOES_NOT_HAVE_ANY_EMAIL_PLEASE_SET_ONE';
  //   dialogRef.componentInstance.hintI18N = 'EMAIL';
  //   return dialogRef.afterClosed();
  // }

  openSignPadDialog(): void {
    const dialogRef = this.dialog.open(SignPadDialogComponent, {
      data: new SignPadDialogModel(),
      // width: '90%',
      // height: '90%',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.rentalsService.modify(this.parentId, {signatureDataBase64: result}).subscribe((r) => {
          this.parentItem.freehandSignatureUrl = r.freehandSignatureUrl;
          this.notificationService.successI18N('SIGNATURE_SAVED');
        });
      }
    });
  }

  deleteRental(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('CONFIRM_DELETE_SALE', '', 'DELETE', 'delete', 'warn')
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (confirmDialog: boolean) => {
        if (confirmDialog) {
          // raise exception on ng on init if we ever try to build a deleted component
          this.rentalsService.delete(this.parentId).subscribe( () => {
            this.router.navigateByUrl('/rentals', {replaceUrl: true});
          }, (err) => {
            // tslint:disable-next-line:no-string-literal
            this.notificationService.errorI18N(err.error['detail']);
          });
        } else {
          // cancelled
        }
      });
  }

  // printTicket(): void {
  //   // tslint:disable-next-line:max-line-length
  //   window.open(`${environment.apiUrl}/pos/order/reports/${this.parentId}/ticket?tk=${this.usersService.userTokenValue.token}&print=1`, '_blank');
  // }

  // async assignTicketToClient(): Promise<void> {
  //   await this.checkClientAssigned();
  // }

  // async printInvoice(): Promise<void> {
  //   const client = await this.checkClientAssigned();
  //
  //   if (client == null) {
  //     this.notificationService.errorI18N('SALE_MUST_BE_ASSIGNED_TO_CLIENT_BEFORE_SENDING_IT');
  //     return;
  //   }
  //
  //   const r = await this.usersService.confirmInvoiceInformation(client, ClientsDetailComponent).toPromise();
  //   if (r == null) {
  //     await this.notificationService.infoI18N('INVOICE_PRINTING_CANCELLED_BY_USER');
  //     return;
  //   }
  //
  //   // tslint:disable-next-line:max-line-length
  //   window.open(`${environment.apiUrl}/pos/order/reports/${this.parentId}/invoice?tk=${this.usersService.userTokenValue.token}&print=1`, '_blank');
  // }

  // changeInvoiceSeries(selectedSeries: number): void {
  //   // TODO: check server response, if we have a new invoice number or if we could not change because we are not the last on the series
  //   if (selectedSeries === this.parentItem.invoiceSeries) {
  //     return;
  //   }
  //   this.rentalsService.modify(this.parentId, {invoiceSeries: selectedSeries}).subscribe((r) => {
  //     this.parentItem.invoiceNumber = r.invoiceNumber;
  //     this.parentItem.invoiceSeries = r.invoiceSeries;
  //     this.parentItem.invoiceDate = r.invoiceDate;
  //   }, (err) => {
  //     this.notificationService.warningI18N(err.error[0]);
  //
  //     // this is just to trigger the change detection and make select go back to the previous value:
  //     const oldSeries = this.parentItem.invoiceSeries;
  //     this.parentItem.invoiceSeries = 99999;
  //     setTimeout(() => {
  //       this.parentItem.invoiceSeries = oldSeries;
  //     });
  //
  //   });
  // }
  //
  // generateInvoice(): void {
  //   this.rentalsService.generateInvoice(this.parentId).subscribe((r) => {
  //     this.parentItem.invoiceNumber = r.invoiceNumber;
  //     this.parentItem.invoiceSeries = r.invoiceSeries;
  //     this.parentItem.invoiceDate = r.invoiceDate;
  //   });
  // }
}
