import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  ChildItemEditableListAbstractComponent,
  ChildItemFieldMetadata,
  ChildItemFieldTypes,
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {BankTransaction} from '../../models/finances_bank';
import {FinancesBankTransactionService} from '../../services/finances-bank-transaction.service';
import {BikeCRMApiPaginated} from '../../models/api';

@Component({
  selector: 'app-finances-bank-transaction-list',
  templateUrl: '../abstract-child-editable-item-list/abstract-child-editable-item-list.component.html',
  styleUrls: ['../abstract-child-editable-item-list/abstract-child-editable-item-list.component.scss'],
  // TODO: Document
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FinancesBankTransactionList extends ChildItemEditableListAbstractComponent<BankTransaction> implements OnInit {
  parentApiRelName = 'bankAccount';

  @Output() itemsUpdated = new EventEmitter();

  @Input() showFooter = true;
  @Input() showVat = false;

  showFooterAddOption = false;

  addItemLiteralI18n = 'ADD_BANK_TRANSACTION';

  bankTransactions$: Observable<BikeCRMApiPaginated<any>>;

  fields = {
    description: {
      metadata: new ChildItemFieldMetadata('DESCRIPTION', 'ADD_DESCRIPTION', ChildItemFieldTypes.text, '', true, null, false),
      mainInput: false,
      w: 60,
      align: 'start'
    },
    amount: {
      metadata: new ChildItemFieldMetadata('AMOUNT', 'ADD_AMOUNT', ChildItemFieldTypes.currency, 0, true, null, false),
      mainInput: true,
      w: 20,
      align: 'end'
    },
    dateTime: {
      metadata: new ChildItemFieldMetadata('DATE', '', ChildItemFieldTypes.date, new Date().toISOString(), true, null, false, false),
      mainInput: false,
      w: 20,
      align: 'end'
    },
    balance: {
      metadata: new ChildItemFieldMetadata('BALANCE', '', ChildItemFieldTypes.currency, null, true, null, false, false, true),
      mainInput: false,
      w: 20,
      align: 'end'
    },
    more_opts: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.action, null, true, null, false),
      mainInput: false,
      w: 10,
      align: 'end'
    }
  };

  @ViewChild(MatTable, {static: true}) table: MatTable<BankTransaction>;
  dataSource = new MatTableDataSource();

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    notificationService: CrmNotificationsService,
    private financesBankTransactionService: FinancesBankTransactionService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected usersService: UsersService
  ) {
    super(dialog, translate, notificationService, financesBankTransactionService, nativeInterfacesService, usersService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // TODO: can we move this to the parent abstract class? at least a loadItems call?
    this.bankTransactions$ = this.financesBankTransactionService.getPaginatedList(
      {
        bank_account: this.parent.id
      },
      '',
      false,
      1,
      20
    );

    this.bankTransactions$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.items = x.results;
      this.dataSource.data = this.items;
      this.itemsUpdated.emit();
    });

  }

  createNewItemInstance(): BankTransaction {
    const item = new BankTransaction();
    item.id = this._getID(item);
    return item;
  }

  reconcileCash(amount: number): void {
    // console.log('reconcileCash', amount);
    const currentBalance = this.items[0].balance;
    // console.log('currentBalance', currentBalance);
    const newBalance = amount;
    let diff = newBalance - parseFloat(currentBalance);
    diff = parseFloat(diff.toFixed(10));
    const transaction = this.createNewItemInstance();
    transaction.description = this.translate.instant('CASH_RECONCILIATION');
    transaction.amount = diff.toString();
    transaction.dateTime = new Date().toISOString();
    this.addItemStart(transaction);
  }

}
