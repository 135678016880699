<div class="base-paddings">
      <div style="display: block">
        <canvas baseChart
                [data]="chartData"
                [options]="chartOptions"
                [type]="chartType"
                height="300px"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"
        >
        </canvas>
      </div>
</div>
