import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'src/environments/environment';
import {TranslateService} from '@ngx-translate/core';

const defaultOptions = { progressBar: true };

@Injectable({
  providedIn: 'root'
})
export class CrmNotificationsService {

  constructor(private toastr: ToastrService, protected translate: TranslateService) { }

  warning(message?: string, title?: string): void {
    this.toastr.warning(message, title, defaultOptions);
  }

  async warningI18N(messageI18n?: string, titleI18n?: string): Promise<void> {
    messageI18n = messageI18n ? await this.translate.get(messageI18n).toPromise() : '';
    titleI18n = titleI18n ? await this.translate.get(titleI18n).toPromise() : '';
    this.toastr.warning(messageI18n, titleI18n, defaultOptions);
  }

  success(message?: string, title?: string): void {
    this.toastr.success(message, title, defaultOptions);
  }

  async successI18N(messageI18n?: string, titleI18n?: string): Promise<void> {
    messageI18n = messageI18n ? await this.translate.get(messageI18n).toPromise() : '';
    titleI18n = titleI18n ? await this.translate.get(titleI18n).toPromise() : '';
    this.toastr.success(messageI18n, titleI18n, defaultOptions);
  }

  info(message?: string, title?: string): void {
    this.toastr.info(message, title, defaultOptions);
  }

  async infoI18N(messageI18n?: string, titleI18n?: string): Promise<void> {
    messageI18n = messageI18n ? await this.translate.get(messageI18n).toPromise() : '';
    titleI18n = titleI18n ? await this.translate.get(titleI18n).toPromise() : '';
    this.toastr.info(messageI18n, titleI18n, defaultOptions);
  }

  error(message?: string, title?: string): void {
    this.toastr.error(message, title, defaultOptions);
  }

  async errorI18N(messageI18n?: string, titleI18n?: string): Promise<void> {
    messageI18n = messageI18n ? await this.translate.get(messageI18n).toPromise() : '';
    titleI18n = titleI18n ? await this.translate.get(titleI18n).toPromise() : '';
    this.toastr.error(messageI18n, titleI18n, defaultOptions);
  }

  debug(message?: string, title?: string): void {
    title = title ?? '';
    message = message ?? '';
    if (!environment.production) {
      console.log(`${title} - ${message}`);
    }
  }
}
