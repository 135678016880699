import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BikeCRMApiAbstract, GetOrCreateResponse} from './bikecrm-api-base';
import {Observable, of, throwError} from 'rxjs';
import {concatMap, delay, publishLast, refCount, retryWhen} from 'rxjs/operators';
import {Product} from '../models/pos_product';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PosProductService extends BikeCRMApiAbstract {
  apiPath = 'pos_product';

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getOrCreateByCode(code: string): Observable<GetOrCreateResponse<Product>> {
    return this.http.get<any>(`${environment.apiUrl}/api/${this.apiPath}/get_or_create_by_code/?code=${code}`).pipe(
      retryWhen(errors => errors.pipe(delay(1500),
        concatMap((e, index) => {
            if (e.status === 404) {
              // If the code is not found, we don't need to retry, we just want to retry if there is some
              //  connection issues or server issues.
              return throwError(e);
            }
            return index === 3 ? throwError(e) : of(null);
          }
        ),
      )),
      publishLast(),
      refCount()
    );
  }

  duplicate(productId: string): Observable<GetOrCreateResponse<Product>> {
    return this.http.post<any>(`${environment.apiUrl}/api/${this.apiPath}/duplicate/`, {
      original_product_id: productId
    }).pipe(
      retryWhen(errors => errors.pipe(delay(1500),
        concatMap((e, index) => {
            if (e.status === 404) {
              // If the code is not found, we don't need to retry, we just want to retry if there is some
              //  connection issues or server issues.
              return throwError(e);
            }
            return index === 3 ? throwError(e) : of(null);
          }
        ),
      )),
      publishLast(),
      refCount()
    );
  }

  moveStock(productId: string, workshopId: string, amount: number): Observable<Product> {
    return this.http.post<any>(`${environment.apiUrl}/api/${this.apiPath}/${productId}/move_stock/`, {
      product_id: productId,
      workshop_id: workshopId,
      amount
    }).pipe(
      retryWhen(errors => errors.pipe(delay(1500),
        concatMap((e, index) => {
            if (e.status === 404) {
              // If the code is not found, we don't need to retry, we just want to retry if there is some
              //  connection issues or server issues.
              return throwError(e);
            }
            return index === 3 ? throwError(e) : of(null);
          }
        ),
      )),
      publishLast(),
      refCount()
    );
  }

}
