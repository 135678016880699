<h1 mat-dialog-title *ngIf="confirmDialogModel.titleI18nKey">{{ confirmDialogModel.titleI18nKey | translate }}</h1>
<div mat-dialog-content>
    <p>{{ confirmDialogModel.messageI18nKey | translate }}</p>
</div>
<div mat-dialog-actions>
    <button *ngIf="confirmDialogModel.cancelI18nKey" mat-stroked-button [color]="confirmDialogModel.cancelColor" (click)="closeDialog()" cdkFocusInitial data-cy="dialog-cancel-button">
        <mat-icon *ngIf="confirmDialogModel.cancelIcon" class="mr1">{{ confirmDialogModel.cancelIcon }}</mat-icon>
        {{ confirmDialogModel.cancelI18nKey | translate }}
    </button>
    <button *ngIf="confirmDialogModel.confirmI18nKey" mat-stroked-button [color]="confirmDialogModel.confirmColor" (click)="confirmDialog()" data-cy="dialog-confirm-button">
        <mat-icon *ngIf="confirmDialogModel.confirmIcon" class="mr1">{{ confirmDialogModel.confirmIcon }}</mat-icon>
        {{ confirmDialogModel.confirmI18nKey | translate }}
    </button>
</div>
