import {AfterViewInit, Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements OnInit, AfterViewInit {
  // https://stackoverflow.com/questions/51510936/how-to-set-autofocus-on-a-matinput-element-on-click-event-in-angular-6
  constructor(private elementRef: ElementRef) {}

  autoFocusEnabled = true;

  @Input() set appAutofocus(condition: boolean) {
    this.autoFocusEnabled = condition !== false;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (!this.autoFocusEnabled) {
      return;
    }
    // console.log('AutofocusDirective ngAfterViewInit');
    // TODO: this is a hack, but without the delay the autofocus does not work in certain scenarios like
    //      on dialogs (test it on SimpleTextDialogComponent)
    setTimeout(() => this.elementRef.nativeElement.focus(), 0);
    setTimeout(() => this.elementRef.nativeElement.focus(), 500);
  }

}
