import {Component, ComponentRef, Input, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'app-tabs-user-related-components',
  templateUrl: './tabs-user-related-components.component.html',
  styleUrls: ['./tabs-user-related-components.component.scss']
})
export class TabsUserRelatedComponentsComponent {
  // TODO: rename, this is not necessarily user related (client detail or product detail also use this)
  @Input() tabComponents?;

  params;

  // TODO: needed?
  @ViewChild('viewContainerRef', {read: ViewContainerRef, static: true})
  public tabContainerRef: ComponentRef<any>;

  constructor(
  ) {}

}
