import {Component, EventEmitter} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

class ErrorDialogComponent {
}

@Component({
  selector: 'app-progress-dialog-component',
  template: `<div style="text-align: center; padding: 15px;">
    <h2>{{titleI18nKey | translate}}</h2>
  <p>{{messageI18nKey | translate}}</p>
  <mat-spinner style="margin-left: auto; margin-right: auto; margin-top: 15px;"></mat-spinner>
  <button style="margin-top: 30px;" mat-button (click)="onCancel.emit(); dialog.close()">{{ 'CANCEL' | translate }}</button></div>`
})
export class ProgressDialogComponent {
  // https://material.angular.io/components/progress-bar/overview
  // https://material.angular.io/components/progress-spinner/overview
  public titleI18nKey: string;
  public messageI18nKey: string;
  onCancel = new EventEmitter();

  constructor(public dialog: MatDialogRef<ErrorDialogComponent>) {
  }
}
