<div fxLayout="column" class="mt3" style="background-color: white">
  <div fxLayout="row" fxLayoutAlign="space-between center">
      <h3 class="px3 py2 m0i">
        <!-- Payment status -->
        {{ 'PAYMENTS.PAYMENT' | translate }}:
        <!--  TODO: extracto to custom component, we have this also in some lists (orders and bss) -->
        <span *ngIf="paymentStatus === 'unk'">{{ "PAYMENTS.UNKNOWN" |translate }}</span>
        <span *ngIf="paymentStatus === 'pai'" class="chip-custom-green">{{ "PAYMENTS.PAID" |translate }}</span>
        <span *ngIf="paymentStatus === 'pen'" class="chip-custom-red">{{ "PAYMENTS.PENDING" |translate }}</span>
        <span *ngIf="paymentStatus === 'par'" class="chip-custom-yellow">{{ "PAYMENTS.PARTIALLY_PAID" |translate }}</span>
        <span *ngIf="paymentStatus === 'adv'" class="chip-custom-yellow">{{ "PAYMENTS.ADVANCE_PAYMENT" |translate }}</span>

        <span *ngIf="paymentStatus != 'pai' && globalPaymentPendingAmount > 0" class="text-warning ml2">{{ 'PAYMENTS.PENDING_AMOUNT' | translate }}: - {{globalPaymentPendingAmount | currency: currencyCode :'symbol-narrow' }}</span>
        <span *ngIf="paymentStatus != 'pai' && globalPaymentPendingAmount < 0" class="text-success ml2">+ {{globalPaymentPendingAmount * -1 | currency: currencyCode :'symbol-narrow' }}</span>
      </h3>

  <!--  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="bottom_drawer"></mat-icon>-->
      <button mat-stroked-button *ngIf="showOpenDrawerButton" color="primary" (click)="openDrawer()" class="mr3i">{{ 'PAYMENTS.OPEN_CASH_DRAWER' | translate }}</button>
  </div>
  <mat-divider></mat-divider>

  <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between" fxLayoutGap="5px" class="px3 pt3 pb3">
    <!-- Payment method buttons -->
    <button *ngFor="let method of usersService.getCustomConfig().get_payment_methods()"
            mat-stroked-button
            color="{{ paymentMethod === method ? 'primary' : '' }}"
            (click)="setPaid(method)">{{ getPaymentMethodi18nKey(method) | translate }}</button>


<!--    <button mat-stroked-button color="{{ paymentMethod === 'cas' ? 'primary' : '' }}" (click)="setPaid('cas')">{{ 'PAYMENTS.CASH' | translate }}</button>-->
<!--    <button mat-stroked-button color="{{ paymentMethod === 'car' ? 'primary' : '' }}" (click)="setPaid('car')">{{ 'PAYMENTS.CARD' | translate }}</button>-->
<!--    <button mat-stroked-button color="{{ paymentMethod === 'biz' ? 'primary' : '' }}" (click)="setPaid('biz')">Bizum</button>-->
<!--    <button mat-stroked-button color="{{ paymentMethod === 'tra' ? 'primary' : '' }}" (click)="setPaid('tra')">{{ 'PAYMENTS.BANK_TRANSFER' | translate }}</button>-->
<!--    <button mat-stroked-button color="{{ paymentMethod === 'piq' ? 'primary' : '' }}" (click)="setPaid('piq')">Payconiq</button>-->

  </div>

  <div *ngIf="payments && (payments.length > 0)" class="pl3">
    <!-- List of payments -->
    <span *ngFor="let payment of payments" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" class="p1">

      <input matInput [matDatepicker]="picker" style="width:1px;visibility: hidden;" [value]="payment.paymentDt"
             (dateChange)="dateChanged(payment, $event)" autocomplete="off" disabled>

      <span (click)="picker.open()">{{ payment.paymentDt | date: 'dd/MM/yyyy' }}</span>

      <mat-datepicker #picker touchUi="false" disabled="false"></mat-datepicker>

      <span>{{ getPaymentLiterali18N(payment) | translate }}</span>

      <app-inline-edit class="w100" style="min-height: 58px;"
                       [data]='getPaymentAmountLiteral(payment)'
                       addHint="{{ 'PAYMENTS.AMOUNT' | translate }}"
                       [currencyCode]="currencyCode"
                       type="currency"
                       (focusOut)="onChangeAmount(payment, $event)"></app-inline-edit>

      <mat-icon (click)="deletePayment(payment.id)" aria-hidden="false" fontIcon="delete"></mat-icon>
    </span>
  </div>

</div>
