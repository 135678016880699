import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppMetricsService {

  EVENT_NAMES = {
    APP_VERSION: 'app-version',
    BSS_CONTROLS: 'bss-controls'
  };

  constructor() {}

  sendEvent(eventName: string, eventValue: string): void {
    try {
      const properties = {};
      properties[eventName] = eventValue;
      // @ts-ignore
      plausible('metrics', {props: properties});
    } catch (e) {
      // TODO: log to sentry
      console.error(e);
    }
  }
}
