<!-- <h2 class="pb2" style="background: #4a4a4a; color: white"> -->
<h2 class="pb2">
  {{ "ADD_PREDEFINED_TASKS" | translate }}
</h2>

<ng-container *ngIf="defaultWorkshop$ | async as workshop; else loading">
  <div fxLayout="column">
    <div fxLayout="row wrap" fxLayoutGap="10px">
      <button mat-stroked-button
              fxFlex.lt-sm="100"
              class="mt1i"
              *ngFor="let tpl of workshop.extraData.servicesheetTemplates"
              (click)="toggleTpl(tpl)"
              [disabled]="isDisabled(tpl)"
              [class.muted]="!isActive(tpl)">
        <span *ngIf="!isDisabled(tpl)">{{getTemplateLiteral(tpl)}}</span>
        <span *ngIf="isDisabled(tpl)">{{ tpl.name }} - {{ 'ALREADY_ADDED' | translate }}</span>
      </button>
    </div>
    <div fxLayoutAlign="end" class="pt2">
      <button mat-stroked-button
              color="primary"
              (click)="confirmDialog()">
        {{ 'CONFIRM' | translate }}
      </button>
    </div>
  </div>
</ng-container>

<ng-template #noBssTemplates>
  <small>{{ 'NO_BSS_TEMPLATES_CONFIGURED' | translate }}</small>
</ng-template>

<ng-template #loading>
<!--  TODO: i18n: -->
  <p>loading ...</p>
</ng-template>
