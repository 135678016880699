<div class="base-paddings">

  <mat-form-field appearance="fill" class="mr2">
    <mat-label>{{ 'YEAR' | translate }}</mat-label>
    <mat-select [(value)]="selectedYear">
      <mat-option *ngFor="let year of years" [value]="year">
        {{year}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="mr2">
    <mat-label>{{ 'CTX_REPORTS.PERIOD' | translate }}</mat-label>
    <mat-select [(value)]="selectedPeriodLiteral" (selectionChange)="onSelectedPeriodChange($event)">
      <mat-option value="Q1">
        {{ 'CTX_REPORTS.Q1' | translate }}
      </mat-option>
      <mat-option value="Q2">
        {{ 'CTX_REPORTS.Q2' | translate }}
      </mat-option>
      <mat-option value="Q3">
        {{ 'CTX_REPORTS.Q3' | translate }}
      </mat-option>
      <mat-option value="Q4">
        {{ 'CTX_REPORTS.Q4' | translate }}
      </mat-option>
      <mat-option value="-1" disabled>
        {{ 'MONTHS' | translate }}
      </mat-option>
      <mat-option value="M1">
        {{ 'JANUARY' | translate }}
      </mat-option>
      <mat-option value="M2">
        {{ 'FEBRUARY' | translate }}
      </mat-option>
      <mat-option value="M3">
        {{ 'MARCH' | translate }}
      </mat-option>
      <mat-option value="M4">
        {{ 'APRIL' | translate }}
      </mat-option>
      <mat-option value="M5">
        {{ 'MAY' | translate }}
      </mat-option>
      <mat-option value="M6">
        {{ 'JUNE' | translate }}
      </mat-option>
      <mat-option value="M7">
        {{ 'JULY' | translate }}
      </mat-option>
      <mat-option value="M8">
        {{ 'AUGUST' | translate }}
      </mat-option>
      <mat-option value="M9">
        {{ 'SEPTEMBER' | translate }}
      </mat-option>
      <mat-option value="M10">
        {{ 'OCTOBER' | translate }}
      </mat-option>
      <mat-option value="M11">
        {{ 'NOVEMBER' | translate }}
      </mat-option>
      <mat-option value="M12">
        {{ 'DECEMBER' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div>
    <h4>{{ 'CTX_REPORTS.DOWNLOAD_INVOICES_REPORT' | translate: {format:'xls'} }} ({{ "CTX_REPORTS.FOR_TAX_MANAGER" | translate }})</h4>
    <p>
      <mat-icon>info</mat-icon>
      {{ 'CTX_REPORTS.INVOICES_REPORT_EXPLANATION' | translate }}
    </p>
    <mat-form-field appearance="fill" class="mr2">
      <mat-label>{{ 'INVOICE_SERIES' | translate }}</mat-label>
      <mat-select [(value)]="selectedInvoiceSeries">
        <mat-option value="all">{{ 'ALL' | translate }}</mat-option>
        <mat-option value="1">1</mat-option>
        <mat-option value="2">2</mat-option>
        <mat-option value="3">3</mat-option>
        <mat-option value="4">4</mat-option>
        <mat-option value="5">5</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="downloadInvoicesReport()">{{ 'DOWNLOAD' | translate }}</button>
  </div>

  <div>
    <h4>{{ 'CTX_REPORTS.DOWNLOAD_DAILY_INCOME_REPORT' | translate: {format:'xls'} }} ({{ "CTX_REPORTS.FOR_TAX_MANAGER" | translate }})</h4>

    <p>
      <mat-icon>info</mat-icon>
      {{ 'CTX_REPORTS.DAILY_INCOME_REPORT_EXPLANATION' | translate }}
    </p>

    <mat-checkbox [(ngModel)]="dailyIncomeIncludeInvoices" class="mr2">{{ 'CTX_REPORTS.INCLUDE_INVOICES' | translate }}</mat-checkbox>

    <button mat-raised-button color="primary" (click)="downloadDailyIncomeReport()">{{ 'DOWNLOAD' | translate }}</button>
  </div>

  <hr class="mt3i mb4">

  <div>
    <h4>{{ 'CTX_REPORTS.DOWNLOAD_FINISHED_BSS' | translate: {format:'xls'} }}</h4>

    <button mat-raised-button color="primary" (click)="downloadFinishedBssCSV()">{{ 'DOWNLOAD' | translate }}</button>
  </div>
  <div class="pt2">
    <h4>{{ 'CTX_REPORTS.DOWNLOAD_SALES' | translate: {format:'xls'} }}</h4>

    <button mat-raised-button color="primary" (click)="downloadSalesCSV()">{{ 'DOWNLOAD' | translate }}</button>
    <button mat-raised-button color="primary" class="ml2" (click)="downloadDetailedSalesPDF()">{{ 'DOWNLOAD_DETAILED_PDF' | translate }} (Beta)</button>
  </div>

  <div>
    <h4>{{ 'CTX_REPORTS.DOWNLOAD_RENTALS' | translate: {format:'xls'} }}</h4>

    <button mat-raised-button color="primary" (click)="downloadRentals()">{{ 'DOWNLOAD' | translate }}</button>
  </div>
  <div class="pt2">
    <h4>{{ 'CTX_REPORTS.DOWNLOAD_PRODUCTS_SALES' | translate: {format:'xls'} }} ({{ "CTX_REPORTS.FOR_INTERNAL_USE" | translate }})</h4>

    <button mat-raised-button color="primary" (click)="downloadProductSalesCSV()">{{ 'DOWNLOAD' | translate }}</button>
  </div>

  <hr class="mt3i">

  <div>
    <h4>{{ 'CTX_REPORTS.DOWNLOAD_SUPPLIER_ORDERS' | translate: {format:'xls'} }} ({{ "CTX_REPORTS.FOR_TAX_MANAGER" | translate }})</h4>

    <button mat-raised-button color="primary" (click)="downloadSupplierOrders()">{{ 'DOWNLOAD' | translate }}</button>
  </div>

  <hr class="mt3i">

  <div class="pt2">
    <h4>{{ 'CTX_REPORTS.DOWNLOAD_CLIENTS' | translate: {format:'xls'} }} ({{ "CTX_REPORTS.FOR_INTERNAL_USE" | translate }})</h4>

    <button mat-raised-button color="primary" (click)="downloadClientsXls()">{{ 'DOWNLOAD' | translate }}</button>
  </div>

  <div class="pt2">
    <h4>{{ 'CTX_REPORTS.DOWNLOAD_PRODUCT_LIST' | translate: {format:'xls'} }} ({{ "CTX_REPORTS.FOR_INTERNAL_USE" | translate }})</h4>

    <button mat-raised-button color="primary" (click)="downloadProductListXls()">{{ 'DOWNLOAD' | translate }}</button>
  </div>


  <hr class="mt3i">

  <div class="pt2">
    <h4>{{ 'CTX_REPORTS.PAYMENT_METHODS' | translate: {format:'xls'} }} ({{ "CTX_REPORTS.FOR_INTERNAL_USE" | translate }})</h4>

<!--    TODO: make this dyunamic, as every business have different payment methods -->
    <button class="mr3i" mat-raised-button color="primary" (click)="downloadroPaymentMethodXls('car')">{{ 'CARD' | translate }}</button>
    <button class="mr3i" mat-raised-button color="primary" (click)="downloadroPaymentMethodXls('cas')">{{ 'CASH' | translate }}</button>
    <button class="mr3i" mat-raised-button color="primary" (click)="downloadroPaymentMethodXls('biz')">{{ 'BIZUM' | translate }}</button>
    <button class="mr3i" mat-raised-button color="primary" (click)="downloadroPaymentMethodXls('tra')">{{ 'TRANSFER' | translate }}</button>
  </div>

</div>
