import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BillableItem, Payment, PAYMENT_METHOD_I18N_KEY} from '../../models/billable_item';
import {UntypedFormControl} from '@angular/forms';
import {UsersService} from '../../services/users.service';
import {FinancesPaymentsService} from '../../services/finances-payments.service';
import {WorkshopService} from '../../services/workshop.service';
import {MatDialog} from '@angular/material/dialog';
import {CashChangeCalculatorComponent} from '../cash-change-calculator/cash-change-calculator';

@Component({
  selector: 'app-payment-status-card',
  templateUrl: './payment-status-card.component.html',
  styleUrls: ['./payment-status-card.component.scss']
})
export class PaymentStatusCardComponent implements OnInit {
  // TODO: show progress while saving? if it takes long only, more than one retry?
  // TODO:
  //  - move the responsability of saving payments to this component
  //  - call payment service (not existent yet) from here
  //  - support partial payments

  // tslint:disable-next-line:variable-name
  _billableItem: BillableItem = null;

  paymentStatus: string;
  paymentMethod: string;
  paymentDt: string;

  currencyCode = 'EUR';

  globalPaymentPendingAmount = 0;

  payments: Payment[] = [];

  contentType = '';

  date;

  showOpenDrawerButton = false;
  webHookCashDrawerOpen = '';

  get billableItem(): BillableItem {
    return this._billableItem;
  }

  @Output() billableItemChange: EventEmitter<BillableItem> = new EventEmitter();

  @Input() set billableItem(billableItem: BillableItem) {
    // TODO: we clone the objects instead of using directly the properties inside billableItem, so we can from the parent component,
    //  set back the original billableItem, with the original values, if the api call fails
    this._billableItem = billableItem;
    // console.log('set billableItem', billableItem);

    // TODO Improve detection of billable item type (maybe add a type or content type to the api itself)
    if (billableItem.hasOwnProperty('owner') && billableItem.hasOwnProperty('bikeType') && billableItem.hasOwnProperty('assignedTo')) {
      this.contentType = 'bikes.bikeservicesheet';
    } else if (billableItem.hasOwnProperty('startDatetime') && billableItem.hasOwnProperty('pickupDatetime')) {
      this.contentType = 'rentals.rental';
    } else {
      this.contentType = 'pos.order';
    }

    this.financesPaymentsService.getPaymentStatus(billableItem.id, this.contentType).subscribe(
      (paymentStatus) => {
        this.payments = paymentStatus.results;
        this.updatePaymentStatus();
      });

  }

  constructor(
    private financesPaymentsService: FinancesPaymentsService,
    protected usersService: UsersService,
    protected workshopService: WorkshopService,
    private dialog: MatDialog
  ) {}

  deletePayment(paymentId: string): void {
    this.financesPaymentsService.delete(paymentId).subscribe(
      (response) => {
        this.payments = this.payments.filter((payment) => payment.id !== paymentId);
        this.updatePaymentStatus();
      });
  }

  getPaymentMethodi18nKey(paymentMethod: string): string {
    return PAYMENT_METHOD_I18N_KEY[paymentMethod];
  }

  updatePaymentStatus(): void {
    let amountPaid = 0;
    for (const payment of this.payments) {
      amountPaid += Number(payment.amount);
    }
    amountPaid = parseFloat(amountPaid.toFixed(2));

    // if this.billableItem.totalAmountWithTax is a string, we need to convert it to a number
    if (typeof this.billableItem.totalAmountWithTax === 'string') {
      // TODO: should not happen, report to sentry?
      this.billableItem.totalAmountWithTax = parseFloat(this.billableItem.totalAmountWithTax);
    }
    const totalAmountToPay = parseFloat(this.billableItem.totalAmountWithTax.toFixed(2));

    this.globalPaymentPendingAmount = totalAmountToPay - amountPaid;

    if (this.payments.length === 0) {
      this.paymentMethod = 'unk';
      this.paymentStatus = 'pen';
      if (this.billableItem.hasOwnProperty('closed')) {
        // tslint:disable-next-line:no-string-literal
        if (this.billableItem['closed'] && this.globalPaymentPendingAmount === 0) {
          this.paymentStatus = 'pai';
        }
      } else if (this.globalPaymentPendingAmount === 0) {
        this.paymentStatus = 'pai';
      }
    } else {
      if (this.globalPaymentPendingAmount > 0) {
        this.paymentStatus = 'par';
      }
      if (this.globalPaymentPendingAmount === 0) {
        this.paymentStatus = 'pai';
      }
      if (this.globalPaymentPendingAmount < 0) {
        this.paymentStatus = 'adv';  // advance payment
      }

      this.paymentMethod = this.payments[0].paymentMethod;
      for (const payment of this.payments) {
        if (this.paymentMethod !== payment.paymentMethod) {
          this.paymentMethod = 'mix';
        }
      }
    }
    // TODO: make all this class use billable item as source of truth, instead of keeping a copy of the values
    this.billableItem.roPaymentStatus = this.paymentStatus;
    this.billableItem.roPaymentMethod = this.paymentMethod;
    this.billableItem.roPaymentDt = this.paymentDt;
    this.billableItemChange.emit(this.billableItem);
  }

  getPaymentAmountLiteral(payment: Payment): string {
    if (Number(payment.amount) === 0) {
      return '0';
    }
    return Number(payment.amount).toFixed(2);
  }

  setPaid(paymentMethod: string): void {
    // TODO: maybe we can make this on the service? iterate over every data, and if its a number
    //  make sure we dont have more than 10 decimals
    const data = {
      amount: this.globalPaymentPendingAmount.toFixed(10),
      paymentMethod,
      paymentDt: this.paymentDt,
      contentType: this.contentType,
      objectId: this.billableItem.id,
    };
    this.financesPaymentsService.create(data).subscribe(
      (response) => {
        this.payments.push(response);
        this.updatePaymentStatus();
      });
    if (paymentMethod === 'cas') {
      // tslint:disable-next-line:no-string-literal
      this.openCashChangeCalculator(data['amount']);
      // TODO: re-enable with wrapper or qztray
      // this.openDrawer();
    }
  }

  ngOnInit(): void {
    this.currencyCode = this.usersService.business.currency.toUpperCase();
    this.date = new UntypedFormControl(this.paymentDt);

    this.workshopService.getFirstWorkshop().subscribe(
      (workshop) => {
        if (workshop.webhookCashDrawerOpen && workshop.webhookCashDrawerOpen.length > 5) {
          this.showOpenDrawerButton = true;
          this.webHookCashDrawerOpen = workshop.webhookCashDrawerOpen;
        }
      });
  }

  openCashChangeCalculator(amount: string): void {
    // open a dialog with the cash change calculator
    this.dialog.open(CashChangeCalculatorComponent, {
      height: '700px',
      width: '1100px',
      maxWidth: '90vw',
      maxHeight: '70vh',
      data: {
        totalPrice: Number(amount),
        currencyCode: this.currencyCode,
      }
    }).afterClosed().subscribe(
      (result) => {
        // if (result) {
        //   this.setPaid('cas');
        // }
      });
  }

  onChangeAmount(payment: Payment, amount): void {
    // count how many commas we have
    const commas = amount.match(/,/g);
    if (commas && commas.length === 1) {
      amount = amount.replace(',', '.');
    } else if (commas && commas.length > 1) {
      throw new Error('Invalid amount, too many commas');
    }

    // remove letters and other characters that are not numbers or dots
    amount = amount.replace(/[^0-9.]/g, '');

    payment.amount = amount;
    const data = {
      amount,
    };
    this.financesPaymentsService.modify(payment.id, data).subscribe(
      (response) => {
        this.payments = this.payments.map((p) => {
          if (p.id === response.id) {
            return response;
          }
          return p;
        });
        this.updatePaymentStatus();
      });
  }

  getPaymentLiterali18N(payment: Payment): string {
    return PAYMENT_METHOD_I18N_KEY[payment.paymentMethod];

  }

  dateChanged(payment: Payment, date): void {
    console.log('dateChanged', date.value);
    payment.paymentDt = date.value;
    const data = {
      paymentDt: payment.paymentDt,
    };
    this.financesPaymentsService.modify(payment.id, data).subscribe(
      (response) => {
        this.payments = this.payments.map((p) => {
          if (p.id === response.id) {
            return response;
          }
          return p;
        });
        this.updatePaymentStatus();
      });
  }

  openDrawer(): void {

    if (!this.webHookCashDrawerOpen || this.webHookCashDrawerOpen.length < 5) {
      return;
    }
    this.financesPaymentsService.openCashDrawer().subscribe(
      (response) => {
        // console.log('response', response);
      });
  }


}
