import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';
import {distinctUntilKeyChanged, map, publishLast, refCount, retry, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Bike} from '../models/bike';
import {BikeCRMApiPaginated} from '../models/api';
import {BikeCRMApiAbstract} from './bikecrm-api-base';

@Injectable({
  providedIn: 'root'
})
export class BikesService extends BikeCRMApiAbstract {
  apiPath = 'bike';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getById(bikeID: string): Observable<Bike> {
    return this.http.get<Bike>(`${environment.apiUrl}/api/bike/${bikeID}/`).pipe(
      publishLast(),
      refCount()
    );
  }

  create(formData: FormData): Observable<Bike> {
    return this.http.post<Bike>(`${environment.apiUrl}/api/bike/`, formData)
      .pipe(map(newBike => {
        return newBike as Bike;
      }));
  }

  modifyByID(id: string, formData: FormData): Observable<Bike> {
    return this.http.patch(`${environment.apiUrl}/api/bike/${id}/`, formData)
      .pipe(
        map(modifiedBike => {
          return modifiedBike as Bike;
        }),
        retry(3));
  }

  getAll(): Observable<Bike[]> {
    const obs = this.http.get<BikeCRMApiPaginated<Bike>>(`${environment.apiUrl}/api/bike/`);
    return timer(0, environment.refreshRateNormal)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
        map((r) => r.results),
        retry(3)
      );
  }

  getOwned(ownerId: string): Observable<Bike[]> {
    const obs = this.http.get<BikeCRMApiPaginated<Bike>>(`${environment.apiUrl}/api/bike/`, { params: { owner__uuid: ownerId } });
    return timer(0, environment.refreshRateNormal)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
        map((r) => r.results),
        retry(3)
      );
  }

}
