import {BrowserModule} from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import {Integrations} from '@sentry/tracing';

import {APP_INITIALIZER, ErrorHandler, Injectable, isDevMode, LOCALE_ID, NgModule} from '@angular/core';

import {Router} from '@angular/router';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {LoginComponent} from './pages/login/login.component';
import {FullCalendarModule} from '@fullcalendar/angular';

import {ToastrModule} from 'ngx-toastr';

// Angular Material
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './services/auth-interceptor';
import {ClientsListComponent} from './pages/clients-list/clients-list.component';
import {ClientsDetailComponent} from './pages/clients-detail/clients-detail.component';
import {FilterListPipe} from './pipes/filter-list.pipe';
import {SlugifyPipe} from './pipes/slugify.pipe';
import {FinishedPipe} from './pipes/finished.pipe';
import {ConfirmationDialogComponent} from './components/utils/confirmation-dialog/confirmation-dialog.component';
import {BikesDetailComponent} from './pages/bikes-detail/bikes-detail.component';
import {ServicesheetListComponent} from './pages/servicesheet-list/servicesheet-list.component';
import {ServicesheetDetailComponent} from './pages/servicesheet-detail/servicesheet-detail.component';
import {BikeCardComponent} from './components/bike-card/bike-card.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {DateAgoPipe} from './pipes/date-ago.pipe';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {ImageKitImageComponent} from './components/image-kit-image/image-kit-image.component';
import {ImageProfileComponent} from './components/image-profile/image-profile.component';
import {MatNativeDateModule} from '@angular/material/core';
import {WorkshopListComponent} from './pages/workshop-list/workshop-list.component';
import {BusinessDetailComponent} from './components/business-detail/business-detail.component';
import {BikeTypePipe} from './pipes/bike-type';
import {MatSortModule} from '@angular/material/sort';
import {registerLocaleData} from '@angular/common';
import {
  PredefinedTasksSelectorDialogComponent
} from './components/dialogs/predefined-tasks-selector-dialog/predefined-tasks-selector.component';
import {NewBssStepperComponent} from './pages/new-bss-stepper/new-bss-stepper.component';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {AnalyticsCrmComponent} from './components/analytics-crm/analytics-crm.component';
import {EnumStrToArrayPipe} from './pipes/enum-to-array';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {Subject} from 'rxjs';
import {HotkeyModule, IHotkeyOptions} from 'angular2-hotkeys';
import {EditInputComponent} from './components/edit-input/edit-input.component';
import {AutofocusDirective} from './directives/autofocus.directive';
import {PosProductsDetailComponent} from './pages/pos-products-detail/pos-products-detail.component';
import {PosProductsListComponent} from './pages/pos-products-list/pos-products-list.component';
import {PosOrdersDetailComponent} from './pages/pos-orders-detail/pos-orders-detail.component';
import {PosOrderItemListComponent} from './components/pos-order-item-list/pos-order-item-list.component';
import {BssTaskListComponent} from './components/bss-task-list/bss-task-list.component';
import {PosOrdersListComponent} from './pages/pos-orders-list/pos-orders-list.component';
import {
  SimpleTextDialogComponent
} from './components/dialogs/simple-text-dialog-component/simple-text-dialog.component';
import {ProgressDialogComponent} from './components/dialogs/progress-dialog-component/progress-dialog.component';
import {ConfirmDialogComponent} from './components/utils/confirm-dialog/confirm-dialog.component';
import {InlineEditComponent} from './components/inline-edit/inline-edit.component';
import {FabCustomComponent} from './components/fab-custom/fab-custom.component';
import {StatisticsComponent} from './components/statistics/statistics.component';
import {NgChartsModule} from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// TODO: upgrade to new modular SDK to take advantatges of tree shacking:
//    https://github.com/angular/angularfire/blob/master/docs/version-7-upgrade.md
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAnalyticsModule, ScreenTrackingService} from '@angular/fire/compat/analytics';
import {ReportsWorkshopComponent} from './pages/reports-workshop/reports-workshop.component';
import {StatisticsWorkshopComponent} from './pages/statistics-workshop/statistics-workshop.component';
import {PaymentStatusCardComponent} from './components/payment-status-card/payment-status-card.component';
import {BssNotesComponent} from './components/bss-notes/bss-notes.component';
import {
  TabsUserRelatedComponentsComponent
} from './components/tabs-user-related-components/tabs-user-related-components.component';
import {DclWrapperComponent} from './components/dcl-wrapper/dcl-wrapper.component';
import {PosBrandsListComponent} from './pages/pos-brands-list/pos-brands-list.component';
import {PosBrandsDetailComponent} from './pages/pos-brands-detail/pos-brands-detail.component';
import {PosCategoriesDetailComponent} from './pages/pos-categories-detail/pos-categories-detail.component';
import {PosCategoriesListComponent} from './pages/pos-categories-list/pos-categories-list.component';
import {
  PrintLabelsDialogComponent
} from './components/dialogs/print-labels-dialog-component/print-labels-dialog.component';
import {FinancesBankAccountDetailComponent} from './pages/finances-bank-account-detail/finances-bank-account-detail';
import {FinancesBankTransactionList} from './components/finances-bank-transaction-list/finances-bank-transaction-list';
import {
  AccountingIncomeInvoicesListComponent
} from './pages/accounting-income-invoices-list/accounting-income-invoices-list.component';
import {LocaleService} from './services/locale.service';


import localeEsES from '@angular/common/locales/es';
import localeEsAR from '@angular/common/locales/es-AR';
import localeEnAE from '@angular/common/locales/en-AE';
import localeEsCL from '@angular/common/locales/es-CL';
import localeEsPE from '@angular/common/locales/es-PE';
import localeDE from '@angular/common/locales/de';
import localeFrBE from '@angular/common/locales/fr-BE';
import localeEnUS from '@angular/common/locales/en';
import {WorkflowButtonsComponent} from './components/workflow-buttons/workflow-buttons.component';
import {SatPopoverModule} from '@wjaspers/sat-popover';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ServiceWorkerModule} from '@angular/service-worker';
import {CalendarGeneralComponent} from './pages/calendar-general/calendar-general.component';
import {
  BikesInstalledProductsListComponent
} from './pages/bikes-installed-products-list/bikes-installed-products-list.component';
import {PosSupplierOrdersListComponent} from './pages/pos-supplier-orders-list/pos-supplier-orders-list.component';
import {
  PosSupplierOrdersDetailComponent
} from './pages/pos-supplier-orders-detail/pos-supplier-orders-detail.component';
import {
  PosSupplierOrderItemListComponent
} from './components/pos-supplier-order-item-list/pos-supplier-order-item-list.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {BarCodeScannerComponent} from './pages/barcode-scanner/barcode-scanner';
import {UserChangePassword} from './pages/user_change_password/user_change_password';
import {BssFilesComponent} from './components/bss-files/bss-files.component';
import {FilePondModule, registerPlugin} from 'ngx-filepond';
// import and register filepond file type validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import {InvoiceControlsComponent} from './components/invoice-controls/invoice-controls.component';
import {MoveStockDialogComponent} from './components/dialogs/move-stock-dialog-component/move-stock-dialog.component';
import {InventoryCountListComponent} from './pages/inventory-count-list/inventory-count-list.component';
import {InventoryCountDetailComponent} from './pages/inventory-count-detail/inventory-count-detail.component';
import {
  InventoryCountItemListComponent
} from './components/inventory-count-item-list/inventory-count-item-list.component';
import {RentalsListComponent} from './pages/rentals-list/rentals-list.component';
import {RentalDetailComponent} from './pages/rentals-detail/rental-detail.component';
import {RentalRentedBikesListComponent} from './components/rental-rented-bikes-list/rental-rented-bikes-list.component';
import {BikesListV2Component} from './pages/bikes-list-v2/bikes-list-v2.component';
import {RentalsTariffsListComponent} from './pages/rentals-tariffs-list/rentals-tariffs-list.component';
import {RentalsTariffsDetailComponent} from './pages/rentals-tariffs-detail/rentals-tariffs-detail.component';
import {
  RentalTariffsDurationsComponent
} from './components/rental-tariffs-durations-list/rental-tariffs-durations.component';
import {RentalRentedItemsListComponent} from './components/rental-rented-items-list/rental-rented-items-list.component';
import {
  RentalRentedAccessoriesListComponent
} from './components/rental-rented-accessories-list/rental-rented-accessories-list.component';
import {RentalsAccessoriesListComponent} from './pages/rentals-accessories-list/rentals-accessories-list.component';
import {RentalsAccessoryDetailComponent} from './pages/rentals-accessory-detail/rentals-accessory-detail.component';
import {SignPadDialogComponent} from './components/utils/sign-pad-dialog/sign-pad-dialog.component';
import {ReminderListComponent} from './components/reminder-list/reminder-list.component';
import {Chart} from 'chart.js';
import {CashChangeCalculatorComponent} from './components/cash-change-calculator/cash-change-calculator';


// Does this increase the bundle size? check if we can improve it or download only the needed locales
// TODO: lazy load locales
// locales are needed for currency formats (commas, dots, etc, and dates formats, mainly, maybe more)
// nothing to do with translations
// there are more locales on backend that still are not here
registerLocaleData(localeEsCL, 'es-CL');
registerLocaleData(localeEsES, 'es-ES');
registerLocaleData(localeEsAR, 'es-AR');
registerLocaleData(localeEnAE, 'en-AE');
registerLocaleData(localeEsPE, 'es-PE');
registerLocaleData(localeDE, 'de-DE');
registerLocaleData(localeFrBE, 'fr-BE');
registerLocaleData(localeEnUS, 'en-US');

// AoT requires an exported function for factories
// tslint:disable-next-line:typedef variable-name
export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  // return new MultiTranslateHttpLoader(_httpBackend, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
  if (environment.brand === 'bikecrm') {
    if (environment.isBeta) {
      console.log('http loader factory called for bikecrm');
    }
    return new MultiTranslateHttpLoader(_httpBackend, ['/assets/i18n/common/', '/assets/i18n/bikecrm/']);
  }
  if (environment.brand === 'skooterp') {
    if (environment.isBeta) {
      console.log('http loader factory called for skooterp');
    }
    return new MultiTranslateHttpLoader(_httpBackend, ['/assets/i18n/common/', '/assets/i18n/skooterp/']);
  }
  throw new Error('Unknown brand to load translations');
}

// tslint:disable-next-line:typedef
export function localeFactory(localeService: LocaleService) {
  if (environment.isBeta) {
    console.log('locale factory called');
    // This is `undefined` here now for some reason
    console.log(localeService.getLocale());
  }

  return localeService.getLocale() || 'en';
}

const APP_LOCALE_ID = {
  provide: LOCALE_ID,
  deps: [LocaleService],
  useFactory: localeFactory
};

if (environment.production) {
  let envName = 'unknown';
  if (environment.isBeta) {
    envName = 'beta';
  }
  if (!environment.isBeta) {
    envName = 'production';
  }
  Sentry.init({
    // TODO: move to environment:
    dsn: 'https://be9c9870b8b7494f831bf63b01899258@o464877.ingest.sentry.io/5476125',
    release: environment.version,
    environment: envName,
    enabled: environment.version !== 'LOCAL-DEV',
    integrations: [
      new Integrations.BrowserTracing({
        // TODO: move to environment:
        tracingOrigins: ['localhEnumStrToArrayPipeost', 'https://bikecrm.com', 'https://bikecrm.app', 'https://acceso.skooterp.com'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// https://material.angular.io/components/paginator/examples
// TODO: move to it's own file
@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  // For internationalization, the `$localize` function from
  // the `@angular/localize` package can be used.
  // firstPageLabel = $localize`First page`;
  // itemsPerPageLabel = $localize`Items per page:`;
  // lastPageLabel = $localize`Last page`;

  // You can set labels to an arbitrary string too, or dynamically compute
  // it through other third-party internationalization libraries.
  firstPageLabel = `First page`;
  itemsPerPageLabel = `Items per page:`;
  lastPageLabel = `Last page`;
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} of ${amountPages}`;
  }
}

// TODO: translate
const options: IHotkeyOptions = {
  disableCheatSheet: false, // disable the cheat sheet popover dialog? Default: false
  cheatSheetHotkey: '?', // key combination to trigger the cheat sheet. Default: '?'
  cheatSheetCloseEsc: true, // use also ESC for closing the cheat sheet. Default: false
  // tslint:disable-next-line:max-line-length
  cheatSheetCloseEscDescription: 'Hide this help menu', // description for the ESC key for closing the cheat sheet (if enabed). Default: 'Hide this help menu'
  // tslint:disable-next-line:max-line-length
  cheatSheetDescription: 'Show / hide this help menu', // description for the cheat sheet hot key in the cheat sheet. Default: 'Show / hide this help menu'
};

registerPlugin(FilePondPluginFileValidateType);


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ClientsListComponent,
    ClientsDetailComponent,
    SlugifyPipe,
    EnumStrToArrayPipe,
    FilterListPipe,
    FinishedPipe,
    BikeTypePipe,
    DateAgoPipe,
    ConfirmationDialogComponent,
    ConfirmDialogComponent,
    PosProductsDetailComponent,
    PosProductsListComponent,
    PosBrandsListComponent,
    PosBrandsDetailComponent,
    PosOrdersDetailComponent,
    PosCategoriesDetailComponent,
    PosCategoriesListComponent,
    PosOrdersListComponent,
    PosSupplierOrdersListComponent,
    PosSupplierOrdersDetailComponent,
    PosSupplierOrderItemListComponent,
    InventoryCountListComponent,
    InventoryCountDetailComponent,
    InventoryCountItemListComponent,
    UserChangePassword,
    BikesListV2Component,
    BikesDetailComponent,
    BssTaskListComponent,
    BarCodeScannerComponent,
    BssNotesComponent,
    BssFilesComponent,
    BikesInstalledProductsListComponent,
    StatisticsComponent,
    AccountingIncomeInvoicesListComponent,
    ServicesheetListComponent,
    ServicesheetDetailComponent,
    CashChangeCalculatorComponent,
    BikeCardComponent,
    FabCustomComponent,
    SimpleTextDialogComponent,
    PrintLabelsDialogComponent,
    MoveStockDialogComponent,
    FinancesBankAccountDetailComponent,
    FinancesBankTransactionList,
    ProgressDialogComponent,
    AutofocusDirective,
    EditInputComponent,
    InlineEditComponent,
    InvoiceControlsComponent,
    NotFoundComponent,
    DashboardComponent,
    ImageKitImageComponent,
    ImageProfileComponent,
    WorkshopListComponent,
    BusinessDetailComponent,
    PosOrderItemListComponent,
    ReminderListComponent,
    RentalsListComponent,
    RentalDetailComponent,
    RentalRentedBikesListComponent,
    RentalsTariffsListComponent,
    RentalsTariffsDetailComponent,
    RentalTariffsDurationsComponent,
    RentalRentedItemsListComponent,
    RentalRentedAccessoriesListComponent,
    RentalsAccessoriesListComponent,
    RentalsAccessoryDetailComponent,
    SignPadDialogComponent,
    StatisticsWorkshopComponent,
    ReportsWorkshopComponent,
    CalendarGeneralComponent,
    PredefinedTasksSelectorDialogComponent,
    NewBssStepperComponent,
    AnalyticsCrmComponent,
    PaymentStatusCardComponent,
    TabsUserRelatedComponentsComponent,
    DclWrapperComponent,
    WorkflowButtonsComponent
  ],
    imports: [
        BrowserModule,
        FilePondModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        HotkeyModule.forRoot(options),
        AppRoutingModule,
        FormsModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FullCalendarModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatTooltipModule,
        MatSortModule,
        MatStepperModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatGridListModule,
        MatExpansionModule,
        MatCardModule,
        MatAutocompleteModule,
        HttpClientModule,
        FlexLayoutModule,
        MatListModule,
        MatDialogModule,
        MatTableModule,
        ZXingScannerModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatSidenavModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatButtonModule,
        MatInputModule,
        SatPopoverModule,
        NgChartsModule,
        MatProgressBarModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
  providers: [
    {
      // To be able to use custom icons on material stepper
      // https://material.angular.io/components/stepper/overview
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false}
    },
    APP_LOCALE_ID,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false, // Show dialog so the user can send additional feedback, TODO: true on beta
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl},
    ScreenTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    Chart.register(ChartDataLabels);
  }
}
