import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UsersService} from 'src/app/services/users.service';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-user_change_password',
  templateUrl: './user_change_password.html',
  styleUrls: ['./user_change_password.scss']
})
export class UserChangePassword implements OnInit {
  // TODO: check if they are the same password
  form: UntypedFormGroup;
  loading = false;
  submitted = false;

  brand = environment.brand;

  successMessage = '';
  errorsCurrentPassword = '';
  nonFieldErrors = '';
  newPassword2Errors = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private formBuilder: UntypedFormBuilder,
    private notifications: CrmNotificationsService
  ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      current_password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit(): void {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.successMessage = '';
    this.errorsCurrentPassword = '';
    this.newPassword2Errors = '';
    this.nonFieldErrors = '';

    if (this.f.new_password.value !== this.f.confirm_password.value) {
      this.nonFieldErrors = 'ERROR_PASSWORDS_DO_NOT_MATCH';
      return;
    }

    if (this.f.current_password.value === this.f.new_password.value) {
      this.nonFieldErrors = 'NEW_PASSWORD_MUST_BE_DIFFERENT_FROM_CURRENT_PASSWORD';
      return;
    }

    this.loading = true;

    this.userService.changePassword(this.f.current_password.value, this.f.new_password.value, this.f.confirm_password.value)
      .pipe(first())
      .subscribe(
      data => {
        this.successMessage = 'PASSWORD_CHANGED_SUCCESSFULLY';
        this.loading = false;
        this.submitted = false;
        this.form.reset();
      },
      error => {
        this.loading = false;
        let unknownError = true;
        // console.log(error.error);
        try {
          if (error.error.hasOwnProperty('old_password')) {
            this.errorsCurrentPassword = error.error.old_password[0];
            unknownError = false;
          }

          if (error.error.hasOwnProperty('non_field_errors')) {
            this.nonFieldErrors = error.error.non_field_errors[0];
            unknownError = false;
          }

          if (error.error.hasOwnProperty('new_password2')) {
            this.newPassword2Errors = error.error.new_password2[0];
            unknownError = false;
          }
        } catch (e) {
          // TODO sentry
          console.log(e);
          unknownError = true;
        }

        if (unknownError) {
          this.notifications.errorI18N('ERROR_CHANGING_PASSWORD');
          // TODO: sentry
        }
      }
    );
  }

}
