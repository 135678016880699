import {Component, Input, OnInit} from '@angular/core';
import {ServiceSheet} from 'src/app/models/servicesheets';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {ServiceSheetService} from '../../services/service-sheets.service';


// TODO: use some abstract component to reduce code? just some detail that we only use 2 fields (bss)
@Component({
  selector: 'app-bss-notes',
  templateUrl: './bss-notes.component.html',
  styleUrls: ['./bss-notes.component.scss'],
})
export class BssNotesComponent implements OnInit {
  @Input() parent: ServiceSheet;

  notesPublic: string;
  notesPrivate: string;

  constructor(
    private notificationService: CrmNotificationsService,
    private serviceSheetService: ServiceSheetService,
  ) {
  }

  ngOnInit(): void {
    this.notesPublic = this.parent.generalNotesPublic;
    this.notesPrivate = this.parent.generalNotesPrivate;
  }

  saveNotes(): void {
    if (this.notesPublic !== this.parent.generalNotesPublic || this.notesPrivate !== this.parent.generalNotesPrivate) {
      this.parent.generalNotesPublic = this.notesPublic;
      this.parent.generalNotesPrivate = this.notesPrivate;

      const obj = {
        generalNotesPublic: this.notesPublic || '',
        generalNotesPrivate: this.notesPrivate || ''
      };
      this.serviceSheetService.modify(this.parent.id, obj).subscribe(
        () => {
          // call notification service success:
          this.notificationService.successI18N('SAVED');
        },
        () => {
          // call notification service error:
          this.notificationService.errorI18N('ERROR');
        }
      );
    }
  }
}
