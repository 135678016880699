import {AbstractBaseItem} from './abstract_base_api_item';

export enum PaymentStatus {
  Unknown = 'unk',
  Paid = 'pai',
  PendingAndPartiallyPaid = 'thisisalist,pen,par',
  Pending = 'pen',
  PartiallyPaid = 'par',
  AdvancePayment = 'adv',
}

export enum PaymentMethods {
  Unknown = 'unk',
  Cash = 'cas',
  Card = 'car',
  Bizum = 'biz',
  Financed = 'fin',
  BankTransfer = 'tra',
  Payconiq = 'piq',
  Paypal = 'pay',
  MercadoPago = 'mer',
  Debit = 'deb',
  Credit = 'cre',
  Other = 'oth'
}

// TODO: use enums as keys
export const PAYMENT_STATUS_I18N_KEY = {
  unk: 'PAYMENTS.UNKNOWN',
  pai: 'PAYMENTS.PAID',
  pen: 'PAYMENTS.PENDING',
  par: 'PAYMENTS.PARTIALLY_PAID',
  adv: 'PAYMENTS.ADVANCE_PAYMENT',
};

// TODO: use enums as keys
export const PAYMENT_METHOD_I18N_KEY = {
  unk: 'PAYMENTS.UNKNOWN',
  cas: 'PAYMENTS.CASH',
  car: 'PAYMENTS.CARD',
  biz: 'Bizum',
  fin: 'PAYMENTS.FINANCED',
  tra: 'PAYMENTS.BANK_TRANSFER',
  piq: 'Payconiq',
  mix: 'PAYMENTS.MIXED',
  pay: 'Paypal',
  mer: 'MercadoPago',
  deb: 'PAYMENTS.DEBIT_CARD',
  cre: 'PAYMENTS.CREDIT_CARD',
  oth: 'PAYMENTS.OTHER',
};

export class BillableItem extends AbstractBaseItem {
  roPaymentStatus: string;
  roPaymentMethod: string;
  roPaymentDt: string;
  totalAmountWithTax: number;

  static isBillableItem(obj: object): boolean {
    return (obj.hasOwnProperty('roPaymentMethod') && obj.hasOwnProperty('roPaymentStatus'));
  }
}

// TODO: move to a separate file:
export class Payment extends AbstractBaseItem {
  id: string;
  paymentMethod: string;
  paymentDt: string;

  amount: number;
}
