import {Component, Inject, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


interface Currency {
  code: string;
  symbol: string;
  denominations: number[];
  billThreshold: number;
}

@Component({
  selector: 'app-cash-change-calculator',
  templateUrl: './cash-change-calculator.component.html',
  styleUrls: ['./cash-change-calculator.component.scss']
})
export class CashChangeCalculatorComponent implements OnInit {
  @Input() totalPrice = 0;
  @Input() currencyCode = 'EUR';

  calculatorForm: FormGroup;
  change = 0;
  amountPaid = 0;
  changeBreakdown: { [key: number]: number } = {};

  currencies: Currency[] = [
    {
      code: 'USD',
      symbol: '$',
      denominations: [100, 50, 20, 10, 5, 2, 1, 0.5, 0.25, 0.1, 0.05, 0.01],
      billThreshold: 1
    },
    {
      code: 'EUR',
      symbol: '€',
      denominations: [500, 200, 100, 50, 20, 10, 5, 2, 1, 0.5, 0.2, 0.1, 0.05, 0.02, 0.01],
      billThreshold: 5
    },
  ];

  currentCurrency: Currency;

  constructor(private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: {totalPrice: string, currencyCode: string}) {
    this.calculatorForm = this.fb.group({});
    this.currentCurrency = this.currencies[0];
    if (data.totalPrice) {
      this.totalPrice = parseFloat(data.totalPrice);
    }
    if (data.currencyCode) {
      this.currencyCode = data.currencyCode;
    }
  }

  ngOnInit(): void {
    this.currentCurrency = this.currencies.find(c => c.code === this.currencyCode) || this.currencies[0];
    this.initForm();
  }

  initForm(): void {
    this.calculatorForm = this.fb.group({});
    this.currentCurrency.denominations.forEach(denomination => {
      this.calculatorForm.addControl(denomination.toString(), this.fb.control(0));
    });
  }

  getControl(denomination: number | string): AbstractControl {
    if (denomination.toString().includes('.')) {
      return this.calculatorForm.controls[denomination.toString()];
    }
    return this.calculatorForm.get(denomination.toString());
  }

  updatePaidAmount(denomination: number, increment: boolean): void {
    const control = this.getControl(denomination);
    if (control) {
      const currentValue = control.value || 0;
      control.setValue(increment ? currentValue + 1 : Math.max(0, currentValue - 1));
      this.calculateChange();
    }
  }

  calculateChange(): void {
    if (this.calculatorForm.valid) {
      this.amountPaid = 0;

      Object.keys(this.calculatorForm.controls).forEach(key => {
        const denomination = parseFloat(key);
        const count = this.getControl(key)?.value || 0;
        this.amountPaid += denomination * count;
      });

      this.change = Math.max(0, +(this.amountPaid - this.totalPrice).toFixed(2));
      this.breakdownChange();
    }
  }

  breakdownChange(): void {
    this.changeBreakdown = {};
    let remainingChange = this.change;

    for (const denomination of this.currentCurrency.denominations) {
      const count = Math.floor(remainingChange / denomination);
      if (count > 0) {
        this.changeBreakdown[denomination] = count;
        remainingChange = +(remainingChange - denomination * count).toFixed(2);
      }
    }
  }

  // formatCurrency(value: number): string {
  //   return `${this.currentCurrency.symbol}${(value || 0).toFixed(2)}`;
  // }

  isBill(denomination: number): boolean {
    return denomination >= this.currentCurrency.billThreshold;
  }
}
