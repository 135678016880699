import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Bike} from 'src/app/models/bike';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {UsersService} from '../../services/users.service';
import {
  DetailField,
  DetailFieldTypes,
  ItemDetailAbstractComponent
} from '../../components/abstract-item-detail/abstract-item-detail.component';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {RentalsTariffsService} from '../../services/rentals-tariffs.service';
import {RentalTariff} from '../../models/rental_tariff';
import {RentalAccessoriesService} from '../../services/rental-accessories.service';

// tslint:disable-next-line:variable-name
export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};


@Component({
  selector: 'app-rentals-accessory-detail',
  templateUrl: '../../components/abstract-item-detail/abstract-item-detail.component.html',
  styleUrls: ['../../components/abstract-item-detail/abstract-item-detail.component.scss']
})
export class RentalsAccessoryDetailComponent extends ItemDetailAbstractComponent<Bike> implements OnInit {
  navBase = '/rentals_accessories/';

  showTopComponent = true;
  hideDetailFields = true;

  printLabelsEnabled = true;

  bikeTypesSimpleList = null;

  editableFields = {
    name: new DetailField('NAME', DetailFieldTypes.text, '', ['start', 0, 100], [Validators.required]),
    // TODO:
    // type: new DetailField('TYPE', DetailFieldTypes.selector, '', ['start', 1, 33]),
    // sn: new DetailField('SERIAL_NUMBER', DetailFieldTypes.text, '', ['start', 1, 33]),
    rentalTariff: new DetailField('RENTAL_TARIFF', DetailFieldTypes.selector, '', ['start', 3, 33]),
    picture: new DetailField('MAIN_PICTURE', DetailFieldTypes.picture, '', ['start', 4, 33]),
    // owner: new DetailField('OWNER', DetailFieldTypes.hidden, '', ['start', 4, 33]),
  };

  constructor(
    @Optional() public dialogRef: MatDialogRef<RentalsAccessoryDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { itemId: string, extraMessageI18nKey: string, mode: string },
    protected notificationService: CrmNotificationsService,
    protected activatedRoute: ActivatedRoute,
    protected translate: TranslateService,
    protected router: Router,
    protected rentalAccessoriesService: RentalAccessoriesService,
    protected formBuilder: UntypedFormBuilder,
    protected usersService: UsersService,
    protected dialog: MatDialog,
    protected nativeInterfacesService: NativeInterfacesService,
    protected rentalsTariffsService: RentalsTariffsService,
  ) {
    super(dialogRef,
      data,
      router,
      activatedRoute,
      notificationService,
      translate,
      formBuilder,
      dialog,
      rentalAccessoriesService,
      nativeInterfacesService,
      usersService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onFormReady(): void {
    super.onFormReady();
  }

  loadDataItem(item: Bike = null): void {
    super.loadDataItem(item);
    // update bike card:
    this.loadTopComponent();
  }

  getSelectFieldOptionsList(field: string): Array<{ value: string, label: string }> {
    if (field in this.selectorFieldsOptions && this.selectorFieldsOptions[field] != null) {
      return this.selectorFieldsOptions[field];
    }

    switch (field) {
      case 'rentalTariff':
        this.selectorFieldsOptions[field] = [];
        this.rentalsTariffsService.getList({}).subscribe((tariffs: RentalTariff[]) => {
          if (this.selectorFieldsOptions[field].length > 0) {
            return this.selectorFieldsOptions[field];
          }
          for (const t of tariffs) {
            this.selectorFieldsOptions[field].push({value: t.id, label: t.name});
            }
        });
        return this.selectorFieldsOptions[field];
      // case 'type':
      //   TODO: set for accessory type and not bike types
      //   if (this.bikeTypesSimpleList) {
      //     return this.bikeTypesSimpleList;
      //   }
      //   const groups = this.usersService.getCustomConfig().get_bike_types();
      //   // TODO: add support for groups, as in the stepper
      //   this.bikeTypesSimpleList = [];
      //   for (const group of groups) {
      //     // tslint:disable-next-line:no-string-literal
      //     for (const type of group['bikes']) {
      //       this.bikeTypesSimpleList.push({value: type.value, label: type.I18N_KEY});
      //     }
      //   }
      //   return this.bikeTypesSimpleList;
      default:
        return [];
    }
  }

  // printLabelItem(bike: Bike): void {
  //   window.open(`${environment.apiUrl}/bikes/print/labels?bike_id=${bike.id}&print=1`, '_blank');
  // }

}
