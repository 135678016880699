import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BikeCRMApiAbstract} from './bikecrm-api-base';
import {UsersService} from './users.service';

@Injectable({
  providedIn: 'root'
})
export class RentedBikesService extends BikeCRMApiAbstract {
  apiPath = 'rentals_rented_bikes';

  constructor(
    private userService: UsersService,
    protected http: HttpClient
  ) {
    super(http);
  }

  getRentedBikes(rentalId: string): Observable<any> {
    return this.getList({rental: rentalId});
  }

  // create(data: FormData): Observable<any> {
  //   data.set('openedBy', this.userService.selectedMechanic.id);
  //   return super.create(data);
  // }
  //
  // getOrderItems(orderId: string): Observable<OrderItem[]> {
  //   const obs = this.http.get<BikeCRMApiPaginated<OrderItem>>(
  //     `${environment.apiUrl}/api/pos_order_item/`,
  //     {params: {object_id: orderId, content_type: 'order'}}
  //   );
  //   // for now, refreshing the tasks from a sheet is causing duplicates, as you create one task,
  //   //          we save it, and then we retrieve the same one from the server
  //   // TODO: return errors and retry with delays, etc
  //   return timer(0, environment.refreshRateUltraLow)
  //     .pipe(
  //       switchMap(() => obs),
  //       distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
  //       map((r) => r.results),
  //       retry(3)
  //     );
  // }
  //
  // generateInvoice(orderId: string): Observable<any> {
  //   return this.http.post(`${environment.apiUrl}/api/${this.apiPath}/${orderId}/generate_invoice/`, {})
  //     .pipe(
  //       map(r => {
  //         return r;
  //       }),
  //       retry(3));
  // }
  //
  // sendTicketToClient(orderId: string): Observable<any> {
  //   return this.http.post(`${environment.apiUrl}/api/${this.apiPath}/${orderId}/send_ticket_client/`, {})
  //     .pipe(
  //       map(r => {
  //         return r;
  //       }),
  //       retry(3));
  // }
  //
  // sendInvoiceToClient(orderId: string): Observable<any> {
  //   return this.http.post(`${environment.apiUrl}/api/${this.apiPath}/${orderId}/send_invoice_client/`, {})
  //     .pipe(
  //       map(r => {
  //         return r;
  //       }),
  //       retry(3));
  // }
}
