import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BikeCRMApiAbstract} from './bikecrm-api-base';

@Injectable({
  providedIn: 'root'
})
export class FinancesBankAccountService extends BikeCRMApiAbstract {
  apiPath = 'bank_account';

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

}
