import {Component, ComponentRef, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {User} from 'src/app/models/user';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {UsersService} from 'src/app/services/users.service';
import {
  DetailField,
  DetailFieldTypes,
  ItemDetailAbstractComponent
} from '../../components/abstract-item-detail/abstract-item-detail.component';
import {takeUntil} from 'rxjs/operators';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {
  TabsUserRelatedComponentsComponent
} from '../../components/tabs-user-related-components/tabs-user-related-components.component';
import {ServicesheetListComponent, viewModes} from '../servicesheet-list/servicesheet-list.component';
import {PosOrdersListComponent} from '../pos-orders-list/pos-orders-list.component';
import {PosSupplierOrdersListComponent} from '../pos-supplier-orders-list/pos-supplier-orders-list.component';
import {BikesListV2Component} from "../bikes-list-v2/bikes-list-v2.component";

@Component({
  selector: 'app-clients-detail',
  templateUrl: '../../components/abstract-item-detail/abstract-item-detail.component.html',
  styleUrls: ['../../components/abstract-item-detail/abstract-item-detail.component.scss']
  // providers: [SlugifyPipe]
})
export class ClientsDetailComponent extends ItemDetailAbstractComponent<User> implements OnInit {
  mode = 'clients';  // clients, staff, suppliers

  navBase = `/${this.mode}/`;

  editableFields = {
    name: new DetailField('NAME', DetailFieldTypes.text, '', ['start', 0, 66], [Validators.required]),
    isProfessional: new DetailField('IS_PROFESSIONAL', DetailFieldTypes.boolean, false, ['start', 0, 33]),
    email: new DetailField('EMAIL', DetailFieldTypes.text, '', ['start', 1, 33], [Validators.email]),
    phoneNumber: new DetailField('PHONE_NUMBER', DetailFieldTypes.phone, '', ['start', 1, 33], [Validators.maxLength(17)]),

    nif: new DetailField('NIF', DetailFieldTypes.text, '', ['start', 1, 33]),
    address: new DetailField('ADDRESS', DetailFieldTypes.text, '', ['start', 2, 50]),
    city: new DetailField('CITY', DetailFieldTypes.text, '', ['start', 2, 50]),

    postalCode: new DetailField('POSTAL_CODE', DetailFieldTypes.text, '', ['start', 4, 50]),
    country: new DetailField('COUNTRY', DetailFieldTypes.text, '', ['start', 4, 50]),

    denormLastVisit: new DetailField('LAST_VISIT', DetailFieldTypes.date, '', ['start', 0, 0], null, true),

    notes: new DetailField('NOTES', DetailFieldTypes.textArea, '', ['start', 5, 100]),

    profilePicture: new DetailField('PROFILE_PICTURE', DetailFieldTypes.profile_picture, '', ['start', 6, 33])
  };

  relatedComponentRef: ComponentRef<any>;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ClientsDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { itemId: string, extraMessageI18nKey: string, mode: string },
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected notificationService: CrmNotificationsService,
    protected translate: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected usersService: UsersService,
    protected nativeInterfacesService: NativeInterfacesService
  ) {
    super(dialogRef,
      data,
      router,
      activatedRoute,
      notificationService,
      translate,
      formBuilder,
      dialog,
      usersService,
      nativeInterfacesService,
      usersService);
  }


  ngOnInit(): void {
    if (this.dialogRef != null) {
      this.dialogMode = true;
    }
    // TODO: improve logic to determine if we should create staff or client member,
    //      make mandatory for all calls to explicitly set if its staff or client
    if (this.dialogMode) {
      if (this.data.mode === 'staff-detail-edit') {
        this.mode = 'staff';
      }
    } else {
      if (this.activatedRoute.snapshot.url[0].path === 'staff') {
        this.mode = 'staff';
      } else if (this.activatedRoute.snapshot.url[0].path === 'suppliers') {
        this.mode = 'suppliers';
      }
    }
    this.navBase = `/${this.mode}/`;

    // tslint:disable-next-line:no-string-literal
    this.editableFields['isBusinessClient'] = new DetailField('', DetailFieldTypes.hidden, (this.mode === 'clients').toString(), ['start', 0, 0]);
    // tslint:disable-next-line:no-string-literal
    this.editableFields['isBusinessEmployee'] = new DetailField('', DetailFieldTypes.hidden, (this.mode === 'staff').toString(), ['start', 0, 0]);
    // tslint:disable-next-line:no-string-literal
    this.editableFields['isBusinessSupplier'] = new DetailField('', DetailFieldTypes.hidden, (this.mode === 'suppliers').toString(), ['start', 0, 0]);

    super.ngOnInit();

    if (this.dialogMode) {
      delete this.editableFields.profilePicture;
    }
  }

  getContactPhoneItem(item: User): string {
    return item.phoneNumberFormatted;
  }

  onFormSubmit(): void {
    super.onFormSubmit();

    // This is nor perfect, even and edit will trigger another mechanic count increment, and then you will see assigned to fields
    // the main case we are trying to fix is that you add a mechanich and you don't see the fields inmediatly, the business objects refreshes
    // from server regularly, so it will be fixed in a few seconds if you didn't actually add a mechanic. TODO: still we should fix this
    // ondelete, on edit, and on create are different cases
    if (this.mode === 'staff' && this.usersService.business) {
      this.usersService.business.mechanicsCount += 1;
    }
  }

  loadRelatedComponent(): void {
    // we can abstract the takeuntil ondestroy to the abstract parent class
    // Load list of bikes and bss for the client
    // TODO: if its supplier load list of filtered products
    if (!this.item$) {
      return;
    }
    this.item$.pipe(takeUntil(this.onDestroy$)).subscribe((i) => {
      if (!this.relatedComponentHost) {
        return;
      }
      this.relatedComponentHost.clear();
      this.relatedComponentRef = this.relatedComponentHost.createComponent(TabsUserRelatedComponentsComponent);
      // this.relatedComponentRef = this.viewContainerRef.createComponent(TabsUserRelatedComponentsComponent);
      // TODO: Setting params for the component once is created causes ExpressionChangedAfterItHasBeenCheckedError
      //  on debug mode, we should improve this. maybe with a setter?


      if (this.mode === 'clients') {
        this.relatedComponentRef.instance.tabComponents = [
          {i18n_label: 'SERVICE_SHEETS', component: ServicesheetListComponent, params: {minimal: true, parentId: i.id, parentApiFilter: 'bike__owner__id', defaultFilters: {closed: ''}}},
          {i18n_label: 'BIKES', component: BikesListV2Component, params: {user: i}},
          {i18n_label: 'SALES', component: PosOrdersListComponent, params: {parentId: i.id, parentApiFilter: 'client__id'}},
          {i18n_label: 'BUDGETS', component: ServicesheetListComponent, params: {minimal: true, parentId: i.id, parentApiFilter: 'bike__owner__id', viewMode: viewModes.BudgetView, defaultFilters: {budget_status: 'thisisalist,nea,den,app'}}},
        ];
      }
      else if (this.mode === 'staff') {
        this.relatedComponentRef.instance.tabComponents = [
          {i18n_label: 'SERVICE_SHEETS', component: ServicesheetListComponent, params: {minimal: true, defaultFilters: {assigned_to_id: i.id}}},
          // {i18n_label: 'BIKES', component: BikesListComponent, params: {user: i}},
          // {i18n_label: 'SALES', component: PosOrdersListComponent, params: {parentId: i.id, parentApiFilter: 'client__id'}},
          // {i18n_label: 'BUDGETS', component: ServicesheetListComponent, params: {minimal: true, parentId: i.id, parentApiFilter: 'bike__owner__id', viewMode: viewModes.BudgetView, defaultFilters: {budget_status: 'thisisalist,nea,den,app'}}},
        ];
      }
      else if (this.mode === 'suppliers') {
        this.relatedComponentRef.instance.tabComponents = [
          {i18n_label: 'SUPPLIER_ORDERS', component: PosSupplierOrdersListComponent, params: {parentId: i.id, parentApiFilter: 'supplier__id', defaultFilters: {closed: ''}}},
        ];
      }
    });
  }

}
