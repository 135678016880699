<div class="base-paddings">
    <ng-container *ngIf="parentId">
        <ng-container *ngIf="parentItem; else loading">

            <!-- Fab -->
            <app-custom-fab [primaryFab]="primaryFab" [secondaryFab]="secondaryFab" (fabAction)="onFabAction($event)" [ngClass]="readyToAddChildItems ? '' : 'disabled'"></app-custom-fab>

            <!--                  TODO: extract this to some component -->
<!--            <div *ngIf="showSharePrintButtons" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="14px">-->
<!--              <img [matMenuTriggerFor]="sendMenuWhatsapp" alt="Send whatsapp" class="mr2 pointer" style="width: 24px;" src="/assets/images/icons/whatsapp_fill.svg"/>-->
<!--              <mat-icon [matMenuTriggerFor]="sendMenuMail" class="pointer">mail</mat-icon>-->
<!--              <mat-icon [matMenuTriggerFor]="printMenu" class="pointer">print</mat-icon>-->
<!--              <mat-icon [matMenuTriggerFor]="downloadPDFMenu" class="pointer">picture_as_pdf</mat-icon>-->
<!--              <mat-icon [matMenuTriggerFor]="moreBssOptsMenu" class="pointer">more_vert</mat-icon>-->
<!--            </div>-->

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
                <!-- Left/top info pane -->
                <div fxFlex.gt-sm="30" class="contrast-bg h100" fxLayout="column">
                  <div fxLayout="column" fxLayoutAlign="center center" class="pt2">
                    <h1 *ngIf="titleI18N">{{ titleI18N | translate }}</h1>
                    <mat-icon *ngIf="titleIcon" aria-hidden="true" aria-label="" style="font-size: 140px; color: gray; width: 140px; height: 140px;">{{ titleIcon }}</mat-icon>
                  </div>
                    <!-- Order info -->
                    <div class="p2" fxLayout="column">
                      <!-- TODO: better abstract this, but for now, every class that inherits this can add here some specific code -->
                        <ng-container *ngIf="!parentItem.hasOwnProperty('balance')">
                          <span *ngIf="parentItem.shortPrettyId">{{ 'ID' | translate }}: {{ parentItem.shortPrettyId }}</span>
                          <span>{{ 'DATE' | translate }}: {{ parentItem.createdAt | date }}</span>
                          <span *ngIf="parentItem.pendingItemsToScannCount">{{ 'PENDING_ITEMS_TO_SCAN' | translate }}: {{ parentItem.pendingItemsToScannCount }}</span>
                        </ng-container>

                        <!-- TODO this is crying for a refactor:  -->
                        <!-- Supplier Order:  -->
                        <ng-container *ngIf="parentItem.hasOwnProperty('documentType') && parentItem.hasOwnProperty('supplierName')">
                          <mat-form-field>
                            <mat-label>{{ "DOCUMENT_TYPE" | translate }}</mat-label>
                            <mat-select (selectionChange)="selectedDocumentType($event.value)" [value]="parentItem.documentType">
                              <mat-option value="invoic">{{ "INVOICE" | translate }}</mat-option>
                              <mat-option value="ticket">{{ "TICKET" | translate }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>{{ "DOCUMENT_NUMBER" | translate }}</mat-label>
                            <input matInput [(ngModel)]="parentItem.documentNumber" (change)="changedDocumentNumber()">
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="parentItem.hasOwnProperty('supplierName')">
                          <span>{{ 'SUPPLIER' | translate }}: {{ parentItem.supplierName }}</span>
                        </ng-container>

                        <!-- TODO this is crying for a refactor:  -->
                        <!-- Rental: if fields start_datetime and pickup_datetime -->
                        <ng-container #rentalStatusSelect *ngIf="parentItem.hasOwnProperty('startDatetime') && parentItem.hasOwnProperty('pickupDatetime')">
                          <img *ngIf="parentItem.freehandSignatureUrl" [src]="parentItem.freehandSignatureUrl" alt="Freehand signature" style="max-width: 100%; max-height: 100px; object-fit: contain;">
                          <mat-form-field>
                            <mat-label>{{ "RENTAL_STATUS" | translate }}</mat-label>
                            <mat-select (selectionChange)="changeRentalStatus($event.value)" [value]="parentItem.status">
                              <mat-option *ngFor="let status of rentalStatuses" [value]="status.value">{{ status.labeli18N | translate }}</mat-option>
                            </mat-select>
                          </mat-form-field>

                          <app-inline-edit type="datetime" [titleIsTheData]="false" [data]="parentItem.startDatetime" (focusOut)="startDatetimeChanged($event)" title="{{ 'RENTAL_START_DATE' | translate }}"></app-inline-edit>
                          <app-inline-edit type="datetime" [titleIsTheData]="false" [data]="parentItem.endDatetime" (focusOut)="endDatetimeChanged($event)" title="{{ 'RENTAL_END_DATE' | translate }}"></app-inline-edit>
                        </ng-container>
                        <!-- TODO this is crying for a refactor:  -->
                        <!-- Rental tariff -->
                        <ng-container *ngIf="parentItem.hasOwnProperty('excessDurationEveryHours') && parentItem.hasOwnProperty('excessDurationPrice')">
                          <mat-form-field>
                            <mat-label>{{ "TARIFF_NAME" | translate }}</mat-label>
                            <input matInput [(ngModel)]="parentItem.name" (change)="changedTariffName()">
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>{{ "TARIFF_EXCESS_HOURS_FREQUENCY" | translate }}</mat-label>
                            <input matInput [(ngModel)]="parentItem.excessDurationEveryHours" (change)="excessDurationEveryHoursChanged()">
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>{{ "TARIFF_EXCESS_PRICE" | translate }}</mat-label>
                            <input matInput [value]="excessDurationPriceWithVat" (change)="excessDurationPriceChanged($event)">
                          </mat-form-field>
                        </ng-container>

                        <!-- TODO: better abstract this, but for now, every class that inherits this can add here some specific code -->
                        <ng-container *ngIf="parentItem.hasOwnProperty('clientName')">
                          <!-- TODO: use component client detail in small mode (maybe set a template here of customInfo) and there add the component -->
                          <span>{{ 'CLIENT' | translate }}: {{ parentItem.clientName }}</span>
                        </ng-container>

<!--                        <mat-divider></mat-divider>-->
                        <ng-container *ngIf="parentItem.hasOwnProperty('invoiceSeries') && (parentItem.budgetStatus == 'don' || parentItem.budgetStatus == 'app')">
                          <app-invoice-controls
                            [invoiceSeries]="parentItem.invoiceSeries"
                            [invoiceNumber]="parentItem.invoiceNumber"
                            [invoiceDate]="parentItem.invoiceDate"
                            (invoiceSeriesChange)="changeInvoiceSeries($event)"
                            (generateInvoiceEvent)="generateInvoice()">
                          </app-invoice-controls>
                        </ng-container>
<!--                        <mat-divider></mat-divider>-->

                    </div>
                    <mat-divider class="pb2" *ngIf="actions.length > 0"></mat-divider>

                  <!-- Actions -->
                    <div class="p2" fxLayout="column" *ngIf="actions.length > 0">
                      <ng-container *ngFor="let a of (actions.length == 4 ? actions : actions.slice(0,3)); first as isFirst">
                        <button mat-stroked-button [disabled]="!a.enabled"
                                class="mt2"
                                color="{{ isFirst ? 'primary' : 'accent' }}" (click)="onAction(a.id)">{{ a.i18nKey | translate}}</button>
                      </ng-container>
                      <ng-container *ngIf="actions.length > 4">
                        <button class="mt2" mat-button color="accent" [matMenuTriggerFor]="menu_more_actions">
                          <!-- <mat-icon>arrow_drop_down</mat-icon> -->
                          {{ 'MORE_ACTIONS' | translate | uppercase }}
                        </button>
                      </ng-container>
                    </div> <!-- Actions End -->
                </div>

                <!-- Right/bottom info pane -->
                <div fxFlex.gt-sm="70" fxLayout="column" fxLayoutGap="10px" class="pb4 mb4 h100" [ngClass]="readyToAddChildItems ? '' : 'disabled'">

                  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="14px">
                    <img *ngIf="showDocumentTypeChannelMenu('whatsapp')" [matMenuTriggerFor]="sendMenuWhatsapp" alt="Send whatsapp" class="mr2 pointer" style="width: 24px;" src="/assets/images/icons/whatsapp_fill.svg"/>
                    <mat-icon *ngIf="showDocumentTypeChannelMenu('mail')" [matMenuTriggerFor]="sendMenuMail" class="pointer">mail</mat-icon>
                    <mat-icon *ngIf="showDocumentTypeChannelMenu('print')" [matMenuTriggerFor]="printMenu" class="pointer">print</mat-icon>
                    <mat-icon *ngIf="showDocumentTypeChannelMenu('pdf')" [matMenuTriggerFor]="downloadPDFMenu" class="pointer">picture_as_pdf</mat-icon>
                    <mat-icon *ngIf="showDocumentTypeChannelMenu('more')" [matMenuTriggerFor]="moreActionIconsOptsMenu" class="pointer">more_vert</mat-icon>
                  </div>

                  <template #childListHost></template>
                  <ng-container *ngIf="isParentBillableItem()">
                    <app-payment-status-card #paymentCard [billableItem]="parentItem"></app-payment-status-card>
                  </ng-container>

                </div>
            </div>

        </ng-container>
        <ng-template #loading>loading...</ng-template>
    </ng-container>


</div>

<mat-menu #menu_more_actions="matMenu">
  <ng-container *ngFor="let a of actions.slice(3,20); first as isFirst">
    <button mat-menu-item (click)="onAction(a.id)">{{ a.i18nKey | translate}}</button>
  </ng-container>
</mat-menu>

<mat-menu #sendMenuWhatsapp="matMenu">
  <button mat-menu-item
          *ngIf="documentTypes['ticket']?.enabled"
          (click)="onAction('send_ticket_by_whatsapp'); trackNewSendPrintButtonsUsed()"
          [disabled]="!documentTypes['ticket'].whatsapp_enabled">
    {{ 'TICKET' | translate }}
    <ng-container *ngIf="!documentTypes['ticket'].whatsapp_enabled"><br><small>{{ documentTypes['ticket'].disable_reason | translate }}</small></ng-container>
  </button>
  <button mat-menu-item
          *ngIf="documentTypes['invoice']?.enabled"
          (click)="onAction('send_invoice_by_whatsapp'); trackNewSendPrintButtonsUsed()"
          [disabled]="!documentTypes['invoice'].whatsapp_enabled">
    {{ 'INVOICE' | translate }}
    <ng-container *ngIf="!documentTypes['invoice'].whatsapp_enabled"><br><small>{{ documentTypes['invoice'].disable_reason | translate }}</small></ng-container>
  </button>

</mat-menu>

<mat-menu #sendMenuMail="matMenu">
  <button mat-menu-item
          *ngIf="documentTypes['ticket']?.enabled"
          (click)="onAction('send_ticket_by_email'); trackNewSendPrintButtonsUsed()"
          [disabled]="!documentTypes['ticket'].mail_enabled">
    {{ 'TICKET' | translate }}
    <ng-container *ngIf="!documentTypes['ticket'].mail_enabled"><br><small>{{ documentTypes['ticket'].disable_reason | translate }}</small></ng-container>
  </button>

  <!--  <button mat-menu-item (click)="sendTicketClient()">{{ 'TICKET' | translate }}</button>-->

  <button mat-menu-item
          *ngIf="documentTypes['budget']?.enabled"
          (click)="onAction('send_budget_by_email'); trackNewSendPrintButtonsUsed()"
          [disabled]="!documentTypes['budget'].mail_enabled">
    {{ 'BUDGET' | translate }}
    <ng-container *ngIf="!documentTypes['budget'].mail_enabled"><br><small>{{ documentTypes['budget'].disable_reason | translate }}</small></ng-container>
  </button>
  <button mat-menu-item
          *ngIf="documentTypes['invoice']?.enabled"
          (click)="onAction('send_invoice_by_email'); trackNewSendPrintButtonsUsed()"
          [disabled]="!documentTypes['invoice'].mail_enabled">
    {{ 'INVOICE' | translate }}
    <ng-container *ngIf="!documentTypes['invoice'].mail_enabled"><br><small>{{ documentTypes['invoice'].disable_reason | translate }}</small></ng-container>
  </button>
</mat-menu>

<mat-menu #printMenu="matMenu">
  <button mat-menu-item
          *ngIf="documentTypes['ticket']?.enabled"
          (click)="onAction('print_ticket_80mm'); trackNewSendPrintButtonsUsed()">
    {{ 'TICKET' | translate }}
  </button>
  <!--  <button mat-menu-item (click)="printBudget()">{{ 'BUDGET' | translate }}</button>-->
  <button mat-menu-item
          *ngIf="documentTypes['invoice']?.enabled"
          (click)="onAction('print_invoice'); trackNewSendPrintButtonsUsed()">
    {{ 'INVOICE' | translate }}
  </button>
</mat-menu>

<mat-menu #downloadPDFMenu="matMenu">
  <!--  <button mat-menu-item (click)="downloadPDF('ticket'); trackNewSendPrintButtonsUsed()">{{ 'TICKET' | translate }}</button>-->
  <button mat-menu-item
          *ngIf="documentTypes['budget']?.enabled"
          (click)="onAction('download_budget_pdf'); trackNewSendPrintButtonsUsed()"
          [disabled]="!documentTypes['budget'].pdf_enabled">
    {{ 'BUDGET' | translate }}
    <ng-container *ngIf="!documentTypes['budget'].pdf_enabled"><br><small>{{ documentTypes['budget'].disable_reason | translate }}</small></ng-container>
  </button>
  <button mat-menu-item
          *ngIf="documentTypes['invoice']?.enabled"
          (click)="onAction('download_invoice_pdf'); trackNewSendPrintButtonsUsed()"
          [disabled]="!documentTypes['invoice'].pdf_enabled">
    {{ 'INVOICE' | translate }}
    <ng-container *ngIf="documentTypes['invoice']?.pdf_enabled"><br><small>{{ documentTypes['invoice'].disable_reason | translate }}</small></ng-container>
  </button>
</mat-menu>

<mat-menu #moreActionIconsOptsMenu="matMenu">
  <button mat-menu-item (click)="deleteItem(); trackNewSendPrintButtonsUsed()">
    <mat-icon color="warn">delete</mat-icon>{{ deleteI18N | translate }}
  </button>
</mat-menu>
