import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BikeCRMApiAbstract} from './bikecrm-api-base';

@Injectable({
  providedIn: 'root'
})
export class BikesInstalledProductsService extends BikeCRMApiAbstract {
  apiPath = 'bike_installed_products';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
