import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {RentalRentedBikesListComponent} from '../rental-rented-bikes-list/rental-rented-bikes-list.component';
import {
  RentalRentedAccessoriesListComponent
} from '../rental-rented-accessories-list/rental-rented-accessories-list.component';

@Component({
  selector: 'app-rental-rented-items-list',
  templateUrl: './rental-rented-items-list.component.html',
  styleUrls: ['./rental-rented-items-list.component.scss'],
})
export class RentalRentedItemsListComponent implements OnInit {
  @Output() itemsChange = new EventEmitter<object[]>();  // just one item changed
  @Output() itemsUpdated = new EventEmitter();

  @Input() parent: any; // TODO: improve type hint
  @Input() showFooter = true;
  @Input() showVat = false;
  // TODO: show only if there is some discount in the order
  @Input() parentApiContentTypeName: string; // Accepted: order, bikeservicesheet, or rental

  @ViewChild('rented_bikes_list', {static: true}) rentalRentedBikesListComponent: RentalRentedBikesListComponent;
  @ViewChild('rented_accessories_list', {static: true}) rentalRentedAccessoriesListComponent: RentalRentedAccessoriesListComponent;

  totalAmountWithTax = 0;
  currencyCode: string;

  constructor(
    protected nativeInterfacesService: NativeInterfacesService,
    protected usersService: UsersService
  ) {
  }

  ngOnInit(): void {
    this.currencyCode = this.usersService.business.currency.toUpperCase();
  }

  commuteVatFieldsDisplay(): void {
    console.log('TODO IMPLEMENT commuteVatFieldsDisplay');
  }

  showVatFields(show = true): void {
    console.log('TODO IMPLEMENT showVatFields', show);
  }

  addRentedBikeDialog(): void {
    this.rentalRentedBikesListComponent.addRentedBikeDialog();
  }

  addRentedAccessoryDialog(): void {
    this.rentalRentedAccessoriesListComponent.addRentedAccessoryDialog();
  }

  onItemsChange(items: object[]): void {
    this.totalAmountWithTax = this.rentalRentedBikesListComponent.getTotalCost() + this.rentalRentedAccessoriesListComponent.getTotalCost();
  }

  onItemsUpdated(): void {
    this.totalAmountWithTax = this.rentalRentedBikesListComponent.getTotalCost() + this.rentalRentedAccessoriesListComponent.getTotalCost();
  }

}
