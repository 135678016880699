import {AbstractChildItem} from './abstract_child_item';


export class Reminder extends AbstractChildItem<any> {
  parentRelName = 'contentObject';

  id: string;
  title: string;

  dueDate: string;
  completedAt: string;
  isCompleted: boolean;

  contentType: string;
  objectId: string;
}
