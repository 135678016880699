<div class="speed-dial-wrapper">
  <ng-container *ngFor="let fab of _fabs; let i = index">

    <ng-container *ngIf="fab != null && fab.type == getFabTypes().singleAction">
      <button  mat-fab class="mr3i" [ngClass.gt-sm]="{'extended-fab-button': fab.i18nKey.length > 1 }" [attr.data-cy]="fab.id" color="primary" (click)="onAction(fab.id)">
        <mat-icon>{{ fab.icon }}</mat-icon>
        <span fxHide *ngIf="fab.i18nKey" fxShow.gt-sm
              ngClass.gt-sm="extended-fab-button__text">{{ fab.i18nKey | translate }}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="fab != null && fab.type == getFabTypes().multipleAction">
      <button *ngIf="fab" mat-fab [ngClass.gt-sm]="{'extended-fab-button': fab.i18nKey.length > 1 }" color="primary"
              [@preventInitialAnimation]="true"
              [attr.data-cy]="fab.id"
              [satPopoverAnchor]="dial" (click)="dial.toggle()">
        <mat-icon [@spinInOut]="'in'" *ngIf="!dial.isOpen()">{{ fab.icon }}</mat-icon>
        <mat-icon [@spinInOut]="'in'" *ngIf="dial.isOpen()">close</mat-icon>
        <span fxHide fxShow.gt-sm ngClass.gt-sm="extended-fab-button__text">{{ fab.i18nKey | translate }}</span>
      </button>

      <!-- Fab multipleAction actions dial -->
      <sat-popover #dial verticalAlign="above" [autoFocus]="false">
        <div class="dial" fxFlex fxLayout="column" fxLayoutAlign="center end">
          <ng-container *ngFor="let a of fab.actions">
            <div>
              <button mat-fab class="extended-fab-button button-border-primary"
                      style="background-color: white;"
                      [attr.data-cy]="a.id"
                      (click)="onAction(a.id) && dial.close()">
                <mat-icon>{{a.icon}}</mat-icon>
                <span class="extended-fab-button__text">{{a.i18nKey | translate}}</span>
              </button>
            </div>
          </ng-container>
        </div>
      </sat-popover>
    </ng-container>

  </ng-container>

</div>
