import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Workshop, WorkshopExtraDataBssTemplate } from 'src/app/models/workshop';
import { WorkshopService } from 'src/app/services/workshop.service';

@Component({
  selector: 'app-predefined-tasks-selector',
  templateUrl: './predefined-tasks-selector.component.html',
  styleUrls: ['./predefined-tasks-selector.component.scss']
})
export class PredefinedTasksSelectorDialogComponent implements OnInit {
  // TODO: add predefined task from here
  // TODO: delete predefined tasks from here

  defaultWorkshop$: Observable<Workshop>;

  selectedTemplates: WorkshopExtraDataBssTemplate[] = [];
  disabledTemplatesStrings: string[] = [];  // those should not be enabled/shown

  constructor(
    private workshopService: WorkshopService,
    public dialog: MatDialogRef<PredefinedTasksSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PredefinedTasksSelectorDialogComponentModel) {
    this.disabledTemplatesStrings = data.disabledStrings;
  }

  ngOnInit(): void {
    this.defaultWorkshop$ = this.workshopService.getFirstWorkshop();
  }

  // closeDialog(): void {
  // this.dialog.close([]);
  // }
  confirmDialog(): void {
    this.dialog.close(this.selectedTemplates);
  }

  isDisabled(template: WorkshopExtraDataBssTemplate): boolean {
    return this.disabledTemplatesStrings.filter(obj => template.name === obj).length === 1;
  }
  isActive(template: WorkshopExtraDataBssTemplate): boolean {
    return this.selectedTemplates.filter(obj => template.name === obj.name).length === 1;
  }

  toggleTpl(template: WorkshopExtraDataBssTemplate): void {
    if (this.isActive(template)) {
      this.selectedTemplates = this.selectedTemplates.filter(obj => obj.name !== template.name);
    } else {
      this.selectedTemplates.push(template);
    }
  }

  getTemplateLiteral(tpl: WorkshopExtraDataBssTemplate): string {
    return WorkshopExtraDataBssTemplate.literal(tpl);
  }
}

export class PredefinedTasksSelectorDialogComponentModel {
  disabledStrings: string[];

  constructor(public currentTaskDescriptions: string[]) {
    this.disabledStrings = currentTaskDescriptions;
  }
}
