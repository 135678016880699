import {AfterViewInit, Component, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {UsersService} from 'src/app/services/users.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {RentalsTariffsService} from '../../services/rentals-tariffs.service';
import {RentalTariff} from '../../models/rental_tariff';

@Component({
  selector: 'app-rentals-tariffs-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class RentalsTariffsListComponent extends ItemSimpleFiltrableListAbstractComponent<RentalTariff> implements OnInit, AfterViewInit {
  // TODO: filters like archived or staff, and others should be abstracted

  pageTitleI18N = 'LOADING_AND_THREE_DOTS';

  autoFocusSearchBox = true;

  urlBaseDetailItem = `/bikes_rental_tariffs/`;

  displayedColumnsMetaData = {
    name: new DisplayedColumnMetaData('NAME'),
    // description: new DisplayedColumnMetaData('DESCRIPTION'),
    excessDurationEveryHours: new DisplayedColumnMetaData('EXCESS_DURATION_EVERY_HOURS'),
    excessDurationPrice: new DisplayedColumnMetaData('EXCESS_DURATION_PRICE', DisplayedColumnTypes.currency),
  };

  primaryFab = new Fab('NEW_TARIFF', 'add', 'new_tariff', FabTypes.singleAction);
  secondaryFab = null;

  apiFilters = {};

  constructor(
    @Optional() public dialogRef: MatDialogRef<RentalsTariffsListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected rentalsTariffsService: RentalsTariffsService,
    protected usersService: UsersService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      rentalsTariffsService,
      nativeInterfacesService,
      dialog,
      media$,
      usersService,
      privacyModeService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  async onFabAction(actionId: string): Promise<boolean> {
    if (actionId === 'new_tariff') {
      this.router.navigate([`/${this.urlBaseDetailItem}/create`]);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }
}
