<div fxLayout="row" fxLayoutAlign="space-between center">
  <h4 fxFlex="30">{{ 'INVOICE' | translate }}:</h4>

  <mat-form-field fxFlex="30">
    <mat-label>{{ "INVOICE_SERIES" | translate }}</mat-label>
    <mat-select (selectionChange)="changeInvoiceSeries($event.value)" [value]="invoiceSeries.toString()">
      <mat-option value="1">1</mat-option>
      <mat-option value="2">2</mat-option>
      <mat-option value="3">3</mat-option>
      <mat-option value="4">4</mat-option>
      <mat-option value="5">5</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="!invoiceNumber">
    <!-- TODO: abstract this to order-component, and not here! -->
    <button fxFlex="30" mat-stroked-button (click)="generateInvoice()">{{ 'GENERATE_INVOICE' | translate }}</button>
  </ng-container>
</div>
<div fxLayout="column" fxLayoutGap="20px">
  <div>
    <ng-container *ngIf="invoiceNumber">
      <!-- TODO: abstract this to order-component, and not here! -->
      <span>{{ invoiceNumber }} ({{ invoiceDate | date: 'mediumDate' }})</span>
    </ng-container>
  </div>
</div>
