import {AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {
  FilterMetaData,
  FilterOption,
  FilterTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {PosOrdersService} from '../../services/pos-orders.service';
import {Order} from '../../models/pos_order';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {PAYMENT_METHOD_I18N_KEY, PAYMENT_STATUS_I18N_KEY, PaymentStatus} from '../../models/billable_item';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';

export enum viewModes {
  OrderView,
  BudgetView
}


@Component({
  selector: 'app-pos-orders-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class PosOrdersListComponent extends ItemSimpleFiltrableListAbstractComponent<Order>
  implements OnInit, OnDestroy, AfterViewInit {
  // TODO: rename to sales, not orders
  // TODO: implement filters like, payment status
  // TODO: be able to pass a user, and get all his orders

  pageTitleI18N = 'LOADING_AND_THREE_DOTS';

  urlBaseDetailItem = 'pos/orders';

  displayedColumnsMetaData = null;

  searchPlaceHolderi18NKey = 'SALES_SEARCH_PLACEHOLDER';

  filtersMetaData = {};

  primaryFab = null;
  secondaryFab = null;

  @Input()
  viewMode: viewModes = null;

  constructor(
    @Optional() public dialogRef: MatDialogRef<PosOrdersListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    private userService: UsersService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected posOrdersService: PosOrdersService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      posOrdersService,
      nativeInterfacesService,
      dialog,
      media$,
      userService,
      privacyModeService
    );
  }

  ngOnInit(): void {
    if (this.userService.userMe && this.userService.userMe.workshop) {
      // TODO: this shluld not happen, we should wait for the user to be loaded (maybe on a lodcing screen?)
      // tslint:disable-next-line
      this.defaultFilters['workshop_id'] = this.userService.userMe.workshop;
    }

    // TODO: remove, if parentId is not null, just create a ticket for that user
    if (this.parentId) {
      this.primaryFab = null;
    }

    if (this.dialogRef != null) {
      this.dialogMode = true;
      if (this.data.mode === 'floating-selector') {
      }
    } else {
    }

    if (this.parentId && this.parentApiFilter === 'client__id') {
      // We are inside a bike service sheet list, we can add a new service sheet to this bike
      this.addButtonI18N = 'NEW_SALE';
    }

    this.configureViewMode();
    this.populateOpenedByFilter();

    super.ngOnInit();
  }

  configureViewMode(): void {
    if (this.router.url.indexOf('budgets') !== -1 || this.viewMode === viewModes.BudgetView) {
      // Budget view
      this.pageTitleI18N = 'BUDGETS_STORE';
      this.viewMode = viewModes.BudgetView;

      this.primaryFab = new Fab('NEW_BUDGET', 'add', 'fab_add_new_budget', FabTypes.singleAction);

      this.filtersMetaData = {
        budget_status: new FilterMetaData(
          'STATUS',
          FilterTypes.select_single,
          [
            new FilterOption('thisisalist,nea,den,app,dia', 'CTX_SERVICE_SHEETS.ALL'),
            // new FilterOption(['dia'], 'CTX_SERVICE_SHEETS.PENDING_DIAGNOSIS'),
            new FilterOption(['nea'], 'CTX_SERVICE_SHEETS.PENDING'),
            new FilterOption(['den'], 'CTX_SERVICE_SHEETS.DENIED'),
            new FilterOption(['app'], 'CTX_SERVICE_SHEETS.APPROVED'),
          ]),
      };
    } else if (this.router.url.indexOf('budgets') === -1 || this.viewMode === viewModes.OrderView) {
      this.viewMode = viewModes.OrderView;
      // Sales view
      this.primaryFab = new Fab('NEW_SALE', 'add', 'fab_add_new_order', FabTypes.singleAction);
      this.pageTitleI18N = 'SALES';
      this.viewMode = viewModes.OrderView;
      // TODO: maybe on the future we can set hard api filters (that are not on query params, and cannot be changed by the user)
      //  for this type of viewmode use case
      this.apiFilters = {budget_status: ['don', 'app', 'dia']};

      // TODO: move to abstract class
      const enabledPaymentMethods = this.userService.getCustomConfig().get_payment_methods();
      const filterOptionsPaymentMethod = [];
      filterOptionsPaymentMethod.push(new FilterOption('default_null_filter', 'PAYMENTS.ALL'));
      for (const paymentMethod of enabledPaymentMethods) {
        filterOptionsPaymentMethod.push(new FilterOption(paymentMethod, PAYMENT_METHOD_I18N_KEY[paymentMethod]));
      }

      this.filtersMetaData = {
        openedBy: new FilterMetaData('OPENED_BY', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')]),
        paymentMethod: new FilterMetaData(
          'PAYMENTS.PAYMENT_METHOD',
          FilterTypes.select_single,
          filterOptionsPaymentMethod),
        paymentStatus: new FilterMetaData(
          'PAYMENTS.PAYMENT_STATUS',
          FilterTypes.select_single,
          [
            new FilterOption('default_null_filter', 'PAYMENTS.ALL'),
            new FilterOption(PaymentStatus.Paid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Paid]),
            new FilterOption(PaymentStatus.PendingAndPartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
            // new FilterOption(PaymentStatus.Pending, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
            // new FilterOption(PaymentStatus.PartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.PartiallyPaid]),
            new FilterOption(PaymentStatus.Unknown, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Unknown]),
            new FilterOption(PaymentStatus.AdvancePayment, PAYMENT_STATUS_I18N_KEY[PaymentStatus.AdvancePayment]),
          ])
      };

      if (this.userService.business && this.userService.business.mechanicsCount < 2 && this.filtersMetaData.hasOwnProperty('assigned_to_id')) {
        console.log(this.filtersMetaData);
        // tslint:disable-next-line
        delete this.filtersMetaData['assigned_to_id'];
      }
    } else {
      throw new Error('Invalid view mode on PosOrdersListComponent');
    }
  }

  configureColumns(): void {
    if (this.viewMode === viewModes.BudgetView) {
      this.displayedColumnsCustomConfig = this.userService.getCustomConfig().get_shop_budget_list_columns();
    } else if (this.viewMode === viewModes.OrderView) {
      this.displayedColumnsCustomConfig = this.userService.getCustomConfig().get_shop_ticket_list_columns();
    } else {
      throw new Error('Invalid view mode on PosOrdersListComponent');
    }
    super.configureColumns();
  }

  populateOpenedByFilter(): void {
    this.userService.getStaff().subscribe(staffUsers => {
      const filterOptions = [new FilterOption('', 'All')];
      for (const staffUser of staffUsers) {
        filterOptions.push(new FilterOption(staffUser.id, staffUser.name));
      }
      // tslint:disable-next-line:no-string-literal
      this.filtersMetaData['openedBy'] = new FilterMetaData('OPENED_BY', FilterTypes.select_single, filterOptions);
    });
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'fab_add_new_order') {
      this.router.navigate(['/pos/orders/create']);
      return true;
    }
    if (actionId === 'fab_add_new_budget') {
      this.router.navigate(['/pos/budgets/create']);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }
}
