import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {BarcodeScannerAbstractComponent} from '../abstract-barcode-scanner/abstract-barcode-scanner';
import {MobileDetectionService} from '../../services/mobile-detection.service';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
})
export class InlineEditComponent extends BarcodeScannerAbstractComponent implements OnInit {
  // https://stackblitz.com/edit/angular-inline-edit?file=src%2Fapp%2Fautofocus.directive.ts
  /*
  * Component that show a value, and allow you to edit it if you click on it (on a popup)
  */

  @Input() data: string;
  @Input() dataDisplay: string;

  @Input() title: string;
  @Input() suffix: string;

  @Input() showBarCodeButton = false;
  // TODO: force implement those two output, the editModeStatus is handy for example to avoid searching a product by code,
  //      if the input comes from a barcode scanner and we are doing a manuall edit on a input field
  @Output() focusOut: EventEmitter<string> = new EventEmitter<string>();
  @Output() editModeStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onInputChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() autoCompleteOptionSelected: EventEmitter<string> = new EventEmitter<string>();

  @Input() addHint: string;
  @Input() placeholder = '';
  @Input() align = 'start';
  @Input() currencyCode: string;
  // Format {id: string, value: string}[]
  @Input() autoCompletefilteredOptions: object[] = [];

  @Input() type = 'text';  // text, currency, number, integer
  @Input() mainInput = false;  // in a form with multiple inputs this is the main one (for focus proposes)
  @Input() editable = true;
  @Input() dueDatetime = true;  // if the datetime represents a due date, we will show a warning if it's in the past
  @Input() showMaxLengthHint = false;
  @Input() titleIsTheData = true;

  @Input() inputAlignRight = false;

  @Input() autocompleteDebounceTime = 500;

  @ViewChild('datetimeInput') datetimeInput: any;

  private inputChangeSubject = new Subject<string>();
  private inputChangeSubscription: Subscription;

  inputType = 'text';  // text, number
  inputMode = 'text';  // text, numeric, what virtualkeyboard to trigger on devices
  maxLength = 140;

  isMobile = false;

  editMode = false;

  constructor(public nativeInterfacesService: NativeInterfacesService,
              dialog: MatDialog,
              mobileDetectionService: MobileDetectionService,
              public sanitizer: DomSanitizer) {
    super(nativeInterfacesService, dialog);
    this.isMobile = mobileDetectionService.areWeOnMobile;

    this.inputChangeSubscription = this.inputChangeSubject.pipe(
      // TODO: this is not working, the debounce time is not being set
      // debounceTime(this.autocompleteDebounceTime), // Adjust the debounce time as needed
      debounceTime(100), // Adjust the debounce time as needed
      distinctUntilChanged() // This is optional, removes duplicate consecutive items
    ).subscribe(value => {
      this.onInputChange.emit(value);
    });
  }

  ngOnInit(): void {
    // When creating a new field that it's still empty, we want to autofocus and enable edit mode, but only if it's marked as "main_text"
    this.autoCompletefilteredOptions = [];
    if (!this.data && this.mainInput && this.editable) {
      this.editMode = true;
      this.editModeStatus.emit(this.editMode);
    }
    if (this.type === 'number' || this.type === 'integer') {
      this.inputAlignRight = true;
      this.inputType = 'number';
    }
    if (this.type === 'currency') {
      this.inputType = 'text';
      this.inputMode = 'decimal';
    }

    if (this.type === 'currency') {
      this.maxLength = 15;
    }

    if (this.dataDisplay == null) {
      this.dataDisplay = this.data;
    }
  }

  enableEditMode(): void {

    if (this.editable) {
      if (this.type === 'currency' || this.type === 'number' || this.type === 'integer') {
        // This makes it easier to edit preventing not wanted leading and trailing zeros
        if (Number(this.data) === 0) {
          this.data = '';
        }
      }
      this.editMode = true;
      this.editModeStatus.emit(this.editMode);

      if (this.type === 'datetime') {
        setTimeout(() => {
          // this.datetimeInput.setFocus();
          this.datetimeInput.nativeElement.showPicker();
          // this.datetimeInput.nativeElement.addEventListener('click', event => {
          //   console.log('click');
          // });

          // this.datetimeInput.nativeElement.addEventListener('input', event => {
          //   // console.log('input');
          // });
          //
          // this.datetimeInput.nativeElement.addEventListener('change', event => {
          //   // console.log('change');
          // });
        }, 10);
      }
    }
  }

  isKeyboardBarCodeScannerEnabled(): boolean {
    // There's no need, as this is just an input, we can just let the barcode scanner input the field itself
    return false;
  }

  onDateTimeChange(event: any): void {
    this.data = event.target.value;
    this.onFocusOut(!this.isMobile);
  }

  isDueDate(date: string): boolean {
    if (!this.dueDatetime || (this.type !== 'datetime' && this.type !== 'date')) {
      return false;
    }
    if (date === null || date === undefined) {
      return false;
    }
    const d = new Date(date);
    return d < new Date();
  }

  onInput(event: string): void {
    this.inputChangeSubject.next(event);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.autoCompleteOptionSelected.emit(event.option.value);
  }

  onFocusOut(disableEditMode = true): void {
    // without this timeout, the focusOut event is emitted before the mouse click event is triggered
    // this causes problems: for example, if you select something from the autocomplete with the mouse
    // won't be selected, with only 100ms, on chrome doesn't work, but on firefox does
    // TODO: we should find a better way to handle this, this is a hack
    setTimeout(() => {
      if (this.type === 'integer') {
        if (isNaN(parseInt(this.data, 10))) {
          this.data = null;
        } else {
          this.data = parseInt(this.data, 10).toString();
        }
      }
      this.focusOut.emit(this.data);
      if (disableEditMode) {
        this.editMode = false;
      }
      this.editModeStatus.emit(this.editMode);
    }, 200);
  }

  appendText(text: string): void {
    // console.log('appendText');
    if (this.data !== null) {
      this.data = this.data.toString().concat(' ' + text);
    } else {
      this.data = text;
    }
  }

  onBarCodeScanner(code: string): void {
    this.appendText(code.toString());
  }
}
