import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {BikesInstalledProductsService} from '../../services/bikes-installed-products.service';
import {BikeInstalledProduct} from '../../models/bike_installed_product';


@Component({
  selector: 'app-bikes-installed-products-list',
  templateUrl: 'bikes-installed-products-list.component.html',
  styleUrls: ['bikes-installed-products-list.component.scss']
})
export class BikesInstalledProductsListComponent implements OnInit {

  @Input() bikeId: string;

  bikesInstalledProducts: BikeInstalledProduct[] = [];
  retiredBikesInstalledProducts: BikeInstalledProduct[] = [];

  constructor(
    @Optional() public dialogRef: MatDialogRef<BikesInstalledProductsListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected bikesInstalledProductsService: BikesInstalledProductsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected media$: MediaObserver,
  ) {}

  ngOnInit(): void {
    this.refreshComponents();
  }

  refreshComponents(): void {
    this.bikesInstalledProductsService.getList({bike__id: this.bikeId}).subscribe((bikesInstalledProducts) => {
      this.bikesInstalledProducts = bikesInstalledProducts.filter((bip) => !bip.retired);
      this.retiredBikesInstalledProducts = bikesInstalledProducts.filter((bip) => bip.retired);
    });
  }

  getPercentageOfWear(bikeInstalledProduct: BikeInstalledProduct): number {
    const kmWear = Math.round((bikeInstalledProduct.currentLifetimeKm / bikeInstalledProduct.lifetimeKm) * 100);
    const daysWear = Math.round((bikeInstalledProduct.currentLifetimeDays / bikeInstalledProduct.lifetimeDays) * 100);
    return Math.max(kmWear, daysWear);
  }

  retire(bikeInstalledProduct: BikeInstalledProduct): void {
    this.bikesInstalledProductsService.modify(bikeInstalledProduct.id, {retired: true}).subscribe(() => {
      this.refreshComponents();
    });
  }
}
