/* tslint:disable:no-string-literal */


export const analyticsSummaryFields = {
  totalBssCount: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_BSS_COUNT',
    TYPE: 'text',
  },
  totalBssOpenedCount: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_BSS_OPENED_COUNT',
    TYPE: 'text',
  },
  totalBssClosedCount: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_BSS_CLOSED_COUNT',
    TYPE: 'text',
  },
  meta_newline: {
    I18N_KEY: '',
    TYPE: 'newline',
  },
  meta_divider: {
    I18N_KEY: '',
    TYPE: 'divider',
  },
  totalBudgetsPendingCount: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_BUDGET_PENDING_COUNT',
    TYPE: 'text',
  },
  businessBssOpenedLast7dCount: {
    I18N_KEY: 'CTX_ANALYTICS.BSS_OPENED_LAST_7D',
    TYPE: 'text',
  },
  businessBssClosedLast7dCount: {
    I18N_KEY: 'CTX_ANALYTICS.BSS_CLOSED_LAST_7D',
    TYPE: 'text',
  },
  businessBssOpenedLast30dCount: {
    I18N_KEY: 'CTX_ANALYTICS.BSS_OPENED_LAST_30D',
    TYPE: 'text',
  },
  businessBssClosedLast30dCount: {
    I18N_KEY: 'CTX_ANALYTICS.BSS_CLOSED_LAST_30D',
    TYPE: 'text',
  },
  avgTimePerBikeLast30d: {
    I18N_KEY: 'CTX_ANALYTICS.AVG_TIME_WORKING_ON_BSS_LAST_30D',
    TYPE: 'totalTime',
  },
  avgCostPerBikeLast30d: {
    I18N_KEY: 'CTX_ANALYTICS.AVG_COST_BSS_LAST_30D',
    TYPE: 'currency',
  },
  totalCostsBssLast30d: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_COST_BSS_LAST_30D',
    TYPE: 'currency',
  },
  bikesReadyToCheckoutCount: {
    I18N_KEY: 'CTX_ANALYTICS.BIKES_READY_TO_CHECKOUT',
    TYPE: 'text',
  },
  totalIncomeToday: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_INCOME_TODAY',
    TYPE: 'currency',
  },
  totalIncomeTodayCash: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_INCOME_TODAY_CASH',
    TYPE: 'currency',
  },
  totalIncomeTodayCard: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_INCOME_TODAY_CARD',
    TYPE: 'currency',
  },
  totalIncomeYesterday: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_INCOME_YESTERDAY',
    TYPE: 'currency',
  },
  totalIncomeYesterdayCash: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_INCOME_YESTERDAY_CASH',
    TYPE: 'currency',
  },
  totalPendingClosedSalesPayments: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_PENDING_CLOSED_SALES_PAYMENTS',
    TYPE: 'currency',
  },
  totalPendingFuturePayments: {
    I18N_KEY: 'CTX_ANALYTICS.TOTAL_PENDING_FUTURE_PAYMENTS',
    TYPE: 'currency',
  }
};

