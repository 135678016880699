import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {TranslateService} from '@ngx-translate/core';
import {Business} from './models/business';
import {User, UserToken} from './models/user';
import {UsersService} from './services/users.service';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {TitleService} from './services/title.service';
import {NativeInterfacesService} from './services/native-interfaces.service';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {PrivacyModeService} from './services/privacy-mode.service';
import {AppMetricsService} from './services/app-metrics.service';
import {SwUpdate} from '@angular/service-worker';
import {HotkeysService} from 'angular2-hotkeys';
import {Observable} from 'rxjs';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  // TODO: check if it will be better to have this default to true, or at least detect viewport earlier to avoid early redrawings
  brand = environment.brand;
  brandName = environment.brandName;

  opened = false;
  fullscreened = false;

  title = '';
  userToken: UserToken;
  userMe: User;
  business?: Business;

  allMechanics: User[];
  selectedMechanic: User;
  selectedMechanicId: string;

  sideNavMode: MatDrawerMode = 'side';
  appVersion = environment.version;
  env = environment;
  protected readonly environment = environment;

  // users$: Observable<User[]>;
  userMe$: Observable<User>;

  constructor(
    private cd: ChangeDetectorRef,
    public nativeInterfacesService: NativeInterfacesService,
    titleService: TitleService,
    analytics: AngularFireAnalytics, // TODO: use custom service
    private translate: TranslateService,
    public media$: MediaObserver,
    public userService: UsersService,
    public privacyModeService: PrivacyModeService,
    public appMetricsService: AppMetricsService,
    private swUpdate: SwUpdate,
    private hotkeysService: HotkeysService
  ) {
    appMetricsService.sendEvent(appMetricsService.EVENT_NAMES.APP_VERSION, this.appVersion);
    this.checkUpdate();

    this.userMe$ = userService.userMe$;

    this.title = titleService.title; // initial Value

    titleService.titleChange.subscribe((newTitle) => {
      setTimeout(() => {
        // avoid ExpressionChangedAfterItHasBeenCheckedError
        this.title = newTitle;
      });
      // this.title = newTitle;
      // this.cd.detectChanges(); // avoid ExpressionChangedAfterItHasBeenCheckedError
    });

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

    this.selectedMechanic = userService.selectedMechanic;
    this.selectedMechanicId = userService.selectedMechanic?.id;

    // TODO de-nest this bullshit and use map, switchmap, concat or wahtever
    this.userService.userToken$.pipe(
      filter(x => x !== null),
      distinctUntilChanged()
    )
      .subscribe(ut => {
        this.userToken = ut;
        this.userService.userMe$.pipe(
          filter(x => x !== null),
          distinctUntilChanged()
        )
          .subscribe(x => {
            // console.log(x);
            this.userMe = x;
            if (this.userMe.workshopColor?.length > 0) {
              try {
                // @ts-ignore
                document.getElementsByClassName('mat-toolbar')[0].style.background = this.userMe.workshopColor;
                localStorage.setItem('workshopColor', this.userMe.workshopColor);
              } catch (e) {
                console.error(e);
              }
            }
            // console.log(this.userMe);
            analytics.setUserId(this.userMe.id);
          });
        this.userService.getStaff().subscribe(x => this.allMechanics = x);
        this.userService.business$.subscribe(x => {
          this.business = x;
          if (this.business != null) {
            analytics.setUserProperties({ businessId: this.business.id });
            translate.use(this.business.language);
          }
        });

        this.userService.selectedMechanic$.subscribe(m => {
          this.selectedMechanic = m;
          // console.log('selectedMechanic', m);
          this.selectedMechanicId = m?.id;
        });
        this.userService.updateUserAndBusinessInfo();
      });
  }

  selectedMech(event): void {
    this.userService.selectedMechanic = this.allMechanics.find(el => el.id === event.value);
  }


  ngOnInit(): void {
    this.media$.asObservable().subscribe(() => {
      this.opened = this.media$.isActive('gt-md');
      this.sideNavMode = this.media$.isActive('lt-lg') ? 'over' : 'side';
    });

    if (localStorage.getItem('workshopColor')) {
      try {
        // @ts-ignore
        document.getElementsByClassName('mat-toolbar')[0].style.background = localStorage.getItem('workshopColor');
      } catch (e) {
        console.error(e);
      }
    }
  }

  ngOnDestroy(): void {
    // TODO: unsubscribes
  }

  checkUpdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (this.business) {
          this.translate.use(this.business.language);
        }
        const literal = this.translate.instant('NEW_VERSION_AVAILABLE_LOAD_IT_NOW');
        if (confirm(literal)) {
          window.location.reload();
        }
      });
    }
  }

  onNavItemClicked(): void {
    if (this.media$.isActive('lt-lg')) {
      this.opened = false;
    }
  }

  goBack(): void {
    window.history.back();
  }

  refreshPage(): void {
    // TODO: on fully, use fully native interface
    // https://stackoverflow.com/questions/5721704/window-location-reload-with-clear-cache
    location.reload();
  }

  openFullscreen(): void {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
    this.fullscreened = true;
  }

  logout(): void {
    this.userService.logout();
  }

  closeFullscreen(): void {
    const elem = document.documentElement;
    if (document.exitFullscreen) {
      document.exitFullscreen();
      // } else if (document.mozCancelFullScreen) { /* Firefox */
      //   document.mozCancelFullScreen();
      // } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      //   document.webkitExitFullscreen();
      // } else if (document.msExitFullscreen) { /* IE/Edge */
      //   document.msExitFullscreen();
    }
    this.fullscreened = false;
  }

  goToSubscriptionPortal(): void {
    const businessEmail = this.business.stripeEmail;
    const billingPortalUrl = `https://billing.stripe.com/p/login/5kAcP1eaq2eOaRidQQ?prefilled_email=${businessEmail}`;
    // https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window
    // window.open(billingPortalUrl, '_blank').focus();
    window.open(billingPortalUrl, 'bikeCRMStripeBilling').focus();
  }

  changeOfRoutes(): void {
    this.hotkeysService.reset();
  }
}
