import {AbstractBaseItem} from './abstract_base_api_item';
import {AbstractChildItem} from './abstract_child_item';


export class RentalTariff extends AbstractBaseItem {
  id: string;

  business: string;
  name: string;
  description: string;

  excessDurationEveryHours: number;
  excessDurationPrice: number;

  vat: number;

}

export class RentalTariffPriceDuration extends AbstractChildItem<RentalTariff> {
  parentRelName = 'tariff';

  name: string;
  price: number;
  durationMaxHours: number;
}
