/* tslint:disable:no-string-literal */


import {serviceSheetFields} from './custom_config_servicesheet_api_fields';
import {posTicketFields} from './custom_config_pos_api_fields';
import {analyticsSummaryFields} from './custom_config_analytics_summary_list_fields';


export class CustomConfigBSSListConfig {
  defaultOrdering: string;
  defaultItemsPerPage: number;
}

export class CustomConfig {
  apiObject: object;

  public module_bike_workshop_timer_enabled(): boolean {
    try {
      if (this.apiObject['MODULES']['BIKE_WORKSHOP']['JOB_TIMER_ENABLED'] === undefined || this.apiObject['MODULES']['BIKE_WORKSHOP']['JOB_TIMER_ENABLED'] === null) {
        return true; // Default state is enabled
      }
      return this.apiObject['MODULES']['BIKE_WORKSHOP']['JOB_TIMER_ENABLED'] === true;
    } catch (error) {
      return true;  // Default state is enabled
    }
  }

  public get_payment_methods(): string[] {
    try {
      if (this.apiObject['PAYMENT_METHODS'] != null && this.apiObject['PAYMENT_METHODS'].length > 0) {
        return this.apiObject['PAYMENT_METHODS'];
      }
    } catch (error) {
      // Do nothing
    }
    return ['cas', 'car', 'biz', 'tra'];  // Default payment methods
  }

  public get_bike_types(): object[] {
    // TODO: this types should be validated on backend, to avoid errors
    let bikeTypes;
    try {
      bikeTypes = this.apiObject['MODULES']['BIKE_WORKSHOP']['BIKE_TYPES'];
      if (bikeTypes == null) {
        throw new Error('BIKE_TYPES is null');
      }
    } catch (error) {
      bikeTypes = [
        {
          subtype_I18NKey: 'BIKE_TYPES.POPULAR',
          bikes: [
            {value: 'MTB Hardtail', I18N_KEY: 'BIKE_TYPES.MTB_HARDTAIL'},
            {value: 'MTB Full suspension', I18N_KEY: 'BIKE_TYPES.MTB_FULL_SUSPENSION'},
            {value: 'Road', I18N_KEY: 'BIKE_TYPES.ROAD'},
            {value: 'Gravel', I18N_KEY: 'BIKE_TYPES.GRAVEL'},
            {value: 'Kick scooter', I18N_KEY: 'BIKE_TYPES.KICK_SCOOTER'},
            {value: 'Folding', I18N_KEY: 'BIKE_TYPES.FOLDING'}
          ]
        }, {
          subtype_I18NKey: 'BIKE_TYPES.MTB',
          bikes: [
            {value: 'MTB Hardtail', I18N_KEY: 'BIKE_TYPES.MTB_HARDTAIL'},
            {value: 'MTB Full suspension', I18N_KEY: 'BIKE_TYPES.MTB_FULL_SUSPENSION'},
            {value: 'MTB e-bike', I18N_KEY: 'BIKE_TYPES.MTB_EBIKE'},
            {value: 'MTB Downhill', I18N_KEY: 'BIKE_TYPES.MTB_DOWNHILL'},
            {value: 'Fatbike', I18N_KEY: 'BIKE_TYPES.FATBIKE'}
          ]
        }, {
          subtype_I18NKey: 'BIKE_TYPES.ROAD',
          bikes: [
            {value: 'Road', I18N_KEY: 'BIKE_TYPES.ROAD'},
            {value: 'Road Aero', I18N_KEY: 'BIKE_TYPES.ROAD_AERO'},
            {value: 'Road Gran Fondo', I18N_KEY: 'BIKE_TYPES.ROAD_GRAN_FONDO'},
            {value: 'Triathlon/TT', I18N_KEY: 'BIKE_TYPES.TRIATHLON_TT'}
          ]
        },
        {
          subtype_I18NKey: 'BIKE_TYPES.OTHERS',
          bikes: [
            {value: 'Cargo', I18N_KEY: 'BIKE_TYPES.CARGO'},
            {value: 'MTB', I18N_KEY: 'BIKE_TYPES.MTB'},
            {value: 'Folding', I18N_KEY: 'BIKE_TYPES.FOLDING'},
            {value: 'BMX', I18N_KEY: 'BIKE_TYPES.BMX'},
            {value: 'Commuter', I18N_KEY : 'BIKE_TYPES.COMMUTER'},
            {value: 'Dirt jump', I18N_KEY: 'BIKE_TYPES.DIRT_JUMP'},
            {value: 'Children\'s', I18N_KEY: 'BIKE_TYPES.CHILDRENS'},
            {value: 'Touring', I18N_KEY: 'BIKE_TYPES.TOURING'},
            {value: 'Cyclocross', I18N_KEY: 'BIKE_TYPES.CYCLOCROSS'},
            {value: 'Electric', I18N_KEY: 'BIKE_TYPES.ELECTRIC'},
            {value: 'Track', I18N_KEY: 'BIKE_TYPES.TRACK'},
            {value: 'Tandem', I18N_KEY: 'BIKE_TYPES.TANDEM'},
            {value: 'Kids balance', I18N_KEY: 'BIKE_TYPES.KIDS_BALANCE'},
            {value: 'Kick scooter', I18N_KEY: 'BIKE_TYPES.KICK_SCOOTER'},
            {value: 'Other', I18N_KEY: 'BIKE_TYPES.OTHER'}
          ]
        }
      ];
    }
    return bikeTypes;
  }

  public get_shop_ticket_list_columns(): object[] {
    let columns;

    try {
      columns = this.apiObject['MODULES']['SHOP']['TICKET_LIST']['COLUMNS'];
    } catch (error) {
      // Default columns
      columns = [
        {
          FIELD: 'createdAt',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        },
        {
          FIELD: 'shortPrettyId',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'shortDescription',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'roPaymentStatus',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'roPaymentMethod',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'invoiceNumber',
          SHOW_IN_TABLET: false,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'itemCount',
          SHOW_IN_TABLET: false,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'totalAmountWithTax',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }
      ];
    }

    // Fill other field properties:
    for (const column of columns) {
      for (const key of Object.keys(posTicketFields[column.FIELD])) {
        column[key] = posTicketFields[column.FIELD][key];
      }

    }
    return columns;
  }

  public get_shop_budget_list_columns(): object[] {
    let columns;

    try {
      columns = this.apiObject['MODULES']['SHOP']['BUDGET_LIST']['COLUMNS'];
    } catch (error) {
      // Default columns
      columns = [
        {
          FIELD: 'budgetStatus',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }, {
          FIELD: 'shortDescription',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'createdAt',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }, {
          FIELD: 'itemCount',
          SHOW_IN_TABLET: false,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'totalAmountWithTax',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }
      ];
    }

    // Fill other field properties:
    for (const column of columns) {
      for (const key of Object.keys(posTicketFields[column.FIELD])) {
        column[key] = posTicketFields[column.FIELD][key];
      }

    }
    return columns;
  }

  public get_analytics_summary_list(): object[] {
    let summaryList;
    try {
      summaryList = this.apiObject['DASHBOARD']['ANALYTICS_SUMMARY_LIST'];
    } catch (error) {
      // Default summary list
      summaryList = [
        {
          FIELD: 'totalBssCount'
        },
        {
          FIELD: 'totalBssOpenedCount'
        },
        {
          FIELD: 'totalBssClosedCount'
        },
        {
          FIELD: 'meta_newline'
        },
        {
          FIELD: 'totalBudgetsPendingCount'
        },
        {
          FIELD: 'meta_newline'
        },
        {
          FIELD: 'businessBssOpenedLast7dCount'
        },
        {
          FIELD: 'businessBssClosedLast7dCount'
        },
        {
          FIELD: 'businessBssOpenedLast30dCount'
        },
        {
          FIELD: 'businessBssClosedLast30dCount'
        },
        {
          FIELD: 'meta_divider'
        },
        {
          FIELD: 'avgTimePerBikeLast30d'
        },
        {
          FIELD: 'avgCostPerBikeLast30d'
        },
        {
          FIELD: 'totalCostsBssLast30d'
        },
        {
          FIELD: 'bikesReadyToCheckoutCount'
        },
        {
          FIELD: 'meta_divider'
        },
        {
          FIELD: 'totalIncomeToday'
        },
        {
          FIELD: 'totalIncomeTodayCash'
        },
        {
          FIELD: 'totalIncomeTodayCard'
        },
        {
          FIELD: 'totalIncomeYesterday'
        },
        {
          FIELD: 'totalIncomeYesterdayCash'
        },
        {
          FIELD: 'totalPendingClosedSalesPayments'
        },
        {
          FIELD: 'totalPendingFuturePayments'
        }
      ];
    }

    for (const summaryListField of summaryList) {
      if (this.module_bike_workshop_timer_enabled() === false && summaryListField.FIELD === 'avgTimePerBikeLast30d') {
        summaryList.splice(summaryList.indexOf(summaryListField), 1);
        continue;
      }
      for (const key of Object.keys(analyticsSummaryFields[summaryListField.FIELD])) {
        if (summaryListField.hasOwnProperty(key)) {
          continue;
        }
        summaryListField[key] = analyticsSummaryFields[summaryListField.FIELD][key];
      }
    }
    return summaryList;
  }

  public get_service_sheets_list_columns(): object[] {
    let columns;
    try {
      columns = this.apiObject['MODULES']['BIKE_WORKSHOP']['SERVICESHEET_LIST']['COLUMNS'];
    } catch (error) {
      // Default columns
      columns = [
        {
          FIELD: 'priority',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'checkInStatus',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'roPaymentStatus',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'roPaymentMethod',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'ownerName',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }, {
          FIELD: 'invoiceNumber',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'bikeName',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }, {
          FIELD: 'checkInDt',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'closedAt',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'estimatedDeliveryDt',
          SHOW_IN_TABLET: false,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'assignedToName',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'notifiedClient',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: false
        }, {
          FIELD: 'totalAmountWithTax',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }
      ];
    }

    // Fill other field properties:
    for (const column of columns) {
      for (const key of Object.keys(serviceSheetFields[column.FIELD])) {
        column[key] = serviceSheetFields[column.FIELD][key];
      }
    }
    return columns;
  }

  public get_budget_list_columns(): object[] {
    let columns;
    try {
      columns = this.apiObject['MODULES']['BIKE_WORKSHOP']['BUDGET_LIST']['COLUMNS'];
    } catch (error) {
      columns = [
        {
          FIELD: 'budgetStatus',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }, {
          FIELD: 'ownerName',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }, {
          FIELD: 'bikeName',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }, {
          FIELD: 'createdAt',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }, {
          FIELD: 'totalAmountWithTax',
          SHOW_IN_TABLET: true,
          SHOW_IN_PHONE: true
        }
      ];

      for (const column of columns) {
        for (const key of Object.keys(serviceSheetFields[column.FIELD])) {
          column[key] = serviceSheetFields[column.FIELD][key];
        }
      }
    }

    return columns;

  }

  public get_bss_list_config(): CustomConfigBSSListConfig {
    // options: PRIORITY-NEWEST, PRIORITY-OLDEST
    let defaultOrdering = 'PRIORITY-NEWEST';
    let defaultItemsPerPage = 50;
    try {
      const listConfig = this.apiObject['MODULES']['BIKE_WORKSHOP']['LIST_CONFIG'];
      defaultOrdering = listConfig['DEFAULT_BSS_ORDERING'];
      defaultItemsPerPage = listConfig['DEFAULT_ITEMS_PER_PAGE'];
    } catch (err) {

    }
    const customConfigBSSListConfig = new CustomConfigBSSListConfig();
    customConfigBSSListConfig.defaultItemsPerPage = defaultItemsPerPage;
    customConfigBSSListConfig.defaultOrdering = defaultOrdering;
    return customConfigBSSListConfig
  }
}
