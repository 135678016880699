<div fxLayout="column" fxLayoutAlign="center center" class="p4">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutAlign="center center" class="mt3"
        style="width: 600px;">
    <mat-form-field style="width: 250px;">
      <input matInput placeholder="{{ 'CURRENT_PASSWORD' | translate }}" formControlName="current_password"
             class="form-control"
             type="password" [ngClass]="{ 'is-invalid': submitted && f.current_password.errors }">
    </mat-form-field>
    <span class="text-warning pt1 pb2" *ngIf="errorsCurrentPassword">{{ errorsCurrentPassword | translate }}</span>

    <mat-form-field style="width: 250px;">
      <input matInput placeholder="{{ 'NEW_PASSWORD' | translate }}" formControlName="new_password" class="form-control"
             type="password" [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }">
    </mat-form-field>

    <mat-form-field style="width: 250px;">
      <input matInput placeholder="{{ 'CONFIRM_PASSWORD' | translate }}" formControlName="confirm_password"
             class="form-control"
             type="password" [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }">
    </mat-form-field>

    <span class="text-warning pt1 pb2" *ngIf="newPassword2Errors">{{ newPassword2Errors | translate }}</span>

    <span class="text-warning py2" *ngIf="nonFieldErrors">{{ nonFieldErrors | translate }}</span>

    <span class="text-success py2" *ngIf="successMessage">{{ successMessage | translate }}</span>

    <button mat-raised-button [disabled]="loading" color="primary" type="submit">
      <mat-spinner *ngIf="loading" class="mr1"></mat-spinner>
      {{ 'CHANGE_PASSWORD' | translate }}
    </button>
  </form>
</div>
