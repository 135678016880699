import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ServiceSheet} from 'src/app/models/servicesheets';
import {ServiceSheetService} from '../../services/service-sheets.service';
import {environment} from '../../../environments/environment';
import {UsersService} from '../../services/users.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../utils/confirm-dialog/confirm-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CrmNotificationsService} from '../../services/crm-notifications.service';


// TODO: use some abstract component to reduce code? just some detail that we only use 2 fields (bss)
@Component({
  selector: 'app-bss-files',
  templateUrl: './bss-files.component.html',
  styleUrls: ['./bss-files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BssFilesComponent implements OnInit, OnDestroy {
  @Input() parent: ServiceSheet;

  @ViewChild('myPond') myPond: any;

  private onDestroy$ = new Subject<void>();

  pondOptions = {};

  pondFiles = [];

  bssImages = [];

  constructor(
    private usersService: UsersService,
    private serviceSheetService: ServiceSheetService,
    public dialog: MatDialog,
    private notificationService: CrmNotificationsService,
  ) {
  }

  ngOnInit(): void {
    this.loadImages();

    // TODO: i18N / icon picture add
    this.pondOptions = {
      class: 'my-filepond',
      multiple: true,
      name: 'file',
      labelIdle: 'Drop files here',
      allowRevert: false,
      acceptedFileTypes: 'image/jpeg',
      credits: false,
      server: {
        url: `${environment.apiUrl}/api/servicesheet_file/`,
        process: {
          headers: {
            Authorization: `Token ${this.usersService.authorizationToken}`,
            'X-Requested-With': 'XMLHttpRequest'
          },
          onload: (response: any) => {
            // on finished, response is a ServiceSheetFile object
            const res = JSON.parse(response);
            this.bssImages.push(res);
            // console.log('onload', res);
            return res;
          },
          onerror: (response: any) => {
            const res = JSON.parse(response);
            // TODO: sentry
            console.log('onerror', res);
            return res;
          },
          ondata: (formData: any) => {
            formData.append('serviceSheet', this.parent.id);
            // TODO: add private/public option
            formData.append('private', 'false');
            // console.log('ondata', formData);
            return formData;
          },
        },
      }
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async loadImages(): Promise<void> {
    const res = await this.serviceSheetService.getImages(this.parent.id).toPromise();
    this.bssImages = res.results;
  }

  _getUrlToLoad(url: string, orig= false): string {
    if (url == null) {
      return '';
    }
    let u = url.replace('https://bikecrmstatic.s3.amazonaws.com/media/', environment.imageKitUrlEndpoint);
    u = u.replace('https://bikecrmstatic-beta.s3.amazonaws.com/media/', environment.imageKitUrlEndpoint);

    if (orig) {
      return u;
    }

    const ikTransformation = 'tr:w-512,h-512';
    u = `${u}?${ikTransformation}`;
    return u;
  }

  downloadFile(file: any): void {
    const url = this._getUrlToLoad(file.file, true);
    window.open(url, '_blank');
  }

  deleteFile(file: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('CONFIRM_DELETE_FILE', '', 'DELETE', 'delete', 'warn')
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (confirmDialog: boolean) => {
        if (confirmDialog) {
          this.serviceSheetService.deleteFile(file.id).subscribe(
            () => {
              this.bssImages = this.bssImages.filter((f: any) => f.id !== file.id);
              this.notificationService.successI18N('BSS_FILE_DELETED');
            },
            error => {

            }
          );
        } else {
          // cancelled
        }
      });
  }

  pondHandleInit(): void {
    // console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any): void {
    // console.log('A file was added', event);
  }

}
