
export class WorkshopExtraDataBssTemplate {
    constructor(
        public name: string,
        public priceMaterials: number,
        public laborCost: number
    ) { }

    public static priceMaterialsWoTax(templ: WorkshopExtraDataBssTemplate, vat: number): number {
      // TODO: use MoneyService
      return +(templ.priceMaterials / (1 + vat)).toFixed(5);
    }

    public static priceLaborWoTax(templ: WorkshopExtraDataBssTemplate, vat: number): number {
      // TODO: use MoneyService
      return +(templ.laborCost / (1 + vat)).toFixed(5);
    }

    public static literal(templ: WorkshopExtraDataBssTemplate): string {
        let s = templ.name;
        if (templ.priceMaterials) {
            s = s + ` - ${templ.priceMaterials}€`;
        }
        if (templ.laborCost) {
            s = s + ` + ${templ.laborCost}€`;
        }
        return s;
    }
}

export class WorkshopExtraData {
    constructor(
        public servicesheetTemplates: WorkshopExtraDataBssTemplate[]
    ) { }
}

export class Workshop {
    id: string;
    name: string;
    slug: string;
    business: string;

    workshopColor: string;

    appendBikePickupMessage: string;
    appendMessageAllClientDocuments: string;
    appendTextBudgets: string;
    appendTextTickets: string;
    appendTextInvoices: string;
    appendTextReceipts: string;
    appendTextRentalTickets: string;

    address: string;
    city: string;
    postalCode: string;
    country: string;
    website: string;

    webhookCashDrawerOpen: string;

    extraData: WorkshopExtraData;
}
