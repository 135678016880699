import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Workshop, WorkshopExtraData, WorkshopExtraDataBssTemplate} from 'src/app/models/workshop';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {WorkshopService} from 'src/app/services/workshop.service';
import {UsersService} from '../../services/users.service';
import {Business} from '../../models/business';

@Component({
  selector: 'app-workshop-list',
  templateUrl: './workshop-list.component.html',
  styleUrls: ['./workshop-list.component.scss']
})
export class WorkshopListComponent implements OnInit {
  workshop: Workshop;
  business: Business;
  workshopForm: UntypedFormGroup;
  currencySymbol: string;

  SERVER_FIELDS_TO_I18N_MAP = {
    website: 'WEBSITE'
  };

  constructor(
    private workshopService: WorkshopService,
    private notificationService: CrmNotificationsService,
    private userService: UsersService,
    private t: TranslateService
  ) { }

  ngOnInit(): void {
    this.currencySymbol = this.userService.business.currencySymbol;
    this.business = this.userService.business;
    this.workshopService.getFirstWorkshop().subscribe(w => {
      this.workshop = w;
      this.workshopForm = new UntypedFormGroup({
        address: new UntypedFormControl(this.workshop.address),
        city: new UntypedFormControl(this.workshop.city),
        postalCode: new UntypedFormControl(this.workshop.postalCode),
        website: new UntypedFormControl(this.workshop.website),
        appendBikePickupMessage: new UntypedFormControl(this.workshop.appendBikePickupMessage),
        appendMessageAllClientDocuments: new UntypedFormControl(this.workshop.appendMessageAllClientDocuments),
        appendTextBudgets: new UntypedFormControl(this.workshop.appendTextBudgets),
        appendTextTickets: new UntypedFormControl(this.workshop.appendTextTickets),
        appendTextInvoices: new UntypedFormControl(this.workshop.appendTextInvoices),
        appendTextReceipts: new UntypedFormControl(this.workshop.appendTextReceipts),
        appendTextRentalTickets: new UntypedFormControl(this.workshop.appendTextRentalTickets),
        bssTemplates: new UntypedFormArray([])
      });

      if (w.extraData) {
        for (const tpl of w.extraData.servicesheetTemplates) {
          this.bssTemplates.push(
            new UntypedFormGroup({
              name: new UntypedFormControl(tpl.name),
              priceMaterials: new UntypedFormControl(tpl.priceMaterials),
              laborCost: new UntypedFormControl(tpl.laborCost)
            })
          );
        }
      }
    });
  }

  onSubmit(workshopID: string): void {
    const formData = new FormData();
    let website = this.workshopForm.get('website').value;
    if (website && website.length > 0 && !website.startsWith('http')) {
      website = 'http://' + website;
    }
    formData.append('address', this.workshopForm.get('address').value);
    formData.append('city', this.workshopForm.get('city').value);
    formData.append('postalCode', this.workshopForm.get('postalCode').value);
    formData.append('website', website);
    formData.append('appendBikePickupMessage', this.workshopForm.get('appendBikePickupMessage').value);
    formData.append('appendMessageAllClientDocuments', this.workshopForm.get('appendMessageAllClientDocuments').value);
    formData.append('appendTextBudgets', this.workshopForm.get('appendTextBudgets').value);
    formData.append('appendTextTickets', this.workshopForm.get('appendTextTickets').value);
    formData.append('appendTextInvoices', this.workshopForm.get('appendTextInvoices').value);
    formData.append('appendTextReceipts', this.workshopForm.get('appendTextReceipts').value);
    formData.append('appendTextRentalTickets', this.workshopForm.get('appendTextRentalTickets').value);
    const workshopExtraDataTemplatesArray = new Array<WorkshopExtraDataBssTemplate>();
    for (const element of this.bssTemplates.controls) {
      workshopExtraDataTemplatesArray.push(
        new WorkshopExtraDataBssTemplate(
          element.get('name').value,
          element.get('priceMaterials').value,
          element.get('laborCost').value)
      );
    }

    // TODO: we should validate on server that we don't override other json data!

    let extraData: WorkshopExtraData;
    if (this.workshop.extraData) {
      // preserve other fields that are not part of the array we are using
      extraData = this.workshop.extraData;
    } else {
      extraData = new WorkshopExtraData(new Array<WorkshopExtraDataBssTemplate>());
    }
    extraData.servicesheetTemplates = this.bssTemplates.value;

    formData.append('extraData', JSON.stringify(extraData));

    this.workshopService.modify(workshopID, formData).subscribe({
      next: async () => {
        this.notificationService.success(await this.t.get('FEEDBACK_MESSAGES.BUSINESS_SAVED_SUCCESSFULLY').toPromise());
      },
      error: async (err) => {
        // this.notificationService.error(await this.t.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(), 'Error modifying Workshop');
        const errorsDict = err.error;
        for (const key in errorsDict) {
          if (errorsDict.hasOwnProperty(key)) {
            const element = errorsDict[key];

            let errorTitle = key;
            if (this.SERVER_FIELDS_TO_I18N_MAP.hasOwnProperty(key)) {
              errorTitle = this.SERVER_FIELDS_TO_I18N_MAP[key];
            }

            // TODO: instead of using notificationservice, use the built in form validation to show errors
            await this.notificationService.errorI18N(element[0], errorTitle);
          }
        }
      }
    });
  }

  get bssTemplates(): UntypedFormArray {
    return this.workshopForm.get('bssTemplates') as UntypedFormArray;
  }

  removeBssTemplate(index: number): void {
    this.bssTemplates.removeAt(index);
    this.onSubmit(this.workshop.id);
  }

  addBssTemplate(): void {
    this.bssTemplates.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(''),
        priceMaterials: new UntypedFormControl(''),
        laborCost: new UntypedFormControl('')
      })
    );
  }
}
