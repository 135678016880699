import {Component, Host, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {filter} from 'rxjs/operators';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {BarcodeScannerAbstractComponent} from '../abstract-barcode-scanner/abstract-barcode-scanner';
import {SatPopover} from '@wjaspers/sat-popover';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-input',
  styleUrls: ['./edit-input.component.scss'],
  templateUrl: './edit-input.component.html'
})
export class EditInputComponent extends BarcodeScannerAbstractComponent implements OnInit, OnDestroy {

  /** DEPRECATED: use inline-edit component when possible (inline-edit still does not have datepicker support */
  /** Overrides the comment and provides a reset value when changes are cancelled. */
  @Input() title = '';
  @Input() type = 'text';
  @Input() showBarCodeButton = false;

  @Input()
  get value(): string {
    return this._value;
  }

  set value(x: string) {
    this.textInput = this._value = x;
  }

  // tslint:disable-next-line:variable-name
  private _value = '';


  /** Form model for the input. */
  textInput = '';

  constructor(
    @Optional() @Host() public popover: SatPopover,
    public native: NativeInterfacesService,
    protected dialog: MatDialog
  ) {
    super(native, dialog);
  }

  ngOnInit(): void {
    super.ngOnInit();
    console.log('!!!DEPRECATED!!! EditInputComponent: use inline-edit component when possible');
    // subscribe to cancellations and reset form value
    if (this.popover) {
      this.popover.closed.pipe(filter(val => val == null))
        .subscribe(() => {
          this.textInput = this.value || '';
          // console.log('closed');
        });

      // this.popover.afterClose.pipe(filter(val => val == null))
      //   .subscribe(() => console.log('afterClose'));

    }
  }

  onBarCodeScanner(code: string): void {
    this.appendText(code.toString());
  }

  isKeyboardBarCodeScannerEnabled(): boolean {
    return false;
  }

  appendText(text: string): void {
    if (this.textInput !== null) {
      this.textInput = this.textInput.toString().concat(' ' + text);
    } else {
      this.textInput = text;
    }
  }

  dateChange(event): void {
    if (this.popover) {
      this.popover.close(event.value);
    }
  }

  onSubmit(): void {
    if (this.popover) {
      this.popover.close(this.textInput);
    }
  }

  onCancel(): void {
    if (this.popover) {
      this.popover.close();
    }
  }
}
