<form (ngSubmit)="onSubmit()">
    <!-- /** DEPRECATED: use inline-edit component when possible */ -->
  <!-- <div style="height: 155px;"> -->
    <div class="mat-subtitle-1">{{ title }}</div>

    <!-- type text: -->
    <ng-container *ngIf="type !== 'datetime'">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-form-field>
          <input matInput type="{{type}}" maxLength="140" name="textInput" [(ngModel)]="textInput" autocomplete="off">
          <mat-hint align="end">{{textInput?.length || 0}}/140</mat-hint>
        </mat-form-field>
        <mat-icon *ngIf="native.inFully && showBarCodeButton" (click)="startCamBarcodeScanner()">qr_code_scanner</mat-icon>
      </div>
    </ng-container>

    <!-- Type datetime -->
    <ng-container *ngIf="type === 'datetime'">
      <mat-form-field class="mt1">
        <mat-label>{{ 'DATE' | translate }}</mat-label>
<!--        TODO: change to [value]-->
        <input matInput (click)="picker.open()" [matDatepicker]="picker" [(value)]="textInput"
          (dateChange)="dateChange($event)" autocomplete="off" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <!-- TODO: enable touchUI on tablet -->
        <mat-datepicker #picker touchUi="false" disabled="false"></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <div class="actions">
      <button mat-button type="button" color="" (click)="onCancel()">CANCEL</button>
      <button mat-button type="submit" color="primary">SAVE</button>
    </div>
  <!-- </div> -->
</form>
