import {Component, Inject, Input, OnDestroy, OnInit, Optional, TemplateRef} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {
  FilterMetaData,
  FilterOption,
  FilterTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {ServiceSheet} from '../../models/servicesheets';
import {ServiceSheetService} from '../../services/service-sheets.service';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {PAYMENT_STATUS_I18N_KEY, PaymentStatus} from '../../models/billable_item';
import {BUDGET_STATUS_I18N_KEY} from '../../models/abstract_base_api_item';


export enum viewModes {
  ServiceSheetView,
  BudgetView
}


@Component({
  selector: 'app-servicesheet-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class ServicesheetListComponent extends ItemSimpleFiltrableListAbstractComponent<ServiceSheet>
  implements OnInit, OnDestroy {

  pageTitleI18N = 'LOADING_AND_THREE_DOTS';

  urlBaseDetailItem = 'servicesheets';

  searchPlaceHolderi18NKey = 'BSS_SEARCH_PLACEHOLDER';

  displayedColumnsMetaData = null;

  filtersMetaData = {};

  primaryFab = null;
  secondaryFab = null;

  @Input()
  viewMode: viewModes = null;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ServicesheetListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    private userService: UsersService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected serviceSheetsService: ServiceSheetService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      serviceSheetsService,
      nativeInterfacesService,
      dialog,
      media$,
      userService,
      privacyModeService
    );

  }

  ngOnInit(): void {
    // super.ngOnInit();

    // TODO: remove, if parentId is not null, just create a ticket for that user
    if (this.parentId) {
      this.primaryFab = null;
    }

    if (this.dialogRef != null) {
      this.dialogMode = true;
      if (this.data.mode === 'floating-selector') {
      }
    } else {
    }

    if (this.parentId && this.parentApiFilter === 'bike__id') {
      // We are inside a bike service sheet list, we can add a new service sheet to this bike
      this.addButtonI18N = 'NEW_SERVICE_SHEET';
    }

    // default page size:
    // tslint:disable-next-line
    this.defaultFilters['limit'] = this.userService.getCustomConfig().get_bss_list_config().defaultItemsPerPage;
    if (this.userService.userMe && this.userService.userMe.workshop) {
      // TODO: this should not happen, we should wait for the user to be loaded (maybe on a loading screen?)
      // tslint:disable-next-line
      this.defaultFilters['workshop_id'] = this.userService.userMe.workshop;
    }

    this.configureViewMode();
    this.populateAssignedToFilter();

    super.ngOnInit();
  }

  configureViewMode(): void {
    if (this.router.url.indexOf('budgets') !== -1 || this.viewMode === viewModes.BudgetView) {
      // Budget view
      this.pageTitleI18N = 'BUDGETS_WORKSHOP';
      this.viewMode = viewModes.BudgetView;

      this.primaryFab = new Fab('NEW_BUDGET', 'add', 'fab_add_new_budget', FabTypes.singleAction);

      this.filtersMetaData = {
        budget_status: new FilterMetaData(
          'STATUS',
          FilterTypes.select_single,
          [
            new FilterOption('thisisalist,nea,den,app,dia', 'CTX_SERVICE_SHEETS.ALL'),
            new FilterOption(['dia'], BUDGET_STATUS_I18N_KEY.dia),
            new FilterOption(['nea'], BUDGET_STATUS_I18N_KEY.nea),
            new FilterOption(['den'], BUDGET_STATUS_I18N_KEY.den),
            new FilterOption(['app'], BUDGET_STATUS_I18N_KEY.app),
          ]),
      };
    } else if (this.router.url.indexOf('budgets') === -1 || this.viewMode === viewModes.ServiceSheetView) {
      // Service sheet view
      this.viewMode = viewModes.ServiceSheetView;
      this.pageTitleI18N = 'SERVICE_SHEETS';

      this.primaryFab = new Fab('NEW_SERVICESHEET', 'add', 'fab_add_new_servicesheet', FabTypes.singleAction);
      this.viewMode = viewModes.ServiceSheetView;
      // TODO: maybe on the future we can set hard api filters (that are not on query params, and cannot be changed by the user)
      //  for this type of viewmode use case
      this.apiFilters = {budget_status: ['don', 'app', 'dia']};

      // TODO: this should be custom configurable by the user
      this.filtersMetaData = {
        assigned_to_id: new FilterMetaData('ASSIGNED_TO', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')]),
        closed: new FilterMetaData(
          'WORK_STATUS',
          FilterTypes.select_single,
          [
            new FilterOption('default_null_filter', 'CTX_SERVICE_SHEETS.ALL'),
            new FilterOption('false', 'CTX_SERVICE_SHEETS.PENDING'),
            new FilterOption('true', 'CTX_SERVICE_SHEETS.FINISHED'),
          ]),
        payment_status: new FilterMetaData(
          'PAYMENTS.PAYMENT_STATUS',
          FilterTypes.select_single,
          [
            new FilterOption('default_null_filter', 'PAYMENTS.ALL'),
            new FilterOption(PaymentStatus.Paid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Paid]),
            new FilterOption(PaymentStatus.PendingAndPartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
            // new FilterOption(PaymentStatus.Pending, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
            // new FilterOption(PaymentStatus.PartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.PartiallyPaid]),
            // new FilterOption(PaymentStatus.Unknown, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Unknown]),
            new FilterOption(PaymentStatus.AdvancePayment, PAYMENT_STATUS_I18N_KEY[PaymentStatus.AdvancePayment]),
          ]),
        check_in_status: new FilterMetaData(
          'CHECK_IN_STATUS',
          FilterTypes.select_single,
          [
            new FilterOption('default_null_filter', 'CTX_SERVICE_SHEETS.ALL'),
            new FilterOption('ret', 'PENDING_SCHEDULED_PICK_UP'),
            new FilterOption('cpe', 'PENDING_CHECK_IN'),
            new FilterOption('cin', 'CHECKED_IN'),
            new FilterOption('cou', 'CHECKED_OUT'),
          ]),
      };

      if (this.userService.business && this.userService.business.mechanicsCount < 2 && this.filtersMetaData.hasOwnProperty('assigned_to_id')) {
        console.log(this.filtersMetaData);
        // tslint:disable-next-line
        delete this.filtersMetaData['assigned_to_id'];
      }
    }
  }

  configureColumns(): void {
    if (this.viewMode === viewModes.ServiceSheetView) {
      this.displayedColumnsCustomConfig = this.userService.getCustomConfig().get_service_sheets_list_columns();

      // Don't show assigned to column if there is only one mechanic
      if (this.userService.business && this.userService.business.mechanicsCount < 2) {
        // tslint:disable-next-line:no-string-literal
        this.displayedColumnsCustomConfig = this.displayedColumnsCustomConfig.filter((item) => item['FIELD'] !== 'assignedToName');
      }

      try {
        for (const column of this.displayedColumnsCustomConfig) {
          // tslint:disable-next-line
          if (column['FIELD'] === 'createdAt') {
            // I want to change the display type of this column, but only for this view
            // tslint:disable-next-line
            column['TYPE'] = 'dateAgo';
          }
        }

        const fieldsNotShownOnMinimalView = [
          'invoiceNumber',
          'roPaymentStatus',
          'roPaymentMethod',
          'notifiedClient',
          'totalAmountWithTax',
          'closedAt'];
        // TODO: I don't like this approache, but I don't have time to do it better
        //   maybe we should add another flag to the fields that is called "showOnMinimalView" or something like that
        //   otherwise this will not be configurable either
        // tslint:disable-next-line
        if (this.minimal) {
          // tslint:disable-next-line
          this.displayedColumnsCustomConfig = this.displayedColumnsCustomConfig.filter((item) => !fieldsNotShownOnMinimalView.includes(item['FIELD']));
        }
      } catch (e) {
        // console.error(e);
      }
    } else if (this.viewMode === viewModes.BudgetView) {
      this.displayedColumnsCustomConfig = this.userService.getCustomConfig().get_budget_list_columns();
    } else {
      throw new Error('Unknown view mode');
    }
    super.configureColumns();
  }

  populateAssignedToFilter(): void {
    if (this.minimal) {
        return;
    }
    if (!this.filtersMetaData.hasOwnProperty('assigned_to_id')) {
      return;
    }
    this.userService.getStaff().subscribe(staffUsers => {
      const filterOptions = [new FilterOption('default_null_filter', 'ALL')];
      for (const staffUser of staffUsers) {
        filterOptions.push(new FilterOption(staffUser.id, staffUser.name));
      }
      // tslint:disable-next-line:no-string-literal
      this.filtersMetaData['assigned_to_id'] = new FilterMetaData('ASSIGNED_TO', FilterTypes.select_single, filterOptions);
    });
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'fab_add_new_servicesheet') {
      this.router.navigate(['/new_bss_stepper']);
      return true;
    }
    if (actionId === 'fab_add_new_budget') {
      this.router.navigate(['/new_bss_stepper', {budget: true}]);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  getFieldTemplateRef(item: ServiceSheet, field: string): TemplateRef<any> {
    if (field === 'priority') {
      return this.templateIcon;
    }
    if (field === 'checkInStatus') {
      return this.templateIcon;
    }
    if (field === 'notifiedClient') {
      return this.templateIcon;
    }
    return super.getFieldTemplateRef(item, field);
  }

  getFieldTemplatedContext(item: ServiceSheet, field: string): object {
    if (field === 'priority') {
      const priotiyIcons = {
        hig: {icon: 'arrow_upward', color: 'red'},
        nor: {icon: 'horizontal_rule', color: 'black'},
        low: {icon: 'arrow_downward', color: 'blue'},
        pau: {icon: 'pause_circle', color: 'yellowgreen'},
      };
      return {icon: priotiyIcons[item.priority].icon, color: priotiyIcons[item.priority].color};
    }
    // tooltipI18N, customClass
    if (field === 'checkInStatus') {
      const checkInStatusIcons = {
        cou: {icon: 'check_circle', customClass: 'text-success', tooltipI18N: 'CHECKED_OUT'},
        cin: {icon: 'build', customClass: '', tooltipI18N: 'CHECKED_IN'},
        cpe: {icon: 'event_upcoming', customClass: 'text-warning', tooltipI18N: 'CHECK_IN_SCHEDULED'},
        ret: {icon: 'airport_shuttle', customClass: 'text-warning', tooltipI18N: 'PICKUP_SCHEDULED'},
      };
      // TODO: re-enable show check in date as a tooltip, or at least show it in the list as a configurable column
      return {
        icon: checkInStatusIcons[item.checkInStatus].icon,
        customClass: checkInStatusIcons[item.checkInStatus].customClass,
        // tooltipI18N: checkInStatusIcons[item.checkInStatus].tooltipI18N + "{{ element.checkInScheduledDt | date:'mediumDate' }}"
        tooltipI18N: checkInStatusIcons[item.checkInStatus].tooltipI18N
      };
    }
    if (field === 'notifiedClient') {
      if (item.notifiedClient) {
        return {icon: 'email', customClass: 'text-success'};
      }
      return {icon: ''};
    }
    return super.getFieldTemplatedContext(item, field);
  }

  getExtraRowClass(serviceSheet: ServiceSheet): string {
    if (this.viewMode === viewModes.BudgetView) {
      return '';
    }
    if (serviceSheet.closed) {
      return 'border-l-indicator-success';
    }
    if (serviceSheet.started) {
      return 'border-l-indicator-warning';
    }
    if (!serviceSheet.closed) {
      return 'border-l-indicator-neutral';
    }
    return super.getExtraRowClass(serviceSheet);
  }
}
