import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppMetricsService} from '../../services/app-metrics.service';

export enum STEP_STATUS {
  NOTSTARTED = 0,
  STARTED = 1,
  FINISHED = 2
}

@Component({
  selector: 'app-workflow-buttons',
  templateUrl: './workflow-buttons.component.html',
  styleUrls: ['./workflow-buttons.component.scss']
})
export class WorkflowButtonsComponent implements OnInit {

  STEP_STATUS = STEP_STATUS;

  @Input() steps;

  @Input() nextStepAction = null;
  @Output() stepChange = new EventEmitter();

  constructor(public appMetricsService: AppMetricsService) { }

  ngOnInit(): void {
  }

  setCurrentStep(step: object): void {
    // tslint:disable-next-line
    if (step['type'] !== 'select') {
      this.stepChange.emit({step});
      this.appMetricsService.sendEvent(this.appMetricsService.EVENT_NAMES.BSS_CONTROLS, 'workflow-buttons');
    }
  }

  setCurrentStepMenu(step: object, menuValue: string): void {
    this.stepChange.emit({step, menuValue});
    this.appMetricsService.sendEvent(this.appMetricsService.EVENT_NAMES.BSS_CONTROLS, 'workflow-buttons');
  }
}
