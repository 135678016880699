import {Component, OnDestroy, OnInit} from '@angular/core';
import {SummaryAnalytics} from 'src/app/models/analytics';
import {AnalyticsCRMService} from 'src/app/services/analytics-crm.service';
import {UsersService} from '../../services/users.service';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-analytics-crm',
  templateUrl: './analytics-crm.component.html',
  styleUrls: ['./analytics-crm.component.scss']
})
export class AnalyticsCrmComponent implements OnInit, OnDestroy {
  summaryAnalytics: SummaryAnalytics;
  currencyCode: string;

  analyticsSummaryListCustomConfig: object[] = null;

  destroyed = new Subject();

  constructor(
    private analyticsCRMService: AnalyticsCRMService,
    private usersService: UsersService,
    public privacyModeService: PrivacyModeService
  ) {
  }

  ngOnInit(): void {
    // TODO: remove from this, use | currency pipe on template

    this.usersService.business$.pipe(
      takeUntil(this.destroyed),
    ).subscribe(b => this.currencyCode = b ? b.currency.toUpperCase() : null);

    this.configureAnalyticsSummaryList();
    // TODO: unsubscribe on destroy, etc
    this.analyticsCRMService.getSummary().subscribe(an => this.summaryAnalytics = an);
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  // dupe code on service sheet detail ts
  getTotalTimeString(time: number): string {
    return new Date(time * 1000).toISOString().substr(11, 8);
  }

  configureAnalyticsSummaryList(): void {
    this.analyticsSummaryListCustomConfig = this.usersService.getCustomConfig().get_analytics_summary_list();
  }

}
