<mat-card class="cash-change-calculator">
  <mat-card-header>
    <mat-card-title>{{ "CTX_CASH_CHANGE_CALCULATOR.TITLE" | translate }}</mat-card-title>
    <mat-card-subtitle>{{ "CTX_CASH_CHANGE_CALCULATOR.TOTAL_PRICE" | translate }}: {{ totalPrice | currency: currentCurrency.code : 'symbol-narrow' }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div fxLayout="column-reverse" fxLayout.gt-md="row wrap" fxLayoutGap="16px grid">

      <div>
        <mat-card class="summary-section" fxLayout="column" fxLayoutGap="8px">
          <mat-card-content>
            <p><strong>{{ "CTX_CASH_CHANGE_CALCULATOR.AMOUNT_PAID" | translate }}:</strong> {{ amountPaid | currency: currentCurrency.code : 'symbol-narrow' }}</p>
            <p><strong>{{ "CTX_CASH_CHANGE_CALCULATOR.CHANGE_DUE" | translate }}:</strong> {{ change | currency: currentCurrency.code : 'symbol-narrow' }}</p>
          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="change > 0" class="change-breakdown-section">
          <mat-card-title>{{ "CTX_CASH_CHANGE_CALCULATOR.CHANGE_BREAKDOWN" | translate }}</mat-card-title>
          <mat-card-content>
            <mat-list>
              <mat-list-item *ngFor="let item of changeBreakdown | keyvalue">
                {{ +item.key | currency: currentCurrency.code : 'symbol-narrow' }}: {{ item.value }}
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <form [formGroup]="calculatorForm" fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
            <mat-card fxFlex="100" fxFlex.gt-sm="calc(50% - 16px)" class="denomination-section">
              <mat-card-title>{{ "CTX_CASH_CHANGE_CALCULATOR.BILLS" | translate }}</mat-card-title>
              <mat-card-content>
                <ng-container *ngFor="let denomination of currentCurrency.denominations">
                  <ng-container *ngIf="isBill(denomination)">
                    <div class="denomination-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                        <span fxFlex="80px">{{ denomination | currency: currentCurrency.code : 'symbol-narrow' }}</span>
                        <button mat-mini-fab class="pastel-red-button" (click)="updatePaidAmount(denomination, false)">-</button>
                        <span fxFlex="40px" class="count-display">{{ getControl(denomination)?.value || 0 }}</span>
                        <button mat-mini-fab class="pastel-green-button" (click)="updatePaidAmount(denomination, true)">+</button>
                    </div>
                  </ng-container>
                </ng-container>
              </mat-card-content>
            </mat-card>

            <mat-card fxFlex="100" fxFlex.gt-sm="calc(50% - 16px)" class="denomination-section">
              <mat-card-title>{{ "CTX_CASH_CHANGE_CALCULATOR.COINS" | translate }}</mat-card-title>
              <mat-card-content>
                <ng-container *ngFor="let denomination of currentCurrency.denominations">
                  <ng-container *ngIf="!isBill(denomination)">
                    <div class="denomination-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                        <span fxFlex="80px">{{ denomination | currency: currentCurrency.code : 'symbol-narrow' }}</span>
                        <button mat-mini-fab class="pastel-red-button" (click)="updatePaidAmount(denomination, false)">-</button>
                        <span fxFlex="40px" class="count-display">{{ getControl(denomination)?.value || 0 }}</span>
                        <button mat-mini-fab class="pastel-green-button" (click)="updatePaidAmount(denomination, true)">+</button>
                    </div>
                  </ng-container>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </div>
        </form>
      </div>


    </div>
  </mat-card-content>

</mat-card>

