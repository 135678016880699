import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UsersService} from 'src/app/services/users.service';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup;
  loading = false;
  submitted = false;

  brand = environment.brand;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private formBuilder: UntypedFormBuilder,
    private notifications: CrmNotificationsService
  ) { }

  ngOnInit(): void {

    if (this.userService.isLogged) {
      const returnUrl = 'dashboard';
      this.router.navigateByUrl(returnUrl, { replaceUrl: true });
    }

    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      business: ['', Validators.required],
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit(): void {
    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    const businessSlug = this.f.business.value;

    this.userService.login(`${businessSlug}___${this.f.username.value}`, this.f.password.value)
      .pipe(first())
      .subscribe({
        next: () => {
          // get return url from query parameters or default to home page
          // const returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
          const returnUrl = 'dashboard';
          this.router.navigateByUrl(returnUrl, { replaceUrl: true });
        },
        error: error => {
          // this.alertService.error(error);
          this.loading = false;
          try {
            // TODO: translate i18n
            this.notifications.warning(error.error.non_field_errors[0]);
          } catch (error) {
            // TODO: report
            this.notifications.error('Unknown error, please try again later or contact support');
          }
        }
      });
  }

}
