<div fxLayout="column" fxLayoutGap="14" class="p2">

  <h3>{{ 'INSTALLED_COMPONENTS' | translate }}</h3>
  <div *ngIf="bikesInstalledProducts.length === 0" fxLayout="row" fxLayoutAlign="space-around center">
    <span>{{ 'NO_INSTALLED_COMPONENTS' | translate }}</span>
  </div>

  <div *ngFor="let bikesInstalledProduct of bikesInstalledProducts" fxLayout="row" fxLayoutAlign="space-around center">

    <span>{{ bikesInstalledProduct.name }}</span>

    <span>{{ bikesInstalledProduct.createdAt | dateAgo }}</span>

    <span [ngClass]="{'text-danger': getPercentageOfWear(bikesInstalledProduct) > 90}">{{ bikesInstalledProduct.currentLifetimeKm }} / {{ bikesInstalledProduct.lifetimeKm }} km</span>

    <mat-progress-bar mode="determinate" [value]="getPercentageOfWear(bikesInstalledProduct)" style="width: 50px;"></mat-progress-bar>

    <mat-icon (click)="retire(bikesInstalledProduct)" matTooltip="{{'RETIRE_COMPONENT' | translate }}">backspace</mat-icon>
  </div>

  <hr>

  <h3>{{ 'RETIRED_COMPONENTS' | translate }}</h3>
  <div *ngIf="retiredBikesInstalledProducts.length === 0" fxLayout="row" fxLayoutAlign="space-around center">
    <span>{{ 'NO_RETIRED_COMPONENTS' | translate }}</span>
  </div>
  <div *ngFor="let retiredProduct of retiredBikesInstalledProducts" fxLayout="row" fxLayoutAlign="space-around center">

  <span>{{ retiredProduct.name }}</span>

  <span>{{ retiredProduct.createdAt | dateAgo }}</span>

  <span [ngClass]="{'text-danger': getPercentageOfWear(retiredProduct) > 90}">{{ retiredProduct.currentLifetimeKm }} / {{ retiredProduct.lifetimeKm }} km</span>

  <mat-progress-bar mode="determinate" [value]="getPercentageOfWear(retiredProduct)" style="width: 50px;"></mat-progress-bar>
  </div>

</div>
