import {enableProdMode, LOCALE_ID} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  // TODO: why here and not in app module normal providers, so this is the default, until app.module.ts is loaded?
  providers: [{ provide: LOCALE_ID, useValue: 'en-US' }]
}).then(success => { })
  .catch(err => console.error(err));
