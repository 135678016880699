<div class="base-paddings">
  <ng-container *ngIf="tabComponents">
    <!-- TODO: lazy load tabs? -->
    <mat-tab-group animationDuration="0ms">
      <mat-tab *ngFor="let tabComponent of tabComponents;" label="{{ tabComponent.i18n_label | translate }}">
        <dcl-wrapper [type]="tabComponent.component" [params]="tabComponent.params"></dcl-wrapper>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</div>
