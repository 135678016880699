<!-- TODO: too many references to orderitem and other things that should be abstract -->
<table mat-table [dataSource]="dataSource" multiTemplateDataRows fxFill>

  <!--- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition" -->

  <ng-container *ngFor="let colDef of displayedColumns">

    <ng-container matColumnDef="{{ colDef }}">
      <th mat-header-cell fxFlex="{{ fields[colDef].w }}" fxLayoutAlign="{{ fields[colDef].align }} center"
                       *matHeaderCellDef
                       class="mat-body-strong"> {{ fieldMetaData(colDef).i18nKey | translate }} </th>

      <td mat-cell fxFlex="{{ fields[colDef].w }}" fxLayoutAlign="{{ fields[colDef].align }} center"
                *matCellDef="let orderItem" [ngClass]="{'disabled': isItemSyncing(orderItem)}">

        <!-- height here avoids glitches going to edit and non edit, as edit mode has a higher height than non edit -->
<!--        TODO: remove this and look for an abstract solution: -->
        <ng-container *ngIf="colDef == 'reminderSubjectDocument'">
          <mat-icon (click)="goToDocument(orderItem)">link</mat-icon>
        </ng-container>

        <ng-container *ngIf="fieldMetaData(colDef).type == childItemFieldTypes.text">
          <app-inline-edit class="w100" style="min-height: 58px;" [data]='orderItem[colDef]'
                           [mainInput]="fields[colDef].mainInput"
                           [autoCompletefilteredOptions]="fields[colDef].autoCompleteOptions ? fields[colDef].autoCompleteOptions : []"
                           (autoCompleteOptionSelected)="onAutoCompleteOptionSelected($event, orderItem, colDef)"
                           (onInputChange)="onInputChange($event, orderItem, colDef)"
                           addHint="{{ fieldMetaData(colDef).i18nKeyAddAction | translate }}"
                           (focusOut)="closedEdit(orderItem, $event, colDef)"
                           [editable]="allowEditList() && fieldMetaData(colDef).readonly !== true"
                           (editModeStatus)="onEditStatusChange($event, orderItem, colDef)"></app-inline-edit>
        </ng-container>

        <ng-container *ngIf="fieldMetaData(colDef).type == childItemFieldTypes.number">
          <app-inline-edit class="w100" style="min-height: 58px;" type="number" align="end" [data]='orderItem[colDef]'
                           [mainInput]="fields[colDef].mainInput"
                           addHint="{{ fieldMetaData(colDef).i18nKeyAddAction | translate }}"
                           (focusOut)="closedEdit(orderItem, $event, colDef)"
                           [editable]="allowEditList() && fieldMetaData(colDef).readonly !== true"
                           (editModeStatus)="onEditStatusChange($event, orderItem, colDef)"></app-inline-edit>
        </ng-container>
        <ng-container *ngIf="fieldMetaData(colDef).type == childItemFieldTypes.date">
          <!-- TODO: allow data edit with some date selector -->
          <span>{{ orderItem[colDef] | date:'dd/MM/yy' }}</span><small style="color: gray; margin-left: 2px;">{{ orderItem[colDef] | date:'HH:mm' }}</small>
        </ng-container>
        <ng-container *ngIf="fieldMetaData(colDef).type == childItemFieldTypes.integer">
          <app-inline-edit class="w100" style="min-height: 58px;" type="integer" align="end" [data]='orderItem[colDef]'
                           [mainInput]="fields[colDef].mainInput"
                           addHint="{{ fieldMetaData(colDef).i18nKeyAddAction | translate }}"
                           (focusOut)="closedEdit(orderItem, $event, colDef)"
                           [editable]="allowEditList() && fieldMetaData(colDef).readonly !== true"
                           (editModeStatus)="onEditStatusChange($event, orderItem, colDef)"></app-inline-edit>
        </ng-container>
        <ng-container *ngIf="fieldMetaData(colDef).type == childItemFieldTypes.percent">
          <app-inline-edit class="w100" style="min-height: 58px;" type="percent" align="end"
                           [data]='orderItem[colDef]'
                           [mainInput]="fields[colDef].mainInput"
                           [editable]="allowEditList() && fieldMetaData(colDef).readonly !== true"
                           addHint="{{ fieldMetaData(colDef).i18nKeyAddAction | translate }}"
                           (focusOut)="closedEdit(orderItem, $event, colDef)"
                           (editModeStatus)="onEditStatusChange($event, orderItem, colDef)"></app-inline-edit>
        </ng-container>

        <ng-container *ngIf="fieldMetaData(colDef).type == childItemFieldTypes.currency">
          <app-inline-edit class="w100" style="min-height: 58px;" type="currency" align="end" [data]='orderItem[colDef]'
                           [mainInput]="fields[colDef].mainInput"
                           addHint="{{ fieldMetaData(colDef).i18nKeyAddAction | translate }}"
                           (focusOut)="closedEdit(orderItem, $event, colDef)"
                           [editable]="allowEditList() && fieldMetaData(colDef).readonly !== true"
                           [currencyCode]="currencyCode"
                           (editModeStatus)="onEditStatusChange($event, orderItem, colDef)"></app-inline-edit>
<!--          <small class="muted">↳ {{ getTotalCostItem(orderItem) | currency: currencyCode : 'symbol-narrow' }}</small>-->
        </ng-container>

        <ng-container *ngIf="fieldMetaData(colDef).type == childItemFieldTypes.boolean">
          <ng-container *ngIf="mode === 'editList'">
            <div fxFlexAlign="center" *ngIf="orderItem.id && !_isDraft(orderItem)">
              <button mat-stroked-button type="button" *ngIf="!orderItem[colDef]"
                      color="primary" (click)="onBooleanFieldChange(orderItem, colDef, true)">
                <mat-icon>radio_button_unchecked</mat-icon>
                <span *ngIf="getBooleanLiteral(orderItem)" fxHide fxShow.gt-sm class="ml1">{{ getBooleanLiteral(orderItem) }}</span>
              </button>
              <button mat-stroked-button type="button" *ngIf="orderItem[colDef]" color="success" (click)="onBooleanFieldChange(orderItem, colDef, false)">
                <!--                    <mat-icon>published_with_changes</mat-icon>-->
                <mat-icon>check_circle_outline</mat-icon>
                <span *ngIf="getBooleanLiteral(orderItem)" fxHide fxShow.gt-sm class="ml1">{{ getBooleanLiteral(orderItem) }}</span>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="mode === 'viewList'">
            <div fxFlexAlign="center">
              <span class="material-symbols-outlined">
                <mat-icon *ngIf="orderItem[colDef]" color="success">check_circle_outline</mat-icon>
                <mat-icon *ngIf="!orderItem[colDef]" color="primary">radio_button_unchecked</mat-icon>
              </span>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="fieldMetaData(colDef).type == childItemFieldTypes.calculated">
          <div class="w100" fxLayout="column" fxLayoutAlign="{{ fields[colDef].align }} center">
            <app-inline-edit class="w100"
                             style="min-height: 58px;"
                             type="{{ getInputTypeCalculated(colDef) }}"
                             align="end"
                             [data]='getCalculatedField(orderItem, colDef)'
                             [dataDisplay]='getCalculatedFieldDisplay(orderItem, colDef)'
                             [mainInput]="fields[colDef].mainInput"
                             [currencyCode]="currencyCode"
                             addHint="{{ fieldMetaData(colDef).i18nKeyAddAction | translate }}"
                             [editable]="allowEditList() && fieldMetaData(colDef).readonly !== true"
                             (focusOut)="setCalculatedField(orderItem, $event, colDef)"
                             (editModeStatus)="onEditStatusChange($event, orderItem, colDef)"></app-inline-edit>
            <!-- TODO: extract this somehow -->
<!--            <small class="muted">↳ {{ getTotalCostItem(orderItem) | currency: currencyCode : 'symbol-narrow' }}</small>-->
          </div>
        </ng-container>

        <ng-container *ngIf="fieldMetaData(colDef).type == childItemFieldTypes.action && allowEditList()">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="deleteItem(orderItem)">
              <mat-icon>delete_outline</mat-icon>
              <span>{{ 'DELETE' | translate}}</span>
            </button>
          </mat-menu>
        </ng-container>

        <span *ngIf="isItemSyncing(orderItem) && fields[colDef].mainInput">{{ 'SAVING' | translate }}...</span>
      </td>

      <!-- TODO: extract this somehow, like a param and a calc for last row aggregations or something like that -->
      <!-- TODO: for now we hide this if we are in bank accounts, detecting per field, this is a bad way to do it -->
      <!-- TODO: also we are leaving the row there. it's preferred to remove the row or pupulate it with usefull info -->
      <ng-container>
        <ng-container *ngIf="fields[colDef].mainInput">
          <td mat-footer-cell *matFooterCellDef fxFlex="{{ fields[colDef].w }}"
                           fxLayoutAlign="{{ fields[colDef].align }} center"
                           class="mat-body-strong">
            <ng-container *ngIf="!fields.hasOwnProperty('balance') && !fields.hasOwnProperty('dueDateMonths') && !fields.hasOwnProperty('dueDate')">  {{ 'TOTAL_COST' | translate }} </ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="fieldMetaData(colDef).i18nKey.indexOf('TOTAL') == -1 && !fields[colDef].mainInput">
          <td mat-footer-cell *matFooterCellDef fxFlex="{{ fields[colDef].w }}"
                           fxLayoutAlign="{{ fields[colDef].align }} center"></td>
        </ng-container>
        <ng-container *ngIf="fieldMetaData(colDef).i18nKey.indexOf('TOTAL') != -1 && !fields[colDef].mainInput">
          <td mat-footer-cell *matFooterCellDef fxFlex="{{ fields[colDef].w }}"
                           fxLayoutAlign="{{ fields[colDef].align }} center"
                           class="mat-body-strong"> {{ getTotalCost() | currency: currencyCode : 'symbol-narrow' }} </td>
        </ng-container>
      </ng-container>

    </ng-container>
  </ng-container>

  <ng-container matColumnDef="addItemFooter">
    <td mat-footer-cell *matFooterCellDef style="border-bottom: 0;">
      <span (click)="addItemEnd()" class="muted py3 block"
            style="text-align: center; cursor: pointer;">+ {{ addItemLiteralI18n | translate }}</span>
    </td>
  </ng-container>

  <tr mat-header-row fxLayoutGap="5px" *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
           fxLayoutGap="5px"
           class="element-row">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['addItemFooter']" class="add-item-footer-row" fxLayoutGap="5px"
                  [ngStyle]="{'display': (allowEditList() && showFooterAddOption) ? 'flex' : 'none'}"></tr>
  <ng-container *ngIf="showFooter">
    <tr mat-footer-row *matFooterRowDef="displayedColumns" fxLayoutGap="5px"></tr>
  </ng-container>
</table>
