import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  ChildItemEditableListAbstractComponent,
  ChildItemFieldMetadata,
  ChildItemFieldTypes,
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {BikeCRMApiPaginated} from '../../models/api';
import {Reminder} from '../../models/reminder';
import {ReminderService} from '../../services/reminder.service';
import {Router} from '@angular/router';

@Component({
  selector: 'reminder-list',
  templateUrl: '../abstract-child-editable-item-list/abstract-child-editable-item-list.component.html',
  styleUrls: ['../abstract-child-editable-item-list/abstract-child-editable-item-list.component.scss'],
  // TODO: Document
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReminderListComponent extends ChildItemEditableListAbstractComponent<Reminder> implements OnInit {
  parentApiRelName = 'contentObject';

  @Input() contentType = '';
  @Input() objectId = '';

  showFooter = true;

  showFooterAddOption = true;

  addItemLiteralI18n = 'NEW_REMINDER';

  reminders$: Observable<BikeCRMApiPaginated<any>>;

  fields = {
    title: {
      metadata: new ChildItemFieldMetadata('REMINDER_TITLE', 'ADD_REMINDER_TITLE', ChildItemFieldTypes.text, '', true, null, false),
      mainInput: false,
      w: 35,
      align: 'start'
    },
    dueDateMonths: {
      metadata: new ChildItemFieldMetadata('REMINDER_DUE_DATE_MONTHS', 'ADD_DUE_DATE', ChildItemFieldTypes.calculated, 6, true, null, false),
      mainInput: true,
      w: 15,
      align: 'end'
    },
    reminderSubjectDocument: {
      metadata: new ChildItemFieldMetadata('REMINDER_SUBJECT_DOCUMENT', '', ChildItemFieldTypes.calculated, '', true, null, false, false, true),
      mainInput: true,
      w: 10,
      align: 'end'
    },
    isCompleted: {
      metadata: new ChildItemFieldMetadata('IS_COMPLETED', '', ChildItemFieldTypes.boolean, false, true, null, false),
      mainInput: false
    },
    dueDate: {
      metadata: new ChildItemFieldMetadata('REMINDER_DUE_DATE', '', ChildItemFieldTypes.date, '', true, null, false, true),
      mainInput: false,
      w: 10,
      align: 'end'
    },
    more_opts: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.action, false, true, null, false),
      mainInput: false,
      align: 'end'
    },
    workshop: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.text, '', true, null, false, true),
      mainInput: false,
      w: 1,
      align: 'end'
    },
    contentType: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.text, this.contentType, true, null, false, true),
      mainInput: false,
      w: 1,
      align: 'end'
    },
    objectId: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.foreign_key, this.objectId, true, null, false, true),
      mainInput: false,
      w: 1,
      align: 'end'
    },
  };

  @ViewChild(MatTable, {static: true}) table: MatTable<Reminder>;
  dataSource = new MatTableDataSource();

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    notificationService: CrmNotificationsService,
    private reminderService: ReminderService,
    private router: Router,
    protected nativeInterfacesService: NativeInterfacesService,
    protected usersService: UsersService
  ) {
    super(dialog, translate, notificationService, reminderService, nativeInterfacesService, usersService);
  }

  ngOnInit(): void {
    this.parent = this.objectId;
    super.ngOnInit();

    // TODO: find a better way to set the default value for workshop:
    this.fields.workshop.metadata.defaultValue = this.usersService.business.workshops[0].id;

    if (this.objectId === '') {
      delete this.fields.contentType;
      delete this.fields.objectId;
      delete this.fields.dueDateMonths;

      this.fields.dueDate.metadata.hidden = false;

      this.reminders$ = this.reminderService.getPaginatedList({}, '', false, 1, 20);
    } else {
      delete this.fields.reminderSubjectDocument;

      // TODO: can we move this to the parent abstract class? at least a loadItems call?
      this.reminders$ = this.reminderService.getPaginatedList(
        {
          content_type: this.contentType,
          object_id: this.objectId
        },
        '',
        false,
        1,
        20
      );
    }

    this.reminders$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.items = x.results;
      this.dataSource.data = this.items;
    });

  }

  createNewItemInstance(): Reminder {
    const item = new Reminder();
    item.id = this._getID(item);
    // TODO: don't hardcode default months for due date
    item.dueDate = new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString();
    item.contentType = this.contentType;
    item.objectId = this.objectId;
    return item;
  }

  goToDocument(item: Reminder): void {
    if (item.contentType === 'bikes | bike') {
      this.router.navigate([`/bikes/${item.objectId}`], {replaceUrl: true});
    } else {
    }
  }


  getInputTypeCalculated(field: string): string {
    if (field === 'dueDateMonths') {
      return 'string';
    }
    if (field === 'reminderSubjectDocument') {
      return 'string';
    }
    return super.getInputTypeCalculated(field);
  }


  getCalculatedField(item: any, field: string): any {
    if (field === 'dueDateMonths') {
      return Math.round((new Date(item.dueDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24 * 30));
    }
    if (field === 'reminderSubjectDocument') {
      if (item.contentType === 'bikes | bike') {
        return 'BIKE';
      }
      return 'GENERAL';
    }
    return super.getCalculatedField(item, field);
  }


  getCalculatedFieldDisplay(item: any, field: string): any {
    if (field === 'dueDateMonths') {
      return `${this.getCalculatedField(item, field)} MONTHS`;
    }
    return super.getCalculatedFieldDisplay(item, field);
  }

  setCalculatedField(item: any, modifiedText: string, field: string): void {
    if (field === 'dueDateMonths') {
      const newDate = new Date();
      newDate.setMonth(newDate.getMonth() + parseInt(modifiedText, 10));
      item.dueDate = newDate.toISOString();
      this.saveItem(item);
      return;
    }
    super.setCalculatedField(item, modifiedText, field);
  }

  saveItem(item: Reminder): void {
    if (this.objectId === null || this.objectId === '') {
      item.objectId = null;
      this.contentType = null;
    } else {
      item.objectId = this.objectId;
      item.contentType = this.contentType;
    }
    super.saveItem(item);
  }
}
