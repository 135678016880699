<div *ngIf="privacyModeService.isPrivacyModeEnabled()" fxLayoutAlign="center">
  <!--      TODO: on click, ask for disable privacy mode -->
  <mat-icon>visibility_off</mat-icon>
</div>
<ng-container *ngIf="summaryAnalytics">
    <div *ngIf="!privacyModeService.isPrivacyModeEnabled()">

        <div *ngFor="let summaryField of analyticsSummaryListCustomConfig" fxLayout="row" fxLayoutAlign="space-between">
          <span *ngIf="summaryField['TYPE'] == 'text'">{{ summaryField['I18N_KEY'] | translate }}:</span><span *ngIf="summaryField['TYPE'] == 'text'">{{ summaryAnalytics[summaryField['FIELD']] }}</span>
          <span *ngIf="summaryField['TYPE'] == 'currency'">{{ summaryField['I18N_KEY'] | translate }}:</span><span *ngIf="summaryField['TYPE'] == 'currency'">{{ summaryAnalytics[summaryField['FIELD']] | currency: currencyCode :'symbol-narrow' }}</span>
          <span *ngIf="summaryField['TYPE'] == 'totalTime'">{{ summaryField['I18N_KEY'] | translate }}:</span><span *ngIf="summaryField['TYPE'] == 'totalTime'">{{ getTotalTimeString(summaryAnalytics[summaryField['FIELD']]) }}</span>
          <hr *ngIf="summaryField['TYPE'] == 'meta_divider'"/>
          <div *ngIf="summaryField['TYPE'] == 'meta_newline'" class="my1"></div>
        </div>
    </div>
</ng-container>
