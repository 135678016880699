import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NativeInterfacesService} from '../../../services/native-interfaces.service';
import {Workshop} from '../../../models/workshop';
import {UsersService} from '../../../services/users.service';


class ErrorDialogComponent {
}

@Component({
  selector: 'app-move-stock-dialog-component',
  template: `
        <div class="p2"><h2>{{ "MOVE_STOCK" | translate }}</h2>
        <form (ngSubmit)="onSave(workShop.value, aumountToMove.value)" [formGroup]="simpleForm">
          <div>
            <label>{{ 'AMOUNT_TO_MOVE' | translate }}</label>
            <br>
            <mat-form-field>
              <mat-label>{{ 'AMOUNT_TO_MOVE' | translate }}</mat-label>
              <input matInput type="number" autocomplete="off" #aumountToMove value="1">
            </mat-form-field>
            <br>
            <mat-form-field>
              <mat-label>{{ 'WORKSHOP' | translate }}</mat-label>
              <mat-select #workShop [(value)]="selectedWorkshop">
                <mat-option *ngFor="let workshop of workshops" [value]="workshop.id" [disabled]="workshop.id == workShopId" (select)="workshop.id != workShopId">
                  {{workshop.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <br>
          <br>

          <button type="submit" [disabled]="!simpleForm.valid" mat-button>{{ "MOVE" | translate }}</button>
        </form>
        </div>

  `
})
export class MoveStockDialogComponent {
  // TODO: a little of overlap with simple text dialog, maybe we can have a generic
  //  dialog that you can pass different inputs

  simpleForm: UntypedFormGroup;
  workShopId = '';
  workshops: Workshop[] = [];
  selectedWorkshop = '';

  productId: string;

  constructor(public dialog: MatDialogRef<ErrorDialogComponent>,
              fb: UntypedFormBuilder,
              public native: NativeInterfacesService,
              private userService: UsersService,
              @Inject(MAT_DIALOG_DATA) public data: {product_id: string, workShopId: string}) {
    this.simpleForm = fb.group({});

    this.productId = data.product_id;
    this.workShopId = data.workShopId;

    this.workshops = this.userService.business.workshops;

    if (this.workshops.length === 2) {
      // if there is only 2 workshops, we can select the other one by default
      for (const workshop of this.workshops) {
        if (workshop.id !== this.workShopId) {
          this.selectedWorkshop = workshop.id;
        }
      }
    }
  }

  onSave(workShopId: string, amount: string): void {
    this.dialog.close({productId: this.productId, workShopId, amount});
  }

}
