import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UsersService} from './users.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private userService: UsersService
  ) { }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      this.userService.logout();
      // navigate /delete cookies or whatever
      // this.router.navigateByUrl(`/login`);
      // if you've caught / handled the error, you don't want to rethrow
      //    it unless you also want downstream consumers to have to handle it as well.
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (!this.userService.isLogged) {
      return next.handle(req);
    }
    const authToken = this.userService.authorizationToken;
    // let lang = req.headers.get('Accept-Language');
    let lang = 'en';  // TODO improve default language
    if (this.userService.business) {
      lang = this.userService.business.language;
    }

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      // move the language header to its own interceptor?
      headers: req.headers.set('Authorization', `Token ${authToken}`).set('Accept-Language', lang)
    });

    // send cloned request with header to the next handler.
    if (authReq.url.indexOf('api/users/me/') !== -1) {
      // only check for 403 on this api, to avoid bugs
      return next.handle(authReq).pipe(catchError(x => this.handleAuthError(x)));
    } else {
      return next.handle(authReq);
    }
  }
}
