import {AbstractChildItem} from './abstract_child_item';
import {Product} from './pos_product';
import {BillableItem} from './billable_item';
import {BudgetStates} from './abstract_base_api_item';


export class Order extends BillableItem {
    client: string;
    clientName: string;
    clientMail: string;
    clientPhoneFormatted: string;

    invoiceNumber: string;
    invoiceSeries: number;
    invoiceDate: string;

    createdAt: string;

    budgetStatus: BudgetStates;

    itemCount: number;
    totalCost: number;
}

export class OrderItem extends AbstractChildItem<Order> {
    parentRelName = 'order';
    product: string;

    cost = 0;
    price = 0;
    priceRecommended = 0;
    // TODO: don't hardcode vat
    vat = 0.21;
    discountPercent = 0;

    qty = 1;
    productCode: string;

    static totalCost(orderItem: OrderItem): number {
      // We should make this method static because when we get all the items from the service (api), we end up with a list of objects
      // not a list of OrderItems, so we can't access methods from those simple objects
      return (orderItem.price + (orderItem.price * orderItem.vat)) * orderItem.qty;
    }

    static createFromProduct(product: Product): OrderItem {
      const noi = new OrderItem();
      noi.name = product.name;
      noi.cost = product.cost;
      noi.price = product.price;
      noi.vat = product.vat;
      noi.product = product.id;
      noi.priceRecommended = product.priceRecommended;
      return noi;
    }
}
