import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CalendarOptions} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import {ServiceSheetService} from '../../services/service-sheets.service';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, {Draggable} from '@fullcalendar/interaction';
import {LocaleService} from '../../services/locale.service';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import ukLocale from '@fullcalendar/core/locales/uk';
import caLocale from '@fullcalendar/core/locales/ca';
import ptLocale from '@fullcalendar/core/locales/pt';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {PaidStatus} from '../../types/payment_status';
import {firstValueFrom} from 'rxjs';
import {Router} from '@angular/router';
import {UsersService} from '../../services/users.service';


@Component({
    selector: 'app-calendar-general',
    templateUrl: './calendar-general.component.html',
    styleUrls: ['./calendar-general.component.scss']
})
export class CalendarGeneralComponent implements OnInit {
    // Notes:
    //  - the color of the event dot marker is the same as the event backgroundColor

    // TODO:
    //  - navigate to service sheet detail on event click, etc
    //  - loading state
    //  - filter by current dates

    @ViewChild('calendar') calendarComponent: FullCalendarComponent;
    @ViewChild('bssUndated') bssUndatedRef: ElementRef;

    bssUndatedList = [];

    activeStart: Date;
    activeEnd: Date;

    hideClosed = false;

    // slotMinTime and slotMaxTime are interesting to make the calendar not that big, but if some event
    // is outside the range, it will not be shown, so we need to make sure we get all the events
    calendarOptions: CalendarOptions = {
        locales: [esLocale, frLocale, ukLocale, caLocale, ptLocale],
        height: '100%',
        // slotMinTime: '08:00:00',
        // slotMaxTime: '21:00:00',
        defaultTimedEventDuration: '01:00:00',
        expandRows: true,
        // dragScroll: true,
        droppable: true,
        plugins: [
            interactionPlugin,
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
        ],
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        navLinks: true, // can click day/week names to navigate views
        initialView: 'timeGridWeek',  // dayGridMonth, dayGridMonth, dayGrid, listWeek, listMonth, timeGridWeek, timeGridDay
        weekends: true,  // make it configurable and save to local storage, and what happens when something is on the weekend and weekends is false?
        // editable: true,
        eventStartEditable: true,
        eventDurationEditable: true,
        nowIndicator: true,
        selectable: true,
        events: [],
        datesSet: (args) => {
            // console.log('datesSet');
            this.activeStart = args.view.activeStart;
            this.activeEnd = args.view.activeEnd;
            this.loadCalendarEvents();
        },
        // select: this.handleDateSelect.bind(this),
        dateClick: this.handleDateClick.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventChange: this.handleEventChange.bind(this),
        drop: this.handleDrop.bind(this),
        eventResize: this.handleEventChange.bind(this),
        // eventsSet: this.handleEvents.bind(this)
        // eventRemove, eventAdd, windowResize, eventMouseEnter, eventMouseLeave
        // loading, select, unselect
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
    };

    constructor(
        public localeService: LocaleService,
        public serviceSheetService: ServiceSheetService,
        public usersService: UsersService,
        public router: Router
    ) {
        const locale = this.localeService.getLocale();
        if (locale.includes('es')) {
            this.calendarOptions.locale = esLocale;
        }
        if (locale.includes('fr')) {
            this.calendarOptions.locale = frLocale;
        }
        if (locale.includes('uk')) {
            this.calendarOptions.locale = ukLocale;
        }
        if (locale.includes('ca')) {
            this.calendarOptions.locale = caLocale;
        }
        if (locale.includes('pt')) {
            this.calendarOptions.locale = ptLocale;
        }
    }

    ngOnInit(): void {
    }


    getServiceSheetColorAndIcon(serviceSheet): {icon: string, statusI18n: string, backgroundColor: string} {
      let icon = '';
      let statusI18n = '';
      let backgroundColor = '#949494';
      if (serviceSheet.checkInStatus === 'ret') {
        icon = 'airport_shuttle';
        statusI18n = 'PENDING_SCHEDULED_PICK_UP';
        //     TODO: put warning color if it's past the date
      }
      if (serviceSheet.checkInStatus === 'cpe') {
        icon = 'event_upcoming';
        statusI18n = 'PENDING_CHECK_IN';
        //     TODO: put warning color if it's past the date
      }
      if (serviceSheet.checkInStatus === 'cin') {
        icon = 'build';
        statusI18n = 'CHECKED_IN';
        backgroundColor = '#95d586';
      }
      if (serviceSheet.checkInStatus === 'cou') {
        icon = 'check_circle';
        statusI18n = 'CHECKED_OUT';
        backgroundColor = '#95d586';
      }
      if (serviceSheet.closed) {
        icon = 'check_circle';
        statusI18n = 'READY';
        backgroundColor = '#95d586';
      } else if (serviceSheet.started) {
        icon = 'build';
        statusI18n = 'PENDING';
        backgroundColor = '#ffc263';
      }
      return {icon, statusI18n, backgroundColor};
    }

    configureDraggableUndatedBss(): void {
      // console.log('bssUndatedList1', this.bssUndatedList);

      const d = new Draggable(this.bssUndatedRef.nativeElement, {
        itemSelector: '.bss-undated',
        eventData(eventEl): any {
          // console.log('eventData', eventEl['event']);
          // console.log(eventEl);
          // tslint:disable-next-line
          // console.log(eventEl['event']);
          // tslint:disable-next-line
          const parsedEvent = JSON.parse(eventEl['event']);
          // console.log(parsedEvent);
          // tslint:disable-next-line

          // console.log('parsedEvent', parsedEvent);
          // TODO: this is not working for 2 reasons:
          //  - the parsedEvent does not have the same fields as the serviceSheet
          //  - the mehtod getServiceSheetColorIcon is not available here
          // const {icon, statusI18n, backgroundColor} = this.getServiceSheetColorIcon(parsedEvent);

          return  {
            title: eventEl.innerText,
            // backgroundColor: '#FF9800',
            extendedProps: {
              // tslint:disable-next-line:no-string-literal
              id: parsedEvent['id'],
              // tslint:disable-next-line:no-string-literal
              shortPrettyId: parsedEvent['shortPrettyId'],
              icon: 'build',
              statusI18n: 'PENDING',
              // tslint:disable-next-line:no-string-literal
              ownerName: parsedEvent['ownerName'],
              // tslint:disable-next-line:no-string-literal
              generalNotesPrivate: parsedEvent['generalNotesPrivate'],
              objectType: 'serviceSheet',
              eventType: 'estimatedDeliveryDt'
            }
          };
        }
      });
    }

    onHideClosedChange(value: boolean): void {
      // console.log('hideClosed value has changed:', value);
      this.loadCalendarEvents();
    }

    getGeneralNotesPrivate(generalNotesPrivate: string): string {
      generalNotesPrivate = generalNotesPrivate || '';
      generalNotesPrivate = generalNotesPrivate.replace(/(?:\r\n|\r|\n)/g, ' ');
      if (generalNotesPrivate.length > 20) {
        generalNotesPrivate = generalNotesPrivate.substring(0, 20) + '...';
      }
      return generalNotesPrivate;
    }

    handleEventClick(arg): void {
      // console.log('eventClick', arg);
      // console.log('eventClick id', arg.event.extendedProps.id);
      this.router.navigate(['/servicesheets', arg.event.extendedProps.id]);
    }

    handleDateClick(arg): void {
      // console.log('dateClick id', arg.event.extendedProps.id);
      this.router.navigate(['/new_bss_stepper', {estimatedDeliveryDt: arg.date.toISOString()}]);
    }

    handleDrop(arg): void {
        // console.log('drop', arg);
        // console.log('drop', arg.draggedEl);
        // tslint:disable-next-line
        const parsedEvent = JSON.parse(arg.draggedEl['event']);
        console.log(parsedEvent);
        // const oldEvent = arg.oldEvent;
        const modifications = {};
        modifications[parsedEvent.eventType] = arg.date.toISOString();
        this.serviceSheetService.modify(parsedEvent.id, modifications).toPromise(); // TODO: handle errors not promise

        arg.draggedEl.parentNode.removeChild(arg.draggedEl);
    }

    handleEventChange(arg): void {
        // console.log('eventChange', arg);
        // console.log('eventChange start', arg.event.start.toISOString());
        // console.log('eventChange end', arg.event.end.toISOString());
        // console.log('eventChange duration in seconds', (arg.event.end - arg.event.start) / 1000);
        // console.log('eventChange eventtype', arg.event.extendedProps.eventType);
        const oldEvent = arg.oldEvent;
        const modifications = {};

        if (arg.event.end != null) {
          const durationInSeconds = (arg.event.end - arg.event.start) / 1000;
          // tslint:disable-next-line
          modifications['estimatedWorkTimeSeconds'] = durationInSeconds;
        }

        // oldEvent.extendedProps.eventType = 'estimatedDeliveryDt'?
        modifications[oldEvent.extendedProps.eventType] = arg.event.start.toISOString();
        this.serviceSheetService.modify(oldEvent.extendedProps.id, modifications).toPromise(); // TODO: handle errors not promise
    }

    async loadCalendarEvents(): Promise<void> {
        // TODO: show loading state
        // TODO: filter by current dates
        // TODO: on api filter by bikes that have check in dates and put icons accordingly, etc
        // TODO: paging

        // Load undated service sheets:
        // TODO: paginate:
        this.serviceSheetService.getServiceSheets(
        1,
          200,
          false,
          true,
          PaidStatus.All,
          '',
          '-created_at',
          {estimatedDeliveryDtEmpty: true, workshop_id: this.usersService.userMe.workshop, response_detail_level: 'medium'}
        ).subscribe((serviceSheets) => {
          this.bssUndatedList = serviceSheets.results;
        });

        this.configureDraggableUndatedBss();

        // this.calendarOptions.events = [];
        // console.log('resetting events');
        const eventsAll = [];
        this.calendarComponent.getApi().removeAllEvents();

        // yyyy-mm-dd, and we add 5 days before and after to make sure we get all the events
        const startDate = this.activeStart;
        startDate.setDate(startDate.getDate() - 5);
        const endDate = this.activeEnd;
        endDate.setDate(endDate.getDate() + 5);

        // TODO: for now we only filter by estimatedDeliveryDt, but we should also filter by checkInScheduledDt
        //      and maybe other dates

        const fieldDateFilters = [
            {
                workshop_id: this.usersService.userMe.workshop,
                start_estimatedDeliveryDt: startDate.toISOString().split('T')[0],
                end_estimatedDeliveryDt: endDate.toISOString().split('T')[0],
                dateField: 'estimatedDeliveryDt',
                response_detail_level: 'medium'
            }
            // {
            //     start_checkInScheduledDt: startDate.toISOString().split('T')[0],
            //     end_checkInScheduledDt: endDate.toISOString().split('T')[0],
            //     dateField: 'checkInScheduledDt'
            // }
        ];
        for (const dateFilters of fieldDateFilters) {
            // console.log('dateFilters', dateFilters);
            let currentPage = 1;
            let next = true;

            while (next) {
                // TODO: don't use firstValueFrom and use pure observables
                // TODO: we can still improve performance here, instead of making await, we should make all the requests at the same time
                //      and then wait for all of them to finish while showing a loading state
                const serviceSheets = await firstValueFrom(this.serviceSheetService.getServiceSheets(
                    currentPage,
                    200,
                    this.hideClosed ? false : null,
                    true,
                    PaidStatus.All,
                    '',
                    '-created_at',
                    dateFilters
                ));
                next = serviceSheets.next != null;
                if (next) {
                    currentPage++;
                }

                for (const serviceSheet of serviceSheets.results) {
                    // TODO: properly set backgroundColor, borderColor and icon
                    // if (dateFilters.dateField === 'checkInScheduledDt') {
                    // if (serviceSheet.id === '4ce1a8a4-23dd-43ce-aca4-ebc47ac20937') {
                    //   console.log('serviceSheet', serviceSheet);
                    //   console.log('serviceSheet closed', serviceSheet.closed);
                    // }

                    const {icon, statusI18n, backgroundColor} = this.getServiceSheetColorAndIcon(serviceSheet);

                    const startDt = new Date(serviceSheet[dateFilters.dateField]);
                    const endDt = new Date(startDt.getTime() + serviceSheet.estimatedWorkTimeSeconds * 1000);

                    // console.log('start', startDt);
                    // console.log('endDate', endDate);
                    const event = {
                        title: serviceSheet.bikeName,
                        start: startDt.toISOString(),
                        end: endDt.toISOString(),
                        backgroundColor,
                        // borderColor: serviceSheet.checkInStatus === 'cin' ? '#175b01' : 'rgba(255,0,0,0.62)',
                        borderColor: 'rgba(255,255,255,0.41)',
                        extendedProps: {
                            id: serviceSheet.id,
                            shortPrettyId: serviceSheet.shortPrettyId,
                            icon,
                            statusI18n,
                            ownerName: serviceSheet.ownerName,
                            generalNotesPrivate: serviceSheet.generalNotesPrivate,
                            objectType: 'serviceSheet',
                            eventType: dateFilters.dateField
                        }
                    };
                    this.calendarComponent.getApi().addEvent(event);
                }
                // eventsAll.push(...events);
                // console.log('eventsAll', eventsAll);
                // console.log('events', events);

                // this.calendarOptions.events = eventsAll;

            }

        }
    }
}
