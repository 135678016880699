import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  ChildItemEditableListAbstractComponent,
  ChildItemFieldMetadata,
  ChildItemFieldTypes,
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {Validators} from '@angular/forms';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MoneyService} from '../../services/money.service';
import {UsersService} from '../../services/users.service';
import {CurrencyPipe} from '@angular/common';
import {RentalTariffPriceDuration} from '../../models/rental_tariff';
import {RentalsTariffsDurationsService} from '../../services/rentals-tariffs-durations.service';

@Component({
  selector: 'app-rental-tariffs-durations-list',
  templateUrl: '../abstract-child-editable-item-list/abstract-child-editable-item-list.component.html',
  styleUrls: ['../abstract-child-editable-item-list/abstract-child-editable-item-list.component.scss'],
  // TODO: Document
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RentalTariffsDurationsComponent extends ChildItemEditableListAbstractComponent<RentalTariffPriceDuration> implements OnInit {
  parentApiRelName = 'tariff';

  @Output() itemsUpdated = new EventEmitter();

  addItemLiteralI18n = 'ADD_TARIFF';

  @Input() showFooter = true;
  @Input() showVat = false;
  parentApiContentTypeName = 'tariff';

  rentalTariffsPriceDuration$: Observable<RentalTariffPriceDuration[]>;

  fields = {
    name: {
      // tslint:disable-next-line:max-line-length
      metadata: new ChildItemFieldMetadata('NAME', 'ADD_NAME', ChildItemFieldTypes.text, '---', true, [Validators.required], false),
      mainInput: true,
      autoCompleteOptions: [],
      w: 40,
      align: 'start'
    },
    durationMaxHours: {
      metadata: new ChildItemFieldMetadata('MAX_DURATION_HOURS', 'MAX_DURATION_HOURS', ChildItemFieldTypes.number, 0, true, null, false, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    price_with_vat: {
      metadata: new ChildItemFieldMetadata('PRICE', 'ADD_PRICE', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    price: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, false, null, false, true),
      mainInput: false
    },
  };

  @ViewChild(MatTable, {static: true}) table: MatTable<RentalTariffPriceDuration>;
  dataSource = new MatTableDataSource();

  defaultVat: number;

  expandedFields: { [key: string]: Set<string> } = {};

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    notificationService: CrmNotificationsService,
    protected rentalsTariffsDurationsService: RentalsTariffsDurationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected moneyService: MoneyService,
    protected usersService: UsersService
  ) {
    super(dialog, translate, notificationService, rentalsTariffsDurationsService, nativeInterfacesService, usersService);
  }

  ngOnInit(): void {
    this.defaultVat = Number(this.usersService.business.defaultVat);


    super.ngOnInit();

    // TODO: why is this not managed by the abstract class?
    this.rentalTariffsPriceDuration$ = this.rentalsTariffsDurationsService.getList({tariff: this.parent.id});
    this.rentalTariffsPriceDuration$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.items = x;
      this.dataSource.data = this.items;
      this.itemsUpdated.emit();
    });

    this.showVatFields(this.showVat);
  }

  showVatFields(show = true): void {
    // this.fields.vat.metadata.showAsColumn = show;
    // this.fields.vat.metadata.hidden = !show;
  }

  createNewItemInstance(): RentalTariffPriceDuration {
    const item = new RentalTariffPriceDuration();
    item.id = this._getID(item);
    return item;
  }

  getInputTypeCalculated(field: string): string {
    if (field === 'price_with_vat') {
      return 'simple_html';
    }
    throw Error(`getInputTypeCalculated field: ${field} not found`);
  }

  getCalculatedField(rentalTariffPriceDuration: RentalTariffPriceDuration, field: string): any {
    if (field === 'price_with_vat') {
      return this.moneyService.addVat(rentalTariffPriceDuration.price, this.parent.vat, 2);
    }
    if (field === 'price_with_vat_total') {
      return this.moneyService.addVat(rentalTariffPriceDuration.price, this.parent.vat, 2);
    }
    throw Error(`getCalculatedField field: ${field} not found`);
  }

  onEditStatusChange(s: boolean, orderItem = null, colDef = null): void {
    super.onEditStatusChange(s, colDef);
  }

  getCalculatedFieldDisplay(rentalTariffPriceDuration: RentalTariffPriceDuration, field: string): any {
    if (field === 'price_with_vat') {
      // return this.moneyService.addVat(orderItem.price, orderItem.vat, 2);
      const price = this.moneyService.addVat(rentalTariffPriceDuration.price, this.parent.vat, 2);
      let priceLiteral = price.toFixed(2);
      priceLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceLiteral, this.currencyCode, 'symbol-narrow');
      return `<span class="price">${priceLiteral}</span>`;
    }
    return this.getCalculatedField(rentalTariffPriceDuration, field);
  }

  setCalculatedField(rentalTariffPriceDuration: RentalTariffPriceDuration, modifiedText: string, field: string): void {
    modifiedText = this.cleanInputToString(modifiedText, true);
    const inputAsNumber = Number(modifiedText);
    if (field === 'price_with_vat') {
      rentalTariffPriceDuration.price = this.moneyService.removeVat(inputAsNumber, this.parent.vat);
      this.saveItem(rentalTariffPriceDuration);
      return;
    }
    throw Error(`setCalculatedField field: ${field} not found`);
  }

}
