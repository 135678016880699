import {AbstractChildItem} from './abstract_child_item';
import {BillableItem} from './billable_item';
import {Bike} from './bike';
import {AbstractBaseItem} from './abstract_base_api_item';


export class Rental extends BillableItem {
  client: string;
  clientName: string;

  invoiceNumber: string;
  invoiceSeries: number;
  invoiceDate: string;

  createdAt: string;

  startDatetime: string;
  endDatetime: string;

  pickupDatetime: string;
  returnDatetime: string;
  freehandSignatureUrl: string;
  signatureDataBase64: string;
  clientPhoneFormatted: string;

  status: string;
}

export class RentedBike extends AbstractChildItem<Rental> {
  parentRelName = 'rental';
  rental: string;
  bike: string;

  name: string;
  bikeName: string;
  bikeType: string;
  bikeFrameSize: string;

  price: number;
  priceRecommended: number;
  vat: number;
  discountPercent = 0;
  autoCalculatePrice = true;

  static totalCost(rentedBike: RentedBike): number {
    // We should make this method static because when we get all the items from the service (api), we end up with a list of objects
    // not a list of RentedBikes, so we can't access methods from those simple objects
    return (Number(rentedBike.price) + (Number(rentedBike.price) * Number(rentedBike.vat)));
  }

  static createFromBike(bike: Bike): RentedBike {
    const noi = new RentedBike();
    noi.name = bike.name;
    noi.bikeName = bike.name;
    noi.bikeType = bike.type;
    noi.bikeFrameSize = bike.frameSize;
    noi.bike = bike.id;
    return noi;
  }
}

export class RentalAccessory extends AbstractBaseItem {
  id: string;
  name: string;
  type: string;
}

export class RentedAccessory extends AbstractChildItem<Rental> {
  parentRelName = 'rental';
  rental: string;
  accessory: string;

  name: string;

  price: number;
  priceRecommended: number;
  vat: number;
  discountPercent = 0;
  autoCalculatePrice = true;

  static totalCost(rentedAccessory: RentedAccessory): number {
    // We should make this method static because when we get all the items from the service (api), we end up with a list of objects
    // not a list of RentedAccessory, so we can't access methods from those simple objects
    return (Number(rentedAccessory.price) + (Number(rentedAccessory.price) * Number(rentedAccessory.vat)));
  }

  static createFromAccessory(accessory: RentalAccessory): RentedAccessory {
    const noi = new RentedAccessory();
    noi.name = accessory.name;
    noi.accessory = accessory.id;
    return noi;
  }
}
