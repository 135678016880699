import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  // https://stackoverflow.com/questions/43159090/how-can-i-detect-service-variable-change-when-updated-from-another-component

  title = ' ';  // initial/default value

  titleChange: Subject<string> = new Subject<string>();

  constructor(private translate: TranslateService) {
    this.titleChange.subscribe((newTitle) => {
      this.title = newTitle;
    });
  }

  setTitle(newTitle: string): void {
    this.titleChange.next(newTitle);
  }

  setTitleTranslated(literalID?: string): void {
    if (literalID == null) {
      return this.setTitle(' ');
    }
    this.setTitleTranslatedAsync(literalID);
  }

  async setTitleTranslatedAsync(literalID?: string): Promise<void> {
    return this.setTitle(await this.translate.get(literalID).toPromise());
  }
}
