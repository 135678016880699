<span *ngIf="title" class="px0 my-auto" [ngClass]="{'text-danger': isDueDate(data), 'muted': titleIsTheData}">{{ title }}: </span>
<div fxLayout="row" class="w100" *ngIf='!editMode' [class.text_right]="inputAlignRight">
<!--    <span *ngIf="title" class="px0 my-auto" [ngClass]="{'text-danger': isDueDate(data), 'muted': titleIsTheData}">{{ title }}: </span>-->
    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="10px"
         (click)='enableEditMode()'
         [class.hover_hint]="editable"
         class="edit_field w100"
         data-cy="inline-edit-enable-edit-mode">

        <!-- TODO: add (focus)="enableEditMode()" tabindex="0" to every span, problem is that after leaving focus, index gets resetted, as the dom gets recreated -->
        <!--    Maybe if we hide the inputs instead of using ngif, or we track of last tabindex and keep a manual index that we update with the dom.... -->

        <span *ngIf="data && type != 'currency' && type != 'datetime' && type != 'date' && type != 'simple_html' && type != 'percent'" class="px0" [ngClass]="{'muted': !editable}" fxLayoutAlign="{{ align }}">{{ data }}</span>
        <span *ngIf="type == 'simple_html'" class="px0" [ngClass]="{'muted': !editable}" fxLayoutAlign="{{ align }}" [innerHTML]="sanitizer.bypassSecurityTrustHtml(dataDisplay)"></span>
<!--        <span *ngIf="data && type == 'percent'" class="px0" [ngClass]="{'muted': !editable}" fxLayoutAlign="{{ align }}">{{ data | percent }}</span>-->
        <span *ngIf="data && type == 'percent'" class="px0" [ngClass]="{'muted': !editable}" fxLayoutAlign="{{ align }}">{{ data }} %</span>
        <span *ngIf="data != null && type == 'currency'" class="px0" [ngClass]="{'muted': !editable}">{{ data | currency: currencyCode :'symbol-narrow' }}</span>
        <span *ngIf="(data == null || data.length == 0) && editable" class="px0 muted">{{ addHint }}</span>
        <span *ngIf="(data == null || data.length == 0) && !editable" class="muted px0">{{ placeholder }}</span>

        <span *ngIf="(data && type == 'datetime')">
          <span class="px0" [ngClass]="{'text-danger': isDueDate(data), 'muted': !editable}">{{ data | date: 'mediumDate' }} </span>
          <span class="px0" [ngClass]="{'text-danger': isDueDate(data), 'muted': !editable}">{{ data | date: 'shortTime' }}</span>
        </span>
        <span *ngIf="(data && type == 'date')" class="px0" [ngClass]="{'muted': !editable}">{{ data | date: 'mediumDate' }}</span>
        <span *ngIf="(!data && editable && (type == 'datetime' || type == 'date'))" class="px0">{{ 'ADD' | translate }}</span>
        <span *ngIf="suffix">{{ suffix }}</span>

    </div>
</div>

<!-- type text -->
<ng-container *ngIf="type !== 'datetime'">
    <!-- <input *ngIf='editMode' (focusout)="onFocusOut()" (keydown.enter)='$event.target.blur()' [(ngModel)]='data'
    class='cellInput' appAutofocus addHint="{{ addHint }}"> -->
    <div *ngIf='editMode && editable' class="w100" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-form-field class="w100 px0">
            <mat-label></mat-label>
<!--          TODO: set step any and input mode decimal, only for numeric/currency and comment why we are putting all as type text, also set type back to type var, but use text for currency (not integer) -->
            <input
                   matInput
                   appAutofocus
                   (focusout)="onFocusOut()"
                   (keydown.enter)='$event.target.blur()'
                   data-cy="inline-edit-enable-edit-input"
                   type="{{ inputType }}"
                   inputmode="{{ inputMode }}"
                   maxLength="{{ maxLength }}"
                   name="textInput"
                   [matAutocomplete]="autoCompleteInlineEdit"
                   [(ngModel)]="data"
                   (ngModelChange)="onInput($event)"
                   spellcheck="false"
                   autocomplete="off"
                   [class.text_right]="inputAlignRight">
            <mat-hint align="end" *ngIf="type == 'text' && showMaxLengthHint">{{data?.length || 0}}/{{ maxLength }}</mat-hint>
        </mat-form-field>
        <mat-icon *ngIf="nativeInterfacesService.inFully && showBarCodeButton" (click)="startCamBarcodeScanner()">qr_code_scanner</mat-icon>
    </div>

    <mat-autocomplete #autoCompleteInlineEdit="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let option of autoCompletefilteredOptions" [value]="option.id">
        {{ option.value }}
      </mat-option>
    </mat-autocomplete>
</ng-container>
<ng-container *ngIf="type ==='datetime'">
    <div *ngIf='editMode' class="w100" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
<!--      TODO: apply css to match angular material -->
      <input #datetimeInput
             (focusout)="onFocusOut()"
             (keydown.enter)='$event.target.blur()'
             value="{{ data | date: 'yyyy-MM-ddTHH:mm:ss' }}"
             (change)="onDateTimeChange($event)"
             type="datetime-local"
             name="textInput"
             appAutofocus>
    </div>
</ng-container>

<ng-container *ngIf="type ==='date'">
    <div *ngIf='editMode' class="w100" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-form-field class="w100 px0">
            <mat-label></mat-label>
            <input matInput
                   appAutofocus
                   (focusout)="onFocusOut()"
                   (keydown.enter)='$event.target.blur()'
                   data-cy="inline-edit-enable-edit-input"
                   type="datetime"
                   inputmode="datetime"
                   name="textInput"
                   [(ngModel)]="data"
                   [class.text_right]="inputAlignRight">
        </mat-form-field>
    </div>
</ng-container>
