import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {distinctUntilKeyChanged, map, retry} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {BikeCRMApiPaginated} from '../models/api';
import {Workshop} from '../models/workshop';
import {BikeCRMApiAbstract} from './bikecrm-api-base';

@Injectable({
  providedIn: 'root'
})
export class WorkshopService extends BikeCRMApiAbstract {
  apiPath = 'workshop';

  workshop: Workshop = null;
  lastUpdated = null;
  cacheTime = 1000 * 60 * 60; // 1 hour

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getFirstWorkshop(): Observable<Workshop> {
    if (this.workshop && this.lastUpdated && (new Date().getTime() - this.lastUpdated.getTime()) < this.cacheTime) {
      return new Observable<Workshop>((observer) => {
        observer.next(this.workshop);
        observer.complete();
      });
    }
    this.lastUpdated = new Date();
    return this.http.get<BikeCRMApiPaginated<Workshop>>(`${environment.apiUrl}/api/${this.apiPath}/`).pipe(
      distinctUntilKeyChanged('count'),
      map((r) => {
        this.workshop = r.results[0];
        return r.results[0];
      }),
      retry(3)
    );
  }

}
