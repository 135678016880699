import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ThemePalette} from '@angular/material/core';

export class ConfirmDialogModel {

  constructor(
    public messageI18nKey: string,
    public titleI18nKey: string = '',
    public confirmI18nKey: string = 'YES',
    public confirmIcon: string = 'check',
    public confirmColor: ThemePalette = null,
    public cancelI18nKey: string = 'CANCEL',
    public cancelIcon: string = 'cancel',
    public cancelColor: ThemePalette = null
  ) { }
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {

  confirmDialogModel: ConfirmDialogModel;

  constructor(
    public dialog: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
      this.confirmDialogModel = data;
    }

  closeDialog(): void {
    this.dialog.close(false);
  }
  confirmDialog(): void {
    this.dialog.close(true);
  }
}
