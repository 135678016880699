import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';
import {map, retry, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {SummaryAnalytics} from '../models/analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsCRMService {
  //  TODO: those are super similar apies to getByID, delete, modify, etc.
  //    maybe I can unify code with interfaces, generics and a constructor?
  //    and just make a service called BikeCRMApi? or maybe do it with abstract, so
  //    I can also create custom apis for different models

  // https://stackoverflow.com/questions/44979131/rxjs-retry-with-delay-function


  constructor(
    private http: HttpClient
  ) { }

  getSummary(): Observable<SummaryAnalytics> {
    const obs = this.http.get<SummaryAnalytics>(`${environment.apiUrl}/api/analytics/`);
    return timer(0, environment.refreshRateUltraLow)
      .pipe(
        switchMap(() => obs),
        map((r) => r),
        retry(3)
      );
  }
}
