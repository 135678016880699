<form [formGroup]="businessForm" (ngSubmit)="onSubmit()">
    <div fxLayout="column">
      <div fxLayout="row wrap" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex="40">
              <mat-label>{{ "BUSINESS_NAME" | translate }}</mat-label>
              <input matInput formControlName="name">
          </mat-form-field>

          <!-- <mat-form-field appearance="outline">
              <mat-label>{{ "HOURLY_RATE" | translate }}</mat-label>
              <input matInput formControlName="hourlyrate" type="number">
          </mat-form-field> -->

          <mat-form-field appearance="outline" fxFlex="40">
              <mat-label>{{ "LANGUAGE" | translate }}</mat-label>
              <mat-select formControlName="language">
                  <mat-option *ngFor="let lang of languages" [value]="lang.value">
                      {{lang.viewValue}}
                  </mat-option>
              </mat-select>
          </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{ "LEGAL_NAME" | translate }}</mat-label>
          <input matInput formControlName="legalName">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{ "NIF" | translate }}</mat-label>
          <input matInput formControlName="nif">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{ "ADDRESS" | translate }}</mat-label>
          <input matInput formControlName="address">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{ "CITY" | translate }}</mat-label>
          <input matInput formControlName="city">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{ "POSTAL_CODE" | translate }}</mat-label>
          <input matInput formControlName="postalCode">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{ "COUNTRY" | translate }}</mat-label>
          <input matInput formControlName="country">
        </mat-form-field>


        <!-- TODO: re-enable, but for now is causing problems -->
          <!-- <mat-label>{{ "LOGO" | translate }}</mat-label> -->
          <!-- <input type="file" accept=".jpg,.png,.jpeg" formControlName="logoPicture" name="main_picture" -->
                      <!-- (change)="onFileChange($event)"> -->
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
<!--        <mat-form-field appearance="outline" fxFlex="40">-->
<!--          <mat-label>{{ "INVOICE_GENERATION_MODE" | translate }}</mat-label>-->
<!--          <mat-select formControlName="invoiceGenerationMode">-->
<!--            <mat-option value="auto">-->
<!--              {{ "INVOICE_GENERATION_MODE_AUTO" | translate }}-->
<!--            </mat-option>-->
<!--            <mat-option value="manual">-->
<!--              {{ "INVOICE_GENERATION_MODE_MANUAL" | translate }}-->
<!--            </mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->

        <mat-form-field *ngIf="business?.smsPlan !== 'zero'" appearance="outline" fxFlex="40">
          <mat-label>{{ "USER_MOBILE_NOTIFICATIONS_CONFIGURATION" | translate }}</mat-label>
          <mat-select formControlName="userMobileNotificationConfig">
            <mat-option value="whatsapp_cloud-sms">
              {{ "WHATSAPP_CLOUD_THEN_SMS" | translate }}
            </mat-option>
            <mat-option value="sms-only">
              {{ "SMS_ONLY" | translate }}
            </mat-option>
            <mat-option value="whatsapp-manual">
              {{ "WHATSAPP_MANUAL_OWN_NUMBER_ONLY" | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex fxFlexAlign="start">
        <button mat-raised-button type="submit" color="primary"
                [disabled]="!businessForm.valid">{{ "SAVE" | translate }}</button>
      </div>
    </div>
</form>
