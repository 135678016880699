import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BikeCRMApiAbstract} from './bikecrm-api-base';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {concatMap, delay, publishLast, refCount, retryWhen} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FinancesPaymentsService extends BikeCRMApiAbstract {
  apiPath = 'payments';

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  // TODO: make obserbable any, to be a custom type
  getPaymentStatus(billableItemId: string, contentType ): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/${this.apiPath}/?object_id=${billableItemId}&content_type=${contentType}`).pipe(
      retryWhen(errors => errors.pipe(delay(1500),
        concatMap((e, index) => index === 3 ? throwError(e) : of(null)),
      )),
      publishLast(),
      refCount()
    );
  }

  openCashDrawer(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/${this.apiPath}/open_cash_drawer/`, {});
  }

}
