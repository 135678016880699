<table mat-table [dataSource]="dataSource" multiTemplateDataRows>

    <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="taskDescription">
        <th mat-header-cell fxFlex="40" fxLayoutAlign="start center" *matHeaderCellDef class="mat-body-strong">{{ 'DESCRIPTION' | translate }}</th>

        <!-- <td mat-cell fxFlex="50" *matCellDef="let task" [satPopoverAnchor]="pd" (click)="!task.finished && pd.toggle()"> -->
        <td mat-cell fxFlex="50" *matCellDef="let task" fxLayoutAlign="center" class="no-bottom-border">

            <!-- height here avoids glitches going to edit and non edit, as edit mode has a higher height than non edit -->
            <app-inline-edit class="w100" style="min-height: 58px;" [data]='task.taskDescription' (onInputChange)="onDescriptionChange(task, $event)" autocompleteDebounceTime="0" (autoCompleteOptionSelected)="onAutocompleteOptionSelected(task, $event)" [autoCompletefilteredOptions]="predefinedTasksFiltered" [mainInput]="true" [editable]="!task.finished && (mode !== 'viewList')" addHint="{{ 'ADD_DESCRIPTION' | translate }}" (focusOut)="closedEdit(task, $event, 'taskDescription')" (editModeStatus)="onEditStatusChange($event, task, 'taskDescription')"></app-inline-edit>

        </td>

      <ng-container *ngIf="mode !== 'viewList'">
          <td mat-footer-cell *matFooterCellDef class="mat-body-strong"> {{ 'TOTAL_COST' | translate }} </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="discountPercent">
        <th mat-header-cell fxFlex="15" fxFlex.gt-sm="10" fxLayoutAlign="start center" *matHeaderCellDef class="mat-body-strong">{{ 'DSC_PERCENT' | translate }}</th>

        <!-- <td mat-cell fxFlex="50" *matCellDef="let task" [satPopoverAnchor]="pd" (click)="!task.finished && pd.toggle()"> -->
        <td mat-cell fxFlex="15" fxFlex.gt-sm="10" *matCellDef="let task" fxLayoutAlign="center" class="no-bottom-border">

            <!-- height here avoids glitches going to edit and non edit, as edit mode has a higher height than non edit -->
            <app-inline-edit class="w100" style="min-height: 58px;"
                             type="percent"
                             [data]='getCalculatedField(task, "discountPercent")'
                             [mainInput]="false" [editable]="!task.finished && (mode !== 'viewList')"
                             addHint="{{ 'ADD_DISCOUNT' | translate }}"
                             (focusOut)="setCalculatedField(task, $event, 'discountPercent')"
                             (editModeStatus)="onEditStatusChange($event, task, 'discountPercent')"></app-inline-edit>

        </td>

      <ng-container *ngIf="mode !== 'viewList'">
          <td mat-footer-cell *matFooterCellDef class="mat-body-strong"> {{ 'TOTAL_COST' | translate }} </td>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="mode !== 'viewList'" matColumnDef="addTaskFooter">
        <td mat-footer-cell *matFooterCellDef>
          <span (click)="addItemEnd()" *ngIf="!parent.closed" class="muted py3 block" style="text-align: center; cursor: pointer;" data-cy="add-task-end">+ {{ 'ADD_TASK' | translate }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="task_status">
        <th mat-header-cell fxFlex="10" fxFlex.gt-sm="25" fxLayoutAlign="start center" *matHeaderCellDef> {{ 'TASK_STATUS' | translate }} </th>
        <td mat-cell fxFlex="10" fxFlex.gt-sm="25" fxLayoutAlign="center" *matCellDef="let task" class="no-bottom-border">
          <ng-container *ngIf="mode === 'editList'">
            <div fxFlexAlign="center" *ngIf="!parent.closed && task.id && !_isDraft(task)">
                <button mat-stroked-button type="button" ngClass.gt-sm="task_status_btn" *ngIf="!task.finished"
                        color="primary"
                    (click)="completeTask(task, true)">
                    <mat-icon>radio_button_unchecked</mat-icon>
                    <span fxHide fxShow.gt-sm class="ml1">{{ 'PENDING' | translate }}</span>
                </button>
                <button mat-stroked-button type="button" ngClass.gt-sm="task_status_btn" *ngIf="task.finished" (click)="completeTask(task, false)" color="success">
<!--                    <mat-icon>published_with_changes</mat-icon>-->
                    <mat-icon>check_circle_outline</mat-icon>
                    <span fxHide fxShow.gt-sm class="ml1">{{ 'COMPLETED' | translate }}</span>
                </button>
            </div>
          </ng-container>
          <ng-container *ngIf="mode === 'viewList'">
            <div fxFlexAlign="center">
              <span class="material-symbols-outlined">
                <mat-icon *ngIf="task.finished" color="success">check_circle_outline</mat-icon>
                <mat-icon *ngIf="!task.finished" color="primary">radio_button_unchecked</mat-icon>
              </span>
            </div>
          </ng-container>

        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell fxFlex="15" fxLayoutAlign="end center" *matHeaderCellDef> {{ 'DATE' | translate }} </th>
      <td mat-cell fxFlex="15" class="no-bottom-border" *matCellDef="let task" fxLayoutAlign="end center">
        <div *ngIf="!parent.closed && task.id && !_isDraft(task)">
          <span>{{ task.createdAt | date: 'dd/MM/yyyy' }}</span>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="vatOther">
      <th mat-header-cell fxFlex="10" fxLayoutAlign="end center" *matHeaderCellDef> {{ 'VAT' | translate }} </th>
      <td mat-cell fxFlex="10"
                class="no-bottom-border"
                *matCellDef="let task"
                fxLayoutAlign="end center">

        <app-inline-edit type="number"
                         align="end"
                         type="percent"
                         [data]="getCalculatedField(task, 'vatOther')"
                          [dataDisplay]="getCalculatedFieldDisplay(task, 'vatOther')"
                         addHint="{{ 'VAT' | translate }}"
                         [editable]="!task.finished && (mode !== 'viewList')"
                         (focusOut)="setCalculatedField(task, $event, 'vatOther')"
                         (editModeStatus)="onEditStatusChange($event, task, 'vatOther')"></app-inline-edit>

      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

  <ng-container matColumnDef="qty">
    <th mat-header-cell fxFlex="10" fxLayoutAlign="end center" *matHeaderCellDef> {{ 'QUANTITY' | translate }} </th>
    <td mat-cell fxFlex="10"
        class="no-bottom-border"
        *matCellDef="let task"
        fxLayoutAlign="end center">

      <app-inline-edit align="end"
                       type="{{ getInputTypeCalculated('qty') }}"
                       [data]="getCalculatedField(task, 'qty')"
                       [dataDisplay]="getCalculatedFieldDisplay(task, 'qty')"
                       addHint="{{ 'QUANTITY' | translate }}"
                       [editable]="!task.finished && (mode !== 'viewList')"
                       (focusOut)="closedEdit(task, $event, 'qty')"
                       (editModeStatus)="onEditStatusChange($event, task, 'qty')"></app-inline-edit>

    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

    <ng-container matColumnDef="costOtherWithVat">
        <th mat-header-cell fxFlex="20" fxFlex.gt-sm="10" fxLayoutAlign="end center" *matHeaderCellDef> {{ 'TOTAL' | translate }} </th>
        <td mat-cell fxFlex="20" fxFlex.gt-sm="10"
            class="no-bottom-border"
            *matCellDef="let task"
            fxLayoutAlign="end center">
<!--                <ng-container *ngIf="!task.costOther && task.finished">-->
<!--                    <span>-</span>-->
<!--                </ng-container>-->

                <app-inline-edit type="{{ getInputTypeCalculated('costOtherWithVat') }}"
                                 align="end"
                                 [data]="getCalculatedField(task, 'costOtherWithVat')"
                                 [dataDisplay]="getCalculatedFieldDisplay(task, 'costOtherWithVat')"
                                 addHint="{{ 'ADD_COST' | translate }}"
                                 [currencyCode]="currencyCode"
                                 [editable]="!task.finished && (mode !== 'viewList')"
                                 (focusOut)="setCalculatedField(task, $event, 'costOtherWithVat')"
                                 (editModeStatus)="onEditStatusChange($event, task, 'costOtherWithVat')"></app-inline-edit>

        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Menu for every task -->
    <ng-container matColumnDef="more_opts">
        <th mat-header-cell fxFlex="10" fxLayoutAlign="end center" *matHeaderCellDef></th>
        <td mat-cell fxFlex="10" class="no-bottom-border" fxLayoutAlign="end center" *matCellDef="let task">
            <button mat-icon-button *ngIf="!parent.closed" [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">

                <ng-container *ngFor="let field of fieldsCollapsible">
                  <button mat-menu-item
                          (click)="expandField(task, field)"
                          [disabled]="task[field] || task.finished">
<!--                     TODO: custom icons for different actions -->
                    <mat-icon>build_circle</mat-icon>
  <!--                  TODO: use some translated literal and not the field name -->
  <!--                  <span>{{ 'ADD_LABOR_COST' | translate}}</span>-->
                    <span>{{ fieldMetaData(field).i18nKeyAddAction | translate }}</span>
                  </button>
                </ng-container>

                <button mat-menu-item (click)="deleteItem(task)">
                  <mat-icon>delete_outline</mat-icon>
                  <span>{{ 'DELETE' | translate}}</span>
                </button>
              </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef class="mat-body-strong"> {{ getTotalCost() | currency:currencyCode : 'symbol-narrow' }} </td>

    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <!-- we then "mimic the same grid with fxflex 50/25/15/10 and gap 5px, to align items like price" -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell
                *matCellDef="let task"
                [attr.colspan]="displayedColumns.length"
                style="padding-left: 0; padding-right: 0;">
            <div class="element-detail pl3"
                fxLayout="column"
                fxFlex
                [@detailExpand]="hasAnyFieldExpanded(task) ? 'expanded' : 'collapsed'">

              <!-- Collapsed currency and calculated -->
              <ng-container *ngFor="let field of fieldsCollapsible">
                <ng-container *ngIf="fieldMetaData(field).type === childItemFieldTypes.currency">
                  <div fxLayout="row"
                      fxLayoutGap="5px"
                      class="pb1"
                      *ngIf="isFieldExpanded(task, field)">
                          <!-- Recreate non extended columns with fx flex (TODO: think a more DRY solution) -->
                          <div fxFlex="50" fxLayout="center">
                              <ng-container *ngIf="isOptionalFieldFilled(task, field)">
                                  <div fxLayoutAlign="start" fxLayoutGap="5px">
                                      <mat-icon class="muted my-auto">build_circle</mat-icon>
                                      <span class="my-auto">{{ fieldMetaData(field).i18nKey | translate }}</span>
                                  </div>
                              </ng-container>
                          </div>

                          <div fxFlex="25">
                          </div>

                          <div fxFlex="15" fxLayoutAlign="end center">
                              <app-inline-edit class="w100" align="end" style="min-height: 58px;" type="currency" [currencyCode]="currencyCode" [data]='task[field]' [editable]="!task.finished && (mode !== 'viewList')" addHint="{{ fieldMetaData(field).i18nKeyAddAction | translate }}" (focusOut)="closedEdit(task, $event, field)" (editModeStatus)="onEditStatusChange($event, task, field)"></app-inline-edit>
                          </div>

                          <div fxFlex="10">
                          </div>

                  </div>
                </ng-container>
                <ng-container *ngIf="fieldMetaData(field).type === childItemFieldTypes.calculated">
                  <div fxLayout="row"
                       fxLayoutGap="5px"
                       class="pb1"
                       *ngIf="isFieldExpanded(task, field)">
                    <!-- Recreate non extended columns with fx flex (TODO: think a more DRY solution) -->
                    <div fxFlex="50" fxLayout="center">
                      <ng-container *ngIf="isOptionalFieldFilled(task, field)">
                        <div fxLayoutAlign="start" fxLayoutGap="5px">
                          <mat-icon class="muted my-auto">build_circle</mat-icon>
                          <span class="my-auto">{{ fieldMetaData(field).i18nKey | translate }}</span>
                        </div>
                      </ng-container>
                    </div>

                    <div fxFlex="25">
                    </div>

                    <div fxFlex="15" fxLayoutAlign="end center">
                      <app-inline-edit
                        class="w100"
                        align="end"
                        style="min-height: 58px;"
                        type="{{ getInputTypeCalculated(field) }}"
                        [data]='getCalculatedField(task, field)'
                        [currencyCode]="currencyCode"
                        [editable]="!task.finished && (mode !== 'viewList')"
                        addHint="{{ fieldMetaData(field).i18nKeyAddAction | translate }}"
                        (focusOut)="setCalculatedField(task, $event, field)"
                        (editModeStatus)="onEditStatusChange($event, task, field)"></app-inline-edit>
                    </div>

                    <div fxFlex="10">
                    </div>

                  </div>
                </ng-container>
              </ng-container>

              <!-- Collapsed text fields -->
              <ng-container *ngFor="let field of fieldsCollapsible">
                <ng-container *ngIf="fieldMetaData(field).type === childItemFieldTypes.text">
                  <div fxLayout="row"
                      fxLayoutGap="5px"
                      class="pb1 pr2"
                      *ngIf="isFieldExpanded(task, field)">
                          <div fxFill>
                              <app-inline-edit class="w100" style="min-height: 58px;" [data]='task[field]' title="{{ fieldMetaData(field).i18nKey | translate }}" [editable]="!task.finished && (mode !== 'viewList')" addHint="{{ fieldMetaData(field).i18nKeyAddAction | translate }}" (focusOut)="closedEdit(task, $event, field)" (editModeStatus)="onEditStatusChange($event, task, field)"></app-inline-edit>
                          </div>
                  </div>
                </ng-container>
              </ng-container>

            </div>
        </td>
    </ng-container>

    <tr mat-header-row fxLayoutGap="5px" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        fxLayoutGap="5px">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
        [class.muted]="row.finished">
    </tr>
    <ng-container *ngIf="mode !== 'viewList'">
      <tr mat-footer-row *matFooterRowDef="['addTaskFooter']" class="add-item-footer-row" fxLayoutGap="5px" [ngStyle]="{'display': parent.closed ? 'none' : 'flex'}"></tr>
    </ng-container>

    <ng-container *ngIf="showFooter && mode !== 'viewList'">
      <tr mat-footer-row *matFooterRowDef="displayedColumns" fxLayoutGap="5px"></tr>
    </ng-container>

</table>
