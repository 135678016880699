import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';
import {distinctUntilKeyChanged, map, retry, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {BikeCRMApiPaginated} from '../models/api';
import {
  ServiceSheet,
  ServiceSheetTask,
  ServiceSheetTimeTrackingStatus,
  ServiceSheetWorkSession
} from '../models/servicesheets';
import {UsersService} from './users.service';
import {BikeCRMApiAbstract} from './bikecrm-api-base';
import {PaidStatus} from '../types/payment_status';

@Injectable({
  providedIn: 'root'
})
export class ServiceSheetService extends BikeCRMApiAbstract {
  apiPath = 'servicesheet';

  constructor(
    private userService: UsersService,
    http: HttpClient
  ) {
    super(http);
  }

  // All Bss list of the business
  getServiceSheets(page = 1,
                   c = 200,
                   closed?: boolean,
                   hidePendingBudgets = true,
                   roPaymentStatus: PaidStatus = PaidStatus.All,
                   searchFilter = '',
                   ordering = '-created_at',
                   extraFilters = {}): Observable<BikeCRMApiPaginated<ServiceSheet>> {
    // TODO: use underlaying api to get the list of possible values (abstract .getList() method)
    // TODO: still not happy with this solution
    let budgetStatus = ['nea', 'den', 'app', 'dia'];  // Needs approval, denied & approved
    if (hidePendingBudgets) {
      budgetStatus = ['don', 'app', 'dia'];  // don't need approval, approved & pendinf diagnosis
    }

    let paidStatus = [];
    if (roPaymentStatus === PaidStatus.All) {
      paidStatus = ['pai', 'pen', 'unk', 'par', 'adv'];
    } else if (roPaymentStatus === PaidStatus.Paid) {
      paidStatus = ['pai'];
    } else if (roPaymentStatus === PaidStatus.Pending) {
      paidStatus = ['pen', 'par'];
    } else if (roPaymentStatus === PaidStatus.Unknown) {
      paidStatus = ['unk'];
    } else if (roPaymentStatus === PaidStatus.PendingAndUnknown) {
      paidStatus = ['pen', 'unk', 'par'];
    }

    const p = {
      closed: closed != null ? closed.toString() : '',
      page: page.toString(),
      limit: c.toString(),
      budget_status: budgetStatus,
      payment_status: paidStatus,
      ordering,
      search: searchFilter.trim(),
      ...extraFilters
    };

    const obs = this.http.get<BikeCRMApiPaginated<ServiceSheet>>(
      `${environment.apiUrl}/api/servicesheet/`,
      {params: p}
    );

    return timer(0, environment.refreshRateNormal)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
        map((r) => r),
        retry(3)
      );
  }

  getTicketPublicUrl(serviceSheet: ServiceSheet): string {

    return `${environment.publicApiUrl}/bikes/bss/reports/${serviceSheet.id}/ticket_client?tk=${this.userService.userTokenValue.token}`;
  }

  getReceiptPublicUrl(serviceSheet: ServiceSheet): string {
    return `${environment.publicApiUrl}/bikes/bss/reports/${serviceSheet.id}/receipt?tk=${this.userService.userTokenValue.token}`;
  }

  getBudgetPublicUrl(serviceSheet: ServiceSheet): string {
    return `${environment.publicApiUrl}/bikes/bss/reports/${serviceSheet.id}/budget?tk=${this.userService.userTokenValue.token}`;
  }

  getInvoicePublicUrl(serviceSheet: ServiceSheet): string {
    return `${environment.publicApiUrl}/bikes/bss/reports/${serviceSheet.id}/invoice?tk=${this.userService.userTokenValue.token}`;
  }

  getUnfinishedWorkSessionsTimeTracker(): Observable<BikeCRMApiPaginated<ServiceSheetWorkSession>> {
    const obs = this.http.get<BikeCRMApiPaginated<ServiceSheetWorkSession>>(
      `${environment.apiUrl}/api/servicesheet_work_sessions_time_tracker/`,
      {
        params: {
          unfinished: 'true'
        }
      });

    return timer(0, environment.refreshRateHigh)
      .pipe(
        switchMap(() => obs),
        map((r) => r),
        retry(3)
      );
  }

  // Bss list for one specific bike
  getBikeServiceSheets(page = 1,
                       c = 200,
                       bikeId: string,
                       closed?: boolean,
                       hidePendingBudgets = true,
                       searchFilter = '',
                       ordering = '-created_at'): Observable<BikeCRMApiPaginated<ServiceSheet>> {
    const obs = this.http.get<BikeCRMApiPaginated<ServiceSheet>>(
      `${environment.apiUrl}/api/servicesheet/`,
      {
        params: {
          closed: closed != null ? closed.toString() : '',
          bike__id: bikeId,
          page: page.toString(),
          limit: c.toString(),
          search: searchFilter.trim(),
          ordering
        }
      });

    return timer(0, environment.refreshRateNormal)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
        map((r) => r),
        retry(3)
      );
  }

  // Bss list for one specific user
  getUserBikeServiceSheets(page = 1,
                           c = 200,
                           userId: string,
                           closed?: boolean,
                           hidePendingBudgets = true,
                           searchFilter = '',
                           ordering = '-created_at'): Observable<BikeCRMApiPaginated<ServiceSheet>> {
    const obs = this.http.get<BikeCRMApiPaginated<ServiceSheet>>(
      `${environment.apiUrl}/api/servicesheet/`,
      {
        params: {
          closed: closed != null ? closed.toString() : '',
          bike__owner__id: userId,
          page: page.toString(),
          limit: c.toString(),
          search: searchFilter.trim(),
          ordering
        }
      });

    return timer(0, environment.refreshRateNormal)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
        map((r) => r),
        retry(3)
      );
  }

  manuallyMarkBikeAsNotified(serviceSheetId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/servicesheet/${serviceSheetId}/mark_bike_as_notified/`, {})
      .pipe(
        map(r => {
          return r;
        }),
        retry(3));
  }

  notifyClientServiceSheets(serviceSheetId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/servicesheet/${serviceSheetId}/notify_bike_ready/`, {})
      .pipe(
        map(r => {
          return r;
        }),
        retry(3));
  }

  markAsNotifiedClientServiceSheets(serviceSheetId: string): Observable<any> {
    return this.modify(serviceSheetId, {notifiedClient: true});
  }

  generateInvoice(serviceSheetId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/${this.apiPath}/${serviceSheetId}/generate_invoice/`, {})
      .pipe(
        map(r => {
          return r;
        }),
        retry(3));
  }

  sendBudgetClientServiceSheets(serviceSheetId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/servicesheet/${serviceSheetId}/send_budget_client/`, {})
      .pipe(
        map(r => {
          return r;
        }),
        retry(3));
  }

  completeTask(taskId: string, finished: boolean): Observable<ServiceSheetTask> {
    return this.modify(taskId, {
      finished: finished.toString(),
      completedBy: this.userService.selectedMechanic.id
    });
  }

  // TODO: return a ServiceSheetFile object
  // getImages(serviceSheetId: string): Observable<any> {
  getImages(serviceSheetId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/servicesheet_file/?serviceSheet=${serviceSheetId}`)
      .pipe(
        map(images => {
          return images;
        }),
        retry(3));
  }

  deleteFile(fileId: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/servicesheet_file/${fileId}/`)
      .pipe(
        map(r => {
          return r;
        }),
        retry(3));
  }


  create(data: FormData): Observable<any> {
    data.set('openedBy', this.userService.selectedMechanic.id);
    return super.create(data);
  }

  closeServiceSheet(serviceSheetId: string, closed: boolean): Observable<ServiceSheet> {
    return this.modify(serviceSheetId, {
      closed: closed.toString(),
      closedBy: this.userService.selectedMechanic.id
    });
  }

  //////////////////// Time tracking ////////////////////

  getTimeTrackingStatus(serviceSheetId: string): Observable<ServiceSheetTimeTrackingStatus> {
    return this.http.get(`${environment.apiUrl}/api/servicesheet/${serviceSheetId}/get_time_tracking_status/`)
      .pipe(
        map(status => {
          return status as ServiceSheetTimeTrackingStatus;
        }),
        retry(3));
  }

  startTimeTracking(serviceSheetId: string): Observable<ServiceSheetTimeTrackingStatus> {
    return this.http.post(`${environment.apiUrl}/api/servicesheet/${serviceSheetId}/start_time_tracking/`, {})
      .pipe(
        map(modifiedTask => {
          return modifiedTask as ServiceSheetTimeTrackingStatus;
        }),
        retry(3));
  }

  stopTimeTracking(serviceSheetId: string): Observable<ServiceSheetTimeTrackingStatus> {
    return this.http.post(`${environment.apiUrl}/api/servicesheet/${serviceSheetId}/stop_time_tracking/`, {})
      .pipe(
        map(modifiedTask => {
          return modifiedTask as ServiceSheetTimeTrackingStatus;
        }),
        retry(3));
  }

  sendInvoiceToClient(bssId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/${this.apiPath}/${bssId}/send_invoice_client/`, {})
      .pipe(
        map(r => {
          return r;
        }),
        retry(3));
  }

  sendReceiptToClient(bssId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/${this.apiPath}/${bssId}/send_receipt_client/`, {})
      .pipe(
        map(r => {
          return r;
        }),
        retry(3));
  }

  sendTicketToClient(bssId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/${this.apiPath}/${bssId}/send_ticket_client/`, {})
      .pipe(
        map(r => {
          return r;
        }),
        retry(3));
  }

}
