import {Component, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NativeInterfacesService} from '../../../services/native-interfaces.service';
import {BarcodeScannerAbstractComponent} from '../../abstract-barcode-scanner/abstract-barcode-scanner';

class ErrorDialogComponent {
}

@Component({
  selector: 'app-simple-text-dialog-component',
  template: `<div class="p2"><h2>{{ titleI18N | translate }}</h2>
  <p>{{ messageI18N | translate }}</p>
  <form (ngSubmit)="onSave(textInput.value)" [formGroup]="simpleForm">
    <mat-form-field>
      <input type="text" matInput appAutofocus placeholder="{{ hintI18N | translate }}" autocomplete="off" #textInput>
    </mat-form-field>
    <mat-icon *ngIf="native.inFully && showBarCodeButton" (click)="startCamBarcodeScanner()">qr_code_scanner</mat-icon>
    <button type="submit" [disabled]="!simpleForm.valid" mat-button>OK</button>
  </form></div>
  `
})
export class SimpleTextDialogComponent extends BarcodeScannerAbstractComponent {
  public titleI18N: string;
  public messageI18N: string;
  public hintI18N: string;
  showBarCodeButton = false;  // default value

  @ViewChild('textInput') textInput;

  simpleForm: UntypedFormGroup;


  constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>,
              fb: UntypedFormBuilder,
              protected matDialog: MatDialog,
              public native: NativeInterfacesService) {
    super(native, matDialog);
    this.simpleForm = fb.group({});
  }

  onSave(val: string): void {
    this.dialogRef.close(val.trim());
  }
  onBarCodeScanner(code: string): void {
    this.appendText(code.toString());
  }

  appendText(text: string): void {
    // We should clone the object, otherwise when we remove it from local storage it disappears from here also
    if (this.textInput.nativeElement.value !== null) {
      this.textInput.nativeElement.value = this.textInput.nativeElement.value.toString().concat(' ' + text);
    } else {
      this.textInput.nativeElement.value = text;
    }
  }

}
