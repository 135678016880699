import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input, OnChanges, OnDestroy, OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

// Helper component to add dynamic components, replace div with ng template?
@Component({
  selector: 'dcl-wrapper',
  template: `
    <div #target></div>
  `
})
export class DclWrapperComponent implements OnDestroy, OnChanges, AfterViewInit {
  //  TODO: this component seems a little slow reacting and loading children components?
  @ViewChild('target', {read: ViewContainerRef}) target;

  @Input() type;
  @Input() params;

  cmpRef: ComponentRef<any>;

  // TODO: do we need this?
  private isViewInitialized = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private cdRef: ChangeDetectorRef
  ) {
  }

  updateComponent(): void {
    if (!this.isViewInitialized) {
      return;
    }
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }

    this.cmpRef = this.viewContainerRef.createComponent(this.type);
    // to access the created instance use
    // console.log('param', this.param);

    for (const [key, value] of Object.entries(this.params)) {
      // TODO: Setting params for the component once is created causes ExpressionChangedAfterItHasBeenCheckedError
      //  on debug mode, we should improve this. maybe with a setter?
      this.cmpRef.instance[key] = value;
    }

    // this.cmpRef.instance.someOutput.subscribe(val => doSomething());
    this.cdRef.detectChanges();
  }

  ngOnChanges(): void {
    this.updateComponent();
  }

  ngAfterViewInit(): void {
    this.isViewInitialized = true;
    this.updateComponent();
  }

  ngOnDestroy(): void {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }
}
