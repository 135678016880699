import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {CrmNotificationsService} from '../../services/crm-notifications.service';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {UsersService} from '../../services/users.service';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {PosBrandService} from '../../services/pos-brand.service';
import {PosBrand} from '../../models/pos_brand';


@Component({
  selector: 'app-pos-brands-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class PosBrandsListComponent extends ItemSimpleFiltrableListAbstractComponent<PosBrand>
  implements OnInit, OnDestroy, AfterViewInit {

  pageTitleI18N = 'BRANDS';

  urlBaseDetailItem = 'pos/brands';

  displayedColumnsMetaData = {
    logo: new DisplayedColumnMetaData('', DisplayedColumnTypes.picture),
    name: new DisplayedColumnMetaData('NAME'),
  };


  primaryFab = new Fab('NEW_BRAND', 'add', 'fab_add_new_brand', FabTypes.singleAction);
  secondaryFab = null;

  apiFilters = {};

  constructor(
    @Optional() public dialogRef: MatDialogRef<PosBrandsListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected posBrandService: PosBrandService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected notificationService: CrmNotificationsService,
    protected media$: MediaObserver,
    protected usersService: UsersService,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      posBrandService,
      nativeInterfacesService,
      dialog,
      media$,
      usersService,
      privacyModeService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    // TODO: move to abstract class:
    if (this.dialogRef != null) {
      if (this.data.mode === 'floating-selector') {
      }
    }
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'fab_add_new_brand') {
      this.router.navigate(['/pos/brands/create']);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }
}
