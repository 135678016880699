import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BikeCRMApiAbstract} from './bikecrm-api-base';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryCountService extends BikeCRMApiAbstract {
  apiPath = 'inventory_count';

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  reconcile(id: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/${this.apiPath}/${id}/reconcile/`, {});
  }

}
