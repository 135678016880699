import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {fromEvent, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, takeUntil, tap} from 'rxjs/operators';
import {TitleService} from 'src/app/services/title.service';
import {BikeCRMApiAbstract} from '../../services/bikecrm-api-base';
import {AbstractBaseItem, BUDGET_STATUS_I18N_KEY} from '../../models/abstract_base_api_item';
import {PageEvent} from '@angular/material/paginator';
import {BikeCRMApiPaginated} from '../../models/api';
import {Fab, IFab} from '../fab-custom/fab-interface';
import {BarcodeScannerAbstractComponent} from '../abstract-barcode-scanner/abstract-barcode-scanner';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {UsersService} from '../../services/users.service';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {PAYMENT_METHOD_I18N_KEY, PAYMENT_STATUS_I18N_KEY, PaymentStatus} from '../../models/billable_item';
import {MatMenuTrigger} from '@angular/material/menu';

export enum DisplayedColumnTypes {
  text,
  currency,
  number,
  picture,
  date,
  profile_picture,
  calculated,
  templated,
  simple_html,
  date_time,
  anchor,
  dateAgo
}

export class DisplayedColumnMetaData {
  constructor(i18nKey: string, type?: DisplayedColumnTypes, showInTablet = true, showInPhone = true) {
    this.i18nKey = i18nKey;
    this.type = type ? type : DisplayedColumnTypes.text;
    this.showInTablet = showInTablet;
    this.showInPhone = showInPhone;
  }

  i18nKey: string;
  type: DisplayedColumnTypes;
  showInTablet: boolean;
  showInPhone: boolean;
}

export enum FilterTypes {
  select_single
}

export class FilterOption {
  constructor(value: string | string[], literalI18NOpt: string) {
    this.value = value;
    this.literalI18NOpt = literalI18NOpt;
  }

  value: string | string[];
  literalI18NOpt: string;
}

export class FilterMetaData {
  constructor(i18nKey: string, type?: FilterTypes, options?: FilterOption[]) {
    this.i18nKey = i18nKey;
    this.type = type ? type : FilterTypes.select_single;
    this.options = options ? options : [];
  }

  i18nKey: string;
  type: FilterTypes;
  options: FilterOption[];
}

export interface IItemList {
  displayedColumns: string[];
  displayedColumnsMetaData: { [key: string]: DisplayedColumnMetaData };

  filters: string[];

  // Filters that are not shown in the UI, but are used to filter the API call
  filtersMetaData: { [key: string]: FilterMetaData };

  autoRefreshList: boolean;
  urlBaseDetailItem: string;

  // API filters user can't change or set. For example, if we want to filter only bss of type budget, we can set here
  apiFilters: { [key: string]: (string | boolean | number | string[]) };

  paginationResultCount: number;
  paginationPageSize: number;
  paginationPageSizeOptions: number[];
  paginationPageCurrentPage: number;

  getCalculatedField(item: any, field: string): any;

  getHtmlFieldContent(item: any, field: string): any;

  getFieldTemplateRef(item: any, field: string): TemplateRef<any>;

  getFieldTemplatedContext(item: any, field: string): object;
}

@Component({
  template: '',
})
export abstract class ItemSimpleFiltrableListAbstractComponent<T extends AbstractBaseItem> extends BarcodeScannerAbstractComponent
  implements IItemList, IFab, OnInit, OnDestroy, AfterViewInit {

  @ViewChild('TemplateI18N') public templateI18N: TemplateRef<any>;
  @ViewChild('TemplateIcon') public templateIcon: TemplateRef<any>;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>, // TODO: improve typehint
    @Optional() @Inject(MAT_DIALOG_DATA) protected dialogData: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected itemsService: BikeCRMApiAbstract,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected media$: MediaObserver,
    protected usersService: UsersService,
    protected privacyModeService: PrivacyModeService
  ) {
    super(nativeInterfacesService, dialog);
    if (this.dialogRef != null) {
      this.dialogMode = true;
    }
  }

  // tslint:disable-next-line:variable-name
  private _pageEvent: PageEvent;

  searchEnabled = true;

  @Input() set pageEvent(value: PageEvent) {
    this._pageEvent = value;
    this.setApiFilter('page', (value.pageIndex + 1).toString());  // starts at 0, api starts at 1
    this.setApiFilter('limit', value.pageSize.toString());
    this.refreshList();
  }

  get pageEvent(): PageEvent {
    return this._pageEvent;
  }

  // Minimal, should be understood as embeebed in another component, for example, in a dialog or in a tab, etc
  // In minimal we don't show the title, the fab, search box or filters
  @Input() minimal = false;
  @Input() mode = 'list';  // list, variants-list
  @Input() defaultFilters: { [key: string]: (string | boolean | number) } = {};

  // ParentID makes reference to the "owner" of the item, for example, a customer ID for all his bikes or all his
  // invoices, tickets, etc.
  // In case parentId is set, the list will be filtered by that parentId, passing to the api the parentApiFilter as
  //   name of the filter and the parentId as value, example .../api/endpoint?[parentApiFilter]=[parentId]
  //   this is done automatically by the service (bikecrm-api-base)
  @Input() parentApiFilter = null;
  @Input() parentId = null;

  public get displayedColumnTypes(): typeof DisplayedColumnTypes {
    return DisplayedColumnTypes;
  }

  abstract pageTitleI18N: string;

  // On places where we want to use the barcode we don't want to autofocus the search box for example
  autoFocusSearchBox = false;

  // TODO: take until subscribe on destroy
  items$: Observable<BikeCRMApiPaginated<T[]>>;
  itemsSubs: Subscription;
  items: MatTableDataSource<T[]> = new MatTableDataSource([]);
  autoRefreshList = false;

  displayedColumnsCustomConfig: object[] = null;

  addButtonI18N = null;

  displayedColumns: string[];
  abstract displayedColumnsMetaData: { [key: string]: DisplayedColumnMetaData };

  filters: string[];
  filtersMetaData = {};

  urlBaseDetailItem: string;
  apiFilters: { [key: string]: (string | boolean | number | string[]) } = {};

  abstract primaryFab: Fab;
  abstract secondaryFab: Fab;

  // Right click Menu for rows:
  itemContextMenuActions: any = null;
  rowClicked: T = null;
  menuTopLeftPosition = { x: '0', y: '0' };

  paginationResultCount = 0;

  searchPlaceHolderi18NKey = 'SEARCH';

  currencyCode: string;

  // tslint:disable-next-line:variable-name
  _searchBoxInputActive = false;

  paginationPageCurrentPage;  // api starts at 1
  paginationPageSize;
  searchLiteral = '';
  paginationPageSizeOptions = [20, 50, 100, 150];
  dialogMode = false;

  loading = false;
  errorLoading = false;

  destroyed = new Subject();

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('searchbox') searchBox: ElementRef;

  onFabAction(actionId: string): void {
    console.log('You should implement onFabAction in your component');
  }

  onRightClickRow(event, item: T): void {
    if (this.itemContextMenuActions == null) {
      return;
    }
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();
    this.rowClicked = item;

    // console.log(event);
    // console.log(item);

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  onContextMenuAction(actionId: string, item: T): void {
    console.log('You should implement onContextMenuAction in your component');
  }

  onContextMenuItemClicked(actionId: string): void {
    if (!this.rowClicked) {
      throw Error('rowClicked should be set before calling onContextMenuItemClicked');
    }
    this.onContextMenuAction(actionId, this.rowClicked);
    this.rowClicked = null;
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.minimal) {
      this.titleService.setTitleTranslated(this.pageTitleI18N);
    }

    this.configureKeyboardShortcuts();
    // TODO: remove from this, use | currency pipe on template
    this.usersService.business$.pipe(
      takeUntil(this.destroyed),
    ).subscribe(b => this.currencyCode = b ? b.currency.toUpperCase() : null);
  }

  isRowEnabled(row: T): boolean {
    return true;
  }

  configureColumns(): void {
    if (this.displayedColumnsCustomConfig != null) {
      this.displayedColumnsMetaData = {};
      for (const column of this.displayedColumnsCustomConfig) {
        // tslint:disable-next-line:no-string-literal
        this.displayedColumnsMetaData[column['FIELD']] = new DisplayedColumnMetaData(column['I18N_KEY'], DisplayedColumnTypes[`${column['TYPE']}`], column['SHOW_IN_TABLET'], column['SHOW_IN_PHONE']);
      }
    }

    if (this.displayedColumnsMetaData == null) {
      throw new Error('displayedColumnsMetaData or displayedColumnsCustomConfig should be defined, override configure columns and set it before super');
    }

    this.displayedColumns = Object.keys(this.displayedColumnsMetaData);

    if (this.displayedColumns.length !== Object.keys(this.displayedColumnsMetaData).length) {
      throw new Error('every displayedColumns should be defined in displayedColumnsMetaData');
    }

    // Configure columns to show depending on screen size
    // TODO: take until subscribe on destroy
    this.media$.asObservable().subscribe(() => {
      const colsCandidate = [];
      const isScreenPhone = this.media$.isActive('lt-md');
      const isScreenTablet = this.media$.isActive('gt-sm') && this.media$.isActive('lt-lg');
      const isScreenComputer = this.media$.isActive('gt-md');

      for (const colKey of Object.keys(this.displayedColumnsMetaData)) {
        const col = this.displayedColumnsMetaData[colKey];

        if (isScreenComputer) {
          colsCandidate.push(colKey);
          continue;
        }

        if (isScreenTablet && col.showInTablet) {
          colsCandidate.push(colKey);
          continue;
        }

        if (isScreenPhone && col.showInPhone) {
          colsCandidate.push(colKey);
        }
      }

      this.displayedColumns = colsCandidate;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.itemsSubs) {
      this.itemsSubs.unsubscribe();
    }
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  onSearchFocusOut(): void {
    this._searchBoxInputActive = false;
  }

  onSearchFocusIn(): void {
    this._searchBoxInputActive = true;
  }

  // TODO: maybe we can do our custom component searchbox or matinput
  isKeyboardBarCodeScannerEnabled(): boolean {
    return !this._searchBoxInputActive;
  }

  getUrlItem(el): string[] {
    if (this.urlBaseDetailItem) {
      return [`/${this.urlBaseDetailItem}/`, el.id];
    } else {
      throw Error('you should override getUrlItem or set urlBaseDetailItem on the component');
    }
  }

  onRowClick(el: T, colDef?: string): void {
    if (!this.isRowEnabled(el)) {
      return;
    }
    if (this.dialogRef) {
      this.dialogRef.close(el);
    } else {
      this.router.navigate(this.getUrlItem(el));
    }
  }

  ngAfterViewInit(): void {
    this.setDefaultFilters();

    this.paginationPageCurrentPage = this.getApiFilter('page') ? this.getApiFilter('page') : 1;
    this.paginationPageSize = this.getApiFilter('limit') ? this.getApiFilter('limit') as number : 50;
    this.searchLiteral = this.getApiFilter('search') ? this.getApiFilter('search') as string : '';

    this.configureColumns();
    this.refreshList();

    if (this.searchBox) {
      // On minimal, search is not available (for example)
      fromEvent(this.searchBox.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap((text) => {
            this.setApiFilter('search', this.searchBox.nativeElement.value);
            this.refreshList();
          })
        )
        .subscribe();
    }
  }

  isImageType(e: number): boolean {
    return (e === DisplayedColumnTypes.picture || e === DisplayedColumnTypes.profile_picture);
  }

  getCalculatedField(item: T, field: string): any {
    if (field === 'documentType') {
      if (item[field] === 'ticket') {
        return 'TICKET';
      }
      if (item[field] === 'invoic') {
        return 'INVOICE';
      }
      throw Error('getCalculatedField of documentType implemented, but unknown documentType');
    }
    if (field === 'expandedDetailText') {
      // tslint:disable-next-line
      let privateNotes = item['generalNotesPrivate'] ? item['generalNotesPrivate'] : '';
      privateNotes = privateNotes.split('\n')[0];
      return privateNotes.length > 90 ? privateNotes.slice(0, 90) + '...' : privateNotes;
    }
    throw Error('if the class has any calculated fields you should override getCalculatedField');
  }

  getInputTypeCalculated(field: string): string {
    return 'string';
  }

  getHtmlFieldContent(item: T, field: string): any {
    throw Error('if the class has any simple_html fields you should override getHtmlFieldContent');
  }

  getFieldTemplatedContext(item: T, field: string): object {
    if (field === 'budgetStatus') {
      // TODO: extract to helper class, and use dict instead of if else, like we do with value
      let value;
      let customClass;
      value = BUDGET_STATUS_I18N_KEY[item[field]];
      if (item[field] === 'nea') {
        // needs approval
        customClass = 'chip-custom-yellow';
      } else if (item[field] === 'app') {
        customClass = 'chip-custom-green';
      } else if (item[field] === 'den') {
        customClass = 'chip-custom-red';
      }
      return {value, customClass};
    }
    if (field === 'roPaymentStatus') {
      // TODO: extract to helper class, and use dict instead of if else
      let value;
      let customClass;
      value = PAYMENT_STATUS_I18N_KEY[item[field]];
      if (item[field] === PaymentStatus.Unknown) {
      } else if (item[field] === PaymentStatus.Paid || item[field] === PaymentStatus.AdvancePayment) {
        customClass = 'chip-custom-green';
      } else if (item[field] === PaymentStatus.Pending) {
        customClass = 'chip-custom-red';
      } else if (item[field] === PaymentStatus.PartiallyPaid) {
        customClass = 'chip-custom-yellow';
      }
      return {value, customClass};
    }

    if (field === 'roPaymentMethod') {
      // TODO: extract to helper class, and use dict instead of if else
      let val;
      val = PAYMENT_METHOD_I18N_KEY[item[field]];
      return {value: val};
    }
    throw Error(`if the class has any templated fields you should override getFieldTemplatedContext for field ${field}`);
  }

  getFieldTemplateRef(item: T, field: string): TemplateRef<any> {
    if (field === 'budgetStatus') {
      return this.templateI18N;
    }
    if (field === 'roPaymentStatus') {
      return this.templateI18N;
    }
    if (field === 'roPaymentMethod') {
      return this.templateI18N;
    }
    return null;  // Default template
  }

  isNumericType(e: number): boolean {
    return (e === DisplayedColumnTypes.number || e === DisplayedColumnTypes.currency);
  }

  retryRefreshList(): void {
    this.refreshList();
  }

  public getElementStr(element): string {
    return AbstractBaseItem.str(element);
  }

  onSortChange(event): boolean {
    // TODO: connect to api for sorting on all the Data base
    // console.log(event);
    return false;
  }

  isFilterOnDefaultOption(filterSelected: string): boolean {
    // tslint:disable-next-line:no-string-literal
    if (this.apiFilters[filterSelected] === undefined || this.apiFilters[filterSelected] === null || this.apiFilters[filterSelected] === '' || this.apiFilters[filterSelected] === 'default_null_filter') {
      return true;
    }
    return false;
  }

  isFilterSelectSelected(filterSelected: string, option: object): boolean {
    try {
      if (Array.isArray(this.apiFilters[filterSelected])) {
        // TODO: improve the comprasion of lists, for example sort before compare
        // tslint:disable-next-line:no-string-literal
        if (JSON.stringify(this.apiFilters[filterSelected]) === JSON.stringify(option['value'].replace('thisisalist,', '').split(','))) {
          return true;
        }
      }
    } catch (e) {
    }
    // tslint:disable-next-line:no-string-literal
    if (this.apiFilters[filterSelected] === option['value']) {
      return true;
    }
    if (filterSelected in this.apiFilters) {
      return false;
    }

    // tslint:disable-next-line:no-string-literal
    return option['value'] === 'default_null_filter';

  }


  onFilterChange(field: string, selectedValue: string): void {
    if (field !== 'page' && field !== 'limit') {
      // reset pagination when we change a filter not related to pagination
      this.setApiFilter('page', '1');
    }
    this.setApiFilter(field, selectedValue);
    this.retryRefreshList();
  }

  setDefaultFilters(): void {
    this.filters = Object.keys(this.filtersMetaData);

    // default values:
    for (const filterk of this.filters) {
      const defaultValue = this.filtersMetaData[filterk].options[0].value;
      if (defaultValue) {
        this.setApiFilter(filterk, defaultValue);
      } else {
        this.setApiFilter(filterk, null);
      }
    }

    // if there is any default value pass as an input to the component, override the defaults from the component itself
    for (const key of Object.keys(this.defaultFilters)) {
      this.setApiFilter(key, this.defaultFilters[key]);
    }

    if (!this.dialogMode) {
      // Read from query params and if any, override the default values:
      const queryParams = this.activatedRoute.snapshot.queryParams;
      for (const key of Object.keys(queryParams)) {
        this.setApiFilter(key, queryParams[key]);
      }
    }
  }

  async setQueryParams(): Promise<void> {
    if (this.minimal || this.dialogMode) {
      for (const key of Object.keys(this.apiFilters)) {
        if (!this.apiFilters[key]) {
          delete this.apiFilters[key];
        }
      }
      return;
    }

    const params = {};
    for (const key of Object.keys(this.apiFilters)) {
      if (!this.apiFilters[key]) {
        params[key] = null;
        delete this.apiFilters[key];
      } else {
        params[key] = this.apiFilters[key];
      }
    }
    await this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: params,
        queryParamsHandling: 'merge'
      });
  }

  getApiFilter(key: string): string | boolean | number | string[] {
    return this.apiFilters[key];
  }

  setApiFilter(field: string, selectedValue: string | number | boolean | string[]): void {
    if (field === 'page') {
      this.paginationPageCurrentPage = selectedValue;
    } else if (field === 'limit') {
      this.paginationPageSize = Number(selectedValue);
    } else if (field === 'search') {
      this.searchLiteral = selectedValue as string;
    }

    if (selectedValue && (selectedValue === '' || selectedValue === 'default_null_filter')) {
      selectedValue = null;
    }

    // as from html inputs like selects it can only be string, we need to convert to array if it is a list
    // we make sure that this is a list, putting on the value of the selected option the string 'thisisalist'
    // and then we split it, example of a value set on a select: 'thisisalist,nea,den,app' (for budget status filter)
    if (typeof selectedValue === 'string' && selectedValue.split(',')[0] === 'thisisalist') {
      const newList = selectedValue.split(',');
      delete newList[0];
      selectedValue = newList;
    }

    if (selectedValue) {
      this.apiFilters[field] = selectedValue;
    } else {
      // we need to set it to null, otherwise it will not be deleted from the query params when we call setQueryParams
      this.apiFilters[field] = null;
    }
    this.setQueryParams();
  }

  refreshList(): void {
    if (this.itemsSubs) {
      this.itemsSubs.unsubscribe();
    }

    const search: string = this.getApiFilter('search') as string;

    this.loading = true;
    this.errorLoading = false;

    if (this.parentId && !this.parentApiFilter) {
      throw Error('if the component has parentId, it should have parentApiFilter');
    }

    if (this.parentId && this.parentId !== '0' && this.parentApiFilter && this.parentApiFilter !== '') {
      this.apiFilters[this.parentApiFilter] = this.parentId;
    }
    // tslint:disable-next-line:max-line-length
    this.items$ = this.itemsService.getPaginatedList(this.apiFilters, search?.toLowerCase().trim(), this.autoRefreshList, this.paginationPageCurrentPage, this.paginationPageSize);

    this.items = new MatTableDataSource([]);

    this.itemsSubs = this.items$.subscribe(
      u => {
        this.loading = false;
        this.errorLoading = false;

        this.paginationResultCount = u.count;

        this.items = new MatTableDataSource(u.results);
        this.items.sort = this.sort;

        // Ignore case when sorting:
        this.items.sortingDataAccessor = (data, sortHeaderId) => {
          if (typeof data[sortHeaderId] === 'string') {
            return data[sortHeaderId].toString().toLocaleLowerCase();
          }
          return data[sortHeaderId];
        };
      },
      error => {
        this.loading = false;
        this.errorLoading = true;
      },
      () => {
      }
    );

  }

  getExtraRowClass(item: T): string {
    if (!this.isRowEnabled(item)) {
      return 'disabled-row';
    }
    return '';
  }


  configureKeyboardShortcuts(): void {
  }
}
