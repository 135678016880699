import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {RentalAccessory} from '../../models/rental';
import {RentalAccessoriesService} from '../../services/rental-accessories.service';


@Component({
  selector: 'app-rentals-accessories-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class RentalsAccessoriesListComponent extends ItemSimpleFiltrableListAbstractComponent<RentalAccessory>
  implements OnInit, OnDestroy, AfterViewInit {
  // TODO: rename to sales, not orders
  // TODO: implement filters like, payment status
  // TODO: be able to pass a user, and get all his orders

  pageTitleI18N = 'RENTALS_ACCESSORIES';

  urlBaseDetailItem = 'rentals_accessories';

  displayedColumnsMetaData = {
    name: new DisplayedColumnMetaData('NAME', DisplayedColumnTypes.text),
    type: new DisplayedColumnMetaData('TYPE', DisplayedColumnTypes.text),
    rental_tariff_name: new DisplayedColumnMetaData('RENTAL_TARIFF', DisplayedColumnTypes.text),
  };

  filtersMetaData = {
    // openedBy: new FilterMetaData('OPENED_BY', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')]),
    // paymentMethod: new FilterMetaData(
    //   'PAYMENTS.PAYMENT_METHOD',
    //   FilterTypes.select_single,
    //   [
    //     new FilterOption('default_null_filter', 'PAYMENTS.ALL')
    //   ]),
    // paymentStatus: new FilterMetaData(
    //   'PAYMENTS.PAYMENT_STATUS',
    //   FilterTypes.select_single,
    //   [
    //     new FilterOption('default_null_filter', 'PAYMENTS.ALL'),
    //     new FilterOption(PaymentStatus.Paid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Paid]),
    //     new FilterOption(PaymentStatus.PendingAndPartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
    //     // new FilterOption(PaymentStatus.Pending, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
    //     // new FilterOption(PaymentStatus.PartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.PartiallyPaid]),
    //     new FilterOption(PaymentStatus.Unknown, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Unknown]),
    //     new FilterOption(PaymentStatus.AdvancePayment, PAYMENT_STATUS_I18N_KEY[PaymentStatus.AdvancePayment]),
    //   ])
  };

  primaryFab = new Fab('NEW_ACCESSORY', 'add', 'fab_add_new_accessory', FabTypes.singleAction);
  secondaryFab = null;

  constructor(
    @Optional() public dialogRef: MatDialogRef<RentalsAccessoriesListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    private userService: UsersService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected rentalAccessoriesService: RentalAccessoriesService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      rentalAccessoriesService,
      nativeInterfacesService,
      dialog,
      media$,
      userService,
      privacyModeService
    );
  }

  ngOnInit(): void {
    if (this.userService.userMe && this.userService.userMe.workshop) {
      // TODO: this shluld not happen, we should wait for the user to be loaded (maybe on a lodcing screen?)
      // tslint:disable-next-line
      this.defaultFilters['workshop_id'] = this.userService.userMe.workshop;
    }
    super.ngOnInit();

    // TODO: remove, if parentId is not null, just create a ticket for that user
    if (this.parentId) {
      this.primaryFab = null;
    }

    if (this.dialogRef != null) {
      this.dialogMode = true;
      if (this.data.mode === 'floating-selector') {
      }
    } else {
    }

    // this.populateOpenedByFilter();
  }

  // configureColumns(): void {
  //   this.displayedColumnsCustomConfig = this.userService.getCustomConfig().get_shop_ticket_list_columns();
  //   super.configureColumns();
  // }

  // populateOpenedByFilter(): void {
  //   this.userService.getStaff().subscribe(staffUsers => {
  //     const filterOptions = [new FilterOption('', 'All')];
  //     for (const staffUser of staffUsers) {
  //       filterOptions.push(new FilterOption(staffUser.id, staffUser.name));
  //     }
  //     this.filtersMetaData.openedBy = new FilterMetaData('OPENED_BY', FilterTypes.select_single, filterOptions);
  //   });
  // }

  async onFabAction(actionId: string): Promise<boolean> {
    if (actionId === 'fab_add_new_accessory') {
      this.router.navigate(['/rentals_accessories/create', {}]);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }
}
