import {AbstractBaseItem} from './abstract_base_api_item';
import {AbstractChildItem} from './abstract_child_item';


export class BankAccount extends AbstractBaseItem {
  id: string;
  name: string;
}

export class BankTransaction extends AbstractChildItem<BankAccount> {
  parentRelName = 'bank_account';
  id: string;
  dateTime: string;
  description: string;
  amount: string;
  balance: string;
}
