<div>
<app-rental-rented-bikes-list #rented_bikes_list
                              (itemsChange)="onItemsChange($event)"
                              (itemsUpdated)="onItemsUpdated()"
                              [showVat]="showVat"
                              [parent]="parent"></app-rental-rented-bikes-list>
</div>
<div style="margin-top: 20px;">
<app-rental-rented-accessories-list #rented_accessories_list
                                    (itemsChange)="onItemsChange($event)"
                                    (itemsUpdated)="onItemsUpdated()"
                                    [showVat]="showVat"
                                    [parent]="parent">

</app-rental-rented-accessories-list>
</div>
<div style="text-align: center; padding-top: 20px;">
<b>{{ "TOTAL" | translate }}: {{ totalAmountWithTax | currency: currencyCode : 'symbol-narrow' }}</b>
</div>
