import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OrderItem} from '../models/pos_order';
import {BikeCRMApiAbstract} from './bikecrm-api-base';

@Injectable({
  providedIn: 'root'
})
export class PosOrdersItemService extends BikeCRMApiAbstract {
  apiPath = 'pos_order_item';

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  // TODO: remove and use get GenericChilds?
  getOrderItems(orderId: string, contentType: string): Observable<OrderItem[]> {
    // const obs = this.http.get<BikeCRMApiPaginated<OrderItem>>(
    //   `${environment.apiUrl}/api/${this.apiPath}/`,
    //   {params: {object_id: orderId, content_type: contentType}}
    // );
    // // for now, refreshing the item from an order is causing duplicates, as you create one item,
    // //          we save it, and then we retrieve the same one from the server
    // return timer(0, environment.refreshRateUltraLow)
    //   .pipe(
    //     switchMap(() => obs),
    //     distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
    //     map((r) => r.results),
    //     retry(3)
    //   );
    return this.getGenericChilds(orderId, contentType);
  }

}
