<div fxLayout="column" fxLayoutAlign="center center" class="p4">
    <img src="assets/brands/{{ brand }}/images/logo_square.jpg" class="circle mb3" style="width: 250px; height: 250px;" />

    <ng-container>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutAlign="center center" class="mt3"
            style="width: 600px;">
            <mat-form-field style="width: 250px;">
                <input matInput placeholder="username" formControlName="username" class="form-control" autocorrect="off"
                    autocapitalize="none" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            </mat-form-field>

            <mat-form-field style="width: 250px;">
                <input matInput formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                    placeholder="password" type="password">
            </mat-form-field>

            <mat-form-field style="width: 250px;">
                <input matInput formControlName="business" autocomplete="organization" autocorrect="off" autocapitalize="none" class="form-control"
                    placeholder="Business codename" [ngClass]="{ 'is-invalid': submitted && f.business.errors }">
            </mat-form-field>

            <button mat-raised-button [disabled]="loading" color="primary" type="submit">
                <mat-spinner *ngIf="loading" class="mr1"></mat-spinner>
                Login
            </button>
        </form>
    </ng-container>
</div>
