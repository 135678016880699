<h1 mat-dialog-title *ngIf="title">{{ title }}</h1>
<div mat-dialog-content>
    <p>{{ message }}</p>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button [color]="cancelColor" (click)="closeDialog()" cdkFocusInitial data-cy="dialog-cancel-button">
        <mat-icon *ngIf="cancelIcon" class="mr1">{{ cancelIcon }}</mat-icon>
        {{ cancelLiteral }}
    </button>
    <button mat-stroked-button [color]="confirmColor" (click)="confirmDialog()" data-cy="dialog-confirm-button">
        <mat-icon *ngIf="confirmIcon" class="mr1">{{ confirmIcon }}</mat-icon>
        {{ confirmLiteral }}
    </button>
</div>
