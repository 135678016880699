import {AfterViewInit, Component, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from 'src/app/models/user';
import {TitleService} from 'src/app/services/title.service';
import {UsersService} from 'src/app/services/users.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {ClientsDetailComponent} from '../clients-detail/clients-detail.component';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';

@Component({
  selector: 'app-clients-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class ClientsListComponent extends ItemSimpleFiltrableListAbstractComponent<User> implements OnInit, AfterViewInit {
  // TODO: filters like archived or staff, and others should be abstracted

  pageTitleI18N = 'LOADING_AND_THREE_DOTS';

  autoFocusSearchBox = true;

  mode = 'clients';  // clients, staff, suppliers

  urlBaseDetailItem = `/${this.mode}/`;

  displayedColumnsMetaData = {
    profilePicture: new DisplayedColumnMetaData('', DisplayedColumnTypes.profile_picture),
    name: new DisplayedColumnMetaData('NAME'),
    email: new DisplayedColumnMetaData('EMAIL'),
    phoneNumber: new DisplayedColumnMetaData('PHONE_NUMBER')
  };

  primaryFab = null;
  secondaryFab = null;

  apiFilters = {};

  constructor(
    @Optional() public dialogRef: MatDialogRef<ClientsListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected usersService: UsersService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      usersService,
      nativeInterfacesService,
      dialog,
      media$,
      usersService,
      privacyModeService);
  }

  ngOnInit(): void {
    if (this.dialogMode) {
      if (this.data.mode !== 'client-selector') {
        this.mode = 'staff';
      }
    } else {
      if (this.activatedRoute.snapshot.url[0].path === 'clients') {
        this.mode = 'clients';
      } else if (this.activatedRoute.snapshot.url[0].path === 'staff') {
        this.mode = 'staff';
      } else if (this.activatedRoute.snapshot.url[0].path === 'suppliers') {
        this.mode = 'suppliers';
      }
    }
    this.urlBaseDetailItem = `/${this.mode}/`;

    this.setFilters();

    super.ngOnInit();
  }

  private setFilters(): void {
    if (this.mode === 'staff') {
      this.primaryFab = new Fab('NEW_MECHANIC', 'add', 'new_mechanic', FabTypes.singleAction);
      this.titleService.setTitleTranslated('STAFF');
      this.pageTitleI18N = 'STAFF';
      this.apiFilters = {
        is_business_client: false,
        is_business_employee: true,
        is_business_supplier: false
      };
    } else if (this.mode === 'clients') {
      this.primaryFab = new Fab('NEW_CLIENT', 'add', 'new_client', FabTypes.singleAction);
      this.titleService.setTitleTranslated('CLIENTS');
      this.pageTitleI18N = 'CLIENTS';
      this.apiFilters = {
        is_business_client: true,
        is_business_owner: false,
        is_business_employee: false,
        is_business_supplier: false
      };
    } else if (this.mode === 'suppliers') {
      this.primaryFab = new Fab('NEW_SUPPLIER', 'add', 'new_supplier', FabTypes.singleAction);
      this.titleService.setTitleTranslated('SUPPLIERS');
      this.pageTitleI18N = 'SUPPLIERS';
      this.apiFilters = {
        is_business_client: false,
        is_business_owner: false,
        is_business_employee: false,
        is_business_supplier: true
      };
    }
  }

  async onFabAction(actionId: string): Promise<boolean> {
    if (actionId === 'new_client') {
      if (this.dialogMode) {
        const dialogRef = this.dialog.open(ClientsDetailComponent, {
          maxHeight: '90vh',
          width: '90%',
          maxWidth: '90%',
          panelClass: 'no-padding-dialog-container',
          data: {}
        });
        const newUser = await dialogRef.afterClosed().toPromise();
        if (newUser) {
          this.dialogRef.close(newUser);
        }
      } else {
        this.router.navigate([`/${this.urlBaseDetailItem}/create`]);
      }
      return true;
    }
    if (actionId === 'new_mechanic' || actionId === 'new_supplier') {
      this.router.navigate([`/${this.urlBaseDetailItem}/create`]);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }
}
