import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {HotkeysService} from 'angular2-hotkeys';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {TitleService} from 'src/app/services/title.service';
import {PosProductService} from '../../services/pos-products.service';
import {
  AbstractParentDetailComponent,
  ParentItemAction
} from '../../components/abstract-parent-detail/abstract-parent-detail.component';
import {UsersService} from '../../services/users.service';
import {Fab, FabAction, FabTypes} from '../../components/fab-custom/fab-interface';
import {InventoryCountItem} from '../../models/inventory_count';
import {InventoryCountItemService} from '../../services/inventory-count-item.service';
import {InventoryCountService} from '../../services/inventory-count.service';
import {
  InventoryCountItemListComponent
} from '../../components/inventory-count-item-list/inventory-count-item-list.component';
import {AppMetricsService} from '../../services/app-metrics.service';


@Component({
  selector: 'app-inventory-count-detail',
  templateUrl: '../../components/abstract-parent-detail/abstract-parent-detail.component.html',
  styleUrls: ['../../components/abstract-parent-detail/abstract-parent-detail.component.scss']
})
export class InventoryCountDetailComponent extends AbstractParentDetailComponent<InventoryCountItem> implements OnInit, OnDestroy {

  constructor(
    private hotkeysService: HotkeysService,
    protected titleService: TitleService,
    protected translate: TranslateService,
    protected notificationService: CrmNotificationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected router: Router,
    protected dialog: MatDialog,
    protected progressDialog: MatDialog,
    protected route: ActivatedRoute,
    private inventoryCountService: InventoryCountService,
    private posProductService: PosProductService,
    private inventoryCountItemService: InventoryCountItemService,
    protected usersService: UsersService,
    private resolver: ComponentFactoryResolver,
    public native: NativeInterfacesService,
    public appMetricsService: AppMetricsService
  ) {
    super(
      titleService,
      translate,
      notificationService,
      nativeInterfacesService,
      router,
      dialog,
      progressDialog,
      route,
      inventoryCountService,
      usersService,
      appMetricsService
    );
  }

  navBase = '/inventory_counts/';

  titleI18N = 'INVENTORY_COUNT';
  titleIcon = 'inventory';

  @ViewChild(InventoryCountItemListComponent) childItemListRef: InventoryCountItemListComponent;
  @ViewChild('childListHost', {read: ViewContainerRef, static: false}) childListHost;

  primaryFab = new Fab(
    'ADD_PRODUCT',
    'add',
    'fab_add_product',
    FabTypes.multipleAction,
    [
      new FabAction('SEARCH_PRODUCT', '', 'search_product'),
      new FabAction('ADD_BY_CODE', '', 'add_by_code'),
      new FabAction('ADD_PRODUCT_MANUALLY', '', 'add_item_manually')
    ]);
  secondaryFab = null;

  actions = [
    new ParentItemAction('RECONCILE', '', 'reconcile'),
    new ParentItemAction('DELETE_INVENTORY_COUNT', '', 'delete_inventory_count'),
  ];

  loadChildListComponent(): void {
    // TODO: migrate use componentfactory to viewcontaineref.createcomponent
    //    https://stackoverflow.com/a/70947152/888245
    // Dynamically create the component, so we don't need multiple templates for different types of lists
    this.childListHost.clear();
    const factory: ComponentFactory<InventoryCountItemListComponent> = this.resolver.resolveComponentFactory(InventoryCountItemListComponent);
    this.childListRefComponentRef = this.childListHost.createComponent(factory);
    this.childListRefComponentRef.instance.parentApiContentTypeName = 'order';
    this.childListRefComponentRef.instance.parent = this.parentItem;
    this.childItemListRef = this.childListRefComponentRef.instance;

  }

  ngOnInit(): void {
    this.initialCreateFormData.append('workshop', this.usersService.userMe.workshop);
    super.ngOnInit();
    // TODO: move to an abstract class as 'title' or 'page title' or something on those lines:
    this.titleService.setTitleTranslated(null);

    if (this.nativeInterfacesService.hasCamera) {
      this.secondaryFab = new Fab('', 'qr_code_scanner', 'fab_add_by_cam_code_scanner', FabTypes.singleAction);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // addOrderItem(orderItem?: OrderItem): void {
  //   this.childItemListRef.addItemEnd(orderItem);
  // }

  onFabAction(actionId: string): boolean {
    if (actionId === 'search_product') {
      this.childItemListRef.searchExistingProductDialog();
      return true;
    }
    if (actionId === 'add_by_code') {
      this.childItemListRef.addByCodeDialog();
      return true;
    }
    if (actionId === 'add_item_manually') {
      // this.addOrderItem();
      return true;
    }
    if (actionId === 'fab_add_by_cam_code_scanner') {
      this.childItemListRef.startCamBarcodeScanner();
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  onAction(actionId: string): boolean {
    if (actionId === 'reconcile') {
      this.inventoryCountService.reconcile(this.parentId).subscribe(() => {
        this.notificationService.successI18N('INVENTORY_COUNT_RECONCILED');
        this.router.navigateByUrl('/inventory_counts', {replaceUrl: true});
      });
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  // deleteOrder(): void {
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     data: new ConfirmDialogModel('CONFIRM_DELETE_SALE', '', 'DELETE', 'delete', 'warn')
  //   });
  //
  //   dialogRef
  //     .afterClosed()
  //     .pipe(takeUntil(this.onDestroy$))
  //     .subscribe(async (confirmDialog: boolean) => {
  //       if (confirmDialog) {
  //         // raise exception on ng on init if we ever try to build a deleted component
  //         this.posOrdersService.delete(this.parentId).subscribe( () => {
  //           this.router.navigateByUrl('/pos/orders', {replaceUrl: true});
  //         }, (err) => {
  //           // tslint:disable-next-line:no-string-literal
  //           this.notificationService.errorI18N(err.error['detail']);
  //         });
  //       } else {
  //         // cancelled
  //       }
  //     });
  // }

}
