import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

declare var SignaturePad: any;

export class SignPadDialogModel {

  constructor(
    // public messageI18nKey: string,
    // public titleI18nKey: string = '',
    // public confirmI18nKey: string = 'YES',
    // public confirmIcon: string = 'check',
    // public confirmColor: ThemePalette = null,
    // public cancelI18nKey: string = 'CANCEL',
    // public cancelIcon: string = 'cancel',
    // public cancelColor: ThemePalette = null
  ) {
  }
}

@Component({
  selector: 'app-sign-pad',
  templateUrl: './sign-pad-dialog.component.html',
})
export class SignPadDialogComponent implements OnInit, AfterViewInit {
  // https://github.com/szimek/signature_pad

  // @ViewChild('signPad', {static: true}) signPad: ElementRef;

  signaturePad: any;

  signPadDialogModel: SignPadDialogModel;
  protected readonly close = close;

  constructor(
    public dialog: MatDialogRef<SignPadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SignPadDialogModel) {
    this.signPadDialogModel = data;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const canvas = document.querySelector('canvas');
    this.signaturePad = new SignaturePad(canvas);
    // const signaturePad = new SignaturePad(this.signPad.nativeElement);

    // window.addEventListener('resize', resizeCanvas);
    // this.resizeCanvas();

    // needed to manage hi-dpi screens
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear();
  }

  closeDialog(): void {
    this.dialog.close(false);
  }

  clearCanvas(): void {
    this.signaturePad.clear();
  }

  loadCurrentSignature(): void {
    // this.signaturePad.fromData(data);
  }

  confirmDialog(): void {
    const signature = this.signaturePad.toDataURL('image/png', 0.90);
    this.dialog.close(signature);
  }
}
