import {AbstractChildItem} from './abstract_child_item';
import {AbstractBaseItem} from './abstract_base_api_item';
import {Product} from './pos_product';


export class InventoryCount extends AbstractBaseItem {
  id: string;
  name: string;
  createdAt: string;
  reconciled: boolean;
  mode: string;
  workshop: string;
  pendingItemsToScannCount: number;
  shrinkage: number;
}

export class InventoryCountItem extends AbstractChildItem<InventoryCount> {
  id: string;
  parentRelName = 'inventory_count';
  productName: string;
  productCode: string;
  inventoryCount: string;
  product: string;
  qtyExpected: number;
  qtyCounted: number;
  createdAt: string;

  static createFromProduct(product: Product): InventoryCountItem {
    // console.log('product', product);
    const noi = new InventoryCountItem();
    noi.productName = product.name;
    noi.product = product.id;
    noi.qtyExpected = product.stock;
    return noi;
  }
}
