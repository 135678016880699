import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrivacyModeService {
  privacyModeEnabled = false;

  constructor() {
    this.privacyModeEnabled = localStorage.getItem('privacy_mode_enabled') === '1';
  }

  setPrivacyMode(enabled: boolean): void {
    this.privacyModeEnabled = enabled;
    localStorage.setItem('privacy_mode_enabled', enabled ? '1' : '0');
  }

  isPrivacyModeEnabled(): boolean {
    return this.privacyModeEnabled;
  }

}
