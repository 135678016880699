import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BikeCRMApiAbstract} from './bikecrm-api-base';
import {UsersService} from './users.service';

@Injectable({
  providedIn: 'root'
})
export class RentedAccesoriesService extends BikeCRMApiAbstract {
  apiPath = 'rentals_rented_accessories';

  constructor(
    private userService: UsersService,
    protected http: HttpClient
  ) {
    super(http);
  }

  getRentedAccessories(rentalId: string): Observable<any> {
    return this.getList({rental: rentalId});
  }
}
