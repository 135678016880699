import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {FinancesBankAccountService} from '../../services/finances-bank-account.service';
import {
  AbstractParentDetailComponent,
  ParentItemAction
} from '../../components/abstract-parent-detail/abstract-parent-detail.component';
import {TitleService} from '../../services/title.service';
import {
  FinancesBankTransactionList
} from '../../components/finances-bank-transaction-list/finances-bank-transaction-list';
import {BankAccount} from '../../models/finances_bank';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {
  SimpleTextDialogComponent
} from '../../components/dialogs/simple-text-dialog-component/simple-text-dialog.component';
import {AppMetricsService} from '../../services/app-metrics.service';


@Component({
  selector: 'app-finances-bank-account-detail',
  templateUrl: '../../components/abstract-parent-detail/abstract-parent-detail.component.html',
  styleUrls: ['../../components/abstract-parent-detail/abstract-parent-detail.component.scss']
})
export class FinancesBankAccountDetailComponent extends AbstractParentDetailComponent<BankAccount> implements OnInit {

  titleI18N = 'CASH_REGISTER';
  titleIcon = 'payments';

  primaryFab = new Fab('ADD_TRANSACTION', 'add', 'fab_add_transaction', FabTypes.singleAction);

  secondaryFab = null;

  navBase = '/finances/bank-accounts';

  @ViewChild(FinancesBankTransactionList) childItemListRef: FinancesBankTransactionList;
  @ViewChild('childListHost', {read: ViewContainerRef, static: false}) childListHost;

  actions = [
    new ParentItemAction('CASH_RECONCILIATION', '', 'cash_reconciliation'),
  ];

  constructor(
    protected titleService: TitleService,
    protected translate: TranslateService,
    protected notificationService: CrmNotificationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected router: Router,
    protected dialog: MatDialog,
    protected progressDialog: MatDialog,
    protected route: ActivatedRoute,
    protected financesBankAccountService: FinancesBankAccountService,
    protected usersService: UsersService,
    private resolver: ComponentFactoryResolver,
    public native: NativeInterfacesService,
    public appMetricsService: AppMetricsService
  ) {
    // tslint:disable-next-line:max-line-length
    super(titleService, translate, notificationService, nativeInterfacesService, router, dialog, progressDialog, route, financesBankAccountService, usersService, appMetricsService);
  }

  loadChildListComponent(): void {
    // TODO: migrate use componentfactory to viewcontaineref.createcomponent
    //    https://stackoverflow.com/a/70947152/888245
    // Dynamically create the component, so we don't need multiple templates for different types of lists
    this.childListHost.clear();
    const factory: ComponentFactory<FinancesBankTransactionList> = this.resolver.resolveComponentFactory(FinancesBankTransactionList);
    this.childListRefComponentRef = this.childListHost.createComponent(factory);
    this.childListRefComponentRef.instance.parent = this.parentItem;
    this.childItemListRef = this.childListRefComponentRef.instance;

  }

  onFabAction(actionId: string): void {
    if (actionId === 'fab_add_transaction') {
      this.childItemListRef.addItemStart();
    }
    super.onFabAction(actionId);
  }

  onAction(actionId: string): void {
    if (actionId === 'cash_reconciliation') {
      const dialogRef = this.dialog.open(SimpleTextDialogComponent, {});
      dialogRef.componentInstance.hintI18N = 'CASH_ON_THE_REGISTER';
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.length > 0) {
          const amount = parseFloat(result);
          if (isNaN(amount)) {
            this.notificationService.warningI18N('INVALID_NUMBER');
            return;
          }
          this.childListRefComponentRef.instance.reconcileCash(amount);
        } else {
          // this.notificationService.warningI18N('EMPTY_INPUT');
        }
      });
    }
    super.onAction(actionId);
  }

}
