/* tslint:disable:no-string-literal */


export const serviceSheetFields = {
  priority: {
    I18N_KEY: 'PRIORITY',
    TYPE: 'templated',
  },
  checkInStatus: {
    I18N_KEY: 'STATUS',
    TYPE: 'templated',
  },
  roPaymentStatus: {
    I18N_KEY: 'PAYMENTS.PAYMENT',
    TYPE: 'templated',
  },
  roPaymentMethod: {
    I18N_KEY: 'PAYMENTS.PAYMENT_METHOD',
    TYPE: 'templated',
  },
  ownerName: {
    I18N_KEY: 'CLIENT',
    TYPE: 'text',
  },
  invoiceNumber: {
    I18N_KEY: 'INVOICE',
    TYPE: 'text',
  },
  bikeName: {
    I18N_KEY: 'BIKE',
    TYPE: 'anchor',
  },
  createdAt: {
    I18N_KEY: 'ENTRY_DATE',
    TYPE: 'date',
  },
  checkInDt: {
    I18N_KEY: 'ENTRY_DATE',
    TYPE: 'date',
  },
  closedAt: {
    I18N_KEY: 'FINISHED_DATE',
    TYPE: 'date',
  },
  estimatedDeliveryDt: {
    I18N_KEY: 'ESTIMATED_DELIVERY_DATE',
    TYPE: 'date',
  },
  assignedToName: {
    I18N_KEY: 'ASSIGNED_TO',
    TYPE: 'text',
  },
  notifiedClient: {
    I18N_KEY: 'CTX_SERVICE_SHEETS.CLIENT_NOTIFIED',
    TYPE: 'templated',
  },
  totalAmountWithTax: {
    I18N_KEY: 'AMOUNT',
    TYPE: 'currency',
  },
  budgetStatus: {
    I18N_KEY: 'CTX_SERVICE_SHEETS.BUDGET_STATUS',
    TYPE: 'templated',
  }
};

