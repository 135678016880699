import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-invoice-controls',
  styleUrls: ['./invoice-controls.component.scss'],
  templateUrl: './invoice-controls.component.html'
})
export class InvoiceControlsComponent implements OnInit {

  /** DEPRECATED: use inline-edit component when possible (inline-edit still does not have datepicker support */
  /** Overrides the comment and provides a reset value when changes are cancelled. */
  @Input() invoiceSeries: number;
  @Input() invoiceNumber = '';
  @Input() invoiceDate: string;

  @Output() invoiceSeriesChange = new EventEmitter<number>();
  @Output() generateInvoiceEvent = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
  }

  generateInvoice(): void {
    this.generateInvoiceEvent.emit();
  }

  changeInvoiceSeries(series): void {
    this.invoiceSeriesChange.emit(series);
  }
}
