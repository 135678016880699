import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BikeCRMApiAbstract} from './bikecrm-api-base';

@Injectable({
  providedIn: 'root'
})
export class RentalAccessoriesService extends BikeCRMApiAbstract {
  apiPath = 'rentals_accessories';

  constructor(protected http: HttpClient) {
    super(http);
  }


}
