import {AbstractChildItem} from './abstract_child_item';
import {BillableItem} from './billable_item';
import {BudgetStates} from './abstract_base_api_item';


export enum BssCheckInStates {
  ScheduledRetrieval = 'ret',
  PendingCheckIn = 'cpe',
  CheckedIn = 'cin',
  CheckedOut = 'cou'
}

export enum BssPrioritiesStates {
  High = 'hig',
  Normal = 'nor',
  Low = 'low',
  Paused = 'pau'
}

export class ServiceSheetWorkSession {
  serviceSheet: string;
  totalBssTime: number;
  bssBikeName: string;
  bssBikeOwner: string;
  assignedTo: string;
  startTs: number;
  endTs: number;
}

export class ServiceSheet extends BillableItem {
  bikePrettyShortId: string;
  invoiceNumber: string;
  invoiceSeries: number;
  invoiceDate: string;

  owner: string;
  ownerName: string;
  ownerMail: string;
  ownerPhone: string;
  // Use formatted one to calls, sms, whatsapp, etc. it ensures it have the country code, and it's correctly parsed
  //    it's a read only field
  ownerPhoneFormatted: string;
  bike: string;
  bikeName: string;
  bikeType: string;
  bikePicture: string;

  createdAt: Date;
  updatedAt: Date;
  closedAt: Date;
  estimatedDeliveryDt: string;
  estimatedWorkTimeSeconds: number;

  checkInScheduledDt: string;  // Date when the client should check in (bring) the bike
  checkInStatus: BssCheckInStates;  // ('cpe', 'Pending check in'), ('cin', 'Checked in'), ('cou', 'Checked out'), ('ret', 'Scheduled for Retrieval')
  budgetStatus: BudgetStates;

  closed: boolean;
  started: boolean;
  notifiedClient: boolean;

  priority: BssPrioritiesStates;

  assignedTo: string;
  assignedToName: string;
  openedBy: string;
  openedByName: string;
  closedBy: string;
  closedByName: string;

  pictureFinish: string;

  generalNotesPrivate: string;
  generalNotesPublic: string;

  extraData: object;

  // tasksCount: number;

  // tasks?: ServiceSheetTask[];
}

export class ServiceSheetTask extends AbstractChildItem<ServiceSheet> {
  parentRelName = 'serviceSheet';

  taskDescription: string;
  createdAt: Date;
  updatedAt: Date;
  finishedAt: Date;

  finished: boolean;

  completedBy: string;

  completedByName: string;

  workNotes: string;
  privateNotes: string;

  costMaterials: number;

  costHours = 0;
  vatHours = 0.21;  // TODO: don't use default vat

  costOther = 0;
  vatOther = 0.21;  // TODO: don't use default vat

  costOtherRecommended = 0;
  discountPercent = 0;

  qty = 1;

  // picturePre: string;
  // picturePost: string;

  extraData: object;

  static totalCost(task: ServiceSheetTask): number {
    // We should make this method static because when we get all the items from the service (api), we end up with a list of objects
    // not a list of OrderItems, so we can't access methods from those simple objects
    return ((task.costHours + (task.costHours * task.vatHours)) + (task.costOther + (task.costOther * task.vatOther))) * task.qty;
  }
}

export class ServiceSheetTimeTrackingStatus {
  serviceSheet: string;

  totalSeconds: number;
  currentlyRunning: boolean;
}


export class ServiceSheetFile extends AbstractChildItem<ServiceSheet> {
  parentRelName = 'serviceSheet';

  file: string;
  createdAt: Date;
  updatedAt: Date;
  note: string;
  private: boolean;
  fileType: string;  // pic, pic-pre, pic-post
}
