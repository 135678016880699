<div fxLayout="column" class="py1">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{ 'PRIVATE_NOTES' | translate }}</mat-label>
    <textarea matInput [(ngModel)]="notesPrivate" rows="10" name="description"
              (focusout)="saveNotes()"></textarea>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{ 'PUBLIC_NOTES' | translate }}</mat-label>
    <textarea matInput [(ngModel)]="notesPublic" rows="10" name="description"
              (focusout)="saveNotes()"></textarea>
  </mat-form-field>
</div>
