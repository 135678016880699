import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BikeCRMApiAbstract, GetOrCreateResponse} from './bikecrm-api-base';
import {environment} from '../../environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {Product} from '../models/pos_product';
import {concatMap, delay, publishLast, refCount, retryWhen} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PosParentProductService extends BikeCRMApiAbstract {
  apiPath = 'pos_parent_product';

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  createVariantFromBrother(brotherProductId: string): Observable<GetOrCreateResponse<Product>> {
    return this.http.post(`${environment.apiUrl}/api/${this.apiPath}/create_from_brother/`, {
      brother_product_id: brotherProductId
    }).pipe(
      retryWhen(errors => errors.pipe(delay(1500),
        concatMap((e, index) => {
            if (e.status === 404) {
              // If the code is not found, we don't need to retry, we just want to retry if there is some
              //  connection issues or server issues.
              return throwError(e);
            }
            return index === 3 ? throwError(e) : of(null);
          }
        ),
      )),
      publishLast(),
      refCount()
    );
  }

}
