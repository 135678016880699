import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Business} from 'src/app/models/business';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {UsersService} from 'src/app/services/users.service';

interface Languages {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-business-detail',
  templateUrl: './business-detail.component.html',
  styleUrls: ['./business-detail.component.scss']
})
export class BusinessDetailComponent implements OnInit {
  business: Business;
  // languageControl = new FormControl();
  businessForm: UntypedFormGroup;

  languages: Languages[] = [
    { value: 'en', viewValue: 'English' },
    { value: 'es', viewValue: 'Castellano' },
    { value: 'ca', viewValue: 'Català' },
    { value: 'fr', viewValue: 'Français' }
  ];

  constructor(
    private notificationService: CrmNotificationsService,
    private userService: UsersService,
    private t: TranslateService
  ) {
    // this.business = userService.business;
  }

  ngOnInit(): void {
    console.log('DEPRECATED!!!! extend: ItemDetailAbstractComponent!!!!!!');

    // TODO: unsubscribe on destroy
    this.userService.business$.subscribe(b => {
      this.business = b;
      this.configureForm();
    });
    this.userService.updateUserAndBusinessInfo();
    this.configureForm();
  }

  configureForm(): void {
    // Invoice generation mode causes problems if you enable it after creating invoices manually
    //   as it creates invoices for all past invoices, this should be fixed on backend, for example we can
    //   put all those invoices in a legacy series, but still can cause confusion to the user, for now we don't
    //   allow to change this setting
    this.businessForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.business.name, Validators.required),
      // hourlyrate: new UntypedFormControl(this.business.hourlyRate),
      language: new UntypedFormControl(this.business.language, Validators.required),
      legalName: new UntypedFormControl(this.business.legalName),
      nif: new UntypedFormControl(this.business.nif),
      address: new UntypedFormControl(this.business.address),
      city: new UntypedFormControl(this.business.city),
      postalCode: new UntypedFormControl(this.business.postalCode),
      country: new UntypedFormControl(this.business.country),
      // invoiceGenerationMode: new UntypedFormControl(this.business.invoiceGenerationMode),
      userMobileNotificationConfig: new UntypedFormControl(this.business.userMobileNotificationConfig),
      // TODO: before we enable this again we need to make it optional on backend
      // logoPicture: new FormControl(this.business.logoPicture)
    });
  }

  onSubmit(): void {
    const formData = new FormData();
    formData.append('name', this.businessForm.get('name').value);
    // formData.append('hourlyRate', this.businessForm.get('hourlyrate').value);
    formData.append('language', this.businessForm.get('language').value);

    formData.append('legalName', this.businessForm.get('legalName').value);
    formData.append('nif', this.businessForm.get('nif').value);
    formData.append('address', this.businessForm.get('address').value);
    formData.append('city', this.businessForm.get('city').value);
    formData.append('postalCode', this.businessForm.get('postalCode').value);
    formData.append('country', this.businessForm.get('country').value);
    // formData.append('invoiceGenerationMode', this.businessForm.get('invoiceGenerationMode').value);
    formData.append('userMobileNotificationConfig', this.businessForm.get('userMobileNotificationConfig').value);

    // if (this.businessForm.get('logoPicture').value != null) {
      // formData.append('logoPicture', this.businessForm.get('logoPicture').value);
    // }

    this.userService.modifyBusinessByID(this.business.id, formData).subscribe({
      next: async () => {
        this.notificationService.success(await this.t.get('FEEDBACK_MESSAGES.BUSINESS_SAVED_SUCCESSFULLY').toPromise());
      },
      error: async () => {
        this.notificationService.error(await this.t.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(), 'Error modifying Business');
      }
    });
  }

  onFileChange(event): void {
    // if (event.target.files.length > 0) {
      // this.businessForm.get('logoPicture').setValue(event.target.files[0]);
    // }
  }
}
