import {Injectable} from '@angular/core';
import {Observable, Subject, timer} from 'rxjs';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {MobileDetectionService} from './mobile-detection.service';
import {environment} from '../../environments/environment';

declare const FullyKiosk: object;
declare const fully: any;

@Injectable({
  providedIn: 'root'
})
export class NativeInterfacesService {
  // https://stackoverflow.com/questions/43159090/how-can-i-detect-service-variable-change-when-updated-from-another-component

  // Potentially interesting fully apis:
  // void fully.showKeyboard()
  // void fully.hideKeyboard()
  // void fully.vibrate()
  // void fully.showNotification(String title, String text, String url, boolean highPriority)
  // void fully.log(int type, String tag, String message)
  // void fully.setScreenBrightness(float level)
  // void fully.turnScreenOn()
  // void fully.turnScreenOff()
  // void fully.turnScreenOff(boolean keepAlive)
  // void fully.forceSleep()
  // void fully.showPdf(String url)
  // void fully.startApplication(String packageName)
  // void fully.startApplication(String packageName, String action, String url)
  // void fully.startIntent(String url)
  // void fully.broadcastIntent(String url)
  // void fully.installApkFile(String url)
  // void fully.enableMaintenanceMode()
  // void fully.disableMaintenanceMode()
  // void fully.setMessageOverlay(String text)
  // void fully.registerBroadcastReceiver(String action)
  // void fully.unregisterBroadcastReceiver(String action)

  // float fully.getScreenBrightness()
  // boolean fully.getScreenOn()
  // boolean fully.isKeyboardVisible()
  // boolean fully.isScreenRotationLocked()
  // String fully.getWebviewVersion()
  // int fully.getFullyVersionCode()
  // String fully.getDeviceModel()
  // boolean fully.isInForeground()

  // fully events:
  // void fully.bind('internetDisconnect','todo();')
  // void fully.bind('internetReconnect','todo();')
  // void fully.bind('unplugged','todo();')
  // void fully.bind('pluggedAC','todo();')
  // void fully.bind('pluggedUSB','todo();')
  // void fully.bind('onBatteryLevelChanged','todo();')
  // void fully.bind('onIBeacon','todo();')
  // void fully.bind('broadcastReceived','todo();')
  // void fully.bind('showKeyboard','todo();')
  // void fully.bind('hideKeyboard','todo();')
  // void fully.bind('screenOn','todo();')
  // void fully.bind('screenOff','todo();')


  batteryLevel = new Subject<number>();
  isPlugged = new Subject<boolean>();

  isWifiEnabled = new Subject<boolean>();
  isWifiConnected = new Subject<boolean>();
  isOnline = new Subject<boolean>();

  get inFully(): boolean {
    return this._inFully;
  }

  // tslint:disable-next-line:variable-name
  private _inFully = false;
  private scanner: ZXingScannerComponent;
  public hasCamera = false;

  constructor(protected mobileDetectionService: MobileDetectionService) {
    this.scanner = new ZXingScannerComponent();
    this._inFully = typeof FullyKiosk !== 'undefined';
    this._startHardwareMonitors();
    this.detectCamera();
  }

  isWifiInfoSupported(): boolean {
    // For now, we only support fully kiosk
    return this._inFully;
  }

  isBatteryInfoSupported(): boolean {
    // For now, we only support fully kiosk
    return this._inFully;
  }

  isFully(): boolean {
    // For now, we only support fully kiosk
    return this._inFully;
  }

  openWifiSettings(): boolean {
    // For now, we only support fully kiosk
    if (!this._inFully) {
      return;
    }
    fully.openWifiSettings();
  }

  detectCamera(): void {
    if (this._inFully) {
      this.hasCamera = true;
      return;
    }
    // TODO: this is not yet working, the callback is never called
    // console.log('hasDevices1');
    // this.scanner.hasDevices.subscribe((r) => {
    //   console.log('hasDevices', r);
    //   this.hasCamera = r;
    // });
    // check permissions
    // TODO: remove the isBeta, beta should be as close as possible to the real thing
    // this.hasCamera = this.mobileDetectionService.areWeOnMobile || environment.isBeta;

    // this.hasCamera = this.mobileDetectionService.areWeOnMobile || environment.isBeta;
    this.hasCamera = this.mobileDetectionService.areWeOnMobile || environment.isBeta;
  }

  // showToast(text: string): void {
  //     fully.showToast(text);
  // }

  // tslint:disable-next-line:typedef
  _startHardwareMonitors() {
    // TODO: replace with events, instead of every x seconds, but it's a little bit tricky
    //          with the fully event system
    // TODO: review how I build this observable when I become more proficient on rxjs

    if (!this.isBatteryInfoSupported()) {
      return;
    }

    const obs: Observable<object> = new Observable(subscriber => {
      if (this._inFully) {
        // console.log('fully');

        const isWifiConnected = fully.getWifiSsid() && fully.getWifiSsid().length > 1;
        const status = {
          bat: fully.getBatteryLevel(),
          plugged: fully.isPlugged(),
          wifiEnabled: fully.isWifiEnabled(),
          wifiConnected: isWifiConnected,
          online: navigator.onLine
        };
        subscriber.next(status);

        // TODO move this outside this observer
        this.batteryLevel.next(Math.round(status.bat));
        this.isPlugged.next(status.plugged);
        this.isWifiEnabled.next(status.wifiEnabled);
        this.isWifiConnected.next(status.wifiConnected);

        // TODO: this can work outside fully:
        this.isOnline.next(status.online);
      } else {
        // console.log('browser');
        // subscriber.next(new Date().getMinutes());
      }
      subscriber.complete();
    });

    timer(0, 5000)
      .pipe(
        // tap((r) => console.log(r)),
        switchMap(() => obs),
        distinctUntilChanged(),
        // tap((r) => console.log(r)),
        // skip(1),
        // map((r) => r)
        // tap((r) => this.batteryLevel.next(r)),
      ).subscribe();
  }

}
