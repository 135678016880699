import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {BarCodeScannerComponent} from '../../pages/barcode-scanner/barcode-scanner';
import {MatDialog} from '@angular/material/dialog';

declare const fully: any;

@Component({
    template: '',
})
export abstract class BarcodeScannerAbstractComponent implements OnInit, OnDestroy {
    // This Component implements 2 scanners, the camera scanner (fully) and the keyboard scanner
    // TODO: on the future we may implement a camera scanner that's not the fully one, for iOS, etc.
    // TODO: maybe this class makes more sense as a service? (or at least separate every type of scanner on a service?) and
    //    maybe convert this Component to the BaseComponent of the app, with title, fully, and other common intergaces?

    lastKeyboardEventMs = new Date().getTime();
    lastKeyboardStrokes: string[] = [];

    protected constructor(
        protected nativeInterfacesService: NativeInterfacesService,
        protected dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        if (this.nativeInterfacesService.inFully) {
            if (window.addEventListener) {
                window.addEventListener('storage', this._barCodeListener, false);
            }
        }
    }

    ngOnDestroy(): void {
        if (this.nativeInterfacesService.inFully) {
            window.removeEventListener('storage', this._barCodeListener, false);
        }
    }

    onBarCodeScanner(code: string): void {
        console.log(`onBarCodeScanner not implemented, code scanned: ${code} on class ${this.constructor.name}`);
    }

    startCamBarcodeScanner(): void {
        if (this.nativeInterfacesService.inFully) {
            localStorage.removeItem('camera_barcode');

            // TODO: translate literal
            void fully.scanQrCode('scan bar code / qr code', '$code', -1, -1, true, true);

            // We use localstorage as a "bus" as it's hard to pass $code to an angular method. Also,
            //  we dispatch a custom storage event as those
            //    events are only dispatched to other tabs and windows when local storage changes
            //      Future improvements, pass directly $code to a method,
            //        or at lease skip localstorage and just use a custom event a window listener
            void fully.bind('onQrScanSuccess', 'localStorage.setItem("camera_barcode", \'$code\'); window.dispatchEvent(new Event("storage"));');
            // void fully.bind('onQrScanCancelled', '');
        } else {
            // TODO: check hardware capabilities?
            // open BarCodeScannerComponent as a dialog:
            const dialogRef = this.dialog.open(BarCodeScannerComponent, {
                width: '100%',
                maxWidth: '100%',
                height: '100%',
                maxHeight: '100%',
                // panelClass: 'full-screen-dialog',
                panelClass: 'no-padding-dialog-container',
                disableClose: false
            });

            dialogRef.afterClosed().subscribe(result => {
                // console.log(`The dialog was closed with result: ${result}`);
                if (result) {
                    this.onBarCodeScanner(result);
                }
            });
        }
    }

    // tslint:disable-next-line:variable-name
    private _barCodeListener = () => {
        this.appendBarCodeFromLocalStorage();
    }

    appendBarCodeFromLocalStorage(): void {
        const bc = JSON.parse(window.localStorage.getItem('camera_barcode'));
        if (bc === null) {
            return;
        }

        // We should clone the object, otherwise when we remove it from local storage it disappears from here also
        const code = bc.toString().trim();
        this.onBarCodeScanner(code);
    }

    isKeyboardBarCodeScannerEnabled(): boolean {
        // You should disable it for example when user is using any input on any form
        // having this enabled, without proper management can cause wierd problems, for that reason it's disabled by default
        // TODO: only on local
        console.log('isKeyboardBarCodeScannerEnabled not overridden, barcode scanner is disabled');
        console.log(`on class ${this.constructor.name}`);
        return false;
    }

    // Listen for keyboard / usb scanners
    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        // https://stackoverflow.com/questions/37362488/how-can-i-listen-for-keypress-event-on-the-whole-page/37363257
        // Review and test extensively this method logic:
        if (!this.isKeyboardBarCodeScannerEnabled()) {
            return;
        }

        const currentTsMs = new Date().getTime();

        const diff = currentTsMs - this.lastKeyboardEventMs;
        // 200ms will be enough for a USB scanner, but for the bluetooth we need at least 800ms, so 1s is a safe sweet spot

        const maxMsToInputWholeCode = 1000;
        // We give the scanner 500ms to type all the code, otherwise we reset our array
        if (diff > maxMsToInputWholeCode) {
            this.lastKeyboardStrokes = [];
            this.lastKeyboardEventMs = currentTsMs;
        }
        if (event.key === 'Enter') {
            if (diff < maxMsToInputWholeCode && this.lastKeyboardStrokes.length > 7) {
                const code = this.lastKeyboardStrokes.join('');
                // https://stackoverflow.com/questions/1779013/check-if-string-contains-only-digits
                // const isNumber = code.match(/^[0-9]+$/) != null;
                // if (isNumber) {
                // Maybe on the future we want to filter only numbers? I've already saw barcodes with letters on them...
                // this.onBarCodeScanner(code);
                // }
                this.onBarCodeScanner(code);
                this.lastKeyboardStrokes = [];
            }
        } else {
            this.lastKeyboardStrokes.push(event.key);
        }
    }
}
