<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="center center" fxLayoutGap="8px">
  <div fxLayout="row wrap">
    <div class="workflow-button-step prevent-select"
         [matMenuTriggerFor]="step.type === 'select' && (step.id === 'notified' || step.id === 'markaspaid') ? step.id === 'notified' ? notifiedStepActionMenu : markAsPaidStepActionMenu : null"
         [ngClass]="{
            'workflow-button-step-not-started': step.status === STEP_STATUS.NOTSTARTED,
            'workflow-button-step-started': step.status === STEP_STATUS.STARTED,
            'workflow-button-step-completed': step.status === STEP_STATUS.FINISHED,
            }"
         [ngStyle]="{
            'pointer-events': (step.disabled || step.ongoing) ? 'none' : 'auto',
         }"
         *ngFor="let step of steps"
         (click)="setCurrentStep(step)">

<!--      TODO: for now we only have one hardcoded case for notified menu, make it more dynamic if we need to add more moenus on the future -->
      <mat-menu #notifiedStepActionMenu="matMenu">
        <button *ngFor="let option of step.options"
                mat-menu-item
                (click)="setCurrentStepMenu(step, option.value)"
                [value]="option.value">
          {{ option.i18n | translate }}
        </button>
      </mat-menu>
      <mat-menu #markAsPaidStepActionMenu="matMenu">
        <button *ngFor="let option of step.options"
                mat-menu-item
                (click)="setCurrentStepMenu(step, option.value)"
                [value]="option.value">
          {{ option.i18n | translate }}
        </button>
      </mat-menu>

      <div fxLayout="column">
        <span fxLayout="row" fxFlexAlign="center">{{ step.i18n | translate }}</span>
        <mat-progress-bar *ngIf="step.ongoing" mode="indeterminate"></mat-progress-bar>
        <small *ngIf="step.subtitleI18n" class="muted subtitle">
          {{ step.subtitleI18n | translate }}
<!--          TODO: show here notification channels if any whatsapp, mail, date, and status of read, etc. maybe a submenu? with and (I) i of info? -->
        </small>
      </div>

      <mat-menu *ngIf="step.type === 'select'" #stepActionMenu{{step.id}}="matMenu">
        <button *ngFor="let option of step.options"
                mat-menu-item
                (click)="setCurrentStepMenu(step, option.value)"
                [value]="option.value">
          {{ option.i18n | translate }}
        </button>
      </mat-menu>


    </div>
  </div>

  <span class="workflow-button prevent-select"
        [matMenuTriggerFor]="nextStepAction.type === 'select' ? nextStepActionMenu : null"
        *ngIf="nextStepAction.i18n"
        (click)="setCurrentStep(nextStepAction)">
    {{ nextStepAction.i18n | translate }}
  </span>

  <mat-menu #nextStepActionMenu="matMenu">
    <button *ngFor="let option of nextStepAction.options"
            mat-menu-item
            (click)="setCurrentStepMenu(nextStepAction, option.value)"
            [value]="option.value">
      {{ option.i18n | translate }}
    </button>
  </mat-menu>
</div>
