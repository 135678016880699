import {environment} from 'src/environments/environment';
import {AbstractBaseItem} from './abstract_base_api_item';

export class BikePicture {
    picture: string;
    caption?: string;
}

export class Bike extends AbstractBaseItem {
    id: string;
    prettyId: string;
    shortPrettyId: string;

    business: string;
    ownerName: string;
    owner: string;

    createdAt: string;

    roOdometer: number;

    frameSize: string;

    name: string;
    brand?: string;
    model?: string;
    year?: number;
    sn?: string;

    isAvailableToRent: number; // 0 or 1 (or -1 if no dates provided)

    type?: string;

    mainPictureUrl?: string;
    pictures: BikePicture[];

    static getPictureUrl(bike: Bike): string {
        return Bike.getPictureUrlByType(bike.mainPictureUrl, bike.type);
    }

    static getCSSBackgroundColor(bike: Bike): string {
        // when available I use material 700 (https://www.materialpalette.com/colors)
        // return 'linear-gradient(90deg, rgba(255,0,0,1) 35%, rgb(9, 156, 4) 65%)';

        // Lower case & remove accents
        const n = bike.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const foundColors = [];

        if (n.includes('black') || n.includes('negra') || n.includes('negre') || n.includes('negro')) {
            foundColors.push('#000000');
        }
        if (n.includes('white') || n.includes('blanca') || n.includes('blanc') || n.includes('blanco')) {
            foundColors.push('#FFFFFF');
        }

        if (n.includes('red') || n.includes('roja') || n.includes('rojo') || n.includes('vermella')) {
            foundColors.push('#d32f2f');
        }
        if (n.includes('blue') || n.includes('azul') || n.includes('blau') || n.includes('blava')) {
            foundColors.push('#1976d2');
        }
        if (n.includes('yellow') || n.includes('amarill') || n.includes('grog')) {
            foundColors.push('#fbc02d');
        }
        if (n.includes('green') || n.includes('verde') || n.includes('verd')) {
            foundColors.push('#388e3c');
        }
        if (n.includes('brown') || n.includes('marron') || n.includes('marro')) {
            foundColors.push('#5d4037');
        }
        if (n.includes('orange') || n.includes('naranja') || n.includes('tronja') || n.includes('taronja')) {
            foundColors.push('#f57c00');
        }
        if (n.includes('grey') || n.includes('gris')) {
            foundColors.push('#616161');
        }
        if (n.includes('pink') || n.includes('rosa')) {
            foundColors.push('#c2185b');
        }
        if (n.includes('purple') || n.includes('lila')) {
            foundColors.push('#7b1fa2');
        }
        if (n.includes('turquoise') || n.includes('celeste') || n.includes('turquesa')) {
            // "bianchi color"
            foundColors.push('#3dcfc2');
        }

        if (foundColors.length === 0) {
            return 'black';
        }
        if (foundColors.length === 1) {
            return foundColors[0];
        }
        if (foundColors.length > 1) {
            // TODO: support more than 2 colors
            return `linear-gradient(90deg, ${foundColors[0]} 50%, ${foundColors[1]} 50%)`;
        }
    }

    static getPictureUrlByType(url: string, type: string): string {
        // console.log('getPictureUrlByType', url, type);
        if (url) {
            let p = url;
            p = p.replace('https://bikecrmstatic.s3.amazonaws.com/media/', environment.imageKitUrlEndpoint);
            p = p.replace('https://bikecrmstatic-beta.s3.amazonaws.com/media/', environment.imageKitUrlEndpoint);
            return p;
        }
        if (!type) {
            return '/assets/images/bike_types/unknown.svg';
        }
        if (type === 'Road') {
            return '/assets/images/bike_types/road.svg';
        }
        if (type === 'Cargo') {
            return '/assets/images/bike_types/cargo.svg';
        }
        if (type === 'Fatbike') {
            return '/assets/images/bike_types/fatbike.svg';
        }
        if (type === 'Folding') {
            return '/assets/images/bike_types/folding.svg';
        }
        if (type === 'MTB Hardtail') {
            return '/assets/images/bike_types/hardtail.svg';
        }
        if (type === 'MTB Downhill') {
            return '/assets/images/bike_types/downhill.svg';
        }
        if (type === 'Kick scooter' || type.toLowerCase().includes('kick scooter')) {
            return '/assets/images/bike_types/kick-scooter.svg';
        }
        if (type === 'Kids') {
            return '/assets/images/bike_types/kids.svg';
        }
        if (type === 'MTB Full suspension') {
            return '/assets/images/bike_types/mtb-full-suspension.svg';
        }

        if (type === 'MTB e-bike') {
            return '/assets/images/bike_types/mtb-ebike.svg';
        }

        if (type === 'Touring') {
            return '/assets/images/bike_types/touring.svg';
        }

        if (type === 'Triathlon/TT') {
            return '/assets/images/bike_types/tt.svg';
        }

        if (type === 'Gravel') {
            return '/assets/images/bike_types/gravel.svg';
        }

        // Defaults:
        if (type.includes('MTB')) {
            return '/assets/images/bike_types/hardtail.svg';
        }
        if (type.includes('Road')) {
            return '/assets/images/bike_types/road.svg';
        }

        if (type === 'Cyclocross') {
            return '/assets/images/bike_types/gravel.svg';
        }

        return '/assets/images/bike_types/unknown.svg';
    }
}
