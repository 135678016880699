<div class="p2">
  <mat-accordion>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'BUSINESS_CONFIG' | translate }}
        </mat-panel-title>
        <mat-panel-description>
<!--          This is a summary of the content-->
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-business-detail></app-business-detail>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="workshop">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'WORKSHOP_CONFIG' | translate }}
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="workshopForm" (ngSubmit)="onSubmit(workshop.id)">
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="40">
              <mat-label>{{ "ADDRESS" | translate }}</mat-label>
              <input matInput formControlName="address">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="40">
              <mat-label>{{ "CITY" | translate }}</mat-label>
              <input matInput formControlName="city">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="40">
              <mat-label>{{ "POSTAL_CODE" | translate }}</mat-label>
              <input matInput formControlName="postalCode">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="40">
              <mat-label>{{ "WEBSITE" | translate }}</mat-label>
              <input matInput formControlName="website" type="url">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="85">
              <mat-label>{{ "APPEND_BIKE_PICKUP_MESSAGE" | translate }}</mat-label>
              <input matInput #inputAppendBikePickupMessage formControlName="appendBikePickupMessage" maxlength="50">
              <mat-hint align="end">{{inputAppendBikePickupMessage?.value?.length || 0}}/50</mat-hint>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutGap="10px">

            <mat-form-field appearance="outline" fxFlex="85">
              <mat-label>{{ "APPEND_MESSAGE_TO_ALL_DOCUMENTS" | translate }}</mat-label>
              <textarea matInput
                        formControlName="appendMessageAllClientDocuments"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="85">
              <mat-label>{{ "APPEND_TEXT_TO_BUDGETS" | translate }}</mat-label>
              <textarea matInput
                        formControlName="appendTextBudgets"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="85">
              <mat-label>{{ "APPEND_TEXT_TO_TICKETS" | translate }}</mat-label>
              <textarea matInput
                        formControlName="appendTextTickets"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="85">
              <mat-label>{{ "APPEND_TEXT_TO_INVOICES" | translate }}</mat-label>
              <textarea matInput
                        formControlName="appendTextInvoices"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="85">
              <mat-label>{{ "APPEND_TEXT_TO_RECEIPTS" | translate }}</mat-label>
              <textarea matInput
                        formControlName="appendTextReceipts"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="85" *ngIf="business.rentalEnabled">
              <mat-label>{{ "APPEND_TEXT_TO_RENTALS" | translate }}</mat-label>
              <textarea matInput
                        formControlName="appendTextRentalTickets"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>

          </div>
          <!-- Duplicated button just to be more clear on the UI -->
          <div class="mt2">
            <button mat-raised-button type="submit" color="primary"
                    [disabled]="!workshopForm.valid">{{ "SAVE" | translate }}</button>
          </div>
        </div>

        <hr class="muted my2">

        <div formArrayName="bssTemplates" class="pt2">
          <h3>{{ 'PREDEFINED_TASKS' | translate }}</h3>

          <div *ngFor="let bssTemplateFormGroup of bssTemplates.controls; let i=index">
            <form [formGroup]="bssTemplateFormGroup">
              <mat-form-field appearance="outline" class="mr2">
                <mat-label>{{ "TASK_DESCRIPTION" | translate }}</mat-label>
                <input matInput type="text" formControlName="name">
              </mat-form-field>
              <mat-form-field appearance="outline" class="mx1">
                <mat-label>{{ "COST" | translate }}</mat-label>
                <input matInput type="number" formControlName="priceMaterials">
                <span matSuffix>{{ currencySymbol }}</span>
              </mat-form-field>
              <span>+</span>
              <mat-form-field appearance="outline" class="mx1">
                <mat-label>{{ "LABOR_COST" | translate }}</mat-label>
                <input matInput type="number" formControlName="laborCost">
                <span matSuffix>{{ currencySymbol }}</span>
              </mat-form-field>
              <button type="button" mat-button (click)="removeBssTemplate(i)">
                <mat-icon class="link-action">delete</mat-icon>
              </button>
            </form>
          </div>

          <div class="my2">
            <button mat-stroked-button (click)="addBssTemplate()">
              <mat-icon class="mr1">add_task</mat-icon>{{ 'ADD_PREDEFINED_TASK' | translate }}
            </button>
          </div>

        </div>
        <button mat-raised-button type="submit" color="primary"
                [disabled]="!workshopForm.valid">{{ "SAVE" | translate }}</button>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

</div>
