import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';
import {distinctUntilKeyChanged, map, retry, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {BikeCRMApiPaginated} from '../models/api';
import {ServiceSheetTask} from '../models/servicesheets';
import {UsersService} from './users.service';
import {BikeCRMApiAbstract} from './bikecrm-api-base';

@Injectable({
  providedIn: 'root'
})
export class ServiceSheetServiceTask extends BikeCRMApiAbstract {
  apiPath = 'servicesheet_task';

  constructor(private userService: UsersService,
              protected http: HttpClient) {
    super(http);
  }

  getServiceSheetsTasks(serviceSheetId: string): Observable<ServiceSheetTask[]> {
    const obs = this.http.get<BikeCRMApiPaginated<ServiceSheetTask>>(
      `${environment.apiUrl}/api/servicesheet_task/`,
      {params: {service_sheet__id: serviceSheetId}}
    );
    // for now, refreshing the tasks from a sheet is causing duplicates, as you create one task,
    //          we save it, and then we retrieve the same one from the server
    return timer(0, environment.refreshRateUltraLow)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
        map((r) => r.results),
        retry(3)
      );
  }

  getBikeTasks(bikeId: string): Observable<ServiceSheetTask[]> {
    const obs = this.http.get<BikeCRMApiPaginated<ServiceSheetTask>>(
      `${environment.apiUrl}/api/servicesheet_task/`,
      {params: {bike: bikeId}}
    );
    // for now, refreshing the tasks from a sheet is causing duplicates, as you create one task,
    //          we save it, and then we retrieve the same one from the server
    return timer(0, environment.refreshRateUltraLow)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
        map((r) => r.results),
        retry(3)
      );
  }

  notifyClientServiceSheets(serviceSheetId: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/servicesheet/${serviceSheetId}/notify_bike_ready/`, {})
      .pipe(
        map(r => {
          return r;
        }),
        retry(3));
  }

  completeTask(taskId: string, finished: boolean): Observable<ServiceSheetTask> {
    return this.modify(taskId, {
      finished: finished.toString(),
      completedBy: this.userService.selectedMechanic.id
    });
  }


  create(data: FormData): Observable<any> {
    data.set('openedBy', this.userService.selectedMechanic.id);
    return super.create(data);
  }

  addTask(obj: object | FormData): Observable<ServiceSheetTask> {
    return this.http.post(`${environment.apiUrl}/api/servicesheet_task/`, obj)
      .pipe(
        map(modifiedTask => {
          return modifiedTask as ServiceSheetTask;
        }),
        retry(3));
  }

}
