
export enum FabTypes {
  singleAction,
  multipleAction
}

export class FabAction {
  constructor(i18nKey: string, icon: string, id: string) {
    this.i18nKey = i18nKey;
    this.icon = icon;
    this.id = id;
  }

  i18nKey: string;
  icon: string;
  id: string;
}

export class Fab {
  constructor(i18nKey: string, icon: string, id: string, type: FabTypes, actions?: FabAction[]) {
    this.i18nKey = i18nKey;
    this.icon = icon;
    this.id = id;
    this.type = type;
    this.actions = actions;
  }

  i18nKey: string;
  icon: string;
  id: string;
  type: FabTypes;
  actions: FabAction[];
}

export interface IFab {
  primaryFab: Fab;
  secondaryFab: Fab;

  onFabAction(actionId: string): void;
}
