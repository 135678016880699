import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MobileDetectionService {
  // https://stackoverflow.com/questions/43159090/how-can-i-detect-service-variable-change-when-updated-from-another-component

  areWeOnMobile = false;
  areWeOnIos = false;
  areWeOnAndroid = false;

  constructor(private translate: TranslateService) {
    this.detectMobile();
  }

  detectMobile(): void {
    // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
    this.areWeOnMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    this.areWeOnIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    this.areWeOnAndroid = /Android/i.test(navigator.userAgent);
  }
}
