import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ServiceSheetTask} from 'src/app/models/servicesheets';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {ServiceSheetServiceTask} from '../../services/service-sheets-task.service';
import {
  ChildItemEditableListAbstractComponent,
  ChildItemFieldMetadata,
  ChildItemFieldTypes
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {Validators} from '@angular/forms';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MoneyService} from '../../services/money.service';
import {UsersService} from '../../services/users.service';
import {CurrencyPipe} from '@angular/common';
import {WorkshopService} from '../../services/workshop.service';


// TODO: use the abstract html from ChildItemEditableListAbstractComponent
@Component({
  selector: 'app-bss-task-list',
  templateUrl: './bss-task-list.component.html',
  styleUrls: ['./bss-task-list.component.scss'],
})
export class BssTaskListComponent extends ChildItemEditableListAbstractComponent<ServiceSheetTask> implements OnInit {
  parentApiRelName = 'serviceSheet';
  parentApiContentTypeName = 'bikeservicesheet';

  addItemLiteralI18n = 'ADD_TASK';

  @Output() itemsUpdated = new EventEmitter();
  @Input() showFooter = true;
  @Input() parentType = 'serviceSheet';
  @Input() showVat = false;

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  dataSource = new MatTableDataSource();

  predefinedTasks: object[] = [];

  // Format: {id: string, value: string}[]
  predefinedTasksFiltered: object[] = [];

  // TODO: check that at least one is marked as mainInput (on the abstract class)
  fields = {
    taskDescription: {
      // tslint:disable-next-line:max-line-length
      metadata: new ChildItemFieldMetadata('TASK_DESCRIPTION', 'ADD_DESCRIPTION', ChildItemFieldTypes.text, '---', true, [Validators.required], false),
      mainInput: false
    },
    discountPercent: {
      metadata: new ChildItemFieldMetadata('DSC_PERCENT', 'ADD_DISCOUNT', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false
    },
    // TODO: remove default vat and test it, use the vat from the business
    vatOther: {
      metadata: new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.calculated, 0.21, false, null, false, true),
      mainInput: false
    },
    qty: {
      metadata: new ChildItemFieldMetadata('QUANTITY', 'ADD_QUANTITY', ChildItemFieldTypes.calculated, 1, true, null, false),
      mainInput: false
    },
    costOtherRecommended: {
      metadata: new ChildItemFieldMetadata('COST', 'ADD_COST', ChildItemFieldTypes.currency, 0, false, null, false, true, false),
      mainInput: false
    },
    // TODO: rename to taskStatus
    task_status: {
      metadata: new ChildItemFieldMetadata('TASK_STATUS', '', ChildItemFieldTypes.boolean, false, true, null, false),
      mainInput: false
    },
    costOther: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, true, null, false, true),
      mainInput: false
    },
    costOtherWithVat: {
      metadata: new ChildItemFieldMetadata('COST', 'ADD_COST', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false
    },
    more_opts: {
      metadata: new ChildItemFieldMetadata('MORE_OPTIONS', '', ChildItemFieldTypes.action, false, true, null, false),
      mainInput: false
    },
    costHours: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, false, null, false, true),
      mainInput: false
    },
    costHoursWithVat: {
      metadata: new ChildItemFieldMetadata('LABOR_COST', 'ADD_LABOR_COST', ChildItemFieldTypes.calculated, 0, false, null, true),
      mainInput: false
    },
    // TODO: remove default vat and test it, use the vat from the business
    vatHours: {
      metadata: new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.percent, 0.21, false, null, false, true),
      mainInput: false
    },
    workNotes: {
      metadata: new ChildItemFieldMetadata('PUBLIC_NOTES', 'ADD_PUBLIC_NOTES', ChildItemFieldTypes.text, '', false, null, true),
      mainInput: false
    },
    privateNotes: {
      metadata: new ChildItemFieldMetadata('PRIVATE_NOTES', 'ADD_PRIVATE_NOTES', ChildItemFieldTypes.text, '', false, null, true),
      mainInput: false
    },
  };

  serviceSheetTasks$: Observable<ServiceSheetTask[]>;

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    notificationService: CrmNotificationsService,
    private serviceSheetServiceTask: ServiceSheetServiceTask,
    protected nativeInterfacesService: NativeInterfacesService,
    protected moneyService: MoneyService,
    protected usersService: UsersService,
    private workshopService: WorkshopService

  ) {
    super(dialog, translate, notificationService, serviceSheetServiceTask, nativeInterfacesService, usersService);
  }

  ngOnInit(): void {
    const defaultVat = Number(this.usersService.business.defaultVat);

    // tslint:disable-next-line:max-line-length
    this.fields.vatOther.metadata = new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.percent, defaultVat, false, null, false, true);
    // tslint:disable-next-line:max-line-length
    this.fields.vatHours.metadata = new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.percent, defaultVat, false, null, false, true);

    if (this.mode === 'viewList') {
      delete this.fields.more_opts;
      // add date:
      // tslint:disable-next-line
      this.fields['createdAt'] = {
        metadata: new ChildItemFieldMetadata('DATE', '', ChildItemFieldTypes.date, '', true, null, false),
      };
    }

    super.ngOnInit();

    // TODO: can we move this to abstract-item-list?
    if (this.parentType === 'serviceSheet') {
      this.serviceSheetTasks$ = this.serviceSheetServiceTask.getServiceSheetsTasks(this.parent.id);
    } else {
      this.serviceSheetTasks$ = this.serviceSheetServiceTask.getBikeTasks(this.parent.id);
    }
    this.serviceSheetTasks$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.items = x;
      this.dataSource.data = this.items;
      this.itemsUpdated.emit();
    });

    this.showVatFields(this.showVat);

    const defaultWorkshop$ = this.workshopService.getFirstWorkshop();
    defaultWorkshop$.pipe(takeUntil(this.onDestroy$)).subscribe(workshop => {
      this.predefinedTasks = workshop.extraData ? workshop.extraData.servicesheetTemplates : [];
    });
  }

  isAnyTaskCompleted(): boolean {
    return this.items && this.items.some(t => t.finished);
  }

  areAllTasksCompleted(): boolean {
    return this.items && this.items.every(t => t.finished);
  }

  closeAllTasks(): void {
    // TODO: Maybe optimize this to close all the tasks on the server? maybe clossing the service sheet? and doing it on .save()?
    //  and also this way we will not trigger all the toasts for every task
    this.items.forEach(t => {
      if (!t.finished) {
        this.completeTask(t, true);
      }
    });
  }

  showVatFields(show = true): void {
    this.fields.vatOther.metadata.showAsColumn = show;
    this.fields.vatOther.metadata.hidden = !show;
  }

  createNewItemInstance(): ServiceSheetTask {
    const item = new ServiceSheetTask();
    item.id = this._getID(item);
    return item;
  }

  getTotalCost(): number {
    if (this.items != null) {
      // const totalsOthers = this.items.map(t => t.costOther ? Number(t.costOther) : 0).reduce((acc, value) => acc + value, 0);
      // const totalsLabor = this.items.map(t => t.costHours ? Number(t.costHours) : 0).reduce((acc, value) => acc + value, 0);
      // return totalsOthers + totalsLabor;
      return this.items.reduce((sum, current) => sum + (ServiceSheetTask.totalCost(current) || 0), 0);
    }
    return 0;
  }

  isKeyboardBarCodeScannerEnabled(): boolean {
    return false;
  }

  completeTask(task: ServiceSheetTask, completed: boolean): void {
    this.serviceSheetServiceTask.completeTask(task.id, completed).pipe(takeUntil(this.onDestroy$)).subscribe(
      data => {
        this._updateItems(data);
      },
      async err => {
        this.notificationService.warning(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.PROBLEM_SAVING_TASK_STATE').toPromise()
        );
      },
      async () => {
        if (completed) {
          this.notificationService.success(task.taskDescription,
            await this.translate.get('TASK_COMPLETED').toPromise());
        } else {
          this.notificationService.info(task.taskDescription,
            await this.translate.get('TASK_REOPENED').toPromise());
        }
      }
    );
  }

  getInputTypeCalculated(field: string): string {
    if (field === 'costOtherWithVat') {
      return 'simple_html';
    }
    if (field === 'costHoursWithVat') {
      return 'currency';
    }
    if (field === 'discountPercent') {
      return 'percent';
    }
    if (field === 'vatOther') {
      return 'percent';
    }
    if (field === 'qty') {
      return 'number';
    }
    throw Error(`getInputTypeCalculated field: ${field} not found`);
  }

  getCalculatedField(task: ServiceSheetTask, field: string): any {
    if (field === 'costHoursWithVat') {
      return +this.moneyService.addVat(task.costHours, task.vatHours, 2) * task.qty;
    }
    if (field === 'costOtherWithVat') {
      return +this.moneyService.addVat(task.costOther, task.vatOther, 2) * task.qty;
    }
    if (field === 'discountPercent') {
      return task.discountPercent;
    }
    if (field === 'vatOther') {
      return this.moneyService.basicPointsToPercent(task.vatOther);
    }
    if (field === 'qty') {
      return parseFloat(task.qty.toString());
    }
    throw Error(`getCalculatedField field: ${field} not found`);
  }

  getCalculatedFieldDisplay(task: ServiceSheetTask, field: string): any {
    if (field === 'costOtherWithVat') {
      // return this.moneyService.addVat(task.price, task.vat, 2);
      const price = this.moneyService.addVat(task.costOther, task.vatOther, 2) * task.qty;
      let priceLiteral = price.toFixed(2);
      priceLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceLiteral, this.currencyCode, 'symbol-narrow');
      if (task.costOtherRecommended && task.costOtherRecommended !== 0 && task.costOtherRecommended !== task.costOther) {
        const priceRecommended = this.moneyService.addVat(task.costOtherRecommended, task.vatOther, 2) * task.qty;
        let priceRecommendedLiteral = priceRecommended.toFixed(2);
        priceRecommendedLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceRecommendedLiteral, this.currencyCode, 'symbol-narrow');
        return `<div style="display: flex; flex-direction: column"><small style="text-decoration: line-through">${priceRecommendedLiteral}</small><span style="margin-left: 2px">${priceLiteral}</span></div>`;
      }
      return `<span class="price">${priceLiteral}</span>`;
    }
    return this.getCalculatedField(task, field);
  }

  setCalculatedField(task: ServiceSheetTask, modifiedText: string, field: string): void {
    modifiedText = this.cleanInputToString(modifiedText, true);
    const inputAsNumber = Number(modifiedText);
    if (field === 'costHoursWithVat') {
      let costHours = this.moneyService.removeVat(inputAsNumber, task.vatHours) / task.qty;
      costHours = +costHours.toFixed(10);
      task.costHours = costHours;
      this.saveItem(task);
      return;
    }
    if (field === 'costOtherWithVat') {
      let costOther = this.moneyService.removeVat(inputAsNumber, task.vatOther) / task.qty;
      costOther = +costOther.toFixed(10);
      task.costOther = costOther;
      this.saveItem(task);
      return;
    }
    if (field === 'discountPercent') {
      if (!task.costOtherRecommended || task.costOtherRecommended === 0) {
        task.costOtherRecommended = task.costOther;
      }
      let finalPrice = task.costOtherRecommended - (task.costOtherRecommended * this.moneyService.percentToBasicPoints(inputAsNumber));
      finalPrice = +finalPrice.toFixed(10);
      task.costOther = finalPrice;
      this.saveItem(task);
      return;
    }
    if (field === 'vatOther') {
      task.vatOther = this.moneyService.percentToBasicPoints(inputAsNumber);
      this.saveItem(task);
      return;
    }
    if (field === 'qty') {
      task.qty = inputAsNumber;
      this.saveItem(task);
      return;
    }
    throw Error(`setCalculatedField field: ${field} not found`);
  }

  isOptionalFieldFilled(task: ServiceSheetTask, field: string): boolean {
    if (field === 'costHoursWithVat' && task.costHours) {
      return true;
    }
    return super.isOptionalFieldFilled(task, field);
  }

  onDescriptionChange(task: ServiceSheetTask, event: string): void {
    if (this.predefinedTasks.length === 0) {
      return;
    }
    // TODO: set an real ID for the tasks, and not the name, to avoid problems with duplicated names
    // TODO: use fuse.js and move search to API when there are too many tasks
    // tslint:disable-next-line
    const filteredTasks = this.predefinedTasks.filter(option => option['name'].toLowerCase().includes(event.toLowerCase()));
    this.predefinedTasksFiltered = filteredTasks.map(option => {
      // tslint:disable-next-line
      return {id: option['name'], value: option['name']};
    });
  }

  onAutocompleteOptionSelected(task: ServiceSheetTask, event: string): void {
    // tslint:disable-next-line
    const selectedTask = this.predefinedTasks.find(option => option['name'] === event);
    if (selectedTask) {
      // tslint:disable-next-line
      const inputCost = selectedTask['priceMaterials'] || 0;
      const costOther = this.moneyService.removeVat(inputCost, task.vatOther) / task.qty;
      task.costOther = +costOther.toFixed(10);

      // tslint:disable-next-line
      const inputCostHours = selectedTask['laborCost'] || 0;
      const costHours = this.moneyService.removeVat(inputCostHours, task.vatHours) / task.qty;
      // tslint:disable-next-line
      task.costHours = +costHours.toFixed(10);
      // tslint:disable-next-line
      task.taskDescription = selectedTask['name'];
    }
  }
}
