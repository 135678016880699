import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BikeCRMApiAbstract} from './bikecrm-api-base';
import {UsersService} from './users.service';

@Injectable({
  providedIn: 'root'
})
export class PosSupplierOrdersService extends BikeCRMApiAbstract {
  apiPath = 'pos_supplier_order';

  constructor(
    private userService: UsersService,
    protected http: HttpClient
  ) {
    super(http);
  }

}
