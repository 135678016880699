import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  FilterOption,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {PAYMENT_METHOD_I18N_KEY} from '../../models/billable_item';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {Rental} from '../../models/rental';
import {RentalsService} from '../../services/rentals.service';
import {Observable} from 'rxjs';
import {User} from '../../models/user';
import {ClientsListComponent} from '../clients-list/clients-list.component';


@Component({
  selector: 'app-rentals-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class RentalsListComponent extends ItemSimpleFiltrableListAbstractComponent<Rental>
  implements OnInit, OnDestroy, AfterViewInit {
  // TODO: rename to sales, not orders
  // TODO: implement filters like, payment status
  // TODO: be able to pass a user, and get all his orders

  pageTitleI18N = 'RENTALS';

  urlBaseDetailItem = 'rentals';

  displayedColumnsMetaData = {
    clientName: new DisplayedColumnMetaData('CLIENT', DisplayedColumnTypes.text),
    startDatetime: new DisplayedColumnMetaData('RENTAL_START_DATE', DisplayedColumnTypes.date_time),
    endDatetime: new DisplayedColumnMetaData('RENTAL_END_DATE', DisplayedColumnTypes.date_time),
    status: new DisplayedColumnMetaData('STATUS', DisplayedColumnTypes.templated),
  };

  filtersMetaData = {
    // openedBy: new FilterMetaData('OPENED_BY', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')]),
    // paymentMethod: new FilterMetaData(
    //   'PAYMENTS.PAYMENT_METHOD',
    //   FilterTypes.select_single,
    //   [
    //     new FilterOption('default_null_filter', 'PAYMENTS.ALL')
    //   ]),
    // paymentStatus: new FilterMetaData(
    //   'PAYMENTS.PAYMENT_STATUS',
    //   FilterTypes.select_single,
    //   [
    //     new FilterOption('default_null_filter', 'PAYMENTS.ALL'),
    //     new FilterOption(PaymentStatus.Paid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Paid]),
    //     new FilterOption(PaymentStatus.PendingAndPartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
    //     // new FilterOption(PaymentStatus.Pending, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Pending]),
    //     // new FilterOption(PaymentStatus.PartiallyPaid, PAYMENT_STATUS_I18N_KEY[PaymentStatus.PartiallyPaid]),
    //     new FilterOption(PaymentStatus.Unknown, PAYMENT_STATUS_I18N_KEY[PaymentStatus.Unknown]),
    //     new FilterOption(PaymentStatus.AdvancePayment, PAYMENT_STATUS_I18N_KEY[PaymentStatus.AdvancePayment]),
    //   ])
  };

  primaryFab = new Fab('NEW_RENTAL', 'add', 'fab_add_new_rental', FabTypes.singleAction);
  secondaryFab = null;

  constructor(
    @Optional() public dialogRef: MatDialogRef<RentalsListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    private userService: UsersService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected rentalsService: RentalsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      rentalsService,
      nativeInterfacesService,
      dialog,
      media$,
      userService,
      privacyModeService
    );

    // TODO: move to abstract class
    const enabledPaymentMethods = this.userService.getCustomConfig().get_payment_methods();
    const filterOptionsPaymentMethod = [];
    filterOptionsPaymentMethod.push(new FilterOption('default_null_filter', 'PAYMENTS.ALL'));
    for (const paymentMethod of enabledPaymentMethods) {
      filterOptionsPaymentMethod.push(new FilterOption(paymentMethod, PAYMENT_METHOD_I18N_KEY[paymentMethod]));
    }

    // this.filtersMetaData.paymentMethod = new FilterMetaData(
    //   'PAYMENTS.PAYMENT_METHOD',
    //   FilterTypes.select_single,
    //   filterOptionsPaymentMethod);
  }

  ngOnInit(): void {
    if (this.userService.userMe && this.userService.userMe.workshop) {
      // TODO: this shluld not happen, we should wait for the user to be loaded (maybe on a lodcing screen?)
      // tslint:disable-next-line
      this.defaultFilters['workshop_id'] = this.userService.userMe.workshop;
    }
    super.ngOnInit();

    // TODO: remove, if parentId is not null, just create a ticket for that user
    if (this.parentId) {
      this.primaryFab = null;
    }

    if (this.dialogRef != null) {
      this.dialogMode = true;
      if (this.data.mode === 'floating-selector') {
      }
    } else {
    }

    if (this.parentId && this.parentApiFilter === 'client__id') {
      // We are inside a bike service sheet list, we can add a new service sheet to this bike
      this.addButtonI18N = 'NEW_SALE';
    }

    // this.populateOpenedByFilter();
  }

  // configureColumns(): void {
  //   this.displayedColumnsCustomConfig = this.userService.getCustomConfig().get_shop_ticket_list_columns();
  //   super.configureColumns();
  // }

  // populateOpenedByFilter(): void {
  //   this.userService.getStaff().subscribe(staffUsers => {
  //     const filterOptions = [new FilterOption('', 'All')];
  //     for (const staffUser of staffUsers) {
  //       filterOptions.push(new FilterOption(staffUser.id, staffUser.name));
  //     }
  //     this.filtersMetaData.openedBy = new FilterMetaData('OPENED_BY', FilterTypes.select_single, filterOptions);
  //   });
  // }

  async onFabAction(actionId: string): Promise<boolean> {
    if (actionId === 'fab_add_new_rental') {
      const selectedUser = await this.openClientSelector().toPromise();
      if (!selectedUser) {
        return false;
      } else {
        this.router.navigate(['/rentals/create', {clientId: selectedUser.id}]);
        return true;
      }
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  openClientSelector(): Observable<User> {
    const dialogRef = this.dialog.open(ClientsListComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'client-selector'
      }
    });

    return dialogRef.afterClosed();
  }

  getFieldTemplatedContext(item: Rental, field): any {
    if (field === 'status') {
      if (item.status === 'draft') {
        return {value: 'DRAFT', customClass: 'chip-custom-gray'};
      }
      if (item.status === 'reserved') {
        return {value: 'RESERVED', customClass: 'chip-custom-yellow'};
      }
      if (item.status === 'rented') {
        return {value: 'RENTED', customClass: 'chip-custom-yellow'};
      }
      if (item.status === 'returned') {
        return {value: 'RETURNED', customClass: 'chip-custom-green'};
      }
      if (item.status === 'cancelled') {
        return {value: 'CANCELLED', customClass: 'chip-custom-red'};
      }
    }
    return super.getFieldTemplatedContext(item, field);
  }
}
