import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  DetailField,
  DetailFieldTypes,
  ItemDetailAbstractComponent
} from '../../components/abstract-item-detail/abstract-item-detail.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {PosBrandService} from '../../services/pos-brand.service';
import {PosBrand} from '../../models/pos_brand';


@Component({
  selector: 'app-pos-brand-detail',
  templateUrl: '../../components/abstract-item-detail/abstract-item-detail.component.html',
  styleUrls: ['../../components/abstract-item-detail/abstract-item-detail.component.scss']
})
export class PosBrandsDetailComponent extends ItemDetailAbstractComponent<PosBrand> implements OnInit {
  // TODO: enable fully barcode scanner if fully is present
  // TODO: check how we can read barcodes on web without fully

  navBase = '/pos/brands/';

  editableFields = {
    name: new DetailField('NAME', DetailFieldTypes.text, '', ['start', 0, 75], [Validators.required]),
    logo: new DetailField('PICTURE', DetailFieldTypes.picture, '', ['start', 5, 25])
  };

  constructor(
    @Optional() public dialogRef: MatDialogRef<PosBrandsDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { itemId: string, extraMessageI18nKey: string, code: string },
    protected router: Router,
    protected route: ActivatedRoute,
    protected notificationService: CrmNotificationsService,
    protected translate: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected nativeInterfacesService: NativeInterfacesService,
    protected posBrandService: PosBrandService,
    protected usersService: UsersService
  ) {
    super(dialogRef,
      data,
      router,
      route,
      notificationService,
      translate,
      formBuilder,
      dialog,
      posBrandService,
      nativeInterfacesService,
      usersService);

    // https://stackoverflow.com/questions/38971660/angular-2-reload-route-on-param-change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

}
