import {AfterViewInit, Component, OnInit, Optional, ViewChild} from '@angular/core';
import {BarcodeFormat} from '@zxing/library';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {MatDialogRef} from '@angular/material/dialog';


// TODO: use some abstract component to reduce code? just some detail that we only use 2 fields (bss)
@Component({
    selector: 'app-bar-code-scanner',
    templateUrl: './barcode-scanner.component.html',
    styleUrls: ['./barcode-scanner.scss'],
})
export class BarCodeScannerComponent implements OnInit, AfterViewInit {
    @ViewChild('scanner', {static: false}) scanner: ZXingScannerComponent;

    allowedFormats = [
        BarcodeFormat.QR_CODE,
        BarcodeFormat.EAN_13,
        BarcodeFormat.CODE_128,
        BarcodeFormat.DATA_MATRIX,
        BarcodeFormat.UPC_A
    ];

    constructor(@Optional() public dialogRef: MatDialogRef<BarCodeScannerComponent>) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.scanner.formats = this.allowedFormats;
    }

    scanSuccessHandler(resultString: string): void {
        console.log(`onSuccessfulScan: ${resultString}`);
        this.dialogRef.close(resultString);
    }

}
