import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {distinctUntilChanged, distinctUntilKeyChanged, map, retry, switchMap, tap} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {User, UserToken} from '../models/user';
import {Business} from '../models/business';
import {BikeCRMApiPaginated} from '../models/api';
import * as Sentry from '@sentry/angular';
import {BikeCRMApiAbstract} from './bikecrm-api-base';
// import {ClientsDetailComponent} from '../pages/clients-detail/clients-detail.component';
import {MatDialog} from '@angular/material/dialog';
import {CustomConfig} from '../models/custom_config';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BikeCRMApiAbstract {
  apiPath = 'users';

  private userTokenSubject: BehaviorSubject<UserToken>;
  public userToken$: Observable<UserToken>;

  private userMeSubject: BehaviorSubject<User>;
  public userMe$: Observable<User>;

  private businessSubject: BehaviorSubject<Business>;
  public business$: Observable<Business>;

  // TODO: this can benefit from ngrs/ngxs:
  private selectedMechanicSubject: BehaviorSubject<User>;
  public selectedMechanic$: Observable<User>;

  constructor(
    // TODO: remove router from here?
    private router: Router,
    protected http: HttpClient,
    protected dialog: MatDialog
  ) {
    super(http);
    this.userTokenSubject = new BehaviorSubject<UserToken>(JSON.parse(localStorage.getItem('user_token')));
    this.userToken$ = this.userTokenSubject.asObservable();

    this.userMeSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user_me')));
    this.userMe$ = this.userMeSubject.asObservable();

    this.businessSubject = new BehaviorSubject<Business>(JSON.parse(localStorage.getItem('business')));
    this.business$ = this.businessSubject.asObservable();

    this.selectedMechanicSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('selected_mechanic')));
    this.selectedMechanic$ = this.selectedMechanicSubject.asObservable();
  }

  public get selectedMechanic(): User {
    return this.selectedMechanicSubject.value;
  }

  public set selectedMechanic(mech: User) {
    localStorage.setItem('selected_mechanic', JSON.stringify(mech));
    this.selectedMechanicSubject.next(mech);
  }

  public get business(): Business {
    return this.businessSubject.value;
  }

  public get authorizationToken(): string {
    return this.userTokenSubject.value.token;
  }

  public get userTokenValue(): UserToken {
    return this.userTokenSubject.value;
  }

  public get userMe(): User {
    return this.userMeSubject.value;
  }

  public get isLogged(): boolean {
    return this.userTokenSubject.value != null;
  }

  login(username: string, password: string): Observable<UserToken> {
    return this.http.post<UserToken>(`${environment.apiUrl}/api/auth-token/`, { username, password })
      .pipe(map(userToken => {
        localStorage.setItem('user_token', JSON.stringify(userToken));
        this.userTokenSubject.next(userToken);
        this.updateUserAndBusinessInfo();
        return userToken;
      }));
  }

  async updateUserAndBusinessInfo(): Promise<void> {
    this._getMe().toPromise();
    this._getBusiness().toPromise();
  }

  logout(): void {
    const businessSlug = this.business.slug;

    localStorage.removeItem('user_token');
    localStorage.removeItem('user_me');
    localStorage.removeItem('business');
    localStorage.removeItem('selected_mechanic');
    // TODO: don't remove all clear storage, for example if the user set remember business slug on the login page
    localStorage.clear();

    this.userTokenSubject.next(null);
    this.userMeSubject.next(null);
    this.businessSubject.next(null);
    this.selectedMechanicSubject.next(null);

    this.router.navigate([`/login`]);

    Sentry.configureScope(scope => scope.setUser(null));
  }

  // getClients(s = ''): Observable<User[]> {
  //   // TODO: add search paramn only if len s > 1 and not null
  //   const obs = this.http.get<BikeCRMApiPaginated<User>>(`${environment.apiUrl}/api/${this.apiPath}/`, {
  //     params: {
  //       is_business_client: 'true',
  //       is_business_owner: 'false',
  //       is_business_employee: 'false',
  //       search: s,
  //     }
  //   });
  //   return timer(0, environment.refreshRateLow)
  //     .pipe(timeout(15 * 1000)) // Timeout for all the next pipe
  //     .pipe(
  //       distinctUntilChanged(),
  //       switchMap(() => obs),
  //       distinctUntilKeyChanged('count'), // TODO: compare agains last modified ts (add this field on API)
  //       map((r) => r.results),
  //       // retryWhen(errors => errors.pipe(delay(1500), take(6)))
  //       retryWhen(errors => errors.pipe(delay(1500),
  //         concatMap((e, index) => index === 6 ? throwError(e) : of(null)),
  //       ))
  //     );
  // }

  // getSuppliers(orderBy= '-created_at'): Observable<User[]> {
  getSuppliers(): Observable<User[]> {
    const obs = this.http.get<BikeCRMApiPaginated<User>>(`${environment.apiUrl}/api/${this.apiPath}/`, {
      params: {
        is_business_client: 'false',
        is_business_employee: 'false',
        is_business_supplier: 'true',
        // ordering: orderBy
      }
    });
    return timer(0, environment.refreshRateLow)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'),
        map((r) => r.results),
        retry(3)
      );
  }

  getStaff(): Observable<User[]> {
    const obs = this.http.get<BikeCRMApiPaginated<User>>(`${environment.apiUrl}/api/${this.apiPath}/`, {
      params: {
        is_business_client: 'false',
        is_business_employee: 'true',
        is_business_supplier: 'false',
      }
    });
    return timer(0, environment.refreshRateLow)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'),
        map((r) => r.results),
        retry(3)
      );
  }

  _getBusiness(): Observable<Business> {
    return this.http.get<Business>(`${environment.apiUrl}/api/business/my/`)
      .pipe(
        map(business => {
          localStorage.setItem('business', JSON.stringify(business));
          this.businessSubject.next(business);
          return business;
        }),
        retry(3)
      );
  }

  private _getMe(): Observable<User> {
    const obs = this.http.get<User>(`${environment.apiUrl}/api/${this.apiPath}/me/`);
    return timer(0, environment.refreshRateLow)
      .pipe(
        distinctUntilChanged(),
        switchMap(() => obs),
        // distinctUntilKeyChanged('count'),
        map(userMe => {
          localStorage.setItem('user_me', JSON.stringify(userMe));
          this.userMeSubject.next(userMe);
          if (this.selectedMechanic == null) {
            this.selectedMechanic = userMe;
          }

          Sentry.setUser({
            email: userMe.email,
            username: userMe.username,
            userId: userMe.id,
            isBusinessOwner: userMe.isBusinessOwner,
            isBusinessEmployee: userMe.isBusinessEmployee,
            isBusinessClient: userMe.isBusinessClient,
            businessId: userMe.business,
            hasAccessToBusinessData: userMe.hasAccessToBusinessData
          });

          return userMe as User;
        }),
        retry(3)
      );
  }

  modifyBusinessByID(id: string, formData: FormData): Observable<Business> {
    return this.http.patch(`${environment.apiUrl}/api/business/${id}/`, formData)
      .pipe(
        map(modifiedBusiness => {
          return modifiedBusiness as Business;
        }),
        tap(b => {
          if (b.id === this.business.id) {
            this.businessSubject.next(b);
          }
        }),
        retry(3)
      );
  }

  confirmInvoiceInformation(client: User, clientDetailComponent): Observable<User> {
    const dialogRef = this.dialog.open(clientDetailComponent, {
      maxHeight: '90vh',
      width: '90%',
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'client-detail-edit',
        itemId: client.id,
        extraMessageI18nKey: 'PLEASE_CONFIRM_INVOICE_CLIENT_DATA'
      }
    });

    return dialogRef.afterClosed();
  }

  getCustomConfig(): CustomConfig {
    // TODO: Turn this to a promise or subject or signal? and when we get userMe, we can send a new cf
    const cf = new CustomConfig();
    if (this.userMe == null) {
      return cf;
    }
    cf.apiObject = this.userMe.customConfig;
    return cf;
  }

  changePassword(oldPassword: string, newPassword1: string, newPassword2: string): Observable<UserToken> {
    return this.http.patch<UserToken>(`${environment.apiUrl}/api/user_change_password/`, {
      old_password: oldPassword,
      new_password1: newPassword1,
      new_password2: newPassword2
    }).pipe(
      map(userToken => {
        localStorage.setItem('user_token', JSON.stringify(userToken));
        this.userTokenSubject.next(userToken);
        return userToken;
      })
    );
  }

}
