import {environment} from '../../environments/environment';
import {
  concatMap,
  delay,
  distinctUntilKeyChanged,
  map,
  publishLast,
  refCount,
  retry,
  retryWhen,
  switchMap,
  timeout
} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError, timer} from 'rxjs';
import {BikeCRMApiPaginated} from '../models/api';

export interface BikeCRMApiInterface {
  apiPath: string;

  get(itemId: string): Observable<any>;
  create(data: FormData): Observable<any>;

  modify(itemId: string, obj: object | FormData): Observable<any>;
  delete(itemId: string | FormData): Observable<any>;
}


export class GetOrCreateResponse<T> {
  item: T;
  created: boolean;
}

export abstract class BikeCRMApiAbstract implements BikeCRMApiInterface {
  // TODO: review all apis with retries and delays
  // https://stackoverflow.com/questions/44979131/rxjs-retry-with-delay-function
  abstract apiPath;

  protected constructor(
    protected http: HttpClient
  ) { }

  get(itemId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/${this.apiPath}/${itemId}/`).pipe(
      retryWhen(errors => errors.pipe(delay(1500),
        concatMap((e, index) => index === 3 ? throwError(e) : of(null)),
      )),
      publishLast(),
      refCount()
    );
  }

  create(data: object | FormData): Observable<any> {
    // TODO: use this (index === 6 || e.status === 400) on more calls, to avoid taking to long to just show validation errors or similar
    return this.http.post<any>(`${environment.apiUrl}/api/${this.apiPath}/`, data)
      .pipe(
        map((r) => r),
        retryWhen(errors => errors.pipe(delay(1500),
          concatMap((e, index) => (index === 6 || e.status === 400) ? throwError(e) : of(null)),
        ))
      );
  }

  modify(itemId: string, obj: object | FormData): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/api/${this.apiPath}/${itemId}/`, obj)
      .pipe(
        map((r) => r),
        retryWhen(errors => errors.pipe(delay(1500),
          concatMap((e, index) => (index === 6 || e.status === 400) ? throwError(e) : of(null)),
        ))
      );
  }

  delete(itemId: string): Observable<any> {
    // TODO: retry with delays
    return this.http.delete(`${environment.apiUrl}/api/${this.apiPath}/${itemId}/`)
      .pipe(
        map((r) => r),
        retryWhen(errors => errors.pipe(delay(1500),
          concatMap((error, index) => {
            // TODO: we should make this retry only if server error for all parts of the app
            //  if the server is failing, blocked, updating, etc, we should retry, if the server returns a 4xx, we
            //  should not retry
            // If it's not a 5xx error or if we've retried 6 times, throw the error
            if (!error.status || error.status < 500 || index >= 6) {
              return throwError(error);
            }
            // Add a delay of 1500ms before the next retry
            return of(error).pipe(delay(1500));
          }),
        ))
      );
  }

  getList(filters: {[key: string]: (string | boolean | number)}, s = '', autoRefresh = true): Observable<any[]> {
    if (filters == null) {
      filters = {};
    }
    return this.getPaginatedList(filters, s, autoRefresh).pipe(
      map((r) => r.results)
    );
  }

  getGenericChilds(orderId: string, contentType: string): Observable<any> {
    const obs = this.http.get<BikeCRMApiPaginated<any>>(
      `${environment.apiUrl}/api/${this.apiPath}/`,
      {params: {object_id: orderId, content_type: contentType}}
    );
    // for now, refreshing the item from an order is causing duplicates, as you create one item,
    //          we save it, and then we retrieve the same one from the server
    return timer(0, environment.refreshRateUltraLow)
      .pipe(
        switchMap(() => obs),
        distinctUntilKeyChanged('count'), // TODO: improve with some custom field, like last ts of changed data
        map((r) => r.results),
        retry(3)
      );
  }

  getPaginatedList(filters: {[key: string]: (string | boolean | number | string[] )}, s = '', autoRefresh = true, page = 1, c = 200, searchParam = 'search', searchField = null): Observable<BikeCRMApiPaginated<any[]>> {
    // TODO: add search param only if len s > 1 and not null
    // TODO: remove search param we put it on filters dictionary
    const p = filters;
    p[searchParam] = s;
    if (searchField) {
      p[searchField + '_only'] = 'true';
    }
    // tslint:disable-next-line:no-string-literal
    p['page'] = page.toString();
    // tslint:disable-next-line:no-string-literal
    p['limit'] = c.toString();
    const obs = this.http.get<BikeCRMApiPaginated<any>>(`${environment.apiUrl}/api/${this.apiPath}/`, {
      params: p
    });

    // This RxJS pipe is the gold standard of the project :)
    const refreshRate = autoRefresh ? environment.refreshRateLow : null;
    return timer(0, refreshRate)
      .pipe(
        timeout(15 * 1000),
        switchMap(() => obs),
        distinctUntilKeyChanged('count'), // TODO: compare against last modified ts (add this field on API)
        retryWhen(errors => errors.pipe(delay(1500),
          concatMap((e, index) => index === 6 ? throwError(e) : of(null)),
        ))
      );
  }
}
