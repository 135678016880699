import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OrderItem} from 'src/app/models/pos_order';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {PosOrdersItemService} from '../../services/pos-orders-item.service';
import {
  ChildItemEditableListAbstractComponent,
  ChildItemFieldMetadata,
  ChildItemFieldTypes,
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {Validators} from '@angular/forms';
import {PosProductsListComponent} from '../../pages/pos-products-list/pos-products-list.component';
import {Product} from '../../models/pos_product';
import {ProgressDialogComponent} from '../dialogs/progress-dialog-component/progress-dialog.component';
import {PosProductsDetailComponent} from '../../pages/pos-products-detail/pos-products-detail.component';
import {SimpleTextDialogComponent} from '../dialogs/simple-text-dialog-component/simple-text-dialog.component';
import {PosProductService} from '../../services/pos-products.service';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MoneyService} from '../../services/money.service';
import {UsersService} from '../../services/users.service';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-pos-order-item-list',
  templateUrl: '../abstract-child-editable-item-list/abstract-child-editable-item-list.component.html',
  styleUrls: ['../abstract-child-editable-item-list/abstract-child-editable-item-list.component.scss'],
  // TODO: Document
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PosOrderItemListComponent extends ChildItemEditableListAbstractComponent<OrderItem> implements OnInit {
  parentApiRelName = 'object_id';

  // the parent itemsChange is for one item changed, itemsupdated is when you add or delete an item
  @Output() itemsUpdated = new EventEmitter();

  addItemLiteralI18n = 'ADD_PRODUCT';

  @Input() showFooter = true;
  @Input() showVat = false;
  // TODO: show only if there is some discount in the order
  @Input() showDiscounts = true;
  @Input() parentApiContentTypeName; // Accepted: order or bikeservicesheet

  orderItems$: Observable<OrderItem[]>;

  productsResultsCache: { [id: string]: Product } = {};

  fields = {
    name: {
      // tslint:disable-next-line:max-line-length
      metadata: new ChildItemFieldMetadata('PRODUCT_NAME', 'ADD_PRODUCT_NAME', ChildItemFieldTypes.text, '---', true, [Validators.required], false),
      mainInput: true,
      autoCompleteOptions: [],
      w: 40,
      align: 'start'
    },
    discountPercent: {
      metadata: new ChildItemFieldMetadata('DSC_PERCENT', 'ADD_DISCOUNT', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    vat: {
      metadata: new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.calculated, 0.21, false, null, false, true),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    // price with vat per unit
    price_with_vat_pu: {
      metadata: new ChildItemFieldMetadata('PRICE_PU', 'ADD_PRICE', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    qty: {
      metadata: new ChildItemFieldMetadata('QUANTITY', 'ADD_QUANTITY', ChildItemFieldTypes.integer, 1, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    price_with_vat_total: { // This price has also the quantity
      metadata: new ChildItemFieldMetadata('TOTAL', 'ADD_PRICE', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    more_opts: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.action, null, true, null, false),
      mainInput: false,
      w: 10,
      align: 'end'
    },
    price: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, false, null, false, true),
      mainInput: false
    },
    priceRecommended: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, false, null, false, true),
      mainInput: false
    },
    product: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.foreign_key, null, false, null, false, true),
      mainInput: false
    },
    cost: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, false, null, false, true),
      mainInput: false
    },
  };

  @ViewChild(MatTable, {static: true}) table: MatTable<OrderItem>;
  dataSource = new MatTableDataSource();

  defaultVat: number;

  expandedFields: { [key: string]: Set<string> } = {};

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    notificationService: CrmNotificationsService,
    protected posOrdersItemService: PosOrdersItemService,
    private posProductService: PosProductService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected moneyService: MoneyService,
    protected usersService: UsersService
  ) {
    super(dialog, translate, notificationService, posOrdersItemService, nativeInterfacesService, usersService);
  }

  ngOnInit(): void {
    this.defaultVat = Number(this.usersService.business.defaultVat);

    // tslint:disable-next-line:max-line-length
    this.fields.vat.metadata = new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.calculated, this.defaultVat, false, null, false, true);

    super.ngOnInit();

    // TODO: why is this not managed by the abstract class?
    this.orderItems$ = this.posOrdersItemService.getOrderItems(this.parent.id, this.parentApiContentTypeName);
    this.orderItems$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.items = x;
      this.dataSource.data = this.items;
      this.itemsUpdated.emit();
    });

    this.showVatFields(this.showVat);
  }

  showVatFields(show = true): void {
    this.fields.vat.metadata.showAsColumn = show;
    this.fields.vat.metadata.hidden = !show;
    this.fields.qty.w = show ? 8 : 15;
  }

  createNewItemInstance(): OrderItem {
    const item = new OrderItem();
    item.id = this._getID(item);
    item.vat = this.defaultVat;
    return item;
  }

  getTotalCostItem(orderItem: OrderItem): number {
    return OrderItem.totalCost(orderItem);
  }

  getTotalCost(): number {
    if (this.items != null) {
      return this.items.reduce((sum, current) => sum + (OrderItem.totalCost(current) || 0), 0);
    }
    return 0;
  }

  getInputTypeCalculated(field: string): string {
    if (field === 'price_with_vat_pu') {
      return 'simple_html';
    }
    if (field === 'price_with_vat_total') {
      return 'simple_html';
    }
    if (field === 'vat') {
      return 'percent';
    }
    if (field === 'discountPercent') {
      return 'percent';
    }
    throw Error(`getInputTypeCalculated field: ${field} not found`);
  }

  getCalculatedField(orderItem: OrderItem, field: string): any {
    if (field === 'price_with_vat_pu') {
      return this.moneyService.addVat(orderItem.price, orderItem.vat, 2);
    }
    if (field === 'price_with_vat_total') {
      return this.moneyService.addVat(orderItem.price, orderItem.vat, 2) * orderItem.qty;
    }
    if (field === 'vat') {
      return this.moneyService.basicPointsToPercent(orderItem.vat);
    }
    if (field === 'discountPercent') {
      return orderItem.discountPercent;
    }
    throw Error(`getCalculatedField field: ${field} not found`);
  }

  onEditStatusChange(s: boolean, orderItem = null, colDef = null): void {
    super.onEditStatusChange(s, colDef);

    // Make edits easier, if the field is still in default value, we put the input to empty, so you don't have to delete the default value to input a new one
    // TODO: maybe we can do this in the abstract class on all fields, and instead of optin make it optout
    // TODO: extent this to bss task fields
    if (s && colDef && colDef === 'qty') {
      const defaultQty = this.fields.qty.metadata.defaultValue;
      if (orderItem.qty === defaultQty) {
        orderItem.qty = '';
      }
    }
    if (!s && colDef && colDef === 'qty') {
      if (orderItem.qty === '') {
        orderItem.qty = this.fields.qty.metadata.defaultValue;
      }
    }
  }

  getCalculatedFieldDisplay(orderItem: OrderItem, field: string): any {
    if (field === 'price_with_vat_pu') {
      // return this.moneyService.addVat(orderItem.price, orderItem.vat, 2);
      const price = this.moneyService.addVat(orderItem.price, orderItem.vat, 2);
      let priceLiteral = price.toFixed(2);
      priceLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceLiteral, this.currencyCode, 'symbol-narrow');
      if (orderItem.priceRecommended && orderItem.priceRecommended !== 0 && orderItem.priceRecommended !== orderItem.price) {
        const priceRecommended = this.moneyService.addVat(orderItem.priceRecommended, orderItem.vat, 2);
        let priceRecommendedLiteral = priceRecommended.toFixed(2);
        priceRecommendedLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceRecommendedLiteral, this.currencyCode, 'symbol-narrow');
        return `<div style="display: flex; flex-direction: column"><small style="text-decoration: line-through">${priceRecommendedLiteral}</small><span style="margin-left: 2px">${priceLiteral}</span></div>`;
      }
      return `<span class="price">${priceLiteral}</span>`;
    }
    if (field === 'price_with_vat_total') {
      const price = this.moneyService.addVat(orderItem.price, orderItem.vat, 2) * orderItem.qty;
      let priceLiteral = price.toFixed(2);
      priceLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceLiteral, this.currencyCode, 'symbol-narrow');
      if (orderItem.priceRecommended && orderItem.priceRecommended !== 0 && orderItem.priceRecommended !== orderItem.price) {
        const priceRecommended = this.moneyService.addVat(orderItem.priceRecommended, orderItem.vat, 2) * orderItem.qty;
        let priceRecommendedLiteral = priceRecommended.toFixed(2);
        priceRecommendedLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceRecommendedLiteral, this.currencyCode, 'symbol-narrow');
        return `<div style="display: flex; flex-direction: column"><small style="text-decoration: line-through">${priceRecommendedLiteral}</small><span style="margin-left: 2px">${priceLiteral}</span></div>`;
      }
      return `<span class="price">${priceLiteral}</span>`;
    }
    return this.getCalculatedField(orderItem, field);
  }

  setCalculatedField(orderItem: OrderItem, modifiedText: string, field: string): void {
    modifiedText = this.cleanInputToString(modifiedText, true);
    const inputAsNumber = Number(modifiedText);
    if (field === 'price_with_vat_pu') {
      orderItem.price = this.moneyService.removeVat(inputAsNumber, orderItem.vat);
      orderItem.priceRecommended = orderItem.price;
      this.saveItem(orderItem);
      return;
    }
    if (field === 'price_with_vat_total') {
      orderItem.price = +(this.moneyService.removeVat(inputAsNumber, orderItem.vat) / orderItem.qty).toFixed(10);
      orderItem.priceRecommended = orderItem.price;
      this.saveItem(orderItem);
      return;
    }
    if (field === 'vat') {
      orderItem.vat = this.moneyService.percentToBasicPoints(inputAsNumber);
      this.saveItem(orderItem);
      return;
    }
    if (field === 'discountPercent') {
      if (!orderItem.priceRecommended || orderItem.priceRecommended === 0) {
        orderItem.priceRecommended = orderItem.price;
      }
      let finalPrice = orderItem.priceRecommended - (orderItem.priceRecommended * this.moneyService.percentToBasicPoints(inputAsNumber));
      finalPrice = +finalPrice.toFixed(10);
      orderItem.price = finalPrice;
      this.saveItem(orderItem);
      return;
    }
    throw Error(`setCalculatedField field: ${field} not found`);
  }

  increaseQtyCounted(orderItem: OrderItem): void {
    orderItem.qty++;
    this.posOrdersItemService.modify(orderItem.id, {qty: orderItem.qty}).subscribe();
  }

  addItemByCode(code: string): void {
    let getByCode$;

    for (const i of this.items) {
      if (i.productCode === code) {
        this.increaseQtyCounted(i);
        return;
      }
    }

    const progressDialogRef = this.dialog.open(ProgressDialogComponent, {});
    progressDialogRef.componentInstance.titleI18nKey = 'Searching product';
    progressDialogRef.componentInstance.messageI18nKey = '';
    progressDialogRef.componentInstance.onCancel.subscribe(result => {
      if (getByCode$ != null) {
        getByCode$.unsubscribe();
      }
    });

    getByCode$ = this.posProductService.getOrCreateByCode(code).subscribe(r => {
      progressDialogRef.close();
      const product = r.item;

      if (r.created) {
        // Product just created from Shared data base, give the client the opportunity to set a price or other things
        // TODO: auto adjust height and width to the content
        const dialogRef = this.dialog.open(PosProductsDetailComponent, {
          maxHeight: '90vh',
          minWidth: '60%',
          maxWidth: '90%',
          panelClass: 'no-padding-dialog-container',
          data: {
            itemId: product.id,
            extraMessageI18nKey: 'PRODUCT_JUST_ADDED_TO_THE_BUSINESS_DB_EDIT_BEFORE_ADDING_TO_THE_SALE',
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          const p: Product = result;
          this.addOrderItemFromProduct(p);
        });
      } else {
        if (product == null) {
          this.createNewProductAndAddIt(code);
        } else {
          this.addOrderItemFromProduct(product);
        }
      }
    }, error => {
      progressDialogRef.close();
      this.createNewProductAndAddIt(code);
    });
  }

  onBarCodeScanner(code: string): void {
    this.addItemByCode(code);
  }

  addByCodeDialog(): void {
    const dialogRef = this.dialog.open(SimpleTextDialogComponent, {});
    dialogRef.componentInstance.showBarCodeButton = true;
    dialogRef.componentInstance.hintI18N = 'CODE';
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 1) {
        this.addItemByCode(result);
      } else {
        this.notificationService.warning('EMPTY_CODE');
      }
    });
  }

  createNewProductAndAddIt(code?: string): void {
    const dialogRef = this.dialog.open(PosProductsDetailComponent, {
      maxHeight: '90vh',
      minWidth: '60%',
      maxWidth: '85%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'floating-selector',
        code
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const p: Product = result;
      this.addOrderItemFromProduct(p);
    });
  }

  searchExistingProductDialog(): void {
    const dialogRef = this.dialog.open(PosProductsListComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'floating-selector'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const p: Product = result;
      this.addOrderItemFromProduct(p);
    });
  }

  isKeyboardBarCodeScannerEnabled(): boolean {
    return !this.isEditing;
  }

  addOrderItemFromProduct(p: Product): void {
    if (p == null) {
      return;
    }
    const newOrderItem = OrderItem.createFromProduct(p);
    this.addItemEnd(newOrderItem);
  }

  onInputChange(event: string, orderItem: OrderItem, field: string): void {
    // console.log('onInputChange', event, field);
    if (orderItem.id.indexOf('draft_') !== 0) {
      return;
    }
    if (field === 'name') {
      this.productsResultsCache = {};
      let filters = {};
      if (this.usersService.userMe && this.usersService.userMe.workshop) {
        filters = {workshop_id: this.usersService.userMe.workshop};
      }
      this.posProductService.getPaginatedList(filters, event, false, 1, 5).subscribe(r => {
        // console.log('onInputChange', r);
        for (const i of r.results) {
          // @ts-ignore
          // tslint:disable-next-line
          this.productsResultsCache[i['id']] = i;
        }
        this.fields.name.autoCompleteOptions = r.results.map(x => {
          // tslint:disable-next-line
          return {id: x['id'], value: x['name']};
        });
      });
    }
  }

  onAutoCompleteOptionSelected(event: any, orderItem: OrderItem, field: string): void {
    if (field === 'name') {
      this.addOrderItemFromProduct(this.productsResultsCache[event]);
    }
    this.deleteItem(orderItem);
  }

}
