import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  ChildItemEditableListAbstractComponent,
  ChildItemFieldMetadata,
  ChildItemFieldTypes,
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {Validators} from '@angular/forms';
import {PosProductsListComponent} from '../../pages/pos-products-list/pos-products-list.component';
import {Product} from '../../models/pos_product';
import {ProgressDialogComponent} from '../dialogs/progress-dialog-component/progress-dialog.component';
import {PosProductsDetailComponent} from '../../pages/pos-products-detail/pos-products-detail.component';
import {SimpleTextDialogComponent} from '../dialogs/simple-text-dialog-component/simple-text-dialog.component';
import {PosProductService} from '../../services/pos-products.service';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {InventoryCountItem} from '../../models/inventory_count';
import {InventoryCountItemService} from '../../services/inventory-count-item.service';

@Component({
  selector: 'app-inventory-count-item-list',
  templateUrl: '../abstract-child-editable-item-list/abstract-child-editable-item-list.component.html',
  styleUrls: ['../abstract-child-editable-item-list/abstract-child-editable-item-list.component.scss'],
  // TODO: Document
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InventoryCountItemListComponent extends ChildItemEditableListAbstractComponent<InventoryCountItem> implements OnInit {
  parentApiRelName = 'inventoryCount';

  @Output() itemsUpdated = new EventEmitter();

  addItemLiteralI18n = 'ADD_PRODUCT';

  @Input() showFooter = true;
  @Input() parentApiContentTypeName; // Accepted: order or bikeservicesheet

  inventoryCountItems$: Observable<InventoryCountItem[]>;

  productsResultsCache = {};

  fields = {
    productName: {
      // tslint:disable-next-line:max-line-length
      metadata: new ChildItemFieldMetadata('PRODUCT_NAME', 'ADD_PRODUCT_NAME', ChildItemFieldTypes.text, '---', true, [Validators.required], false, false, true),
      mainInput: true,
      autoCompleteOptions: [],
      w: 40,
      align: 'start'
    },
    qtyCounted: {
      metadata: new ChildItemFieldMetadata('QUANTITY_COUNTED', 'ADD_QUANTITY', ChildItemFieldTypes.integer, 1, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    qtyExpected: {
      metadata: new ChildItemFieldMetadata('QUANTITY_EXPECTED', 'ADD_QUANTITY', ChildItemFieldTypes.integer, 1, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    product: {
      metadata: new ChildItemFieldMetadata('PRODUCT', '', ChildItemFieldTypes.text, '', false, null, false, true),
      mainInput: false,
      w: 30,
      align: 'start'
    }
  };

  @ViewChild(MatTable, {static: true}) table: MatTable<InventoryCountItem>;
  dataSource = new MatTableDataSource();

  defaultVat: number;

  expandedFields: { [key: string]: Set<string> } = {};

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    notificationService: CrmNotificationsService,
    protected inventoryCountItemService: InventoryCountItemService,
    private posProductService: PosProductService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected usersService: UsersService
  ) {
    super(dialog, translate, notificationService, inventoryCountItemService, nativeInterfacesService, usersService);
  }

  ngOnInit(): void {
    this.defaultVat = Number(this.usersService.business.defaultVat);

    super.ngOnInit();

    // TODO: why is this not managed by the abstract class?
    this.inventoryCountItems$ = this.inventoryCountItemService.getList({inventory_count: this.parent.id});
    this.inventoryCountItems$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.items = x;
      this.dataSource.data = this.items;
      this.itemsUpdated.emit();
    });

  }

  createNewItemInstance(): InventoryCountItem {
    const item = new InventoryCountItem();
    item.id = this._getID(item);
    return item;
  }

  getInputTypeCalculated(field: string): string {
    throw Error(`getInputTypeCalculated field: ${field} not found`);
  }

  getCalculatedField(inventoryCountItem: InventoryCountItem, field: string): any {
    throw Error(`getCalculatedField field: ${field} not found`);
  }

  getCalculatedFieldDisplay(inventoryCountItem: InventoryCountItem, field: string): any {
    return this.getCalculatedField(inventoryCountItem, field);
  }

  setCalculatedField(inventoryCountItem: InventoryCountItem, modifiedText: string, field: string): void {
    throw Error(`setCalculatedField field: ${field} not found`);
  }

  increaseQtyCounted(inventoryCountItem: InventoryCountItem): void {
    inventoryCountItem.qtyCounted++;
    this.inventoryCountItemService.modify(inventoryCountItem.id, {qtyCounted: inventoryCountItem.qtyCounted}).subscribe();
  }

  addItemByCode(code: string): void {
    let getByCode$;

    for (const i of this.items) {
      if (i.productCode === code) {
        this.increaseQtyCounted(i);
        return;
      }
    }

    const progressDialogRef = this.dialog.open(ProgressDialogComponent, {});
    progressDialogRef.componentInstance.titleI18nKey = 'Searching product';
    progressDialogRef.componentInstance.messageI18nKey = '';
    progressDialogRef.componentInstance.onCancel.subscribe(result => {
      if (getByCode$ != null) {
        getByCode$.unsubscribe();
      }
    });

    getByCode$ = this.posProductService.getOrCreateByCode(code).subscribe(r => {
      progressDialogRef.close();
      const product = r.item;

      if (r.created) {
        // Product just created from Shared data base, give the client the opportunity to set a price or other things
        // TODO: auto adjust height and width to the content
        const dialogRef = this.dialog.open(PosProductsDetailComponent, {
          maxHeight: '90vh',
          minWidth: '60%',
          maxWidth: '90%',
          panelClass: 'no-padding-dialog-container',
          data: {
            itemId: product.id,
            extraMessageI18nKey: 'PRODUCT_JUST_ADDED_TO_THE_BUSINESS_DB_EDIT_BEFORE_ADDING_TO_THE_SALE',
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          const p: Product = result;
          this.addInventoryCountItemFromProduct(p);
        });
      } else {
        if (product == null) {
          this.createNewProductAndAddIt(code);
        } else {
          this.addInventoryCountItemFromProduct(product);
        }
      }
    }, error => {
      progressDialogRef.close();
      this.createNewProductAndAddIt(code);
    });
  }

  onBarCodeScanner(code: string): void {
    this.addItemByCode(code);
  }

  addByCodeDialog(): void {
    const dialogRef = this.dialog.open(SimpleTextDialogComponent, {});
    dialogRef.componentInstance.showBarCodeButton = true;
    dialogRef.componentInstance.hintI18N = 'CODE';
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 1) {
        this.addItemByCode(result);
      } else {
        this.notificationService.warning('EMPTY_CODE');
      }
    });
  }

  createNewProductAndAddIt(code?: string): void {
    const dialogRef = this.dialog.open(PosProductsDetailComponent, {
      maxHeight: '90vh',
      minWidth: '60%',
      maxWidth: '85%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'floating-selector',
        code
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const p: Product = result;
      this.addInventoryCountItemFromProduct(p);
    });
  }

  searchExistingProductDialog(): void {
    const dialogRef = this.dialog.open(PosProductsListComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'floating-selector'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const p: Product = result;
      this.addInventoryCountItemFromProduct(p);
    });
  }

  isKeyboardBarCodeScannerEnabled(): boolean {
    return !this.isEditing;
  }

  addInventoryCountItemFromProduct(p: Product): void {
    if (p == null) {
      return;
    }
    const newInventoryCountItem = InventoryCountItem.createFromProduct(p);
    newInventoryCountItem.inventoryCount = this.parent;
    this.addItemEnd(newInventoryCountItem);
  }

  onInputChange(event: string, inventoryCountItem: InventoryCountItem, field: string): void {
    // console.log('onInputChange', event, field);
    if (inventoryCountItem.id.indexOf('draft_') !== 0) {
      return;
    }
    if (field === 'name') {
      this.productsResultsCache = {};
      let filters = {};
      if (this.usersService.userMe && this.usersService.userMe.workshop) {
        filters = {workshop_id: this.usersService.userMe.workshop};
      }
      this.posProductService.getPaginatedList(filters, event, false, 1, 5).subscribe(r => {
        // console.log('onInputChange', r);
        for (const i of r.results) {
          // @ts-ignore
          // tslint:disable-next-line
          this.productsResultsCache[i['id']] = i;
        }
        this.fields.productName.autoCompleteOptions = r.results.map(x => {
          // tslint:disable-next-line
          return {id: x['id'], value: x['name']};
        });
      });
    }
  }

  onAutoCompleteOptionSelected(event: any, inventoryCountItem: InventoryCountItem, field: string): void {
    if (field === 'name') {
      this.addInventoryCountItemFromProduct(this.productsResultsCache[event]);
    }
    this.deleteItem(inventoryCountItem);
  }

}
