<div fxFlex fxLayout="column" class="base-paddings" fxLayoutGap="20px">

  <div id="global_filters" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center" fxLayoutGap="10px">

    <mat-form-field fxFlex="95" fxFlex.gt-md="20" *ngIf="workshopsList.length > 1" appearance="outline">
      <mat-label>Workshop</mat-label>
      <mat-select placeholder="Workshop" [(ngModel)]="workshop">
        <mat-option value="all">All</mat-option>
        <mat-option *ngFor="let workshop of workshopsList" [value]="workshop.id">{{ workshop.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="95" fxFlex.gt-md="15" appearance="outline">
      <mat-label>{{ "CTX_STATS.DATE_TO_USE" | translate}}</mat-label>
      <mat-select placeholder="Date to use" [(ngModel)]="dateToFilter">
        <mat-option value="created_at">{{ "CTX_STATS.CREATION_DATE" | translate }}</mat-option>
        <mat-option value="payment_dt">{{ "CTX_STATS.PAYMENT_DATE" | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="95" fxFlex.gt-md="10" appearance="outline">
      <mat-label>{{ "CTX_STATS.AGGREGATE_BY" | translate }}</mat-label>
      <mat-select placeholder="Interval" [(ngModel)]="interval">
        <mat-option value="1mo">{{ "MONTHLY" | translate }}</mat-option>
        <mat-option value="1w">{{ "WEEKLY" | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="95" fxFlex.gt-md="15" appearance="outline">
      <mat-label>Period</mat-label>
      <mat-select placeholder="Workshop" [(ngModel)]="period" (selectionChange)="onPeriodChange()">
        <mat-option value="last12months">{{ "CTX_STATS.LAST_12_MONTHS" | translate }}</mat-option>
        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxFlex="100" fxLayout="row" fxLayoutAlign="center" *ngIf="dateToFilter == 'payment_dt'">
    <mat-icon>info</mat-icon>
    <!-- TODO: move this to server? the api should return a list of extra info? -->
    <span>{{ "CTX_STATS.DATE_TO_USE_PAYMENT_EXTRA_INFO" | translate }}</span>
  </div>

  <div fxFlex fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center">

    <app-statistics fxFlex="95" fxFlex.gt-md="45" dateToFilter="{{ dateToFilter }}" measurement="{{ incomeMeasurement }}" [series]="incomeSeries"
                    workshop="{{ workshop }}" interval="{{ interval }}" startDt="{{ startDt }}" endDt="{{ endDt }}"></app-statistics>

    <app-statistics fxFlex="95" fxFlex.gt-md="45" dateToFilter="{{ dateToFilter }}" measurement="{{ bssMeasurement }}" interval="{{ interval }}"
                    workshop="{{ workshop }}" startDt="{{ startDt }}" endDt="{{ endDt }}"></app-statistics>

  </div>
</div>
