import packageInfo from '../../package.json';

export const environment = {
  production: true,
  isBeta: false,
  brand: 'bikecrm',
  brandName: 'BikeCRM',
  version: packageInfo.version,
  apiUrl: 'https://api.bikecrm.app',
  publicApiUrl: 'https://connect.bikecrm.app',
  refreshRateUltraLow: 1 * 3600 * 1000,
  refreshRateLow: 5 * 60 * 1000,
  refreshRateNormal: 120 * 1000,
  refreshRateHigh: 10 * 1000,
  imageKitPubKey: 'public_yCULDEoEmivv8E8xpPj7xiAOdBE=',
  imageKitUrlEndpoint: 'https://ik.imagekit.io/kigresoft/s3media/',
  firebase: {
    apiKey: 'AIzaSyBV3wGZjB1xWD6zsQHO0XqSC30lTB3KBMc',
    // authDomain: 'bikecrm-2c21e.firebaseapp.com',
    // databaseURL: 'https://bikecrm-2c21e.firebaseio.com',
    projectId: 'bikecrm-2c21e',
    // storageBucket: 'bikecrm-2c21e.appspot.com',
    // messagingSenderId: '817548350655',
    appId: '1:817548350655:web:2e25ea31c5cce4f1e5620a'
    // measurementId: "G-P8PZLCJZTS
  }

};
