import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {TitleService} from 'src/app/services/title.service';
import {environment} from 'src/environments/environment';
import {AbstractParentItem} from '../../models/abstract_parent_item';
import {BikeCRMApiAbstract} from '../../services/bikecrm-api-base';
import {
  ChildItemEditableListAbstractComponent
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {AbstractChildItem} from '../../models/abstract_child_item';
import {animate, query, state, style, transition, trigger} from '@angular/animations';
import {Fab, IFab} from '../fab-custom/fab-interface';
import {BillableItem} from '../../models/billable_item';
import {PaymentStatusCardComponent} from '../payment-status-card/payment-status-card.component';
import {UsersService} from '../../services/users.service';
import {AppMetricsService} from '../../services/app-metrics.service';


/*
  * This class is used as a base for all the detail pages that you want also to show a list of
  *   items related to the parent item.
  *
  * TODO: rename class to something like abstract-master-detail, or abstract-detail-list
  * TODO: make sure there is nothing else specific on order/products (also on tempaltes)
 */

export interface IParentDetail<T> {
  parentItem: T;
  parentId?: string;
  navBase: string;

  titleI18N: string;
  titleIcon: string;
  deleteI18N: string;

  // Sidebar actions:
  actions: ParentItemAction[];

  childItemListRef: ChildItemEditableListAbstractComponent<AbstractChildItem<any>>;
  childListHost: any;

  // TODO: move to a common app interface
  isFully: boolean;

  childListRefComponentRef: ComponentRef<any>;

  configureKeyboardShortcuts(): void;

  loadChildListComponent(): void;
}

export class ParentItemAction {
  constructor(i18nKey: string, icon: string, id: string, enabled = true) {
    this.i18nKey = i18nKey;
    this.icon = icon;
    this.id = id;
    this.enabled = enabled;
  }

  i18nKey: string;
  icon: string;
  id: string;
  enabled: boolean;
}


@Component({
  template: '',
  encapsulation: ViewEncapsulation.None,
  // TODO: Document
  animations: [
    trigger('spinInOut', [
      state('in', style({transform: 'rotate(0)', opacity: '1'})),
      transition(':enter', [
        style({transform: 'rotate(-180deg)', opacity: '0'}),
        animate('150ms ease')
      ]),
      transition(':leave', [
        animate('150ms ease', style({transform: 'rotate(180deg)', opacity: '0'}))
      ]),
    ]),
    trigger('preventInitialAnimation', [
      transition(':enter', [
        query(':enter', [], {optional: true})
      ]),
    ]),
  ],
})
export abstract class AbstractParentDetailComponent<T extends AbstractParentItem>
  implements IParentDetail<T>, IFab, OnInit, AfterViewInit, AfterContentChecked, OnDestroy {

  @ViewChild('paymentCard') paymentCard: PaymentStatusCardComponent;

  env = environment;

  parentItem$: Observable<T>;

  deleteI18N = 'DELETE_ITEM';

  readyToAddChildItems = true;

  showSharePrintButtons = false;

  parentItem: T;

  abstract titleI18N: string;
  abstract titleIcon: string;

  abstract primaryFab: Fab;
  abstract secondaryFab: Fab;

  abstract actions: ParentItemAction[];

  parentId?: string;

  // FormData to send when creating a new parent item
  initialCreateFormData = new FormData();

  // tslint:disable-next-line:variable-name
  _childListLoaded = false;

  // TODO: use fully kiosk js apis, and move to a NativeInterfacesService or similar
  isFully = false;
  childListRefComponentRef: ComponentRef<any>;

  protected onDestroy$: Subject<void> = new Subject<void>();

  abstract navBase;
  abstract childItemListRef;
  abstract childListHost;

  documentTypes = {};

  currencyCode: string;

  onFabAction(actionId: string): void {
    console.log('If you have fab actions defined, you should override this method.');
  }

  // Sidebar actions:
  onAction(actionId: string): void {
    console.log('If you have actions defined, you should override this method.');
  }

  configureKeyboardShortcuts(): void {
  }

  abstract loadChildListComponent(): void;

  constructor(
    protected titleService: TitleService,
    protected translate: TranslateService,
    protected notificationService: CrmNotificationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected router: Router,
    protected dialog: MatDialog,
    protected progressDialog: MatDialog,
    protected route: ActivatedRoute,
    protected parentItemService: BikeCRMApiAbstract,
    protected usersService: UsersService,
    public appMetricsService: AppMetricsService
  ) {
    this.isFully = nativeInterfacesService.inFully;
  }

  ngAfterContentChecked(): void {
    if (!this._childListLoaded && this.childListHost !== undefined && this.parentItem !== null) {
      this.loadChildListComponent();
      this._childListLoaded = true;
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.parentId = this.route.snapshot.paramMap.get('id');
    if (this.parentId) {
      this.parentItem$ = this.parentItemService.get(this.parentId);
      this.parentItem$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
        this.parentItem = x;
        this.onParentItemChange();
      });
    } else {
      this.createNewParentItem();
    }
    this.configureKeyboardShortcuts();
    this.currencyCode = this.usersService.business.currency.toUpperCase();
  }

  onParentItemChange(): void {
    this.updateDocumentTypes();
  }

  updateDocumentTypes(): void {
  }

  showDocumentTypeChannelMenu(channel: string): boolean {
    // TODO: search a better way to do this, is this called often? is this performance critical?
    // TODO: check type by type
    if (channel === 'more') {
      return true;
    }
    const key = channel + '_enabled';
    for (const type of Object.keys(this.documentTypes)) {
      if (this.documentTypes[type][key]) {
        return true;
      }
    }
    return false;
  }

  public ngOnDestroy(): void {
    if (this.childListRefComponentRef) {
      this.childListRefComponentRef.destroy();
    }
    this.onDestroy$.next();
  }

  createNewParentItem(): void {
    const formData = this.initialCreateFormData;

    this.parentItemService.create(formData).pipe(takeUntil(this.onDestroy$)).subscribe(
      x => this.router.navigate([`${this.navBase}${x.id}`], {replaceUrl: true})
    );
  }

  saveParentItem$(): Observable<T> {
    return this.parentItemService.modify(this.parentItem.id, this.parentItem).pipe(takeUntil(this.onDestroy$));
  }

  saveParentItem(): void {
    this.saveParentItem$().subscribe(
      x => {
        this.parentItem = x;
        this.onParentItemChange();
      }
    );
  }

  isParentBillableItem(): boolean {
    return BillableItem.isBillableItem(this.parentItem);
  }

  sendDocumentWhatsapp(document: string): void {
    throw new Error('Method sendDocumentWhatsapp not implemented.');
  }

  async openWhatsApp(phone: string, text: string = null): Promise<void> {
    // TODO: move to a service? and dedup with abstract item detail and service sheet detail
    // TODO: we can also send a link to the bss to the client,
    //      but right now the server won't return a visible page if the bss is not finished
    let p = phone;

    // https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=en
    // https://stackoverflow.com/questions/21935149/sharing-link-on-whatsapp-from-mobile-website-not-application-for-android
    p = p.split('-').join('');
    p = p.split('+').join('');
    p = p.split('(').join('');
    p = p.split(')').join('');
    p = p.replace(/^0+/, '');

    // TODO: improve this logic, what happens with other countries? and if the phone starts with 34 but it still has no country code on it
    // TODO: this should come from the server, with a default prefix for the workshop in all the phones
    // TODO: remove
    // if (p.slice(0, 2) !== '34') {
    //   p = `34${p}`;
    // }

    // const url = `https://wa.me/${p}`;
    if (!text) {
      const literal = await this.translate.get('RENTAL').toPromise();
      text = `${literal}: ${this.parentItem.shortPrettyId}`;
    }
    const url = `https://api.whatsapp.com/send?text=${text}&phone=${p}`;
    // if (this.whatsAppProbablyInstalled()) {
    //   url = `https://web.whatsapp.com/send?text=${text}&phone=${p}`;
    // }
    // window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    // console.log(url);
    window.open(url, '_blank');
  }

  trackNewSendPrintButtonsUsed(): void {
    // TODO: don't hardcode string, this is called also when its not actually a print
    this.appMetricsService.sendEvent(this.appMetricsService.EVENT_NAMES.BSS_CONTROLS, 'new-buttons-print-send');
  }

  deleteItem(): void {
    throw new Error('Method deleteItem not implemented.');
  }

}
