import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {InventoryCountService} from '../../services/inventory-count.service';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {InventoryCount} from '../../models/inventory_count';


@Component({
  selector: 'app-inventory-count-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class InventoryCountListComponent extends ItemSimpleFiltrableListAbstractComponent<InventoryCount>
  implements OnInit, OnDestroy, AfterViewInit {

  pageTitleI18N = 'INVENTORY_COUNTS';

  urlBaseDetailItem = 'inventory_counts';
  primaryFab = new Fab('NEW_INVENTORY_COUNT', 'add', 'fab_new_inventory_count', FabTypes.singleAction);

  displayedColumnsMetaData = {
    name: new DisplayedColumnMetaData('NAME', DisplayedColumnTypes.text),
    createdAt: new DisplayedColumnMetaData('DATE', DisplayedColumnTypes.date),
    mode: new DisplayedColumnMetaData('TYPE', DisplayedColumnTypes.text),
    reconciled: new DisplayedColumnMetaData('RECONCILED', DisplayedColumnTypes.calculated),
    shrinkage: new DisplayedColumnMetaData('SHRINKAGE', DisplayedColumnTypes.currency),
  };

  filtersMetaData = {};

  secondaryFab = null;

  constructor(
    @Optional() public dialogRef: MatDialogRef<InventoryCountListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    protected userService: UsersService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected inventoryCountService: InventoryCountService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      inventoryCountService,
      nativeInterfacesService,
      dialog,
      media$,
      userService,
      privacyModeService
    );

  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  getCalculatedField(item: InventoryCount, field: string): any {
    if (field === 'reconciled') {
      return item.reconciled ? 'YES' : 'NO';
    }
    return super.getCalculatedField(item, field);
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'fab_new_inventory_count') {
      this.router.navigate(['/inventory_counts/create']);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

}
